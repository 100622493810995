import type { ChangeEvent, CSSProperties, MouseEvent } from 'react';
import styled from 'styled-components';
import { FormRow, type IGeneralFormInputProps } from '.';
import { COLOR_BLUM, COLOR_WHITE_WALKER } from '../../styles/variables';
import type { IDropdownOption } from './AutocompleteDropdown';
import { FormLabel } from './FormLabel';

const Label = styled.label`
    background-color: transparent;
    border-radius: 4px;
    color: ${COLOR_BLUM};
    cursor: pointer;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    letter-spacing: 0.125px;
    padding: 0 20px;
    min-width: 75px;
    text-align: center;
`;

const Input = styled.input.attrs(() => ({
    type: 'radio',
}))`
    height: 1px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 0px;

    &:checked + label {
        background-color: ${COLOR_BLUM};
        color: ${COLOR_WHITE_WALKER};
    }
    &:disabled + label {
        cursor: default;
        opacity: 0.3;
    }
`;

const Options = styled.div`
    display: flex;
    flex-wrap: wrap;

    & div ~ div {
        margin-left: 5px;
    }

    margin-top: ${(props: { $onTwoLines?: boolean }) => (props.$onTwoLines ? '15px' : 0)};
`;

interface IRadioButtonProps {
    color?: string;
    disabled?: boolean;
    checked: boolean;
    label: string;
    id: string;
    name: string;
    value?: number | string;
    onChange: (val: string, name: string) => void;
    style?: CSSProperties;
}

const RadioButton = (props: IRadioButtonProps) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => {
        props.onChange(e.currentTarget.value, props.name);
    };

    return (
        <div>
            <Input
                id={props.id}
                disabled={props.disabled}
                name={props.name}
                onChange={handleChange}
                checked={props.checked}
                value={props.value}
            />
            <Label htmlFor={props.id} style={props.style}>
                {props.label}
            </Label>
        </div>
    );
};

interface IFormRadioProps extends IGeneralFormInputProps {
    onChange: (val: string, name: string) => void;
    onTwoLines?: boolean;
    options: IDropdownOption[];
    value?: string;
}

export const FormRadio = (props: IFormRadioProps) => {
    return (
        <FormRow
            style={{
                alignItems: props.onTwoLines ? 'unset' : 'center',
                flexDirection: props.onTwoLines ? 'column' : 'row',
                justifyContent: 'space-between',
            }}
        >
            <FormLabel
                error={props.error}
                disabled={props.disabled}
                helpLink={props.helpLink}
                helpTexts={props.helpTexts}
                htmlFor={props.name}
                value={props.value}
                warning={props.warning}
            >
                {props.label}
            </FormLabel>

            <Options $onTwoLines={props.onTwoLines}>
                {props.options.map((opt: any) => {
                    return (
                        <RadioButton
                            key={opt.value}
                            id={`option-${opt.value}`}
                            disabled={props.disabled || opt.disabled}
                            label={opt.label}
                            name={props.name}
                            onChange={props.onChange}
                            checked={props.value === opt.value}
                            value={opt.value}
                        />
                    );
                })}
            </Options>
        </FormRow>
    );
};
