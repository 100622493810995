export default function () {
    const now = new Date();
    const newVAT = new Date(2024, 7, 14); // 14 Aug 2024 will show new VAT rate
    const removeOldVAT = new Date(2024, 8, 1); // 1 Sep 2025 will remove old VAT rate

    const rates = [0, 10];

    if (now < removeOldVAT) {
        rates.push(24);
    }

    if (now >= newVAT) {
        rates.push(25.5);
    }

    return rates;
}
