import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { RouterProvider } from 'react-router-dom';
import router from 'routes/Router';
import { enGB, fi } from 'date-fns/locale';

const localeMap = {
    en: enGB,
    fi,
};

type AppProps = {
    language: string;
};

const App: React.FC<AppProps> = (props: AppProps) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[props.language as keyof typeof localeMap]}
        >
            <RouterProvider router={router} />
        </LocalizationProvider>
    );
};

export default App;
