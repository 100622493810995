import type { ReactNode } from 'react';
import { TwoColumnView } from 'components/layout/TwoColumnView';
import InvoiceList from './InvoiceList';

interface IDashboardProps {
    children: ReactNode;
}

const FrontPage = (props: IDashboardProps) => {
    return (
        <TwoColumnView>
            <div>
                <InvoiceList />
            </div>
            <div className="hide-scroll">{props.children}</div>
        </TwoColumnView>
    );
};

export default FrontPage;
