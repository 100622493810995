import { useEffect } from 'react';
import type { IRootState } from 'reducers';
import { Cookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ConfirmAction from './ConfirmAction';
import EezyPayUserLoader from 'containers/eezypay/EezyPayUserLoader';
import GlobalStyles from '../styles/global';
import ErrorView from 'containers/error/Error';
import PageTitle from 'components/routes/PageTitle';
import AlertAction from './AlertAction';
import { dispatch } from 'utils/store';
import { loginSuccess } from 'actions/auth';
import onLogoutWithholdingTax from 'queries/fetchGetWithholdingTax/callback.onLogout';

const Root = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const error = useSelector((state: IRootState) => state.auth.error);

    const handleLogoutEvent = (event: any) => {
        switch (event.key) {
            case 'logout-event':
                onLogoutWithholdingTax();
                navigate('/logout');
                break;
            case 'login-time':
                dispatch(loginSuccess());
                if (location.pathname === '/login') {
                    navigate('/', { replace: true });
                } else {
                    window.location.reload();
                }
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('storage', handleLogoutEvent);
        // UUID cookie
        const cookie = new Cookies();
        const uuid = cookie.get('uuid');
        if (!uuid) {
            cookie.set('uuid', uuidv4(), {
                expires: new Date(2050, 1, 1),
                path: '/',
                secure: true,
            });
        }

        return () => {
            window.removeEventListener('storage', handleLogoutEvent);
        };
    }, []);

    return (
        <>
            <ErrorView wholePage />
            <GlobalStyles />
            <PageTitle />
            {error && error.place === 'wholePage' ? null : (
                <>
                    <EezyPayUserLoader />
                    <Outlet />
                    <AlertAction />
                    <ConfirmAction />
                </>
            )}
        </>
    );
};

export default Root;
