import styled from 'styled-components';
import type { IClient } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Label, LabelSmall } from '../../components/textElements';
import { trans } from '../../utils';

interface IInvoiceRecipientProps {
    handleChangeRecipient: () => void;
    handleEditRecipient: () => void;
    recipient: IClient;
}

const P = styled.p`
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Section = styled.div`
    margin-top: 20px;
`;

export const InvoiceRecipientDetailsPanel = (props: IInvoiceRecipientProps) => {
    const { recipient } = props;

    return (
        <div>
            <Flex spread>
                <Label data-mf-replace="**REMOVED**" color="black">
                    {props.recipient.name}
                </Label>
                <EezyButton
                    color="black"
                    style={{ marginLeft: '20px' }}
                    onClick={props.handleChangeRecipient}
                >
                    {trans('invoice.form.recipient.change-recipient')}
                </EezyButton>
            </Flex>
            <Section data-mf-replace="**REMOVED**">
                {recipient.address?.street1 && <P>{recipient.address?.street1}</P>}
                {recipient.address?.street2 && <P>{recipient.address?.street2}</P>}
                {(recipient.address?.zipCode || recipient.address?.town) && (
                    <P>
                        {recipient.address?.zipCode} {recipient.address?.town}
                    </P>
                )}
                {recipient.address?.country && <P>{trans(`countries:${recipient.address?.country}`)}</P>}
            </Section>
            <Section>
                <LabelSmall color="black">{trans('invoice.form.recipient.invoicingMethod.label')}</LabelSmall>
                <P data-mf-replace="**REMOVED**">
                    {recipient.invoicingMethod === 'einvoice'
                        ? recipient.einvoiceOvt
                        : recipient.invoicingMethod === 'email'
                          ? recipient.email
                          : trans(`invoice.form.recipient.invoicingMethod.${recipient.invoicingMethod}`)}
                </P>
            </Section>
            <Section>
                <EezyButton color="black" dark onClick={props.handleEditRecipient}>
                    {trans('general.edit')}
                </EezyButton>
            </Section>
        </div>
    );
};
