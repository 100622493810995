import { gql } from '@apollo/client';

const CHANGE_PASSWORD = gql`
    mutation ChangePassword(
        $currentPassword: String!
        $newPassword: String!
        $newPasswordConfirm: String!
    ) {
        changePassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
            newPasswordConfirm: $newPasswordConfirm
        )
    }
`;

export default CHANGE_PASSWORD;
