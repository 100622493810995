import { validator as v } from '../validation';

export const messageValidator = v.object({
    content: v.string().min(1).required(),
    subject: v.when('isNew', {
        is: true,
        then: v.string().min(1).required(),
        // tslint:disable-next-line: object-literal-sort-keys
        otherwise: v.optional(),
    }),
});
