import { type FormEvent, useState } from 'react';
import type { IInvoice, IInvoiceKeys } from '../../../../shared/src/types/invoice';
import { TextArea } from '../../components/form';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { trans } from '../../utils';

interface IProps {
    handleInvoiceUpdate: (changedProperty: IInvoiceKeys) => void;
    invoice: IInvoice;
}

const InvoiceDescriptionPanel = (props: IProps) => {
    const { invoice } = props;
    const [descriptionChanged, setDescriptionChanged] = useState(false);
    const [description, setDescription] = useState(invoice?.description);
    return (
        <Tools header={<ToolsHeader titleTransKey="invoice.description.title" />}>
            <TextArea
                data-mf-replace="**REMOVED**"
                disabled={!invoice?.id}
                id="invoice-description"
                onBlur={(e: FormEvent<HTMLTextAreaElement>) => {
                    if (descriptionChanged) {
                        props.handleInvoiceUpdate({
                            description: e.currentTarget.value,
                        });
                        setDescriptionChanged(false);
                    }
                }}
                onChange={(e: any) => {
                    setDescription(e.currentTarget.value);
                    setDescriptionChanged(true);
                }}
                style={{
                    lineHeight: '20px',
                    maxHeight: '100px',
                    overflow: 'auto',
                    padding: '8px 0',
                }}
                value={description || ''}
                placeholder={trans('invoice.otherFields.fieldPlaceholder')}
                $variant="form"
            />
        </Tools>
    );
};

export default InvoiceDescriptionPanel;
