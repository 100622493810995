import { gql } from '@apollo/client';

// Note! UPDATE_INVOICE_WITH_RECIPIENT fetches also recipient fields

const UPDATE_INVOICE = gql`
    mutation UpdateInvoice(
        $id: Int!
        $clientOrderNumber: String
        $clientIdSiteNumber: String
        $clientSiteNumber: String
        $contactPersonId: Int
        $debtCollection: Boolean
        $description: String
        $dueDate: String
        $isGroupInvoice: Boolean
        $occupation: Int
        $paymentTerm: Int
        $template: InvoiceTemplate
        $fillType: FillType
        $dispatchDate: String
    ) {
        updateInvoice(
            invoice: {
                id: $id
                clientIdSiteNumber: $clientIdSiteNumber
                clientOrderNumber: $clientOrderNumber
                clientSiteNumber: $clientSiteNumber
                contactPersonId: $contactPersonId
                debtCollection: $debtCollection
                description: $description
                dispatchDate: $dispatchDate
                dueDate: $dueDate
                isGroupInvoice: $isGroupInvoice
                occupation: $occupation
                paymentTerm: $paymentTerm
                template: $template
                fillType: $fillType
            }
        ) {
            clientOrderNumber
            clientIdSiteNumber
            clientSiteNumber
            contactPersonId
            contactPersonName
            creditRequested
            debtCollection
            description
            dispatchDate
            dueDate
            fillType
            id
            invoiceItems {
                description
                endDate
                id
                itemType
                price
                quantity
                quantityUnit
                startDate
                totalPrice
                totalPriceWithVat
                vat
            }
            isGroupInvoice
            occupation
            paymentTerm
            sendingFailed
            status
            template
            total
            totalVats {
                percentage
                total
            }
            totalWithVat
        }
    }
`;

export default UPDATE_INVOICE;
