import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { COLOR_BLUM, COLOR_GREYHOUND, COLOR_IMPORTANT, COLOR_WHITE_WALKER } from 'styles/variables';
import { formatCents } from 'utils';
import { getStatus } from 'utils/invoice/invoiceLogic';
import { EezyButton } from '../Buttons';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { IconBackground } from '../IconBackground';
import { ListItem } from '../layout/List';
import { Bold, Span } from '../textElements';

const EezyExpressSign = () => {
    const { t } = useTranslation();
    return (
        <IconBackground style={{ backgroundColor: COLOR_BLUM }} title={t('invoice.express') || ''}>
            <Icon icon={['fas', 'euro-sign']} color={COLOR_WHITE_WALKER} />
        </IconBackground>
    );
};

const ReclaimedSign = () => {
    const { t } = useTranslation();
    return (
        <IconBackground style={{ backgroundColor: COLOR_BLUM }} title={t('invoice.statuses.reclaimed') || ''}>
            <Icon icon={['fal', 'exclamation-triangle']} color={COLOR_WHITE_WALKER} />
        </IconBackground>
    );
};

const ExclamationSign = () => {
    return (
        <IconBackground style={{ backgroundColor: COLOR_BLUM }}>
            <Icon icon={['fas', 'exclamation']} color={COLOR_WHITE_WALKER} />
        </IconBackground>
    );
};

const TriangleExclamationSign = () => {
    return (
        <IconBackground large style={{ backgroundColor: COLOR_WHITE_WALKER, width: 4, height: 5 }}>
            <Icon icon={['fas', 'exclamation-triangle']} color={COLOR_IMPORTANT} size="lg" />
        </IconBackground>
    );
};

const CreditRequestedSign = () => {
    const { t } = useTranslation();
    return (
        <IconBackground style={{ backgroundColor: COLOR_BLUM }} title={t('invoice.credit.requested') || ''}>
            <Icon icon={['fas', 'question']} color={COLOR_WHITE_WALKER} />
        </IconBackground>
    );
};

export interface IListItemProps {
    invoiceBrief: IBriefInvoice;
    selected?: boolean;
}

const InvoiceListItem = (props: IListItemProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { invoiceBrief } = props;
    const status = getStatus(invoiceBrief);
    const clientName = invoiceBrief.name;

    const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        navigate('/invoices');
    };

    return (
        <ListItem color={COLOR_BLUM} iconColor={status?.iconColor}>
            <div className="item-content">
                <div className="icon-wrapper" title={status?.title}>
                    {status && (
                        <>
                            <Icon
                                aria-hidden={false}
                                aria-label={status?.title}
                                icon={status?.icon}
                                color={COLOR_WHITE_WALKER}
                                className="small"
                            />

                            {status.creditRequested ? (
                                <CreditRequestedSign />
                            ) : status?.reclaimed ? (
                                <ReclaimedSign />
                            ) : status.eezyExpress ? (
                                <EezyExpressSign />
                            ) : status.dueDateToday ? (
                                <TriangleExclamationSign />
                            ) : status?.exclamation ? (
                                <ExclamationSign />
                            ) : null}
                        </>
                    )}
                </div>
                <p title={clientName} style={status?.style || {}}>
                    <span data-mf-replace="**REMOVED**">{clientName}</span>
                </p>
                {props.selected ? (
                    <div>
                        <EezyButton color="purple" dark onClick={handleClose} width={90}>
                            {t('menu.close')}
                        </EezyButton>
                    </div>
                ) : !props.invoiceBrief.isGroupInvoice ? (
                    <Flex column>
                        <div>
                            <Bold color={status?.bigNumber?.color || COLOR_BLUM} style={status?.style || {}}>
                                {formatCents(status?.bigNumber?.amount, true)}
                            </Bold>
                            <Span color={COLOR_GREYHOUND}>{t('form.eurs', { eurs: '' })}</Span>
                        </div>
                        {status?.smallNumber && (
                            <div>
                                <Bold
                                    color={status.smallNumber.color || COLOR_BLUM}
                                    style={status?.style || {}}
                                >
                                    {formatCents(status.smallNumber.amount, true)}
                                </Bold>
                                <Span color={COLOR_GREYHOUND}>{t('form.eurs', { eurs: '' })}</Span>
                            </div>
                        )}
                    </Flex>
                ) : null}
            </div>
        </ListItem>
    );
};

export default memo(InvoiceListItem);
