import { useQuery } from '@apollo/client';
import type { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';
import type { IReceiptCost, ITravel } from '../../../../shared/src/types/costs';
import { customErrorWithData } from 'actions/auth';
import { getInitialCost, getReceiptCost, getTravel, INITIAL_TRAVEL } from 'utils/costs/costLogic';
import { ERROR_NO_COST } from 'utils/error';
import { isCostInvoiceEditable, shouldCostInvoiceBeReturnedBeforeEditing } from 'utils/invoice/invoiceLogic';
import { GET_INVOICE } from '../invoice/queries';
import CostsModal from './CostsModal';

interface IInvoiceCostsProps {
    type: 'cost' | 'travel';
    children: ReactNode;
}

const InvoiceCosts: FC<IInvoiceCostsProps> = ({ type, children }) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const noCostError = (invoiceId?: any) => {
        dispatch(
            customErrorWithData({
                data: { invoiceId },
                errorType: ERROR_NO_COST,
            }),
        );
    };

    const params = useParams();
    const { invoiceId: iid, costId: cid } = params;
    const isGroupInvoice = window.location.href.includes('group');
    const location = useLocation();
    const routeState: any = location.state;

    const invoiceId = iid ? Number.parseInt(iid, 10) : '';
    const costId = !cid || cid === 'new' ? undefined : Number.parseInt(cid, 10);

    const { data: invoiceData } = useQuery(GET_INVOICE, {
        variables: { id: invoiceId, isGroupInvoice },
    });

    if (!invoiceData) {
        // invoice.tsx takes care of error
        return <>{children}</>;
    }

    let currentCost: IReceiptCost | undefined;
    let currentTravel: ITravel | undefined;

    const withoutVAT =
        !isGroupInvoice &&
        invoiceData!.invoice.template === 'domestic' &&
        invoiceData!.invoice.totalWithVat === invoiceData!.invoice.total;

    if (!costId) {
        currentTravel = INITIAL_TRAVEL;
        currentCost = getInitialCost(withoutVAT);
    } else if (type === 'cost') {
        currentCost = getReceiptCost(costId, invoiceData.invoice);
    } else if (type === 'travel') {
        currentTravel = getTravel(costId, invoiceData.invoice);
    }

    if (
        (currentCost === undefined && currentTravel === undefined) ||
        !isCostInvoiceEditable(invoiceData.invoice) ||
        shouldCostInvoiceBeReturnedBeforeEditing(invoiceData?.invoice)
    ) {
        noCostError(invoiceId);
    }

    return (
        <>
            {children}

            <CostsModal
                costId={costId}
                initialStep={routeState?.step}
                invoice={invoiceData.invoice}
                isGroupInvoice={isGroupInvoice}
                receiptCost={currentCost}
                travel={currentTravel}
            />
        </>
    );
};

export default InvoiceCosts;
