import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IContainerMobileScrollTop {
    container: HTMLElement;
    topOffset?: number;
}

const ContainerMobileScrollTop = (props: IContainerMobileScrollTop) => {
    const buttonHeight = 48; // necessary to calculate button position on screen

    const [visible, setVisible] = useState(false);
    const [positionY, setPositionY] = useState(0);

    const { t } = useTranslation();

    const scrollToTop = () => {
        const pageScrollY = window.document.documentElement.scrollTop;
        const y = props.container.getBoundingClientRect().y + pageScrollY - (props.topOffset ?? 0);
        window.scrollTo({ top: y, left: 0, behavior: 'smooth' });
    };

    const checkCanScroll = () => {
        const rect = props.container.getBoundingClientRect();
        const offset = 100;
        const y = rect.y + offset;

        const bottom = Math.min(rect.bottom - 60, window.innerHeight - buttonHeight) - 24; // The button should be at the bottom of the list but not outside
        setPositionY(bottom);

        setVisible(y <= 0);
    };

    useEffect(() => {
        checkCanScroll();

        window.addEventListener('scroll', checkCanScroll);

        return () => {
            window.removeEventListener('scroll', checkCanScroll);
        };
    }, []);

    return (
        <div
            className={['xl:hidden fixed right-8', visible ? undefined : 'hidden'].join(' ')}
            style={{ top: `${positionY}px` }}
        >
            <EezyButton
                className="v2-btn"
                color="purple"
                hasIcon={true}
                iconAlignment="right"
                style={{ height: `${buttonHeight}px` }}
                onClick={scrollToTop}
            >
                {t('utilities.scroll-back-top')}
                <Icon icon={['far', 'arrow-up']} />
            </EezyButton>
        </div>
    );
};

export default ContainerMobileScrollTop;
