import Joi, { type ObjectSchema, type ValidationResult } from '@hapi/joi';

export const validator = Joi.defaults((schema: any) => {
    switch (schema.type) {
        case 'object':
            return (schema as ObjectSchema)
                .unknown(true) // Does not care if object has extra keys
                .options({ abortEarly: false }); // Lists all errors
        default:
            return schema;
    }
});

/**
 * Return undefined if there was no errors,
 * otherwise formatted error descriptions
 */
export const formatValidationResult = (
    valRes: ValidationResult,
): { [key: string]: { type: string; path: string[] } } | undefined => {
    if (!valRes?.error) {
        return undefined;
    }

    const errors = valRes.error.details.map((o: any) => {
        return { [o.context.label]: { type: o.type, path: o.path } };
    });

    const validationErrors = errors.reduce((obj, item) => ({ ...obj, ...item }), {});
    return validationErrors;
};

export const EMAIL_REGEX = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/;
export const MULTIPLE_EMAIL_REGEX = /^([\w-.+]+@([\w-]+\.)+[\w-]{2,},?)+$/;
