import { gql } from '@apollo/client';

const GET_USER_EVENTS_LIST = gql`
    query UserEventsList($name: String, $category: String, $limit: Int, $lastEventFlag: Boolean) {
        userEventsList(
            name: $name,
            category: $category,
            limit: $limit,
            lastEventFlag: $lastEventFlag
        ) {
            id,
            name,
            category,
            additionalInfo,
            created,
            updated
        }
    }
`;

export default GET_USER_EVENTS_LIST;
