import type { CSSProperties } from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';
import { COLOR_BLUM, SCREEN_M } from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IBackButtonWithTitle {
    title?: string;
    style?: CSSProperties;
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 0 25px 0;

    @media (min-width: ${SCREEN_M}px) {
        padding-top: 25px;
    }
`;

const BackButton = styled.div<{ hasTitle: boolean }>`
    cursor: pointer;
    margin-right: auto;
    display: ${(props) => (props.hasTitle ? 'block' : 'flex')};
`;

const Title = styled.div`
    flex: 1;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    color: ${COLOR_BLUM};
`;

const Label = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: ${COLOR_BLUM};
    margin-left: 5px;
`;

const BackButtonWithTitle = (props: IBackButtonWithTitle) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Container style={props.style}>
            <BackButton
                hasTitle={!!props.title}
                onClick={() => {
                    navigate('/'); // Navigate to dashboard
                }}
            >
                <Icon className="large mr-2" icon={['far', 'arrow-left']} color={COLOR_BLUM} />
                {!props.title && <Label>{t('eezyPay:back')}</Label>}
            </BackButton>
            <Title>{props.title}</Title>
        </Container>
    );
};

export default BackButtonWithTitle;
