import styled from 'styled-components';
import {
    BORDER_RADIUS,
    COLOR_SALES_STATS_CARD,
    COLOR_SALES_STATS_CARD_ICON,
    COLOR_SALES_STATS_CARD_DESC,
    COLOR_SALES_STATS_CARD_AMOUNT,
    SCREEN_M,
    SCREEN_XS,
    SCREEN_S,
} from '../../styles/variables';
import { Icon } from 'components/Icon';
import { formatCents } from 'utils';

interface ISalesStatsCard {
    amount: number;
    description?: string;
    className?: string;
}

const SalesStatsCardWrapper = styled.div`
    background: ${COLOR_SALES_STATS_CARD};
    border-radius: ${BORDER_RADIUS};
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 8px;

    @media (min-width: ${SCREEN_S}px) {
        width: calc(50% - 6px);
    }

    @media (min-width: ${SCREEN_XS}px) and (max-width: ${SCREEN_S - 1}px) {
        width: calc(50% - 4px);
    }

    @media (max-width: ${SCREEN_XS - 1}px) {
        width: 100%;
    }
`;

const SalesStatsCardTitle = styled.h3`
    font-size: 24px;
    line-height: 150%;
    font-weight: 600;
    color: ${COLOR_SALES_STATS_CARD_AMOUNT};

    @media (min-width: ${SCREEN_M}px) {
        font-size: 28px;
    }
`;

const SalesStatsCard = (props: ISalesStatsCard) => {
    return (
        <SalesStatsCardWrapper className={['p-5 md:py-6 md:px-7', props.className].join(' ')}>
            <Icon icon={['far', 'coins']} className="regular" color={COLOR_SALES_STATS_CARD_ICON} />
            <SalesStatsCardTitle>
                {formatCents(props.amount * 100, true)}
                <span className="text-lg"> €</span>
            </SalesStatsCardTitle>
            <p className="m-0 text-sm" style={{ color: COLOR_SALES_STATS_CARD_DESC }}>
                {props.description}
            </p>
        </SalesStatsCardWrapper>
    );
};

export default SalesStatsCard;
