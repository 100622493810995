import Notifications from './Notifications';
import DashboardQuickActions from './DashboardQuickActions';
import type { IRootState } from 'reducers';
import { useSelector } from 'react-redux';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import { SCREEN_M } from 'styles/variables';
import DashboardSalesCard from './DashboardSalesCard';
import InvoiceList from './InvoiceList';

const Dashboard = () => {
    const showIntro = useSelector((state: IRootState) => state.app?.showIntro);
    const { width } = useWindowDimensions();
    const isDesktopView = width < SCREEN_M;

    return (
        <div className="flex flex-col gap-5">
            {!showIntro && !isDesktopView && <DashboardQuickActions />}
            <DashboardSalesCard />
            {width < SCREEN_M ? <InvoiceList /> : null}
            <Notifications />
        </div>
    );
};

export default Dashboard;
