import { gql } from '@apollo/client';

const RETURN_COST_INVOICE = gql`
    mutation ReturnCostInvoice($id: Int!, $status: CostInvoiceStatus!) {
        returnCostInvoice(costInvoiceId: $id, costInvoiceStatus: $status) {
            id
            invoiceId
            status
        }
    }
`;

export default RETURN_COST_INVOICE;
