import { memo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Hidden } from '@mui/material';
import { toast } from 'react-toastify';
import type {
    IInvoice,
    IInvoiceItem,
    IInvoiceItemKeys,
    ItemType,
    LanguageCode,
} from '../../../../shared/src/types/invoice';
import { EezyButton, MobileRowButton } from 'components/Buttons';
import ErrorPointer from 'components/ErrorPointer';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import LoadingSpinner from 'components/Loading';
import { PSmall } from 'components/textElements';
import { ErrorTooltip } from 'components/ToolTip';
import { Table } from 'styles/invoiceStyles';
import { COLOR_BLACKWATER, COLOR_DARK_GRAY, COLOR_GREYJOY, COLOR_IMPORTANT } from 'styles/variables';
import { formatCents } from 'utils';
import { useTranslation } from 'react-i18next';
import { abs, notEmpty } from 'utils/calc';
import { transFixed } from 'utils/i18n';
import {
    defaultQuantityUnit,
    defaultVat,
    getInvoiceItemCopy,
    initialRowData,
} from 'utils/invoice/invoiceLogic';
import type { IInvoiceTemplate } from 'utils/invoice/invoiceTemplates';
import validators from '../../utils/invoice/validators';
import { formatValidationResult } from 'utils/validation';
import { closeAllFillHelpers, openFillHelper } from './fillHelpers/utils';
import InvoiceRow from './InvoiceRow';
import { ADD_INVOICE_ITEM, DELETE_INVOICE_ITEM, GET_INVOICE } from './queries';

interface IInvoiceRowsProps {
    editable: boolean;
    errors?: any;
    handleInvoiceItemUpdate?: (o: object) => void;
    invoice?: IInvoice;
    invoiceLanguage?: LanguageCode;
    template: IInvoiceTemplate;
    loadingUpdate?: boolean;
    hasContract?: boolean;
}

const InvoiceRows = (props: IInvoiceRowsProps) => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const showArrows = !!props.invoice && props.editable;

    const rows = props.invoice?.invoiceItems || [];

    const possibleWorkItemVats = rows
        .filter((t: IInvoiceItem) => t.itemType === 'work')
        .map((t: IInvoiceItem) => t.vat)
        .filter(notEmpty);

    const [addInvoiceItem, { loading: addingLoading }] = useMutation(ADD_INVOICE_ITEM, {
        onCompleted: (data) => {
            const newItem = data?.updateInvoiceItem?.invoiceItems.slice(-1)?.[0];
            if (!newItem) return;
            openFillHelper(client, 'invoiceRow', newItem.id);
        },
        onError: () => toast.error(t('errors.general')),
        update(cache, { data: { updateInvoiceItem: invoiceItems } }) {
            const invoiceData: {
                invoice: IInvoice;
            } | null = cache.readQuery({
                query: GET_INVOICE,
                variables: { id: props.invoice?.id },
            });
            if (invoiceData?.invoice) {
                cache.writeQuery({
                    data: {
                        invoice: {
                            ...invoiceData.invoice,
                            invoiceItems: invoiceItems.invoiceItems,
                        },
                    },
                    query: GET_INVOICE,
                    variables: { id: props.invoice?.id },
                });
            }
        },
    });

    const [deleteInvoiceItem] = useMutation(DELETE_INVOICE_ITEM, {
        onError: () => toast.error(t('errors.general')),
    });

    const handleAddInvoiceItem = (itemType: ItemType) => {
        addInvoiceItem({
            variables: {
                invoiceId: props.invoice?.id,
                itemType,
                quantityUnit: defaultQuantityUnit(itemType),
                vat: defaultVat(props.template, possibleWorkItemVats, props.invoice?.recipient?.type),
            },
        });
    };

    const handleUpdateInvoiceItem = (id: number, changedProperties: IInvoiceItemKeys) => {
        if (props.handleInvoiceItemUpdate) {
            const updatedItems = rows.map((item: IInvoiceItem) => {
                if (item.id !== id) {
                    return {
                        ...initialRowData,
                        ...item,
                        __typename: 'InvoiceItem',
                    };
                }
                return {
                    ...item,
                    ...changedProperties,
                    __typename: 'InvoiceItem',
                };
            });

            props.handleInvoiceItemUpdate({
                optimisticResponse: {
                    updateInvoiceItem: {
                        __typename: 'Invoice',
                        invoiceItems: updatedItems,
                        total: props.invoice?.total,
                        totalVats: props.invoice?.totalVats,
                        totalWithVat: props.invoice?.totalWithVat,
                    },
                },
                variables: {
                    id,
                    invoiceId: props.invoice?.id,
                    ...changedProperties,
                },
            });
        }
    };

    const handleDeleteInvoiceItem = (id: number) => {
        closeAllFillHelpers(client);
        deleteInvoiceItem({
            optimisticResponse: {
                deleteInvoiceItem: {
                    __typename: 'Invoice',
                    id: props.invoice?.id,
                    invoiceItems: rows.filter((r) => r.id !== id),
                    total: props.invoice?.total,
                    totalVats: props.invoice?.totalVats,
                    totalWithVat: props.invoice?.totalWithVat,
                },
            },
            variables: { id, invoiceId: props.invoice?.id },
        });
    };

    const handleCopyInvoiceItem = (row: IInvoiceItem) => {
        if (rows.length === 0) {
            return;
        }
        addInvoiceItem({
            variables: {
                invoiceId: props.invoice?.id,
                ...getInvoiceItemCopy(row),
            },
        });
    };

    const hasWorkRowErrors =
        formatValidationResult(validators.invoiceItemsType.validate(rows))?.value?.type ===
        'array.hasUnknown';

    return (
        <div>
            <Hidden smUp>
                <Table>
                    <thead>
                        <tr>
                            <th>{t('invoice.rows')}</th>
                            <th colSpan={2}>{t('invoice.tableColumns.priceWithoutVat')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((item: any, index: number) => {
                            return (
                                <InvoiceRow
                                    editable={props.editable}
                                    key={`item-m-${index}`}
                                    item={item}
                                    handleCopy={handleCopyInvoiceItem}
                                    handleDelete={handleDeleteInvoiceItem}
                                    handleUpdate={handleUpdateInvoiceItem}
                                    template={props.template}
                                    vatOptions={possibleWorkItemVats}
                                    invoice={props.invoice}
                                />
                            );
                        })}
                        {addingLoading && (
                            <tr style={{ height: 69 }}>
                                <td colSpan={2}>
                                    <LoadingSpinner
                                        color={COLOR_GREYJOY}
                                        size="1.5em"
                                        style={{
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {props.editable && (
                    <MobileRowButton
                        disabled={!props.invoice}
                        color={`${COLOR_GREYJOY}`}
                        style={{ paddingTop: 25, width: '100%' }}
                        onClick={() => handleAddInvoiceItem('work')}
                    >
                        {showArrows && hasWorkRowErrors && <ErrorPointer />}
                        {t('invoice.tableColumns.add-row')}
                        {props.invoice && (
                            <Icon
                                icon={['fal', 'pen']}
                                style={{
                                    float: 'right',
                                    height: 15,
                                    marginRight: -10,
                                    width: 15,
                                }}
                            />
                        )}
                    </MobileRowButton>
                )}
                {/* Invoice totals row */}
                <Flex
                    column
                    style={{
                        borderBottom: '3px solid black',
                        borderTop: '3px solid black',
                        marginTop: 30,
                        padding: '15px 0',
                        textTransform: 'uppercase',
                    }}
                >
                    <Flex spread>
                        <PSmall>
                            {showArrows && props.errors?.total && <ErrorPointer style={{ marginTop: 4 }} />}
                            <b>{t('invoice.sum')}</b>
                        </PSmall>
                        <PSmall
                            color={showArrows && props.errors?.total ? COLOR_IMPORTANT : COLOR_DARK_GRAY}
                            aria-live="polite"
                            aria-relevant="text"
                        >
                            <b>
                                {t('form.eurs', {
                                    eurs: formatCents(props.invoice?.total, true),
                                })}
                            </b>
                        </PSmall>
                    </Flex>

                    {props.invoice?.totalVats?.map((vat: any) => {
                        return (
                            <Flex spread key={`vat-${vat.percentage}`}>
                                <PSmall>
                                    <b>
                                        {t('invoice.tableColumns.x-vat', {
                                            percentage: vat.percentage,
                                        })}
                                    </b>
                                </PSmall>
                                <PSmall color={COLOR_DARK_GRAY}>
                                    <b>
                                        {t('form.eurs', {
                                            eurs: formatCents(vat.total, true),
                                        })}
                                    </b>
                                </PSmall>
                            </Flex>
                        );
                    })}

                    <ErrorTooltip
                        arrow
                        placement="bottom"
                        title={
                            showArrows && props.errors?.totalWithVat ? t('errors.negativeInvoiceTotal') : ''
                        }
                    >
                        <Flex spread>
                            <PSmall>
                                {showArrows && props.errors?.totalWithVat && (
                                    <ErrorPointer style={{ marginTop: 4 }} />
                                )}
                                <b>{t('invoice.sum-with-vat')}</b>
                            </PSmall>
                            <PSmall
                                color={
                                    showArrows && props.errors?.totalWithVat
                                        ? COLOR_IMPORTANT
                                        : COLOR_DARK_GRAY
                                }
                                aria-live="off"
                            >
                                <b>
                                    {t('form.eurs', {
                                        eurs: formatCents(props.invoice?.totalWithVat, true),
                                    })}
                                </b>
                            </PSmall>
                        </Flex>
                    </ErrorTooltip>
                    {props.invoice?.paymentInformation && (
                        <>
                            <Flex spread>
                                <PSmall>
                                    <b>{t('invoice.tableColumns.paid')}</b>
                                </PSmall>
                                <PSmall color={COLOR_DARK_GRAY}>
                                    <b>
                                        {t('form.eurs', {
                                            eurs: formatCents(
                                                props.invoice?.paymentInformation?.paidAmount,
                                                true,
                                            ),
                                        })}
                                    </b>
                                </PSmall>
                            </Flex>
                            {props.invoice?.paymentInformation?.reimbursedAmount > 0 && (
                                <Flex spread>
                                    <PSmall>
                                        <b>{t('invoice.tableColumns.reimbursed')}</b>
                                    </PSmall>
                                    <PSmall color={COLOR_DARK_GRAY}>
                                        <b>
                                            {'– '}
                                            {t('form.eurs', {
                                                eurs: formatCents(
                                                    props.invoice?.paymentInformation?.reimbursedAmount,
                                                    true,
                                                ),
                                            })}
                                        </b>
                                    </PSmall>
                                </Flex>
                            )}
                            <Flex spread>
                                <PSmall
                                    color={
                                        props.invoice?.paymentInformation?.unpaidAmount
                                            ? COLOR_IMPORTANT
                                            : COLOR_BLACKWATER
                                    }
                                >
                                    <b>
                                        {props.invoice?.paymentInformation?.unpaidAmount >= 0
                                            ? t('invoice.tableColumns.unpaid')
                                            : t('invoice.tableColumns.overpaid')}
                                    </b>
                                </PSmall>
                                <PSmall
                                    color={
                                        props.invoice?.paymentInformation?.unpaidAmount
                                            ? COLOR_IMPORTANT
                                            : COLOR_DARK_GRAY
                                    }
                                >
                                    <b>
                                        {t('form.eurs', {
                                            eurs: formatCents(
                                                abs(props.invoice?.paymentInformation?.unpaidAmount),
                                                true,
                                            ),
                                        })}
                                    </b>
                                </PSmall>
                            </Flex>
                        </>
                    )}
                </Flex>
            </Hidden>
            <Hidden smDown>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <span id="description">
                                    {transFixed({
                                        str: 'invoice.tableColumns.description',
                                        lang: props.invoiceLanguage,
                                        options: { defaultValue: '' },
                                    })}
                                </span>
                                <span id="startdate" className="invisible">
                                    {t('dates.startDate')}
                                </span>
                                <span id="enddate" className="invisible">
                                    {t('dates.endDate')}
                                </span>
                            </th>
                            <th>
                                {transFixed({
                                    str: 'invoice.tableColumns.quantity',
                                    lang: props.invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </th>
                            <th id="price">
                                {transFixed({
                                    str: 'invoice.tableColumns.price',
                                    lang: props.invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </th>
                            <th id="vat">
                                {transFixed({
                                    str: 'invoice.tableColumns.vat',
                                    lang: props.invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </th>
                            <th id="totalPrice">
                                {transFixed({
                                    str: 'invoice.tableColumns.priceWithoutVat',
                                    lang: props.invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </th>
                            <th id="totalPriceWithVat">
                                {transFixed({
                                    str: 'invoice.tableColumns.priceWithVat',
                                    lang: props.invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th scope="row" colSpan={4}>
                                {showArrows && props.errors?.totalWithVat && (
                                    <ErrorPointer style={{ marginTop: 2 }} />
                                )}
                                {transFixed({
                                    str: 'invoice.sum',
                                    lang: props.invoiceLanguage,
                                    options: { defaultValue: '' },
                                })}
                            </th>
                            <td
                                aria-live="polite"
                                aria-relevant="text"
                                className={showArrows && props.errors?.total ? 'error' : ''}
                            >
                                {t('form.eurs', {
                                    eurs: formatCents(props.invoice?.total, true),
                                })}
                            </td>
                            <td
                                aria-live="off"
                                className={showArrows && props.errors?.totalWithVat ? 'error' : ''}
                            >
                                <ErrorTooltip
                                    title={
                                        showArrows && props.errors?.totalWithVat
                                            ? t('errors.negativeInvoiceTotal')
                                            : ''
                                    }
                                    arrow
                                    placement="bottom"
                                >
                                    <Flex center fullWidth posEnd>
                                        {showArrows && props.errors?.totalWithVat && (
                                            <Icon
                                                icon={['far', 'exclamation-triangle']}
                                                className="small"
                                                color={COLOR_IMPORTANT}
                                            />
                                        )}
                                        <span style={{ minWidth: 80 }}>
                                            {t('form.eurs', {
                                                eurs: formatCents(props.invoice?.totalWithVat, true),
                                            })}
                                        </span>
                                    </Flex>
                                </ErrorTooltip>
                            </td>
                            <td />
                        </tr>
                        {props.invoice?.paymentInformation && (
                            <>
                                <tr>
                                    <th scope="row" colSpan={5}>
                                        {t('invoice.tableColumns.paid')}
                                    </th>
                                    <td>
                                        {t('form.eurs', {
                                            eurs: formatCents(
                                                props.invoice?.paymentInformation?.paidAmount,
                                                true,
                                            ),
                                        })}
                                    </td>
                                </tr>
                                {props.invoice?.paymentInformation?.reimbursedAmount > 0 && (
                                    <tr>
                                        <th scope="row" colSpan={5}>
                                            {t('invoice.tableColumns.reimbursed')}
                                        </th>
                                        <td>
                                            {'– '}
                                            {t('form.eurs', {
                                                eurs: formatCents(
                                                    props.invoice?.paymentInformation?.reimbursedAmount,
                                                    true,
                                                ),
                                            })}
                                        </td>
                                    </tr>
                                )}
                                <tr
                                    className={
                                        props.invoice?.paymentInformation?.unpaidAmount ? 'highlight' : ''
                                    }
                                >
                                    <th scope="row" colSpan={5}>
                                        {props.invoice?.paymentInformation?.unpaidAmount >= 0
                                            ? t('invoice.tableColumns.unpaid')
                                            : t('invoice.tableColumns.overpaid')}
                                    </th>
                                    <td>
                                        {t('form.eurs', {
                                            eurs: formatCents(
                                                abs(props.invoice?.paymentInformation?.unpaidAmount),
                                                true,
                                            ),
                                        })}
                                    </td>
                                </tr>
                            </>
                        )}
                    </tfoot>
                    <tbody>
                        {rows.map((item: any, index: number) => {
                            if (item.quantityUnit === 'other') {
                                item.quantityUnit = '';
                            }

                            return (
                                <InvoiceRow
                                    editable={props.editable}
                                    key={`item-${index}`}
                                    item={item}
                                    handleCopy={handleCopyInvoiceItem}
                                    handleDelete={handleDeleteInvoiceItem}
                                    handleUpdate={handleUpdateInvoiceItem}
                                    template={props.template}
                                    vatOptions={possibleWorkItemVats}
                                />
                            );
                        })}
                        {addingLoading && (
                            <tr style={{ height: 61 }}>
                                <td colSpan={7} style={{ verticalAlign: 'middle' }}>
                                    <LoadingSpinner
                                        color={COLOR_GREYJOY}
                                        size="1.5em"
                                        style={{
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                    />
                                </td>
                            </tr>
                        )}
                        {props.editable && (
                            <tr>
                                <td
                                    colSpan={7}
                                    style={{
                                        paddingBottom: '15px',
                                        paddingTop: '15px',
                                    }}
                                >
                                    {showArrows && hasWorkRowErrors && <ErrorPointer />}
                                    <EezyButton
                                        aria-label={t('invoice.tableColumns.add-work')!}
                                        color="black"
                                        className={showArrows && hasWorkRowErrors ? 'hasError' : ''}
                                        dark
                                        disabled={!props.invoice || addingLoading || props.loadingUpdate}
                                        hasIcon
                                        onClick={() => handleAddInvoiceItem('work')}
                                    >
                                        <Icon color="white" icon={['far', 'plus']} />
                                        {t('invoice.tableColumns.work')}
                                    </EezyButton>
                                    <EezyButton
                                        aria-label={t('invoice.tableColumns.add-travel')!}
                                        color="black"
                                        disabled={!props.invoice || addingLoading}
                                        hasIcon
                                        onClick={() => {
                                            handleAddInvoiceItem('travel');
                                        }}
                                    >
                                        <Icon color="black" icon={['far', 'plus']} />
                                        {t('invoice.tableColumns.travel')}
                                    </EezyButton>
                                    <EezyButton
                                        aria-label={t('invoice.tableColumns.add-material')!}
                                        color="black"
                                        disabled={!props.invoice || addingLoading}
                                        hasIcon
                                        onClick={() => handleAddInvoiceItem('material')}
                                    >
                                        <Icon color="black" icon={['far', 'plus']} />
                                        {t('invoice.tableColumns.material')}
                                    </EezyButton>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Hidden>
        </div>
    );
};

export default memo(InvoiceRows);
