import styled from 'styled-components';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_GRAYS_ANATOMY,
    COLOR_GREYJOY,
    COLOR_IMPORTANT_LIGHTEST,
    LILA_GRADIENT_LT,
    SCREEN_M,
} from '../../styles/variables';

export const List = styled.div`
    & {
        overflow-y: scroll;

        @media (min-width: ${SCREEN_M}px) {
            /* Space for hover & selected effects  */
            margin-left: -10px;
            margin-right: -30px;
            max-height: calc(100vh - 240px);

            max-height: ${(props) =>
                props.style?.top ? `calc(100vh - ${props.style.top}px)` : 'calc(100vh - 240px)'};

            & div.item-content {
                margin-right: 20px;
            }
        }

        & ul {
            @media (min-width: ${SCREEN_M}px) {
                margin: 0 10px;
            }
            & li {
                &:hover {
                    cursor: pointer;
                    @media (min-width: ${SCREEN_M}px) {
                        margin-left: -10px;
                        margin-right: -10px;
                        div.item-content {
                            padding-left: 25px;
                            padding-right: 25px;
                        }
                    }
                }
                &.selected {
                    margin-left: -10px;
                    margin-right: -10px;
                    div.item-content {
                        background: ${LILA_GRADIENT_LT};
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                }

                &:focus {
                    outline: none;
                    &:not(.selected) {
                        margin-left: -10px;
                        margin-right: -10px;
                        div.item-content {
                            padding-left: 25px;
                            padding-right: 25px;
                        }
                    }
                }
            }
        }
        div.content-wrapper {
            margin: 15px 0;
            @media (min-width: ${SCREEN_M}px) {
                margin: 15px 15px 15px 10px;
            }
        }
    }

    & div.icon-wrapper {
        position: relative;
    }
`;

interface IListItemProps {
    iconColor?: string;
    $isImportant?: boolean;
}

export const ListItem = styled.div`
    padding: 5px 0;
    & > div {
        align-items: center;
        background-color: ${(props: IListItemProps) =>
            props.$isImportant ? COLOR_IMPORTANT_LIGHTEST : COLOR_GRAYS_ANATOMY};
        border-radius: ${BORDER_RADIUS};
        display: flex;
        flex-direction: row;
        height: 56px;
        padding-left: 15px;
        padding-right: 15px;

        & > div.icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            min-width: 35px;
            height: 35px;
            border-radius: 35px;
            background-color: ${(props: IListItemProps) =>
                props.iconColor ? props.iconColor : COLOR_GREYJOY};
        }
        & > p:nth-child(2) {
            min-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 10px;
            text-overflow: ellipsis;
            span {
                color: ${(props) => props.color || COLOR_BLACKWATER};
            }
        }
        & > div:nth-child(3) {
            flex-grow: 2;
            min-width: 85px;
            text-align: right;
        }
    }
`;
