import { validator as v } from '../validation';

const countryValidator = v.string().min(1).required();

const streetValidator = v.string().min(1).required();

const street2Validator = [v.allow(null), v.string().allow('').optional()];

const townValidator = v.string().min(1).required();

const zipCodeValidator = v.string().min(1).required();

export const addressValidator = v
    .object({
        country: countryValidator,
        street1: streetValidator,
        street2: street2Validator,
        town: townValidator,
        zipCode: zipCodeValidator,
    })
    .required();
