import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Input, type InputProps } from './Input';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from './Button';
import { Calendar } from 'components/ui/calendar';
import { fi } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { cn, formatDate } from 'utils';
import moment from 'moment';
import { MenuButton } from './MenuButton';
import {
    type DateFilter,
    getTimeLabel,
    getTimeRanges,
    isWithinSingleMonth,
    type TimeRange,
    timeRanges,
} from 'utils/statistics/dateUtils';
import { capitalize } from 'utils/str';
import { Drawer, DrawerDivider } from 'components/ui/drawer';
import { create } from 'zustand';

type DateRangePeriod = 'month' | 'quarter' | 'year';

// There is an issue where if the DateRangePicker sets the period as quarter during the first month of the quarter,
// The date range suggestions think that the period is a month instead of a quarter. This is a workaround for that, to pass the data from DateRangePicker to StatisticsBillingSuggestions
export const useDateRangePeriodStore = create<{
    dateRangePeriod: DateRangePeriod | null;
    setDateRangePeriod: (value: DateRangePeriod | null) => void;
}>((set) => ({
    dateRangePeriod: 'year',
    setDateRangePeriod: (dateRangePeriod) => set({ dateRangePeriod }),
}));

type DateRangePickerProps = {
    value: DateFilter;
    onDateRangeChange: (dateRange: DateFilter, fromMobileView?: boolean) => void;
    calendarProps?: Omit<React.ComponentProps<typeof Calendar>, 'mode' | 'onSelect' | 'selected'>;
    defaultTimeRange?: TimeRange;
    minDate?: Date;
    maxDate?: Date;
    // This is used only on mobile, when the drawer is closed with the confirm button
    onConfirmDateRange?: () => void;
    onChangeCancel?: () => void;
};

const DateRangePicker = forwardRef<
    HTMLInputElement,
    Omit<InputProps, 'startIcon' | 'endIconButton' | 'unit' | 'onEndIconButtonClick' | 'value'> &
        DateRangePickerProps
>(
    (
        {
            label,
            onDateRangeChange,
            calendarProps,
            defaultTimeRange,
            minDate,
            maxDate,
            value,
            onConfirmDateRange,
            onChangeCancel,
            ...props
        },
        ref,
    ) => {
        const { t, i18n } = useTranslation();
        const setDateRangePeriod = useDateRangePeriodStore((state) => state.setDateRangePeriod);
        const dateRangePeriod = useDateRangePeriodStore((state) => state.dateRangePeriod);

        const uuid = useRef<string>(Math.random().toString(36).substring(7));
        const inputFromRef = useRef<HTMLInputElement>(null);
        const inputToRef = useRef<HTMLInputElement>(null);

        // this controls just the calendar view
        const [month, setMonth] = useState(new Date());
        const [open, setOpen] = useState(false);
        const [mobileOpen, setMobileOpen] = useState(false);

        const onMobileOpenChange = (open: boolean, resetUnconfirmedDateRange?: boolean) => {
            if (resetUnconfirmedDateRange && !open) onChangeCancel?.();
            setMobileOpen(open);
            setMonth(value.to);
        };

        const setPeriodFromTimeRange = (timeRange: TimeRange) => {
            switch (timeRange) {
                case 'this_month':
                case 'last_month':
                    setDateRangePeriod('month');
                    break;
                case 'this_quarter':
                case 'last_quarter':
                    setDateRangePeriod('quarter');
                    break;
                case 'this_year':
                case 'last_year':
                    setDateRangePeriod('year');
                    break;
            }
        };

        const timePeriods = getTimeRanges({ dateFilter: value, maxDate });

        const period = useMemo(() => {
            if (!dateRangePeriod) return timePeriods[0] ?? null;
            switch (dateRangePeriod) {
                case 'month':
                    return timePeriods.find((p) => p.includes('month')) ?? null;
                case 'quarter':
                    return timePeriods.find((p) => p.includes('quarter')) ?? null;
                case 'year':
                    return timePeriods.find((p) => p.includes('year')) ?? null;
            }

            return null;
        }, [dateRangePeriod, timePeriods]);

        const changeDateRange = (dateRange: DateFilter, fromMobile?: boolean) => {
            // In the preset date ranges, dateRange.to might be past maxDate, so we need to adjust it
            const adjustedDateRange = {
                from: dateRange.from,
                to: maxDate ? moment.min(moment(dateRange.to), moment(maxDate)).toDate() : dateRange.to,
            };

            setDateRangePeriod(null);

            onDateRangeChange(adjustedDateRange, fromMobile);

            // If dateRange.to is changed, we want the visible month to be the last month of the range
            // instead of the first month of the range
            const setMonthToEndOfRange = !moment(adjustedDateRange.to).isSame(value.to, 'date');
            if (setMonthToEndOfRange) {
                // On desktop we have two calendars visible, so we can subtract 1 month,
                // unless we are in the same month, then we want to pick the same month,
                // so the selection goes to the left calendar
                // On mobile we have only one calendar visible, so we always want to pick the same month
                if (fromMobile || isWithinSingleMonth(adjustedDateRange)) {
                    setMonth(adjustedDateRange.to);
                } else setMonth(moment(adjustedDateRange.to).subtract(1, 'months').toDate());
            } else setMonth(adjustedDateRange.from);
        };

        useEffect(() => {
            if (defaultTimeRange) {
                const timeRange = timeRanges.find(({ id }) => id === defaultTimeRange);
                if (timeRange) changeDateRange(timeRange.dateRange());
                setPeriodFromTimeRange(defaultTimeRange);
            }
        }, []);

        const buttonLabel = (() => {
            if (!value.from || !value.to) return t('dates.select-date');
            if (period) return t(`dates.date-ranges.${period}`);
            return getTimeLabel({ dateFilter: value, maxDate });
        })();

        // This will allow us to use forwardRef and focus properly on the input when clicking the icons etc.
        useImperativeHandle(ref, () => inputFromRef.current as HTMLInputElement);

        // We save these separately, because in desktop we use popover and in mobile we use drawer
        const datePickerTrigger = (
            <Button
                className="min-w-[175px] tg-body-medium rounded-md justify-start gap-3 px-4 w-full"
                variant="outline"
                startIcon={['far', 'calendar']}
            >
                {capitalize(buttonLabel)}
            </Button>
        );

        const datePickerContent = (mobile: boolean) => (
            <div className="flex flex-col md:flex-row">
                <div className="py-2 md:p-2 flex md:flex-col gap-1 border-r border-gray-200 min-w-[135px] overflow-x-auto -mr-6 md:mr-0 md:overflow-x-visible">
                    {timeRanges.map(({ id, dateRange }) => (
                        <MenuButton
                            key={id}
                            onClick={() => {
                                changeDateRange(dateRange(), mobile);
                                setPeriodFromTimeRange(id);
                                setOpen(false);
                            }}
                            selected={period === id}
                            className="rounded-full md:rounded-sm min-w-fit md:min-w-0"
                        >
                            {t(`dates.date-ranges.${id}`)}
                        </MenuButton>
                    ))}
                </div>

                {mobile && <DrawerDivider />}
                <div className={cn('p-3', mobile && 'flex flex-col items-center pt-0')}>
                    {/* Date inputs */}
                    <div className="flex items-center w-full md:w-auto mb-7 md:mb-0">
                        <div className="flex-1">
                            <Input
                                startIcon={mobile ? undefined : ['far', 'calendar']}
                                type="date"
                                min={minDate ? formatDate(minDate, 'yyyy-MM-DD') : undefined}
                                max={maxDate ? formatDate(maxDate, 'yyyy-MM-DD') : undefined}
                                ref={inputFromRef}
                                {...props}
                                onChange={(e) =>
                                    changeDateRange({ from: new Date(e.target.value), to: value?.to }, mobile)
                                }
                                value={value?.from ? formatDate(value.from, 'yyyy-MM-DD') : undefined}
                                label={t('statistic.starts')}
                            />
                        </div>
                        <span className="mx-2 pt-[23px] border-gray-300 border-b w-2" />
                        <div className="flex-1">
                            <Input
                                startIcon={mobile ? undefined : ['far', 'calendar']}
                                type="date"
                                min={minDate ? formatDate(minDate, 'yyyy-MM-DD') : undefined}
                                max={maxDate ? formatDate(maxDate, 'yyyy-MM-DD') : undefined}
                                ref={inputToRef}
                                {...props}
                                onChange={(e) => {
                                    const newDate = new Date(e.target.value);

                                    const newDateRange = {
                                        from: value?.from && newDate < value?.from ? newDate : value?.from,
                                        to: newDate,
                                    };
                                    changeDateRange(newDateRange, mobile);
                                }}
                                value={value?.to ? formatDate(value.to, 'yyyy-MM-DD') : undefined}
                                label={t('statistic.ends')}
                            />
                        </div>
                    </div>
                    <Calendar
                        className="p-0 mt-3"
                        mode="range"
                        selected={value}
                        onSelect={(value) => {
                            if (!value || !value.from || !value.to) return;
                            onDateRangeChange({ from: value.from, to: value.to });
                        }}
                        month={month}
                        onMonthChange={setMonth}
                        numberOfMonths={mobile ? 1 : 2}
                        locale={i18n.language === 'fi' ? fi : undefined}
                        disabled={(date) =>
                            Boolean(
                                (minDate && moment(minDate).isAfter(date)) ||
                                    (maxDate && moment(maxDate).isBefore(date)),
                            )
                        }
                        data-vaul-no-drag
                        {...calendarProps}
                    />
                </div>
            </div>
        );
        return (
            <div className="flex flex-col gap-1 leading-normal w-full">
                {label && (
                    <label htmlFor={props.id ?? uuid.current} className="text-xs text-gray-800 font-semibold">
                        {label}
                    </label>
                )}
                <div className="hidden md:block">
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>{datePickerTrigger}</PopoverTrigger>
                        <PopoverContent className="w-auto p-0">{datePickerContent(false)}</PopoverContent>
                    </Popover>
                </div>
                <div className="block md:hidden">
                    <Drawer
                        open={mobileOpen}
                        onOpenChange={(value: boolean) => onMobileOpenChange(value, true)}
                        trigger={datePickerTrigger}
                        title={t('statistic.choose-daterange')}
                    >
                        {datePickerContent(true)}
                        <div className="flex flex-row gap-3 mt-6">
                            <Button
                                variant="outline"
                                onClick={() => {
                                    onMobileOpenChange(false, true);
                                }}
                                className="flex-1"
                            >
                                {t('statistic.cancel')}
                            </Button>
                            <Button
                                className="flex-1"
                                onClick={() => {
                                    onConfirmDateRange?.();
                                    onMobileOpenChange(false);
                                }}
                            >
                                {t('statistic.confirm')}
                            </Button>
                        </div>
                    </Drawer>
                </div>
            </div>
        );
    },
);

DateRangePicker.displayName = 'DateRangePicker';

export { DateRangePicker };
