import { type CSSProperties, type KeyboardEvent, type SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FormControl, Input, useAutocomplete } from '@mui/material';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import type { IClient, IClientChoiceInput, IYtjBriefCompany } from '../../../../shared/src/types/invoice';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { Label } from 'components/textElements';
import { COLOR_GREY_FOG, COLOR_GREYS_ANATOMY, COLOR_IMPORTANT } from 'styles/variables';
import { filterDuplicateBusinessIds } from 'utils/invoice/invoiceLogic';
import { type IRecipientOption, OwnRecipientOption, YtjRecipientOption } from './InvoiceRecipientOption';
import { GET_INVOICE_RECIPIENTS } from './queries';
import GET_YTJ_COMPANIES from './queries/getYtjCompanies';

const useStyles = makeStyles()({
    input: {
        borderBottom: `1px solid ${COLOR_IMPORTANT}`,
    },
    list: {
        marginTop: '20px',
        maxHeight: '200px',
        overflowY: 'auto',
    },
    note: {
        marginTop: '20px',
    },
    root: {
        borderBottom: `1px solid ${COLOR_GREY_FOG}`,
        minWidth: '300px',
        paddingBottom: '10px',
    },
});

const ListItem = styled.li`
    &:nth-child(even) {
        background: ${COLOR_GREYS_ANATOMY};
    }
    &:hover {
        background: ${COLOR_GREY_FOG};
    }
`;

const flattenRecipient = (rec: IClient): IRecipientOption => {
    const { address, ...rest } = rec;
    return {
        businessId: '',
        email: '',
        name: '',
        ...rest,
        ...address,
    };
};

interface IInvoiceRecipientAutocompleteProps {
    errors?: any;
    freeSolo?: boolean;
    id: string;
    open?: boolean;
    handleNewCustomer?: () => void;
    handleRecipientChange: (recipient: IClientChoiceInput) => void;
    handleYtjRecipientChange: (o: IYtjBriefCompany) => void;
    handleInputChange?: (val: string) => void;
    placeholder?: string;
    recipient?: IClient;
    inputStyle?: CSSProperties;
    showListHeader?: boolean;
}

const isOwnRecipient = (recipient: IRecipientOption | IYtjBriefCompany) => 'id' in recipient;

export const InvoiceRecipientAutocomplete = (props: IInvoiceRecipientAutocompleteProps) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { data: ownRecipients } = useQuery(GET_INVOICE_RECIPIENTS);
    const [ytjSearch, { loading: ytjLoading, data: ytjData, error: ytjError }] =
        useLazyQuery(GET_YTJ_COMPANIES);

    const [recipient, setRecipient] = useState<IRecipientOption | IYtjBriefCompany | null>(() => {
        return props.recipient ? (flattenRecipient(props.recipient) as IRecipientOption) : null;
    });

    const [options, setOptions] = useState([]);
    const [query, setQuery] = useState('');
    const [queryError, setQueryError] = useState('');

    useEffect(() => {
        const ownRecipientOptions =
            ownRecipients?.allClients
                ?.filter((c: IClient) => c.active)
                .sort((c1: IClient, c2: IClient) => c1.name?.localeCompare(c2.name || ''))
                .map((r: IClient): IRecipientOption => {
                    // Autocomplete filter works only with flat objects
                    return flattenRecipient(r);
                }) || [];
        if (ytjData?.ytjSearch?.items) {
            const filteredYtjResults = filterDuplicateBusinessIds(
                ytjData?.ytjSearch?.items,
                ownRecipientOptions,
            );
            setOptions(ownRecipientOptions.concat(filteredYtjResults));
        } else {
            setOptions(ownRecipientOptions);
        }
    }, [ytjData, ownRecipients]);

    useEffect(() => {
        if (ytjError) {
            const errorCode =
                ytjError.graphQLErrors.length > 0 ? ytjError.graphQLErrors[0].extensions?.code : undefined;
            if (errorCode === 'NO_RESULTS' || errorCode === 'TOO_MANY_RESULTS') {
                setQueryError(errorCode);
            } else {
                setQueryError('');
            }
        }
    }, [ytjError]);

    const handleRecipientChange = (rec: IRecipientOption | IYtjBriefCompany) => {
        if (isOwnRecipient(rec)) {
            const ownRecipient = rec as IRecipientOption;
            setRecipient(ownRecipient);
            if (ownRecipient.id && ownRecipient.type) {
                props.handleRecipientChange({
                    id: ownRecipient.id,
                    type: ownRecipient.type,
                });
            }
        } else {
            props.handleYtjRecipientChange(rec);
        }
    };

    const changeQuery = (q: string) => {
        setQuery(q);
        if (q.length >= 5) {
            ytjSearch({ variables: { query: q } });
        } else {
            setQueryError('TOO_SHORT_QUERY');
        }
    };

    const handleEnter = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            // enter pressed, fire search immediately
            ytjSearch({ variables: { query } });
        }
    };

    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        inputValue,
    } = useAutocomplete({
        id: props.id,
        freeSolo: props.freeSolo,
        getOptionLabel: (opt) => {
            if (typeof opt !== 'object') {
                return '';
            }
            return opt.name;
        },
        onChange: (
            _event: SyntheticEvent,
            value: any, //IRecipientOption | IYtjBriefCompany | null
        ) => {
            if (value) {
                handleRecipientChange(value);
            }
        },
        onInputChange: (_e, val, reason) => {
            // Don't fetch ytj companies if input is changed due to a company select
            if (reason === 'input') {
                changeQuery(val);
            }
        },
        open: true,
        openOnFocus: true,
        options,
        value: recipient,
        multiple: false,
    });

    return (
        <div {...getRootProps()} className={classes.root}>
            <div>
                <Flex spread style={{ marginBottom: '10px' }}>
                    <Label color="black" {...getInputLabelProps()}>
                        {t('invoice.search-clients')}
                    </Label>
                    <EezyButton color="black" hasIcon onClick={props.handleNewCustomer}>
                        <Icon icon={['far', 'plus']} />
                        {t('invoice.form.new-recipient')}
                    </EezyButton>
                </Flex>
                <FormControl fullWidth>
                    {/*@ts-expect-error - Here we pass inputProps from useAutocomplete to Input for some reason which causes an ts error. This will get rewritten with new components in the future. */}
                    <Input
                        {...getInputProps()}
                        className={classes.input}
                        autoFocus
                        endAdornment={<Icon className="small" icon={['fal', 'search']} color="black" />}
                        onKeyPress={handleEnter}
                    />
                </FormControl>
            </div>

            {groupedOptions.length > 0 ? (
                <ul {...getListboxProps()} className={classes.list}>
                    {(groupedOptions as IRecipientOption[] | IYtjBriefCompany[]).map(
                        (option: IRecipientOption | IYtjBriefCompany, index: number) => (
                            <ListItem
                                {...getOptionProps({ option, index })}
                                data-mf-replace="**REMOVED** <br>"
                                key={`own-option-${index}`}
                            >
                                {(option as IRecipientOption).id ? (
                                    <OwnRecipientOption
                                        opt={option as IRecipientOption}
                                        inputValue={inputValue}
                                    />
                                ) : (
                                    <YtjRecipientOption
                                        opt={option as IYtjBriefCompany}
                                        inputValue={inputValue}
                                    />
                                )}
                            </ListItem>
                        ),
                    )}
                </ul>
            ) : ytjLoading || queryError ? (
                <div className={classes.note}>
                    {ytjLoading ? t('general.loading') : t(`errors.ytj.${queryError}`)}
                </div>
            ) : null}
        </div>
    );
};
