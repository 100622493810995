export const paymentTerms = [0, 3, 7, 14, 21, 30, 45];

export const vatDocumentUrl = (lang?: string) => {
    if (lang) {
        return `http://ec.europa.eu/taxation_customs/vies/?locale=${lang}`;
    }
    return 'http://ec.europa.eu/taxation_customs/vies/?locale=en';
};

export const OPERATORS = [
    {
        name: 'Aktia',
        id: 'HELSFIHH',
    },
    {
        name: 'Danske Bank',
        id: 'DABAFIHH',
    },
    {
        name: 'DNB',
        id: 'DNBAFIHX',
    },
    {
        name: 'Handelsbanken',
        id: 'HANDFIHH',
    },
    {
        name: 'Nordea Pankki',
        id: 'NDEAFIHH',
    },
    {
        name: 'Oma Säästöpankki Oyj',
        id: 'ITELFIHH',
    },
    {
        name: 'Osuuspankit ja Pohjola Pankki',
        id: 'OKOYFIHH',
    },
    {
        name: 'POP Pankki',
        id: 'POPFFI22',
    },
    {
        name: 'S-Pankki',
        id: 'SBANFIHH',
    },
    {
        name: 'Säästöpankit',
        id: 'ITELFIHH',
    },
    {
        name: 'Ålandsbanken',
        id: 'AABAFI22',
    },
    {
        name: '4US Oy',
        id: 'UTMOST',
    },
    {
        name: 'Apix Messaging Oy',
        id: '003723327487',
    },
    {
        name: 'Basware Oyj',
        id: 'BAWCFI22',
    },
    {
        name: 'CGI Suomi Oy',
        id: '003703575029',
    },
    {
        name: 'Comarch',
        id: '5909000716438',
    },
    {
        name: 'Crediflow AB',
        id: 'CREDIFLOW',
    },
    {
        name: 'Dynatos',
        id: 'ROUTTY',
    },
    {
        name: 'HighJump AS',
        id: '885790000000418',
    },
    {
        name: 'InExchange Factorum AB',
        id: 'INEXCHANGE',
    },
    {
        name: 'Lexmark Expert Systems AB',
        id: 'EXPSYS',
    },
    {
        name: 'Maventa',
        id: '003721291126',
    },
    {
        name: 'Maventa',
        id: 'MAVENTA',
    },
    {
        name: 'Netbox Finland Oy',
        id: '003726044706',
    },
    {
        name: 'Opentext Oy',
        id: '003708599126',
    },
    {
        name: 'OpusCapita Solutions Oy',
        id: 'E204503',
    },
    {
        name: 'Pagero Oy',
        id: '003723609900',
    },
    {
        name: 'Pagero Oy',
        id: 'PAGERO',
    },
    {
        name: 'Palette Software Ab',
        id: 'PALETTE',
    },
    {
        name: 'Posti Messaging Oy',
        id: 'FI28768767',
    },
    {
        name: 'PostNord Strålfors Oy',
        id: '003701150617',
    },
    {
        name: 'Ropo Capital Oy',
        id: '003714377140',
    },
    {
        name: 'Telia Finland Oyj',
        id: '003703575029',
    },
    {
        name: 'TietoEvry Oyj',
        id: '003701011385',
    },
    {
        name: 'Tradeshift Ab',
        id: '885060259470028',
    },
    {
        name: 'Ålands Post Ab',
        id: '003722207029',
    },
];
