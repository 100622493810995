import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import type React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { IDatePair } from '../../../../shared/src/types/costs';
import type { IInvoiceItem } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import {
    DatePicker,
    FormRow,
    FormSection,
    HoursMinutesTime,
    SplitRow,
    StackableRow,
} from '../../components/form';
import { FormLabel } from '../../components/form/FormLabel';
import { Icon } from '../../components/Icon';
import { ValidationMessageBlock } from '../../components/ValidationMessageBlock';
import { COLOR_IMPORTANT, SCREEN_S } from '../../styles/variables';
import { dateFormatWithWeekday, dateOrDefault, formatDate, formatTime, trans } from '../../utils';
import {
    datesContainDate,
    getMaxTravelDate,
    getMinTravelDate,
    type ICostComponentProps,
} from '../../utils/costs/costLogic';
import RepeatedTripsPopover from './RepeatedTripsPopover';
import type { DateRange } from '@mui/x-date-pickers-pro';

const Wrapper = styled.div`
    ${StackableRow} {
        align-items: baseline;
    }
    ${SplitRow} {
        width: 100%;
    }
    ${FormRow} {
        flex-grow: 2;
    }
    ${FormRow} ~ ${FormRow} {
        flex-grow: 1;
        max-width: 140px;
    }
    @media (min-width: ${SCREEN_S}px) {
        & ${SplitRow} ~ ${SplitRow} {
            margin-left: 10px;
        }
    }
`;

const ButtonWrapper = styled.div`
    text-align: center;
    padding-top: 20px;
`;

const PopoverIcon = styled(Icon)`
    position: absolute;
    right: 0;
    bottom: 15px;
`;

export const TravelTime = (props: ICostComponentProps) => {
    const { dispatch, invoice } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dateType, setDateType] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState<DateRange<Date>>([
        props.currentTravel?.startTime ? new Date(props.currentTravel.startTime) : null,
        props.currentTravel?.endTime ? new Date(props.currentTravel.endTime) : null,
    ]);
    const [rangeStartTime, setRangeStartTime] = useState<Date | null>();
    const [rangeEndTime, setRangeEndTime] = useState<Date | null>();
    const [repeatedDates, setRepeatedDates] = useState<IDatePair[]>([]);

    useEffect(() => {
        const startTime = props.currentTravel?.startTime ? new Date(props.currentTravel.startTime) : null;
        const endTime = props.currentTravel?.endTime ? new Date(props.currentTravel.endTime) : null;
        setDateRange([startTime, endTime]);
        setRangeStartTime(startTime);
        setRangeEndTime(endTime);
    }, [props.currentTravel?.startTime, props.currentTravel?.endTime]);

    const invoiceWorkDates: IDatePair[] =
        invoice?.invoiceItems
            ?.filter((row: IInvoiceItem) => row.startDate || row.endDate)
            ?.map((row: IInvoiceItem) => ({
                endDate: row.endDate,
                startDate: row.startDate,
            })) || [];

    const handleUpdate = (payload: any) => {
        dispatch({ payload, type: 'SAVE_TRAVEL' });
    };

    const handleRemoveDate = (d: IDatePair) => {
        const comparable = moment(d.startDate);
        const dates = repeatedDates.filter((rd) => !moment(rd.startDate).isSame(comparable, 'd'));
        setRepeatedDates(dates);
        handleUpdate({
            repeatingDates: dates,
        });
    };

    const handleDatePopoverOpen = (event: React.MouseEvent<any>, type: string) => {
        setDateType(type);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleDatePopoverClose = (date: Date | null) => {
        setAnchorEl(null);
        if (date && dateType === 'startDate') {
            handleStartDateUpdate(date);
        }
        if (date && dateType === 'endDate') {
            handleEndDateUpdate(date);
        }
    };

    const handleDateRangeFinished = () => {
        handleDatePopoverClose(null);

        const start = moment(dateRange[0]).set({
            hours: rangeStartTime?.getHours(),
            minutes: rangeStartTime?.getMinutes(),
        });
        const end = moment(dateRange[1]).set({
            hours: rangeEndTime?.getHours(),
            minutes: rangeEndTime?.getMinutes(),
        });

        if (!end.isAfter(start, 'd')) {
            throw new Error('End date must be after start date!');
        }
        const dates: IDatePair[] = [];
        const current = moment(start);
        while (current.isSameOrBefore(end)) {
            dates.push({
                endDate: moment(current).set({ hour: end.hour(), minute: end.minute() }).toISOString(),
                startDate: current.toISOString(),
            });
            current.add(1, 'd');
        }
        setRepeatedDates(dates);

        handleUpdate({
            endTime: moment(start).set({ hour: end.hour(), minute: end.minute() }).toISOString(),
            repeatingDates: dates,
            startTime: start.toISOString(),
        });
    };

    const handleStartDateUpdate = (date: Date | null) => {
        const d = dateOrDefault(props.currentTravel?.startTime, new Date());
        if (date) {
            handleUpdate({
                startTime: new Date(date.setHours(d.getHours(), d.getMinutes())).toISOString(),
            });
        }
    };

    const handleEndDateUpdate = (date: Date | null) => {
        const d = dateOrDefault(props.currentTravel?.endTime, new Date());
        if (date) {
            handleUpdate({
                endTime: new Date(
                    date.setHours(
                        d.getHours(),
                        props.currentTravel?.endTime ? d.getMinutes() : d.getMinutes() + 1,
                    ),
                ).toISOString(),
            });
        }
    };

    const dateContainedInWorkDates = datesContainDate(
        {
            endDate: props.currentTravel?.endTime,
            startDate: props.currentTravel?.startTime,
        },
        invoiceWorkDates,
    );

    return (
        <Wrapper>
            <FormSection>
                {!repeatedDates.length ? (
                    <StackableRow>
                        <SplitRow posStart>
                            <FormRow style={{ position: 'relative' }}>
                                <FormLabel htmlFor="travel-start" value={props.currentTravel?.startTime}>
                                    {trans('costs.travel-time.start')}
                                </FormLabel>

                                <DatePicker
                                    disableToolbar
                                    keyboardOnly={!props.currentTravel?.id}
                                    label={trans('costs.travel-time.start-date-label')}
                                    icon={['far', 'calendar-day']}
                                    id="travel-start"
                                    onChange={handleStartDateUpdate}
                                    maxDate={dateOrDefault(props.currentTravel?.endTime)}
                                    minDate={getMinTravelDate(new Date())}
                                    placeholder={trans('dates.placeholder')}
                                    required
                                    value={props.currentTravel?.startTime}
                                    hiddeOpenPickerIcon
                                />

                                {!props.currentTravel?.id && (
                                    //TODO: If error message exist, icon goes down from his position.
                                    <PopoverIcon
                                        className={'small'}
                                        onClick={(e) => handleDatePopoverOpen(e, 'startDate')}
                                        icon={['far', 'calendar-day']}
                                    />
                                )}
                            </FormRow>
                            <FormRow>
                                <FormLabel
                                    id="travel-start-time"
                                    aria-label={trans('costs.travel-time.start-time-label')}
                                    value={props.currentTravel?.startTime}
                                >
                                    <span aria-hidden={'true'}>{trans('costs.travel-time.time')}</span>
                                </FormLabel>
                                <HoursMinutesTime
                                    name="travel-start-time"
                                    onChange={(val) =>
                                        handleUpdate({
                                            startTime: val.toISOString(),
                                        })
                                    }
                                    required
                                    value={dateOrDefault(props.currentTravel?.startTime)}
                                />
                            </FormRow>
                        </SplitRow>
                        <SplitRow posStart>
                            <FormRow style={{ position: 'relative' }}>
                                <FormLabel htmlFor="travel-end" value={props.currentTravel?.endTime}>
                                    {trans('costs.travel-time.end')}
                                </FormLabel>
                                <DatePicker
                                    disableToolbar
                                    keyboardOnly={!props.currentTravel?.id}
                                    label={trans('costs.travel-time.end-date-label')}
                                    icon={['far', 'calendar-day']}
                                    id="travel-end"
                                    onChange={handleEndDateUpdate}
                                    maxDate={getMaxTravelDate(
                                        new Date(),
                                        dateOrDefault(props.currentTravel?.startTime),
                                    )}
                                    minDate={dateOrDefault(
                                        props.currentTravel?.startTime,
                                        getMinTravelDate(new Date()),
                                    )}
                                    placeholder={trans('dates.placeholder')}
                                    required
                                    value={props.currentTravel?.endTime}
                                    hiddeOpenPickerIcon
                                />
                                {!props.currentTravel?.id && (
                                    <PopoverIcon
                                        className={'small'}
                                        onClick={(e) => handleDatePopoverOpen(e, 'endDate')}
                                        icon={['far', 'calendar-day']}
                                    />
                                )}
                            </FormRow>
                            <FormRow>
                                <FormLabel
                                    aria-label={trans('costs.travel-time.end-time-label')}
                                    id="travel-end-time"
                                    value={props.currentTravel?.endTime}
                                >
                                    <span aria-hidden={'true'}>{trans('costs.travel-time.time')}</span>
                                </FormLabel>
                                <HoursMinutesTime
                                    name="travel-end-time"
                                    onChange={(val) =>
                                        handleUpdate({
                                            endTime: val.toISOString(),
                                        })
                                    }
                                    required
                                    value={dateOrDefault(props.currentTravel?.endTime)}
                                />
                            </FormRow>
                        </SplitRow>
                    </StackableRow>
                ) : (
                    <>
                        <Table>
                            <TableBody>
                                {repeatedDates.map((r, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {formatDate(r.startDate!, dateFormatWithWeekday)}
                                        </TableCell>
                                        <TableCell>{formatTime(r.startDate!)}</TableCell>
                                        <TableCell>{formatDate(r.endDate!, dateFormatWithWeekday)}</TableCell>
                                        <TableCell>{formatTime(r.endDate!)}</TableCell>
                                        <TableCell>
                                            <EezyButton
                                                color="purple"
                                                onClick={() => handleRemoveDate(r)}
                                                square
                                                style={{ padding: 0 }}
                                                width={32}
                                            >
                                                <Icon color={COLOR_IMPORTANT} icon={['far', 'trash-alt']} />
                                            </EezyButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <ButtonWrapper>
                            <EezyButton color="purple" onClick={(e) => handleDatePopoverOpen(e, '')} square>
                                {trans('costs.travel-time.edit-dates')}
                            </EezyButton>
                        </ButtonWrapper>
                    </>
                )}

                {dateContainedInWorkDates === false && (
                    <ValidationMessageBlock style={{ marginTop: 8 }} type="warning">
                        {trans('costs.travel-date-warning')}
                    </ValidationMessageBlock>
                )}
            </FormSection>
            <RepeatedTripsPopover
                anchorEl={anchorEl}
                endTime={rangeEndTime ? rangeEndTime : undefined}
                onClose={handleDatePopoverClose}
                range={dateRange}
                onDateRangeChanged={(newRange) => {
                    setDateRange(newRange);
                }}
                onDateRangeFinished={handleDateRangeFinished}
                onEndTimeChanged={setRangeEndTime}
                onStartTimeChanged={setRangeStartTime}
                startTime={rangeStartTime ? rangeStartTime : undefined}
            />
        </Wrapper>
    );
};
