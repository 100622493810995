import { gql } from '@apollo/client';

const UPDATE_RECIPIENT = gql`
    mutation UpdateRecipient($recipientId: String!, $recipient: ClientInput!) {
        updateClient(client: $recipient, id: $recipientId) {
            active
            address {
                country
                street1
                street2
                town
                zipCode
            }
            businessId
            contactPeople {
                active
                firstName
                id
                lastName
            }
            einvoiceOvt
            operator
            email
            firstName
            id
            invoiceLanguage
            invoicingMethod
            lastName
            name
            type
        }
    }
`;

export default UPDATE_RECIPIENT;
