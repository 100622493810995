import styled from 'styled-components';
import { COLOR_GREYHOUND } from '../../styles/variables';
import { trans } from '../../utils';
import { EezyButton } from '../Buttons';
import { Flex } from '../Flex';
import { RowTitle } from '../textElements';
import { Modal, ModalActions, ModalContent } from './Modal';
import TermsEn from './TermsEn';
import TermsFi from './TermsFi';
import { dispatch } from 'utils/store';
import { logout } from 'actions/auth';
import { useTranslation } from 'react-i18next';

declare global {
    interface Window {
        dataLayer: Record<string, any>;
    }
}
interface IModalProps {
    accepted: boolean;
    handleConfirm: (document: string) => void;
    handleModalClose: () => void;
    isOpen: boolean;
    disableClose?: boolean;
}

const Section = styled.div`
    margin: 30px 0;

    p {
        color: ${COLOR_GREYHOUND};
        margin-bottom: 10px;
    }
    div {
        margin-bottom: 20px;
    }
    .p1 {
        margin-left: 5px;
    }
    ul > ul {
        margin-left: 10px;
    }
`;

const TermsModal = (props: IModalProps) => {
    const { i18n } = useTranslation();

    const confirm = () => {
        props.handleConfirm('TERMS');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'CompleteRegistration' });
        props.handleModalClose();
    };

    const renderCloseButton = () => {
        if (props.disableClose) {
            return (
                <Flex style={{ marginTop: 50 }}>
                    <EezyButton
                        color="purple"
                        onClick={() => {
                            dispatch(logout());
                        }}
                    >
                        {trans('login.logout')}
                    </EezyButton>
                </Flex>
            );
        }
        return (
            <Flex style={{ marginTop: 50 }}>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {trans('menu.close')}
                </EezyButton>
            </Flex>
        );
    };

    return (
        <Modal
            id="modal-terms"
            isOpen={props.isOpen}
            onClose={() => {
                if (!props.disableClose) {
                    props.handleModalClose();
                } else {
                    return null;
                }
            }}
            title={trans('dashboard.messages.terms.title')}
            disableClose={props.disableClose}
        >
            <ModalContent>
                <Section>{i18n.language === 'fi' ? <TermsFi /> : <TermsEn />}</Section>

                {!props.accepted && (
                    <Flex spread style={{ marginTop: 10 }}>
                        <RowTitle color="black">{trans('dashboard.modal-confirm')}</RowTitle>
                        <EezyButton dark color="black" onClick={confirm}>
                            {trans('form.confirm')}
                        </EezyButton>
                    </Flex>
                )}
                {!props.accepted && renderCloseButton()}
            </ModalContent>
            {props.accepted && !props.disableClose && (
                <ModalActions style={{ justifyContent: 'center' }}>
                    <EezyButton color="purple" dark onClick={props.handleModalClose}>
                        {trans('menu.close')}
                    </EezyButton>
                </ModalActions>
            )}
        </Modal>
    );
};

export default TermsModal;
