import { gql } from '@apollo/client';

const UPDATE_USER_TWO_FACTOR_AUTHENTICATION = gql`
    mutation UpdateUserTwoFactorAuthentication($input: String) {
        updateUserTwoFactorAuthentication(input: $input) {
            twoFactorAuthentication
        }
    }
`;

export default UPDATE_USER_TWO_FACTOR_AUTHENTICATION;
