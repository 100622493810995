import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import LoadingSpinner from 'components/Loading';
import { useState } from 'react';
import styled from 'styled-components';
import { type ButtonColor, EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { RowTitle, SingleTextHalf } from '../../components/textElements';
import {
    BORDER_RADIUS,
    COLOR_GRAYS_ANATOMY,
    COLOR_GREYHOUND,
    COLOR_BLUM,
    SCREEN_M,
    SCREEN_S,
} from '../../styles/variables';
import { formatDate, formatDay, trans } from '../../utils';

interface IStyledNotificationProps {
    backgroundColor?: string;
    $isRead?: boolean;
}

const StyledNotification = styled.div`
    background-color: ${(props: IStyledNotificationProps) =>
        props.backgroundColor ? props.backgroundColor : COLOR_GRAYS_ANATOMY};
    border-radius: ${BORDER_RADIUS};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;

    & .icon-date ${Icon} {
        margin: 5px;
    }

    & div.icon-date {
        align-items: center;
        min-width: 45px;
        width: 45px;
        span {
            color: ${COLOR_GREYHOUND};
            font-size: 13px;
            text-align: center;
        }
    }

    & div.content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 15px;
        overflow: hidden;

        @media (max-width: ${SCREEN_M}px) {
            margin: 0 10px;
        }
        ${RowTitle} {
            line-height: 20px;
            padding-bottom: 4px;
            word-break: break-word;
        }
        ${SingleTextHalf} {
            display: inline-block;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            * {
                color: ${COLOR_GREYHOUND};
                font-size: 15px;
                font-weight: normal;
            }
        }
    }

    &&& div.buttons {
        button {
            margin: 5px 0;
            min-width: 75px;

            svg {
                margin: 0 5px;
            }
        }
        @media (min-width: ${SCREEN_S}px) {
            flex-direction: row;

            button {
                margin: 0 0 0 5px;
                min-width: 110px;
            }
        }
    }

    & ~ &,
    & ~ div.custom-notification,
    div.custom-notification ~ & {
        margin-top: 10px;
    }
`;

export interface INotificationProps {
    backgroundColor?: string;
    buttonColor?: ButtonColor;
    buttonIcon?: IconProp;
    buttonTransKey: string;
    date?: string;
    icon: IconProp;
    iconColor: string;
    isRead: boolean;
    onArchive?: () => void;
    onOpen?: () => void;
    text?: string;
    textHtml?: string;
    title: string;
}

export const Notification = (props: INotificationProps) => {
    const [hideDisabled, setHideDisabled] = useState(false);
    const handleHideClick = () => {
        setHideDisabled(true);
        props.onArchive?.();
    };

    const isThisYear = props.date && new Date(props.date).getFullYear() === new Date().getFullYear();

    return (
        <StyledNotification backgroundColor={props.backgroundColor}>
            <Flex column className="icon-date">
                <Icon icon={props.icon} color={props.iconColor} />
                {props.date && (
                    <div className="flex flex-col">
                        <span title={formatDate(props.date)}>{formatDay(props.date)}</span>
                        {!isThisYear && <span className="-mt-1">{formatDate(props.date, 'YYYY')}</span>}
                    </div>
                )}
            </Flex>
            <div className="content">
                <RowTitle color="black">{props.title}</RowTitle>

                {props.text ? (
                    <SingleTextHalf title={props.text}>{props.text}</SingleTextHalf>
                ) : props.textHtml ? (
                    <SingleTextHalf dangerouslySetInnerHTML={{ __html: props.textHtml }} />
                ) : (
                    <p />
                )}
            </div>
            <Flex column className="buttons">
                {props.onArchive && (
                    <EezyButton color="purple" onClick={handleHideClick} disabled={hideDisabled}>
                        {hideDisabled ? (
                            <LoadingSpinner style={{ marginTop: 7 }} size={15} color={COLOR_BLUM} />
                        ) : (
                            trans('dashboard.hide-button')
                        )}
                    </EezyButton>
                )}
                {props.onOpen && (
                    <EezyButton color={props.buttonColor || 'purple'} dark onClick={props.onOpen}>
                        {trans(props.buttonTransKey)}
                        {props.buttonIcon && <Icon icon={props.buttonIcon} color="white" />}
                    </EezyButton>
                )}
            </Flex>
        </StyledNotification>
    );
};
