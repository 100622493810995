import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { MenuButton } from 'components/common/MenuButton';
import { type DateFilter, isWithinSingleMonth } from 'utils/statistics/dateUtils';
import {
    type SalesPageStatisticSuggestion,
    useStatisticSuggestions,
} from 'hooks/statistics/useStatisticSuggestions';

export type StatisticsBillingSuggestionsProps = {
    dateFilter: DateFilter;
    chartDataDateStrings: string[];
    onSelect: (value: SalesPageStatisticSuggestion) => void;
    minDate: Date;
};

const StatisticsBillingSuggestions = (props: StatisticsBillingSuggestionsProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const { dateRangeSuggestions, disabled, years, months } = useStatisticSuggestions({
        ...props,
        selectSuggestion: props.onSelect,
        closeModal: () => setOpen(false),
    });

    return (
        <div className="flex flex-col md:flex-row md:items-center gap-3">
            {!disabled && (
                <div className="flex gap-3">
                    {dateRangeSuggestions.map((i) => (
                        <div key={i.label}>
                            <Button
                                startIcon={['far', 'plus']}
                                variant="outline"
                                className="capitalize"
                                onClick={() => props.onSelect(i)}
                            >
                                {i.label}
                            </Button>
                        </div>
                    ))}
                </div>
            )}

            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button variant="outline" startIcon={['far', 'calendar']} disabled={disabled}>
                        {t('statistic.select')}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0 border border-gray-200">
                    <div className="flex">
                        <div className="p-2 flex flex-col gap-1 border-r border-gray-200 min-w-[135px]">
                            {years.map((year) => (
                                <MenuButton
                                    key={`year-${year.value}`}
                                    onClick={year.select}
                                    selected={year.selected}
                                    disabled={year.disabled}
                                >
                                    {year.label}
                                </MenuButton>
                            ))}
                        </div>
                        {isWithinSingleMonth(props.dateFilter) && (
                            <div className="p-2 flex flex-col gap-1 border-r border-gray-200 min-w-[135px]">
                                {months.map((month) => (
                                    <MenuButton
                                        key={`month-${month.value}`}
                                        onClick={month.select}
                                        disabled={month.disabled}
                                    >
                                        {month.label}
                                    </MenuButton>
                                ))}
                            </div>
                        )}
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default StatisticsBillingSuggestions;
