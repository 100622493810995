import { useTranslation } from 'react-i18next';
import { Hidden } from '@mui/material';
import { Icon } from './Icon';

export const PenButton = ({ onClick }: { onClick: any }) => {
    const { t } = useTranslation();
    return (
        <Hidden smUp>
            <button
                type="button"
                aria-label={t('general.edit') || ''}
                onClick={onClick}
                style={{
                    float: 'right',
                    marginLeft: 10,
                    marginRight: -10,
                }}
            >
                <Icon icon={['fal', 'pen']} style={{ height: 15, width: 15 }} />
            </button>
        </Hidden>
    );
};
