import { gql } from '@apollo/client';

const RETURN_TURNED_BACK_INVOICE = gql`
    mutation ReturnTurnedBackInvoice($id: Int!) {
        returnTurnedBackInvoice(invoiceId: $id) {
            id
            costInvoice {
                id
                status
            }
            status
        }
    }
`;

export default RETURN_TURNED_BACK_INVOICE;
