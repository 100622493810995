import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import type { INotification } from '../../../../shared/src/types/notifications';
import { showModals } from 'actions/auth';
import { EezyButton } from 'components/Buttons';
import { HeaderRow } from 'components/HeaderRow';
import LoadingSpinner from 'components/Loading';
import { UniqueTitle } from 'components/textElements';
import styled from 'styled-components';
import NotificationsItem from './NotificationsItem';
import { BORDER_RADIUS, COLOR_WHITE_WALKER } from 'styles/variables';
import { subtractTwoWeeks } from 'utils';
import { getLinkHref, getStaticNotificationIconColor } from 'utils/notifications/notifications';
import { Notification } from './Notification';
import NotificationModal from './NotificationModal';
import LIST_NOTIFICATIONS from './queries/listNotifications';
import SET_HIDE_NOTIFICATION from './queries/setHideNotification';
import SET_READ_NOTIFICATION from './queries/setReadNotification';
import { useUser } from 'queries/useUserQuery';
import moment from 'moment';

const shouldHideNotification = (notification: INotification, regDate: string): boolean => {
    const dateLimitForHiding = subtractTwoWeeks(regDate);
    return new Date(notification.date) < new Date(dateLimitForHiding);
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    width: 100%;
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    padding: 27px 20px 20px;
`;

type NotificationsData = {
    getNotifications: INotification[];
};

const Notifications = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    const user = useUser();

    const [visibleNotification, setVisibleNotification] = useState<INotification | undefined>(undefined);
    const [showArchive, setShowArchive] = useState(false);

    const el = useRef<HTMLDivElement>(null);

    const { data: notificationsData, loading } = useQuery<NotificationsData>(LIST_NOTIFICATIONS, {
        errorPolicy: 'all',
        variables: {
            dateLimitForHiding: subtractTwoWeeks(user?.registrationDate ?? ''),
            lang: i18n.language,
            showHidden: showArchive,
            registrationDate: user?.registrationDate,
        },
        skip: !user,
    });

    const notifications = useMemo(
        () =>
            notificationsData?.getNotifications.filter((i) => {
                if (showArchive) return i.isHidden || shouldHideNotification(i, user?.registrationDate ?? '');
                // never show notifications older than 180 days from registration date
                if (moment(user?.registrationDate).subtract(180, 'days').isAfter(moment(i.date))) {
                    return false;
                }

                return !i.isHidden && !shouldHideNotification(i, user?.registrationDate ?? '');
            }) ?? [],
        [showArchive, notificationsData, user],
    );

    const [hideNotification] = useMutation(SET_HIDE_NOTIFICATION, {
        refetchQueries: [LIST_NOTIFICATIONS],
    });

    const [readNotification] = useMutation(SET_READ_NOTIFICATION, {
        refetchQueries: [LIST_NOTIFICATIONS],
    });

    const handleNotificationOpen = (notification: INotification) => {
        const link = getLinkHref(notification);
        if (link) {
            navigate(link);
        } else if (notification.actionType === 'expense') {
            setVisibleNotification(notification);
        } else {
            setVisibleNotification(notification);
            readNotification({
                variables: {
                    id: notification.id,
                    announcementId: notification.announcementId,
                },
            });
        }
    };

    const handleNotificationArchive = (notification: INotification) => {
        hideNotification({
            variables: {
                id: notification.id,
                announcementId: notification.announcementId,
            },
        });
    };

    useEffect(() => {
        const { pathname } = location;
        if (['/notifications'].includes(pathname)) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, []);

    return (
        <Wrapper ref={el}>
            <HeaderRow>
                <UniqueTitle>{t('dashboard.messages.title')}</UniqueTitle>

                <EezyButton color="purple" dark onClick={() => setShowArchive(!showArchive)} width={150}>
                    {showArchive ? t('dashboard.only-new') : t('dashboard.only-archive')}
                </EezyButton>
            </HeaderRow>

            {!showArchive && !user?.isIdentified && (
                <Notification
                    icon={['far', 'exclamation-circle']}
                    iconColor={getStaticNotificationIconColor(false)}
                    isRead={false}
                    title={t('dashboard.messages.auth.title')}
                    text={t('dashboard.messages.auth.text') || ''}
                    buttonTransKey="dashboard.message-button"
                    onOpen={() => dispatch(showModals(['AUTH']))}
                />
            )}

            {!showArchive && user?.yelShouldAsk && (
                <Notification
                    icon={['far', 'exclamation-circle']}
                    iconColor={getStaticNotificationIconColor(false)}
                    isRead={false}
                    title={t('dashboard.messages.yel.title')}
                    text={t('dashboard.messages.yel.text') || ''}
                    buttonTransKey="dashboard.message-button"
                    onOpen={() => dispatch(showModals(['YEL']))}
                />
            )}

            {loading && <LoadingSpinner color="#351f67" style={{ marginTop: 50 }} />}

            {notifications.map((item: INotification) => (
                <NotificationsItem
                    key={item.id || item.announcementId}
                    isIdentified={Boolean(user?.isIdentified)}
                    data={item}
                    onOpen={handleNotificationOpen}
                    onArchive={handleNotificationArchive}
                />
            ))}

            <NotificationModal
                isOpen={!!visibleNotification}
                handleModalClose={() => setVisibleNotification(undefined)}
                notification={visibleNotification}
            />
        </Wrapper>
    );
};

export default Notifications;
