import throttle from 'lodash.throttle';

export interface IPlaceType {
    description: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}

const autocompleteService = { current: null };
const directionService = { current: null };
const directionsRendererService = { current: null };

export const checkServices = () => {
    if (!autocompleteService.current && (window as any).google) {
        autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
        return false;
    }
    return true;
};

export const autoCompleteFetchPlace = throttle(
    (request: { input: string }, callback: (results?: IPlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(
            {
                ...request,
                componentRestrictions: {
                    country: 'fi',
                },
            },
            callback,
        );
    },
    200,
);

export const checkDirectionsServices = () => {
    if (!directionService.current && (window as any).google) {
        directionService.current = new (window as any).google.maps.DirectionsService();
    }
    if (!directionService.current) {
        return false;
    }
    return true;
};

interface IDirectionData {
    origin: string;
    destination: string;
    travelMode: string;
    waypoints?: Array<{ location: string }>;
    drivingOptions?: any;
}

export const getDirections = throttle((request: IDirectionData, callback: (results?: any) => void) => {
    (directionService.current as any).route(request, callback);
}, 200);

export const checkDirectionsRendererServices = () => {
    if (!directionsRendererService.current && (window as any).google) {
        directionsRendererService.current = new (window as any).google.maps.DirectionsRenderer();
    }
    if (!directionsRendererService.current) {
        return false;
    }
    return true;
};

export interface IDirectionsResult {
    geocoded_waypoints: any[];
    request: any;
    routes: any[];
    status: string;
}

// https://developers.google.com/maps/documentation/javascript/controls

export const initMapWithDirections = (element: HTMLElement | null, data: IDirectionsResult) => {
    const mapOptions = {
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
    };

    const map = new (window as any).google.maps.Map(element, mapOptions);
    (directionsRendererService.current as any).setMap(map);
    (directionsRendererService.current as any).setDirections(data);
};

export const initMap = (element: HTMLElement | null) => {
    const mapOptions = {
        center: { lat: 60.163, lng: 24.913 },
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoom: 7,
        zoomControl: false,
    };

    const map = new (window as any).google.maps.Map(element, mapOptions);
    (directionsRendererService.current as any).setMap(map);
};

export const getDistance = (directions: IDirectionsResult) => {
    return directions.routes[0].legs.reduce((total: number, leg: any) => total + leg.distance.value, 0);
};
