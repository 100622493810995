import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { P, RowTitle } from '../../components/textElements';
import { REPORT_SHOULD_HAVE_YEL } from './queries';
import { useTranslation } from 'react-i18next';
import { useUserQuery } from 'queries/useUserQuery';

interface IModalProps {
    accepted?: boolean;
    answered?: boolean;
    handleModalClose: () => void;
    isOpen: boolean;
}

const YelModal = (props: IModalProps) => {
    const { t, i18n } = useTranslation();
    const { refetch } = useUserQuery();

    const [reportYel] = useMutation(REPORT_SHOULD_HAVE_YEL, {
        onCompleted: () => {
            toast(t('general.saved'));
            refetch();
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const handleConfirm = (shouldHave: boolean) => {
        reportYel({ variables: { shouldHave } });
        props.handleModalClose();
    };
    return (
        <Modal
            id="modal-yel"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('dashboard.messages.yel.modal-title')}
        >
            <ModalContent>
                <P color="black">
                    {t('dashboard.messages.yel.1')}
                    <a
                        href={
                            i18n.language === 'fi'
                                ? 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/kevytyrittajan-vakuutukset/yel-vakuutus/'
                                : 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/light-entrepreneurs-insurances/yel-insurance/'
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t('dashboard.messages.yel.link1-anchor')}
                    </a>
                    {t('dashboard.messages.yel.1-end')}
                </P>

                <P color="black">{t('dashboard.messages.yel.2')}</P>

                <Flex spread style={{ marginTop: 30 }}>
                    <RowTitle color="black">{t('dashboard.messages.yel.modal-confirm')}</RowTitle>
                    <div>
                        <EezyButton
                            color="black"
                            disabled={props.answered}
                            onClick={() => handleConfirm(true)}
                            style={
                                props.accepted
                                    ? {
                                          backgroundColor: 'black',
                                          color: 'white',
                                      }
                                    : {}
                            }
                        >
                            {t('general.yes')}
                        </EezyButton>
                        <EezyButton
                            color="black"
                            disabled={props.answered}
                            onClick={() => handleConfirm(false)}
                            style={
                                !props.accepted && props.answered
                                    ? {
                                          backgroundColor: 'black',
                                          color: 'white',
                                      }
                                    : {}
                            }
                        >
                            {t('general.no-I')}
                        </EezyButton>
                    </div>
                </Flex>
            </ModalContent>
            <ModalActions style={{ justifyContent: 'center' }}>
                <EezyButton color="purple" dark onClick={props.handleModalClose}>
                    {t('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};
export default YelModal;
