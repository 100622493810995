import styled from 'styled-components';
import { COLOR_BLUM, SCREEN_S, TOPBAR_HEIGHT } from '../styles/variables';

export default styled.div`
    align-items: center;
    background-color: ${COLOR_BLUM};
    display: flex;
    height: calc(${TOPBAR_HEIGHT}px - 8px);
    justify-content: space-between;
    min-height: 55px; /* for safari */

    padding: 0 15px 0px 15px;
    @media (min-width: ${SCREEN_S}px) {
        padding: 0 30px 0px 15px;
    }
`;
