import { LinearProgress, Popper, Slide } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { showModals } from 'actions/auth';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { Line } from 'components/Lines';
import { BodyPSmall, LabelCapsSmall, LabelMedium } from 'components/textElements';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_GREY_FOG,
    COLOR_GREYHOUND,
    COLOR_IMPORTANT,
    COLOR_STATUS_WAITING,
    COLOR_WHITE_WALKER,
} from 'styles/variables';
import { formatPercentage, trans } from 'utils';
import { getStepsDone, type IGuider, type IStep, sortSteps } from 'utils/guider/guiderUtils';
import GuiderItem from './GuiderItem';

/*
    Use NewUserGuider as an example of how to create a new guider with unique steps
*/

/*const BorderLinearProgress = withStyles(LinearProgress,
    () => createStyles({
        bar: {
            backgroundColor: COLOR_STATUS_WAITING,
            borderRadius: 5
        },
        colorPrimary: {
            backgroundColor: COLOR_GREY_FOG
        },
        root: {
            borderRadius: 10,
            height: 10,
            width: '100%'
        }
    })
);*/

const BorderLinearProgress = muiStyled(LinearProgress)(() => ({
    //TODO: test styles! and delete comented part
    borderRadius: 10,
    height: 10,
    width: '100%',
    '.MuiLinearProgress-bar': {
        backgroundColor: COLOR_STATUS_WAITING,
        borderRadius: 5,
    },
    '.MuiLinearProgress-colorPrimary': {
        backgroundColor: COLOR_GREY_FOG,
    },
}));

const GuiderBackground = styled.div`
    background-color: white;
    border-radius: ${BORDER_RADIUS};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    margin-bottom: 28px;
    min-width: 270px;
    padding: 15px 20px 15px 20px;
    position: relative;
`;

const NumberBubble = styled(Flex).attrs({
    center: true,
    justifyCenter: true,
})`
    background-color: ${COLOR_IMPORTANT};
    border-radius: 16px;
    color: ${COLOR_WHITE_WALKER};
    height: 23px;
    position: absolute;
    right: -11px;
    top: -11px;
    width: 23px;
`;

const Arrow = styled.div`
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid white;
    bottom: -16px;
    height: 0;
    position: absolute;
    right: 50px;
    width: 0;
`;

interface IProps {
    defaultOpen?: boolean;
    guider: IGuider;
    showModals: (modals: string[]) => void;
}

const Guider = (props: IProps) => {
    const stepCount = props.guider.steps.length;
    const stepsDone = getStepsDone(props.guider.steps);
    const stepsUndone = stepCount - stepsDone;
    const isActionDone = props.guider.finalAction.isDone;
    const progress = Math.round((stepsDone / stepCount) * 100);
    const isDone = !stepsUndone && isActionDone;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [guiderOpen, setGuiderOpen] = useState(false);
    const [buttonHidden, setButtonHidden] = useState(true);
    const [buttonPosition, setButtonPosition] = useState('calc(100vh - 24px - 38px)'); // Because of CSS body { container-type: ... }, we cannot use position: fixed with bottom relative to viewport

    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const labelClose = trans(props.guider.closeLabel);
    const labelOpen = trans(props.guider.openLabel);
    const labelArialFab = trans('guider.fab-aria-label', { amount: stepsUndone });

    useEffect(() => {
        if (props.defaultOpen) {
            // Button needs to be mounted first so that popper can be anchored to it
            setButtonHidden(false);
            setTimeout(() => {
                if (anchorRef.current) {
                    setAnchorEl(anchorRef.current);
                }
                setGuiderOpen(true);
            }, 200);
        }
    }, [props.defaultOpen]);

    useEffect(() => {
        if (isDone) {
            // Steps are done. First close the guider with animation,
            // then hide the button
            setGuiderOpen(false);
            setTimeout(() => {
                setButtonHidden(true);
            }, 200);
        } else {
            // Prevent flashing button when guider should not be shown at all
            setButtonHidden(false);
        }
    }, [isDone, setGuiderOpen]);

    useEffect(() => {
        const onScroll = () => {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            // const bottomPadding = '24px';
            // const buttonheight = '38px';
            setButtonPosition(`calc(100vh + ${scrollY}px - 24px - 38px)`);
        };
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        setGuiderOpen(!guiderOpen);
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    return (
        <>
            {!buttonHidden && (
                <EezyButton
                    aria-label={labelArialFab}
                    color="pink-gradient"
                    dark
                    hasIcon
                    onClick={handleClick}
                    ref={anchorRef}
                    style={{
                        // bottom & right are the same for giosg cht widget,
                        // defined in giosg chat window position settings
                        top: buttonPosition,
                        position: 'absolute', // Due to CSS body { container-type }, cannot use position: fixed
                        right: 24,
                        zIndex: 3,
                        transition: 'all 0s ease',
                    }}
                    width={140}
                >
                    <Icon icon={['far', 'pennant']} color={COLOR_BLUM} className="small" />
                    {guiderOpen ? labelClose : labelOpen}
                    {stepsUndone > 0 && (
                        <NumberBubble aria-hidden={true}>
                            <span>{stepsUndone}</span>
                        </NumberBubble>
                    )}
                </EezyButton>
            )}

            <Popper
                anchorEl={anchorEl}
                id={`guider-popper-${props.guider.name}`}
                disablePortal={false}
                modifiers={[
                    // TODO: check is it working!
                    {
                        enabled: true,
                        name: 'preventOverflow',
                        options: {
                            boundariesElement: 'scrollParent',
                        },
                    },
                    {
                        enabled: false,
                        name: 'flip',
                    },
                ]}
                open={guiderOpen && !isDone}
                placement="top-end"
                style={{ zIndex: 3 }}
                transition
            >
                {({ TransitionProps }) => (
                    <Slide {...TransitionProps} direction="up" mountOnEnter unmountOnExit>
                        <GuiderBackground>
                            <Arrow />

                            <LabelMedium color={COLOR_BLACKWATER}>{trans(props.guider.title)}</LabelMedium>

                            {props.guider.subTitle && (
                                <BodyPSmall color={COLOR_GREYHOUND}>
                                    {trans(props.guider.subTitle)}
                                </BodyPSmall>
                            )}
                            <Flex fullWidth center style={{ marginTop: 10 }}>
                                <LabelCapsSmall
                                    style={{
                                        marginRight: 10,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {trans('form.percent', {
                                        percentage: formatPercentage(progress),
                                    })}
                                </LabelCapsSmall>
                                <Flex fullWidth>
                                    <BorderLinearProgress variant="determinate" value={progress} />
                                </Flex>
                            </Flex>

                            <Line style={{ margin: '20px -20px' }} />

                            <Flex column>
                                {props.guider.steps.sort(sortSteps).map((step: IStep) => {
                                    return (
                                        <GuiderItem
                                            key={step.title}
                                            showModals={props.showModals}
                                            step={step}
                                            type="step"
                                        />
                                    );
                                })}
                                <GuiderItem
                                    notAvailable={stepsUndone > 0}
                                    showModals={props.showModals}
                                    step={props.guider.finalAction}
                                    type="step"
                                />
                            </Flex>
                        </GuiderBackground>
                    </Slide>
                )}
            </Popper>
        </>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(null, mapDispatchToProps)(Guider);
