import type { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { COLOR_IMPORTANT, COLOR_WHITE_WALKER } from '../styles/variables';

const StyledErrorBox = styled.div`
    background: ${COLOR_IMPORTANT};
    border-radius: 4px;
    color: ${COLOR_WHITE_WALKER};
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 20px;
    padding: 10px;

    p.heading {
        margin-bottom: 5px;
    }
    p,
    span {
        color: ${COLOR_WHITE_WALKER};
    }

    & ~ & {
        margin-top: 15px;
    }
`;

interface IErrorBoxProps {
    children: ReactNode;
    style?: CSSProperties;
}

const ErrorBox = (props: IErrorBoxProps) => {
    return (
        <StyledErrorBox role="alert" style={props.style}>
            {props.children}
        </StyledErrorBox>
    );
};

export default ErrorBox;
