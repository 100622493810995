import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_DARK_GRAY,
    COLOR_GREY_FOG,
    COLOR_GREYJOY,
    SCREEN_S,
} from '../../styles/variables';

export const CostInvoiceTable = styled.table`
    border-collapse: collapse;
    color: ${COLOR_DARK_GRAY};
    overflow: hidden;
    &:not(:first-of-type) {
        margin-top: 60px;
    }

    thead {
        border-bottom: 3px solid ${COLOR_BLACKWATER};

        tr {
            min-height: 30px;
        }
        th {
            color: ${COLOR_BLACKWATER};
            font-size: 13px;
            text-transform: uppercase;
            @media (min-width: ${SCREEN_S}px) {
                font-size: 15px;
            }
            &:first-child {
                text-align: left;
            }
            &:not(:first-child) {
                text-align: right;
            }
        }
    }

    tbody tr {
        font-size: 13px;
        &.open,
        &.closed {
            height: 50px;
            min-height: 50px;
        }

        @media (min-width: ${SCREEN_S}px) {
            font-size: 15px;
        }
        &.closed td {
            border-bottom: 2px solid ${COLOR_GREY_FOG};
        }
        &.open td {
            border-bottom: 1px dashed ${COLOR_GREYJOY};
        }
        td {
            word-break: break-word;
        }
        & td:first-child {
            padding: 5px 0;
            width: 100%;
        }
        &:not(.details) td:last-child {
            text-align: center;
        }
        & td:not(:first-child):not(:last-child) {
            min-width: 70px;
            padding-left: 5px;
            text-align: right;
        }
        & td:not(:first-child) {
            @media (min-width: ${SCREEN_S}px) {
                padding-left: 10px;
                white-space: nowrap;
            }
        }
        &.details {
            td {
                padding: 15px 0;
                border-bottom: 2px solid ${COLOR_GREY_FOG};
            }
        }
        div.rejected,
        td.rejected {
            text-decoration: line-through;
        }
        div.files {
            word-break: break-all;
            a {
                margin-right: 10px;
            }
        }
    }

    &&& svg {
        font-size: 15px;
        height: 15px;
        width: 15px;
    }
    button,
    a {
        margin: 2px;
    }
`;
