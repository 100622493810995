import type { ReactNode } from 'react';
import type { IInvoice } from '../../../../../shared/src/types/invoice';
import PostIt from '../../../components/PostIt';
import { trans } from '../../../utils';
import TurnBackReason from '../TurnBackReason';

interface IPaddingProps {
    children: ReactNode;
    padded?: boolean;
}

const Padding = (props: IPaddingProps) => {
    if (props.padded) {
        return <div style={{ padding: 15 }}>{props.children}</div>;
    }
    return <>{props.children}</>;
};

interface IProps {
    padded?: boolean;
    square?: boolean;
}

export const CostInvoiceSendingReminderPostIt = (props: IProps) => {
    return (
        <Padding padded={props.padded}>
            <PostIt square={props.square}>{trans('costs.sending-reminder')}</PostIt>
        </Padding>
    );
};

interface ITurnbackReasonPostItProps extends IProps {
    editView?: boolean;
    invoice?: IInvoice;
}

export const InvoiceTurnbackReasonPostIt = (props: ITurnbackReasonPostItProps) => {
    return (
        <Padding padded={props.padded}>
            <PostIt square={props.square}>
                <TurnBackReason
                    titleTransKey={props.editView ? 'invoice.turnBackTitle2' : 'invoice.turnBackTitle1'}
                    turnBackReason={props.invoice?.turnBackReason}
                />
            </PostIt>
        </Padding>
    );
};

export const InvoiceSendingFailedPostIt = (props: IProps) => {
    return (
        <Padding padded={props.padded}>
            <PostIt square={props.square}>
                <p>
                    <b>{trans('invoice.sending-failed-1')}</b>
                </p>
                <p>{trans('invoice.sending-failed-2')}</p>
            </PostIt>
        </Padding>
    );
};

export const CostTurnbackReasonPostIt = (props: ITurnbackReasonPostItProps) => {
    return (
        <Padding padded={props.padded}>
            <PostIt square={props.square}>
                <TurnBackReason
                    titleTransKey="costs.turnBackTitle"
                    turnBackReason={props.invoice?.costInvoice?.turnBackReason}
                >
                    {props.editView ? (
                        <>
                            <p>{trans('costs.turnBackDescEdit1')}</p>
                            <p>{trans('costs.turnBackDescEdit2')}</p>
                            <p>
                                {trans('costs.turnBackDescEdit3', {
                                    costInvoiceNumber: props.invoice?.costInvoice?.costInvoiceNumber,
                                })}
                            </p>
                        </>
                    ) : (
                        <>
                            <p>{trans('costs.turnBackDescPreview1')}</p>
                            <p>
                                {trans('costs.turnBackDescPreview2', {
                                    costInvoiceNumber: props.invoice?.costInvoice?.costInvoiceNumber,
                                })}
                            </p>
                        </>
                    )}
                </TurnBackReason>
            </PostIt>
        </Padding>
    );
};
