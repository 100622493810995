import { gql } from '@apollo/client';

const GET_INVOICE = gql`
    query Invoice($id: Int!, $isGroupInvoice: Boolean) {
        invoice(id: $id, isGroupInvoice: $isGroupInvoice) {
            clientOrderNumber
            clientIdSiteNumber
            clientSiteNumber
            collectionRequested
            contactPersonId
            contactPersonName
            costInvoice {
                allowancesTotal
                id
                costInvoiceNumber
                messageToEezy
                preAccepted
                receiptCosts {
                    amountsWithVats {
                        amount
                        id
                        vatPercent
                    }
                    createTime
                    description
                    id
                    purchaseDate
                    receiptFiles {
                        contentType
                        createTime
                        id
                        name
                        previewUrl
                        url
                    }
                    status
                    type
                }
                status
                travels {
                    allowancesTotal
                    dailyAllowancesFull {
                        quantity
                        salaryType
                        status
                        unitPrice
                    }
                    dailyAllowancesHalf {
                        quantity
                        status
                        unitPrice
                    }
                    endTime
                    id
                    receiptCosts {
                        amountsWithVats {
                            amount
                            id
                            vatPercent
                        }
                        createTime
                        description
                        id
                        purchaseDate
                        receiptFiles {
                            contentType
                            createTime
                            id
                            name
                            previewUrl
                            url
                        }
                        status
                        type
                    }
                    reimbursedKMs {
                        quantity
                        status
                        unitPrice
                    }
                    reimbursedMeals {
                        quantity
                        status
                        unitPrice
                    }
                    reimbursedTrailerKMs {
                        quantity
                        status
                        unitPrice
                    }
                    route
                    startTime
                    taxable
                    totalKMs
                    type
                    carNumber
                }
                turnBackReason
            }
            createdBy {
                id
                name
            }
            creditRequested
            debtCollection
            description
            dispatchDate
            draftNumber
            eezyExpressStatus
            files {
                id
                name
                size
                url
            }
            finalityConfirmationSent
            dueDate
            fillType
            id
            interestPercentage
            invoiceNumber
            invoiceDate
            invoiceItems {
                description
                endDate
                id
                itemType
                price
                quantity
                quantityUnit
                startDate
                totalPrice
                totalPriceWithVat
                vat
                withVat
            }
            isGroupInvoice
            messageToEezy
            notificationDays
            occupation
            paymentInformation {
                paidAmount
                referenceNumber
                reimbursedAmount
                totalWithVat
                unpaidAmount
            }
            paymentTerm
            provisions {
                name
                fixedAmount
                occupationId
                percentage
                personId
                travelExpenseVolume
                travelExpenses
            }
            recipient {
                active
                address {
                    country
                    street1
                    street2
                    town
                    zipCode
                }
                businessId
                contactPeople {
                    active
                    firstName
                    id
                    lastName
                }
                einvoiceOvt
                operator
                email
                firstName
                id
                invoiceLanguage
                invoicingMethod
                lastName
                name
                type
            }
            salaryPaymentStatus
            sendingFailed
            status
            template
            total
            totalVats {
                percentage
                total
            }
            totalWithVat
            turnBackReason
            url
        }
    }
`;

export default GET_INVOICE;
