import type { MutationFunctionOptions } from '@apollo/client';
import type { IUserBasicData } from '../../../../shared/src/types/user';
import type { IConfirmAction } from '../../actions/app';
import { AutoSaveCard } from '../../components/cards';
import { trans } from '../../utils';
import { ProfileSettingsLanguage } from './ProfileSettingsLanguage';
import { ProfileSettingsNotifyInvoicePaid } from './ProfileSettingsNotifyInvoicePaid';
import { ProfileSettingsPassword } from './ProfileSettingsPassword';
import { ProfileSettingsRemoveAccount } from './ProfileSettingsRemoveAccount';
import { ProfileSettingsStorageConsent } from './ProfileSettingsStorageConsent';
import { ProfileSettings2FA } from './ProfileSettings2FA';
import { ProfileSettingsCookieHub } from './ProfileSettingsCookieHub';

interface IProps {
    confirmAction: (payload: IConfirmAction) => void;
    userData: IUserBasicData;
    updateUserData: (options?: MutationFunctionOptions<any, Record<string, any>> | undefined) => Promise<any>;
}

export const ProfileSettings = (props: IProps) => {
    return (
        <AutoSaveCard title={trans('profile:settings.title')}>
            <ProfileSettingsLanguage userData={props.userData} />
            <ProfileSettingsNotifyInvoicePaid
                userData={props.userData}
                updateUserData={props.updateUserData}
            />
            <ProfileSettingsStorageConsent userData={props.userData} updateUserData={props.updateUserData} />
            <ProfileSettings2FA />
            <ProfileSettingsPassword />
            <ProfileSettingsRemoveAccount email={props.userData?.email} />
            <ProfileSettingsCookieHub />
        </AutoSaveCard>
    );
};
