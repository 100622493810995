import React, { type CSSProperties } from 'react';
import { EezyButton } from '../../components/Buttons';
import { trans } from '../../utils';
import DownloadTaxReportModal from './DownloadTaxReportModal';

interface IProps {
    disabled?: boolean;
    style?: CSSProperties;
}

export const DownloadTaxReport = (props: IProps) => {
    const [modalOpen, setModalOpen] = React.useState(false);

    return (
        <div style={props.style}>
            <EezyButton
                dark
                color="purple"
                disabled={props.disabled}
                onClick={() => setModalOpen(true)}
                width={165}
            >
                {trans('salaries:taxCard.download-report')}
            </EezyButton>

            {modalOpen && (
                <DownloadTaxReportModal handleModalClose={() => setModalOpen(false)} isOpen={modalOpen} />
            )}
        </div>
    );
};
