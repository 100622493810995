import styled from 'styled-components';
import { BORDER_RADIUS, COLOR_WHITE_WALKER, SCREEN_M } from '../../styles/variables';

export const DashboardCard = styled.div`
    background: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (min-width: ${SCREEN_M}px) {
        padding: 32px;
        gap: 28px;
    }
`;
