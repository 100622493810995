import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';
import { confirmAction, type IConfirmAction } from '../../actions/app';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import LoadingSpinner from '../../components/Loading';
import { COLOR_IMPORTANT, SCREEN_S } from '../../styles/variables';
import { trans } from '../../utils';
import { GET_INVOICE } from '../invoice/queries';
import { DELETE_COST, DELETE_TRAVEL } from './queries';
import styled from 'styled-components';

interface IProps {
    confirmAction: (payload: IConfirmAction) => void;
    costId: number;
    costInvoiceId: number;
    description: string;
    invoiceId: number;
    isGroupInvoice: boolean;
    type: 'cost' | 'travel';
}

const EezyButtonStyled = styled(EezyButton)`
    width: 38px !important;
    min-width: auto !important;

    @media (max-width: ${SCREEN_S}px) {
        width: 44px !important;
    }
`;

const DeleteCost = (props: IProps) => {
    const [deleteInvoiceCost, { loading: deleteCostLoading }] = useMutation(DELETE_COST, {
        onError: () => toast.error(trans('errors.general')),
        refetchQueries: () => [
            {
                query: GET_INVOICE,
                variables: {
                    id: props.invoiceId,
                    isGroupInvoice: props.isGroupInvoice,
                },
            },
        ],
    });

    const [deleteInvoiceTravel, { loading: deleteTravelLoading }] = useMutation(DELETE_TRAVEL, {
        onError: () => toast.error(trans('errors.general')),
        refetchQueries: () => [
            {
                query: GET_INVOICE,
                variables: {
                    id: props.invoiceId,
                    isGroupInvoice: props.isGroupInvoice,
                },
            },
        ],
    });

    const deleteCost = (id: number) => {
        deleteInvoiceCost({
            variables: { costInvoiceId: props.costInvoiceId, id },
        });
    };

    const deleteTravel = (id: number) => {
        deleteInvoiceTravel({
            variables: { costInvoiceId: props.costInvoiceId, id },
        });
    };

    const handleDeleteTravel = (id: number) => {
        props.confirmAction({
            id: 'cost-delete',
            onAction: () => deleteTravel(id),
            texts: {
                paragraph: 'costs.confirm-delete2-travel',
                submit: 'form.delete',
                title: 'costs.confirm-delete-travel',
            },
        });
    };

    const handleDeleteCost = (id: number) => {
        props.confirmAction({
            id: 'cost-delete',
            onAction: () => deleteCost(id),
            texts: {
                paragraph: 'costs.confirm-delete2-cost',
                submit: 'form.delete',
                title: 'costs.confirm-delete-cost',
            },
        });
    };

    const handleDelete = () => {
        if (props.type === 'cost') {
            handleDeleteCost(props.costId);
        } else if (props.type === 'travel') {
            handleDeleteTravel(props.costId);
        }
    };

    return (
        <EezyButtonStyled
            aria-label={trans('costs.remove-cost', {
                name: props.description,
            })}
            color="black"
            onClick={handleDelete}
            square
            style={{ padding: 0 }}
        >
            {deleteCostLoading || deleteTravelLoading ? (
                <LoadingSpinner color="black" size="1em" />
            ) : (
                <Icon color={COLOR_IMPORTANT} icon={['far', 'trash-alt']} />
            )}
        </EezyButtonStyled>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        confirmAction: (payload: IConfirmAction) => {
            dispatch(confirmAction(payload));
        },
    };
};

export default connect(null, mapDispatchToProps)(DeleteCost);
