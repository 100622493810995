import { gql } from '@apollo/client';

const DELETE_INVOICE = gql`
    mutation DeleteInvoice($invoiceId: Int!) {
        deleteInvoice(invoiceId: $invoiceId) {
            id
        }
    }
`;

export default DELETE_INVOICE;
