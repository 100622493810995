import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import LoadingSpinner from '../../components/Loading';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { trans } from '../../utils';

interface IInvoiceSendProps {
    disabled?: boolean;
    onClick: () => void;
    validationLoading: boolean;
}

const InvoiceSend = (props: IInvoiceSendProps) => {
    return (
        <div>
            <Tools
                header={<ToolsHeader titleTransKey="invoice.send-invoice-title" disabled={props.disabled} />}
            />
            <EezyButton
                color="important"
                dark
                disabled={props.disabled}
                fullWidth={true}
                hasIcon
                iconAlignment="right"
                onClick={props.onClick}
            >
                {props.validationLoading ? <LoadingSpinner size="1em" /> : trans('invoice.send-invoice')}
                <Icon color="white" icon={['far', 'arrow-right']} />
            </EezyButton>
        </div>
    );
};

export default InvoiceSend;
