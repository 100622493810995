import { ChartContainer, ChartTooltip, ChartTooltipContent } from 'components/ui/chart';
import {
    REVENUE_FORECAST_BAR_BORDER_COLORS,
    REVENUE_FORECAST_BAR_COLORS,
} from 'constants/statisticConstants';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Cell, Text, XAxis, YAxis } from 'recharts';
import { cn, formatMoney } from 'utils';
import type { ChartDataStatus, SalesPageStatisticData } from 'utils/statistics/statisticUtils';

interface RevenueForecastChartProps {
    graphData: SalesPageStatisticData;
    // x-axis label
    selectedBar: string | null;
    onBarSelect: (label: string) => void;
}

const barTypes = (['paid', 'completed', 'overdued'] as ChartDataStatus[]).map((status) => ({
    dataKey: status,
    fill: REVENUE_FORECAST_BAR_COLORS[status],
    stroke: REVENUE_FORECAST_BAR_BORDER_COLORS[status],
}));

export const RevenueForecastChart = ({ graphData, selectedBar, onBarSelect }: RevenueForecastChartProps) => {
    const { t } = useTranslation();
    // this is the tailwindcss md breakpoint
    const isMobile = window.innerWidth < 768;

    const chartConfig = {
        [graphData.name]: { label: graphData.name, color: graphData.color },
    };

    const chartData = graphData.items.map((item) => ({
        label: item.label,
        paid: item.values.paid ?? 0,
        completed: item.values.completed ?? 0,
        overdued: item.values.overdued ?? 0,
        total: item.values.total ?? 0,
    }));

    return (
        <ChartContainer
            config={chartConfig}
            className="md:max-h-[350px] min-h-[250px] [&_svg]:overflow-visible !font-jakarta w-[calc(100%-90px)] md:w-full -mt-[5px] md:my-0 [&_.recharts-responsive-container]:!h-[calc(100%+5px)]"
            style={{ height: `${chartData.length * 40}px` }}
        >
            <BarChart data={chartData} accessibilityLayer layout={isMobile ? 'vertical' : 'horizontal'}>
                {isMobile ? (
                    <>
                        <YAxis
                            yAxisId={0}
                            dataKey="label"
                            type="category"
                            tickLine={false}
                            padding={{ top: isMobile ? 24 : 0, bottom: isMobile ? 24 : 0 }}
                            axisLine={{ stroke: '#E5E7EB' }}
                            tickMargin={10}
                            interval={0}
                            tick={(e) => {
                                const {
                                    payload: { value },
                                } = e;
                                return (
                                    <Text {...e} className="capitalize [&>*]:fill-gray-800">
                                        {value}
                                    </Text>
                                );
                            }}
                        />
                        <YAxis
                            yAxisId={1}
                            dataKey="total"
                            type="category"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={-80}
                            padding={{ top: isMobile ? 24 : 0, bottom: isMobile ? 24 : 0 }}
                            interval={0}
                            orientation="right"
                            tickFormatter={(value) => `${formatMoney(value)} €`}
                            tick={(e) => {
                                const {
                                    payload: { value },
                                } = e;
                                const fillClass = (() => {
                                    const data = chartData[e.index];
                                    if (data.overdued > 0) return '[&>*]:fill-red-800';
                                    if (data.total > 0) return '[&>*]:fill-violet-800';
                                    return '[&>*]:fill-gray-300';
                                })();
                                return (
                                    <Text
                                        {...e}
                                        className={cn(fillClass)}
                                        fontWeight="bolder"
                                        fontSize="12px"
                                    >{`${formatMoney(value)} €`}</Text>
                                );
                            }}
                            width={80}
                            mirror
                        />
                        {/* Bars do not appear for some reason without xaxis */}
                        <XAxis type="number" hide />
                    </>
                ) : (
                    <>
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="label"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={10}
                            interval="preserveStartEnd"
                        />
                        <YAxis
                            tickLine={false}
                            axisLine={false}
                            tickMargin={10}
                            tickFormatter={(value) => `${value.toLocaleString('fi')}€`}
                        />
                    </>
                )}
                <ChartTooltip
                    animationDuration={300}
                    cursor={false}
                    content={
                        <ChartTooltipContent
                            labelClassName="capitalize"
                            formatValue={(value) =>
                                new Intl.NumberFormat('fi-FI', {
                                    style: 'currency',
                                    currency: 'EUR',
                                }).format(value ?? 0)
                            }
                            formatName={(name) => t(`invoice.statuses.${name}`)}
                        />
                    }
                />

                {barTypes.map((bar) => (
                    <Bar key={bar.dataKey} {...bar} stackId="stack">
                        {chartData.map((entry) => (
                            <Cell
                                key={entry.label}
                                onClick={() => onBarSelect(entry.label)}
                                strokeOpacity={selectedBar === entry.label || isMobile ? 1 : 0}
                                style={{ cursor: entry.total > 0 ? 'pointer' : 'default' }}
                            />
                        ))}
                    </Bar>
                ))}
            </BarChart>
        </ChartContainer>
    );
};
