import React from 'react';
import type { IFile } from '../../../../shared/src/types/common';
import type {
    CostInvoiceStatus,
    IAllowance,
    IReceiptCost,
    ITravel,
} from '../../../../shared/src/types/costs';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { LabelCapsSmall } from '../../components/textElements';
import { COLOR_DARK_GRAY } from '../../styles/variables';
import { formatCents, formatDate, formatTime, trans } from '../../utils';
import { formatTravelDescription, getTravelRejectedTotal, getTravelTotal } from '../../utils/costs/costLogic';
import { CostTaxBreakDown } from './CostTaxBreakDown';
import DeleteCost from './DeleteCost';
import EditCost from './EditCost';

interface IProps {
    costInvoiceId: number;
    editable?: boolean;
    invoiceId: number;
    isGroupInvoice: boolean;
    travel: ITravel;
    showOnlyRejected?: boolean;
    status: CostInvoiceStatus;
}

const TravelCalculation = ({ allowance }: { allowance: IAllowance }) => {
    if (!allowance.quantity || !allowance.unitPrice) {
        return null;
    }
    return (
        <span>
            {allowance.quantity} x{' '}
            {trans('form.eurs', {
                eurs: formatCents(allowance.unitPrice, true),
            })}{' '}
            ={' '}
            {trans('form.eurs', {
                eurs: formatCents(allowance.unitPrice * allowance.quantity, true),
            })}
        </span>
    );
};

export const CostDocTravelRow = (props: IProps) => {
    const { status, travel } = props;
    const [isOpen, setOpen] = React.useState(false);

    return (
        <>
            <tr className={isOpen ? 'open' : 'closed'} onClick={() => setOpen(!isOpen)}>
                <td data-mf-replace="**REMOVED**">
                    {travel.route.join(' - ')}{' '}
                    {status !== 'incomplete'
                        ? travel.taxable
                            ? trans('costs.travel-taxable')
                            : trans('costs.travel-taxfree')
                        : null}
                </td>
                <td>
                    {travel.startTime && formatDate(travel.startTime)}
                    {travel.endTime &&
                        travel.startTime &&
                        formatDate(travel.startTime) !== formatDate(travel.endTime) && (
                            <> - {formatDate(travel.endTime)}</>
                        )}
                    <Flex posEnd style={{ fontSize: 12, fontWeight: 300 }}>
                        {travel.startTime && formatTime(travel.startTime)}
                        {travel.endTime && travel.startTime && travel.endTime !== travel.startTime && (
                            <> - {formatTime(travel.endTime)}</>
                        )}
                    </Flex>
                </td>
                <td>
                    {props.showOnlyRejected
                        ? trans('form.eurs', {
                              eurs: formatCents(getTravelRejectedTotal(travel), true),
                          })
                        : trans('form.eurs', {
                              eurs: formatCents(getTravelTotal(travel), true),
                          })}
                </td>
                <td>
                    {props.editable && (
                        <>
                            <DeleteCost
                                costId={travel.id}
                                costInvoiceId={props.costInvoiceId}
                                description={formatTravelDescription(travel)}
                                invoiceId={props.invoiceId}
                                isGroupInvoice={props.isGroupInvoice}
                                type="travel"
                            />
                            <EditCost
                                costId={travel.id}
                                invoiceId={props.invoiceId}
                                isGroupInvoice={props.isGroupInvoice}
                                type="travel"
                            />
                        </>
                    )}
                    <button type="button" onClick={() => setOpen(!isOpen)}>
                        <Icon icon={isOpen ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']} color="black" />
                    </button>
                </td>
            </tr>
            <tr style={isOpen ? { display: 'table-row' } : { display: 'none' }} className="details">
                <td colSpan={4}>
                    {travel.reimbursedKMs &&
                        (!props.showOnlyRejected || travel.reimbursedKMs?.status === 'rejected') && (
                            <Flex
                                spread
                                className={
                                    travel.reimbursedKMs.status === 'rejected' && !props.showOnlyRejected
                                        ? 'rejected'
                                        : ''
                                }
                            >
                                <span>{trans('costs.reimbursed-kms')}</span>
                                <TravelCalculation allowance={travel.reimbursedKMs} />
                            </Flex>
                        )}

                    {travel.reimbursedTrailerKMs &&
                        (!props.showOnlyRejected || travel.reimbursedTrailerKMs?.status === 'rejected') && (
                            <Flex
                                spread
                                className={
                                    travel.reimbursedTrailerKMs.status === 'rejected' &&
                                    !props.showOnlyRejected
                                        ? 'rejected'
                                        : ''
                                }
                            >
                                <span>{trans('costs.travel-km-trailer')}</span>
                                <TravelCalculation allowance={travel.reimbursedTrailerKMs} />
                            </Flex>
                        )}

                    {travel.dailyAllowancesFull &&
                        (!props.showOnlyRejected || travel.dailyAllowancesFull.status === 'rejected') && (
                            <Flex
                                spread
                                className={
                                    travel.dailyAllowancesFull.status === 'rejected' &&
                                    !props.showOnlyRejected
                                        ? 'rejected'
                                        : ''
                                }
                            >
                                <span>
                                    {travel.dailyAllowancesFull.salaryType?.includes('domestic-allowance')
                                        ? trans('costs.allowances-daily')
                                        : trans('costs.allowances-foreign')}
                                </span>
                                <TravelCalculation allowance={travel.dailyAllowancesFull} />
                            </Flex>
                        )}
                    {travel.dailyAllowancesHalf &&
                        (!props.showOnlyRejected || travel.dailyAllowancesHalf.status === 'rejected') && (
                            <Flex
                                spread
                                className={
                                    travel.dailyAllowancesHalf.status === 'rejected' &&
                                    !props.showOnlyRejected
                                        ? 'rejected'
                                        : ''
                                }
                            >
                                <span>{trans('costs.allowances-half')}</span>
                                <TravelCalculation allowance={travel.dailyAllowancesHalf} />
                            </Flex>
                        )}

                    {travel.reimbursedMeals &&
                        (!props.showOnlyRejected || travel.reimbursedMeals.status === 'rejected') && (
                            <Flex
                                spread
                                className={
                                    travel.reimbursedMeals.status === 'rejected' && !props.showOnlyRejected
                                        ? 'rejected'
                                        : ''
                                }
                            >
                                <span>{trans('costs.allowances-meal')}</span>
                                <TravelCalculation allowance={travel.reimbursedMeals} />
                            </Flex>
                        )}

                    {travel.receiptCosts.length > 0 && (
                        <div style={{ marginTop: 15 }}>
                            {travel.receiptCosts
                                .filter(
                                    (c: IReceiptCost) => !props.showOnlyRejected || c.status === 'rejected',
                                )
                                .map((r: IReceiptCost, i) => {
                                    return (
                                        <div key={`travel-cost-${i}`} style={{ padding: '10px 0' }}>
                                            <Flex
                                                className={
                                                    r.status === 'rejected' && !props.showOnlyRejected
                                                        ? 'rejected'
                                                        : ''
                                                }
                                            >
                                                <span>
                                                    {r.description}{' '}
                                                    {r.purchaseDate && formatDate(r.purchaseDate)}
                                                </span>
                                            </Flex>
                                            <CostTaxBreakDown cost={r} />

                                            <LabelCapsSmall color={COLOR_DARK_GRAY}>
                                                {trans('costs.receipts')}:
                                            </LabelCapsSmall>
                                            <Flex className="files">
                                                {r.receiptFiles.map((f: IFile, index) => (
                                                    <a
                                                        key={`${index}-${f.name}`}
                                                        href={f.previewUrl}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        {f.name}
                                                    </a>
                                                ))}
                                            </Flex>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </td>
            </tr>
        </>
    );
};
