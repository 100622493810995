import type { ISalesPage } from '../../containers/eezypay/SalesPageCreate';
import * as R from 'ramda';
import type { ITransaction } from '../../containers/eezypay/Transactions';

export interface IMonthYear {
    month: number;
    year: number;
}

export const getSalesPagesMonthList = (pages: ISalesPage[]): IMonthYear[] => {
    const months: IMonthYear[] = [];

    for (const page of pages) {
        if (!page.creationDate) {
            continue;
        }
        const m = new Date(page.creationDate).getMonth();
        const y = new Date(page.creationDate).getFullYear();

        if (!months.find((my) => R.equals(my, { month: m, year: y }))) {
            months.push({ month: m, year: y });
        }
    }

    return months;
};

export const getTransactionsMonthList = (transactions: ITransaction[]): IMonthYear[] => {
    const months: IMonthYear[] = [];

    for (const transaction of transactions) {
        if (!transaction.completed) {
            continue;
        }
        const m = new Date(transaction.completed).getMonth();
        const y = new Date(transaction.completed).getFullYear();

        if (!months.find((my) => R.equals(my, { month: m, year: y }))) {
            months.push({ month: m, year: y });
        }
    }

    return months;
};

export const filterPages = (pages: ISalesPage[], month: number, year: number) => {
    return pages.filter(
        (page: ISalesPage) =>
            page.creationDate &&
            new Date(page.creationDate).getFullYear() === year &&
            new Date(page.creationDate).getMonth() === month,
    );
};

export const filterTransactions = (transaction: ITransaction[], month: number, year: number) => {
    return transaction.filter(
        (transaction: ITransaction) =>
            transaction.completed &&
            new Date(transaction.completed).getFullYear() === year &&
            new Date(transaction.completed).getMonth() === month,
    );
};
