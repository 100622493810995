import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';

import type { ICoworker } from '../../../../shared/src/types/user';
import { deleteSelectedCoworker } from '../../actions/coworker';
import { EezyButton } from '../../components/Buttons';
import { Modal, ModalActions, ModalHeader } from '../../components/modals/Modal';
import { P, UniqueTitle } from '../../components/textElements';
import { DELETE_COWORKER, GET_COWORKERS } from '../../containers/profile/queries';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_GREYHOUND } from '../../styles/variables';
import { trans } from '../../utils';

interface IDeleteCoworkerModalProps {
    handleModalClose: () => void;
    deleteSelectedCoworker: () => void;
    isOpen: boolean;
    selectedCoworker: ICoworker;
}

const DeleteCoworkerModal = (props: IDeleteCoworkerModalProps) => {
    const [deleteCoworker] = useMutation(DELETE_COWORKER, {
        onCompleted: () => {
            toast(trans('profile:group-invoicing.user-delete-toast'));
        },
        onError: () => {
            toast.error(trans('errors.general'));
        },
        refetchQueries: [{ query: GET_COWORKERS }],
    });

    const handleDeleteCoworker = () => {
        if (props.selectedCoworker.id) {
            deleteCoworker({
                variables: {
                    personId: props.selectedCoworker.id,
                },
            });
            props.deleteSelectedCoworker();
            props.handleModalClose();
        }
    };

    return (
        <Modal id="modal-strong-auth" isOpen={props.isOpen} onClose={props.handleModalClose}>
            <ModalHeader>
                <UniqueTitle
                    color={COLOR_BLACKWATER}
                    style={{
                        lineHeight: '35px',
                        padding: '15px 0 0 0',
                    }}
                >
                    {trans('profile:group-invoicing.delete-coworker-modal.title')}
                </UniqueTitle>
                <P
                    style={{
                        color: COLOR_GREYHOUND,
                        fontSize: '15px',
                        margin: '20px 10px 10px 10px',
                        textAlign: 'left',
                    }}
                >
                    {trans('profile:group-invoicing.delete-coworker-modal.description')}
                </P>
            </ModalHeader>

            <ModalActions>
                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    onClick={props.handleModalClose}
                >
                    {trans('general.cancel')}
                </EezyButton>

                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    dark
                    onClick={handleDeleteCoworker}
                >
                    {trans('form.delete')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        selectedCoworker: state.coworker.selectedCoworker,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        deleteSelectedCoworker: () => {
            dispatch(deleteSelectedCoworker());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCoworkerModal);
