import styled from 'styled-components';
import { UniqueTitle } from '../textElements';
import { SCREEN_M } from '../../styles/variables';

export const WholePageBody = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    ${UniqueTitle} {
        margin-bottom: 15px;
        @media (min-width: ${SCREEN_M}px) {
            margin-top: 30px;
        }
    }
`;
