import { type ReactNode, forwardRef } from 'react';
import { FormRow, type IGeneralFormInputProps } from '.';
import { type ButtonColor, EezyButton } from '../Buttons';
import { FormLabel } from './FormLabel';

interface IFormButtonProps extends IGeneralFormInputProps {
    ariaLabel?: string;
    children: ReactNode;
    dark?: boolean;
    hasIcon?: boolean;
    onClick?: () => void;
    width?: number;
}

export const FormButton = forwardRef<HTMLButtonElement, IFormButtonProps>((props, ref) => {
    return (
        <FormRow
            center
            spread
            style={{
                flexDirection: 'row',
                ...props.style,
            }}
        >
            <FormLabel
                error={props.error}
                disabled={props.disabled}
                helpLink={props.helpLink}
                helpTexts={props.helpTexts}
                htmlFor={props.name}
                warning={props.warning}
            >
                {props.label}
            </FormLabel>

            <EezyButton
                ref={ref}
                aria-label={props.ariaLabel}
                color={(props.color as ButtonColor) || 'purple'}
                dark={props.dark}
                disabled={props.disabled}
                hasIcon={props.hasIcon}
                id={props.name}
                onClick={props.onClick}
                type="button"
                width={props.width}
            >
                {props.children}
            </EezyButton>
        </FormRow>
    );
});

FormButton.displayName = 'FormButton';
