import { useRef, useEffect } from 'react';
import { useNavigate, type NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { toast, type ToastOptions } from 'react-toastify';
import { useApolloClient, useLazyQuery, useSubscription } from '@apollo/client';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import LoadingSpinner from 'components/Loading';
import { BodyP, Bold } from 'components/textElements';
import { Toast } from 'components/Toast';
import { AAVA_INVOICE_LIMIT } from 'constants/invoices';
import { COLOR_BLUM, COLOR_IMPORTANT, COLOR_STATUS_DONE, COLOR_WHITE_WALKER } from 'styles/variables';
import { trans } from 'utils';
import { GET_AAVA_INVOICES, GET_ANKKA_INVOICES, GET_INVOICES } from '../dashboard/queries';
import { GET_INVOICE, INVOICE_STATUS_SUBSCRIPTION } from './queries';
import { GtagCommands, GTMEvents } from '../../constants/user';
import type { InvoiceStatusMessage } from '../../../../shared/src/types/invoice';
import type { ToastContentProps } from 'react-toastify/dist/types';

const IconWrapper = styled.div`
    background-color: ${(props: { color: string }) => props.color};
    border-radius: 50%;
    text-align: center;
    padding-left: 1px;
    line-height: 28px;
    height: 30px;
    width: 30px;
`;

let isSuccess: boolean;
const STATUS_TOAST_CONTAINER_ID = 'invoice-sending-status-toast';

const Spinner = () => <LoadingSpinner color={COLOR_IMPORTANT} size="1.5em" />;

const Done = () => (
    <IconWrapper color={COLOR_STATUS_DONE}>
        <Icon className="small" color={COLOR_WHITE_WALKER} icon={['fal', 'check']} />
    </IconWrapper>
);

const ErrorNotify = () => (
    <IconWrapper color={COLOR_IMPORTANT} style={{ marginTop: '10px' }}>
        <Icon className="small" color={COLOR_WHITE_WALKER} icon={['far', 'exclamation-triangle']} />
    </IconWrapper>
);

const invoicePendingToast = 'invoice.sending-pending-1';
const INVOICE_SENDING_PENDING_TOAST_OPTIONS: ToastOptions = {
    containerId: STATUS_TOAST_CONTAINER_ID,
};

const invoiceSentToast = 'invoice.sending-done';
const INVOICE_SENT_TOAST_OPTIONS: ToastOptions = {
    autoClose: 3000,
    closeButton: <Done />,
    containerId: STATUS_TOAST_CONTAINER_ID,
};

const invoiceSendingFailedToast = ({ closeToast }: Partial<ToastContentProps>) => (
    <Flex center>
        <Flex column posStart>
            <Bold color={COLOR_BLUM}>{trans('invoice.sending-failed-1')}</Bold>
            <BodyP color={COLOR_BLUM}>{trans('invoice.sending-failed-2')}</BodyP>
        </Flex>
        <Icon color={COLOR_BLUM} icon={['fal', 'times']} onClick={closeToast} style={{ marginLeft: 10 }} />
    </Flex>
);

const INVOICE_SENDING_FAILED_TOAST_OPTIONS: ToastOptions = {
    closeButton: <ErrorNotify />,
    closeOnClick: false,
    containerId: STATUS_TOAST_CONTAINER_ID,
};

const INVOICE_EDIT_PATH_REGEX = /^\/u\/invoices\/\d+$/;
const INVOICE_VIEW_PATH_REGEX = /^\/u\/invoices\/view\/\d+$/;

const redirect = (navigate: NavigateFunction, invoiceStatus: InvoiceStatusMessage) => {
    if (
        INVOICE_VIEW_PATH_REGEX.test(window.location.pathname) &&
        window.location.pathname.includes(invoiceStatus?.originalId.toString())
    ) {
        navigate(`/invoices/view/${invoiceStatus?.newId}`);
    } else if (
        INVOICE_EDIT_PATH_REGEX.test(window.location.pathname) &&
        window.location.pathname.includes(invoiceStatus?.originalId.toString())
    ) {
        navigate(`/invoices/${invoiceStatus?.newId}`);
    }
};

const InvoiceSendingStatusListener = () => {
    const navigate = useNavigate();
    // toastId ensures the same toast is updated from pending to sent/error
    // instead of showing 2 toast messages
    const toastId = useRef<string | number | null>(null);
    const client = useApolloClient();

    const { data } = useSubscription(INVOICE_STATUS_SUBSCRIPTION, {});
    const [getAnkkaInvoices] = useLazyQuery(GET_ANKKA_INVOICES, {
        fetchPolicy: 'network-only',
    });
    const [getAavaInvoices] = useLazyQuery(GET_AAVA_INVOICES, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.aavaInvoices.total === 1 && isSuccess) {
                window.gtag?.(GtagCommands.event, GTMEvents.first_invoice);
            }
        },
        variables: { page: { offset: 0, pageSize: AAVA_INVOICE_LIMIT } },
    });
    const [getInvoice] = useLazyQuery(GET_INVOICE, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        const invoiceStatus: InvoiceStatusMessage | undefined = data?.invoiceStatus;

        if (invoiceStatus?.status === 'pending') {
            toastId.current = toast(trans(invoicePendingToast), INVOICE_SENDING_PENDING_TOAST_OPTIONS);
        } else if (invoiceStatus?.status === 'sent') {
            isSuccess = true;
            if (toastId.current) {
                toast.update(toastId.current, {
                    ...INVOICE_SENT_TOAST_OPTIONS,
                    render: trans(invoiceSentToast),
                });
            } else {
                toastId.current = toast(trans(invoiceSentToast), INVOICE_SENT_TOAST_OPTIONS);
            }
            getAnkkaInvoices();
            getAavaInvoices();
            redirect(navigate, invoiceStatus);
            client.refetchQueries({ include: [GET_INVOICES] });
        } else if (invoiceStatus?.status === 'error') {
            isSuccess = false;
            if (toastId.current) {
                toast.update(toastId.current, {
                    ...INVOICE_SENDING_FAILED_TOAST_OPTIONS,
                    render: invoiceSendingFailedToast,
                });
            } else {
                toastId.current = toast(invoiceSendingFailedToast, INVOICE_SENDING_FAILED_TOAST_OPTIONS);
            }
            getInvoice({ variables: { id: invoiceStatus?.originalId } });
            getAnkkaInvoices();
            getAavaInvoices();
        }
    }, [data, getInvoice, getAnkkaInvoices, getAavaInvoices]);

    return (
        <Toast
            autoClose={false}
            closeButton={<Spinner />}
            containerId={STATUS_TOAST_CONTAINER_ID}
            enableMultiContainer
            position="bottom-center"
            toastClassName="eezy-toast dark"
        />
    );
};

export default InvoiceSendingStatusListener;
