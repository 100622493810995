// Wrapper component to handle GDPR, restricted work, YEL obligation
// confirmations, registration certification download, etc
// - shows the modals
// - sends the confirmations to backend if needed
// - hides the modals
// A modal is shown by dispatching a showModals-action to redux store.

import { type FC, type ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';
import { type ApolloError, useMutation, useQuery } from '@apollo/client';
import { hideModal as hideModalAction, showModals as showModalsAction } from 'actions/auth';
import DeleteCoworkerModal from '../components/modals/DeleteCoworkerModal';
import EezyPayModal from '../components/modals/EezyPay';
import CampwireModal from '../components/modals/Campwire';
import CampwireRenewModal from '../components/modals/CampwireRenew';
import EezyPayTermsModal from '../components/modals/EezyPayTerms';
import IntroVideoModal from '../components/modals/IntroVideoModal';
import InviteCoworkerModal from '../components/modals/InviteCoworkerModal';
import InvoiceCreationMethodModal from '../components/modals/InvoiceCreationMethodModal';
import SubscriptionModal from '../components/modals/SubscriptionModal';
import TermsModal from '../components/modals/TermsModal';
import UploadFileModal from '../components/modals/UploadFileModal';
import { ACCEPT_CAMPWIRE_TERMS, ACCEPT_USER_TERMS, CREATE_USER } from '../containers/eezypay/queries/index';
import { getErrors } from 'utils/apolloErrors';
import { getDocumentId, type IDocument } from 'utils/user/userUtils';
import AuthModal from './dashboard/AuthModal';
import GdprModal from './dashboard/GdprModal';
import { ACCEPT_DOCUMENT, GET_DOCUMENTS_USER_MUST_ACCEPT } from './dashboard/queries';
import RegistrationCertModal from './dashboard/RegistrationCertModal';
import RestrictedWorkModal from './dashboard/RestrictedWorkModal';
import YelModal from './dashboard/YelModal';
import { GET_USER_CONTACTS } from './profile/queries';
import GET_USER from './eezypay/queries/getUser';
import { eezyPayUserDataLoading } from 'actions/eezyPay';
import { GtagCommands, GTMEvents } from 'constants/user';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { IRootState } from 'reducers';
import FeedbackModal from '../components/modals/FeedbackModal';
import { useUser } from 'queries/useUserQuery';

export type ModalType =
    | 'AUTH'
    | 'GDPR'
    | 'REG_CERT'
    | 'RESTRICTED_WORK'
    | 'SUBSCRIPTION'
    | 'TERMS'
    | 'UPLOAD_FILE'
    | 'YEL'
    | 'CREATION_METHOD'
    | 'INVITE_COWORKER'
    | 'DELETE_COWORKER'
    | 'INTRO_VIDEO'
    | 'EEZY_PAY'
    | 'EEZY_PAY_TERMS_READ_ONLY'
    | 'EEZY_PAY_TERMS'
    | 'CAMPWIRE'
    | 'CAMPWIRE_RENEW'
    | 'FEEDBACK';

interface IModalOpenerProps {
    children?: ReactNode;
}

const ModalOpener: FC<IModalOpenerProps> = ({ children }) => {
    const user = useUser();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const visibleModals = useSelector((state: IRootState) => state.auth.visibleModals);
    const eezyPayUserState = useSelector((state: IRootState) => state.eezyPay.userState);

    const hasAddress = Boolean(user?.mailingAddress?.street1 && user?.mailingAddress?.town);

    const [openModal, setOpenModal] = useState<ModalType | ''>('');
    const [forceAcceptDocument, setForceAcceptDocument] = useState(false);

    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    // For API users the GET_DOCUMENTS_USER_MUST_ACCEPT query throws an error
    // so the unanccepted documents are stored in this state list
    const [forcedDocuments, setForcedDocuments] = useState<IDocument[]>([]);

    const showModals = (modals: string[]) => {
        dispatch(showModalsAction(modals));
    };
    const eezyPayUserDataChanged = () => {
        dispatch(eezyPayUserDataLoading());
    };
    const hideModal = (modal: string) => {
        dispatch(hideModalAction(modal));
    };

    const campwireModalActive = visibleModals.includes('CAMPWIRE');

    const { data: userContactsData } = useQuery(GET_USER_CONTACTS, {
        fetchPolicy: 'network-only',
        skip: !isLoggedIn,
    });

    const { data, error } = useQuery(GET_DOCUMENTS_USER_MUST_ACCEPT, {
        skip: !isLoggedIn,
        onCompleted: (response) => {
            if (response.userMustAccept.length === 0) {
                setForceAcceptDocument(false);
            } else {
                setForceAcceptDocument(true);
                dispatch(showModals(response.userMustAccept.map((t: IDocument) => t.document)));
            }
        },
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept) {
                setForceAcceptDocument(true);
                setForcedDocuments(errors.mustAccept);
                showModals(errors.mustAccept.map((t: IDocument) => t.document));
            }
        },
    });

    // For normal users unaccepted documents are listed in query result
    const documents = (() => {
        if (error) {
            const errors = getErrors(error);
            return errors?.mustAccept;
        }
        return data?.userMustAccept; // Removed the .map() transformation
    })();

    const documentId = useCallback(
        (type: string) => {
            if (documents) {
                // normal user and MUST_ACCEPT query passed normally
                return getDocumentId(documents, type);
            }
            // API user and unnaccepted documents are thrown as an error
            return getDocumentId(forcedDocuments, type);
        },
        [documents, forcedDocuments],
    );

    const notAccepted = useCallback(
        (type: string) => {
            return documentId(type) && true;
        },
        [documentId],
    );

    //   useEffect(() => {
    //       const lang = navigator.language.slice(0, 2);
    //       if (lang === 'en') {
    //           i18n.changeLanguage('en');
    //       } else {
    //           i18n.changeLanguage('fi');
    //       }
    //   }, []);

    useEffect(() => {
        if (visibleModals) {
            for (const type of visibleModals) {
                setOpenModal(type as ModalType);
            }
        }
    }, [visibleModals]);

    const [acceptDocument] = useMutation(ACCEPT_DOCUMENT, {
        onCompleted: () => {
            toast(t('general.confirmed'));
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: [{ query: GET_DOCUMENTS_USER_MUST_ACCEPT }],
    });

    const [acceptEezyPayTerms] = useMutation(ACCEPT_USER_TERMS, {
        context: { clientName: 'eezyPayHasura' },
        onCompleted: () => {
            toast(t('general.confirmed'));
            if (!campwireModalActive) {
                navigate('/sales-pages');
            }
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: [{ query: GET_USER, context: { clientName: 'eezyPayHasura' } }],
    });

    const [acceptCampwireTerms] = useMutation(ACCEPT_CAMPWIRE_TERMS, {
        context: { clientName: 'eezyPayHasura' },
    });

    const [createEezyPayUser] = useMutation(CREATE_USER, {
        context: { clientName: 'eezyPayHasura' },
        onCompleted: () => {
            toast(t('general.confirmed'));
            if (!campwireModalActive) {
                navigate('/sales-pages');
            }
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: [{ query: GET_USER, context: { clientName: 'eezyPayHasura' } }],
    });

    const handleAcceptDocument = (document: string) => {
        acceptDocument({ variables: { documentId: documentId(document) } });
    };

    const closeModal = (modal: ModalType) => {
        setOpenModal('');
        hideModal(modal);
    };

    const eezyPaySignup = () => {
        showModals(['EEZY_PAY_TERMS']);
    };

    const handleAcceptTerms = () => {
        eezyPayUserDataChanged();
        if (eezyPayUserState) {
            if (campwireModalActive) {
                acceptCampwireTerms({
                    variables: {
                        id: user?.id,
                    },
                });
            } else {
                acceptEezyPayTerms({
                    variables: {
                        id: user?.id,
                    },
                });
            }
        } else {
            createEezyPayUser({
                variables: {
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    language: i18n.language,
                    email: userContactsData?.userData.email,
                    phone: `+${userContactsData?.userData.phoneCountryCode} ${userContactsData?.userData.phone}`,
                    userAccountId: user?.accountId,
                    campwireTermsAccepted: campwireModalActive,
                },
            });
        }
        window.gtag?.(GtagCommands.event, GTMEvents.eezypay_sign_up);
    };

    const handleAcceptCampwireTerms = () => {
        acceptCampwireTerms({
            variables: {
                id: user?.id,
            },
        });
    };

    const displayModal = () => {
        switch (openModal) {
            case 'GDPR':
                return (
                    <GdprModal
                        accepted={!notAccepted('GDPR')}
                        handleConfirm={handleAcceptDocument}
                        handleModalClose={() => closeModal('GDPR')}
                        isOpen
                        disableClose={forceAcceptDocument}
                    />
                );
            case 'RESTRICTED_WORK':
                return (
                    <RestrictedWorkModal
                        accepted={!notAccepted('RESTRICTED_WORK')}
                        handleConfirm={handleAcceptDocument}
                        handleModalClose={() => closeModal('RESTRICTED_WORK')}
                        isOpen
                        disableClose={forceAcceptDocument}
                    />
                );
            case 'YEL':
                return <YelModal handleModalClose={() => closeModal('YEL')} isOpen />;
            case 'AUTH':
                return <AuthModal handleModalClose={() => closeModal('AUTH')} isOpen />;
            case 'REG_CERT':
                return <RegistrationCertModal handleModalClose={() => closeModal('REG_CERT')} isOpen />;
            case 'UPLOAD_FILE':
                return <UploadFileModal handleModalClose={() => closeModal('UPLOAD_FILE')} isOpen />;
            case 'SUBSCRIPTION':
                return <SubscriptionModal handleModalClose={() => closeModal('SUBSCRIPTION')} isOpen />;
            case 'TERMS':
                return (
                    <TermsModal
                        accepted={!notAccepted('TERMS')}
                        disableClose={forceAcceptDocument}
                        handleConfirm={handleAcceptDocument}
                        handleModalClose={() => closeModal('TERMS')}
                        isOpen
                    />
                );
            case 'CREATION_METHOD':
                return (
                    <InvoiceCreationMethodModal
                        handleModalClose={() => closeModal('CREATION_METHOD')}
                        isOpen
                    />
                );
            case 'INVITE_COWORKER':
                return <InviteCoworkerModal handleModalClose={() => closeModal('INVITE_COWORKER')} isOpen />;
            case 'FEEDBACK':
                return <FeedbackModal handleModalClose={() => closeModal('FEEDBACK')} isOpen />;
            case 'DELETE_COWORKER':
                return <DeleteCoworkerModal handleModalClose={() => closeModal('DELETE_COWORKER')} isOpen />;
            case 'INTRO_VIDEO':
                return <IntroVideoModal handleModalClose={() => closeModal('INTRO_VIDEO')} isOpen />;
            case 'EEZY_PAY':
                return (
                    <EezyPayModal
                        handleModalClose={() => closeModal('EEZY_PAY')}
                        handleConfirm={eezyPaySignup}
                        hasAddress={hasAddress}
                        isOpen
                    />
                );
            case 'CAMPWIRE':
                return (
                    <CampwireModal
                        handleModalClose={() => closeModal('CAMPWIRE')}
                        hasAddress={hasAddress}
                        acceptCampwireTerms={handleAcceptCampwireTerms}
                        isOpen
                    />
                );
            case 'CAMPWIRE_RENEW':
                return <CampwireRenewModal handleModalClose={() => closeModal('CAMPWIRE_RENEW')} isOpen />;
            case 'EEZY_PAY_TERMS':
                return (
                    <EezyPayTermsModal
                        handleModalClose={() => closeModal('EEZY_PAY_TERMS')}
                        handleConfirm={handleAcceptTerms}
                        isOpen
                    />
                );
            case 'EEZY_PAY_TERMS_READ_ONLY':
                return (
                    <EezyPayTermsModal
                        handleModalClose={() => closeModal('EEZY_PAY_TERMS_READ_ONLY')}
                        readonly={true}
                        isOpen
                    />
                );
        }
    };

    return (
        <>
            {children}
            {displayModal()}
        </>
    );
};

export default ModalOpener;
