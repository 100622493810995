import { useQuery } from '@apollo/client';
import type { ChartColor } from 'constants/statisticConstants';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type DateFilter, getTimeLabel, getTimeRanges } from 'utils/statistics/dateUtils';
import type { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { GET_INVOICES } from 'containers/dashboard/queries';
import { GET_PAYMENTS } from 'containers/eezypay/queries';
import {
    briefInvoiceToStatisticInvoice,
    checkInvoiceAcceptForStatistics,
    handleInvoiceForStatistics,
    invoiceDateFilter,
    type StatisticInvoice,
    statisticInvoicesToGraphData,
} from 'utils/statistics/statisticUtils';
import type { ITransaction } from 'containers/eezypay/Transactions';
import { priceWithoutVat } from 'utils';
import type { DateRange } from 'react-day-picker';
import { useDateRangePeriodStore } from 'components/common/DateRangePicker';

type UseStatisticsDataProps = {
    selectedGraphs?: { dateFilter: DateFilter; color: ChartColor }[];
    withVat: boolean;
    // To show EezyPay data instead of billing data
    useEezyPay?: boolean;
    clientFilter?: string;
    // In revenue forecast the major difference is that we allow future dates, and that
    // the date of an invoice is due date instead of invoice date
    forRevenueForecast?: boolean;
};

// If period is longer than 60 days, we group by month
const DAY_CUTOFF = 60;

export const useStatisticsData = ({
    selectedGraphs,
    withVat,
    useEezyPay,
    clientFilter,
    forRevenueForecast,
}: UseStatisticsDataProps) => {
    const { t } = useTranslation();
    const periodFromStore = useDateRangePeriodStore((state) => state.dateRangePeriod);

    const [dateFilter, setDateFilter] = useState<DateFilter>({
        to: moment().endOf('date').toDate(),
        from: moment().startOf('year').toDate(),
    });

    const { data: allInvoicesData, loading: loadingInvoices } = useQuery<{
        allInvoices: { items: IBriefInvoice[] };
    }>(GET_INVOICES);

    const eezyPayRefetchTimeout = useRef(1000);

    // We could skip this if we are not in the right tab, but that causes
    // the query to be refetched every time we change tabs
    const {
        data: eezyPayData,
        loading: eezyPayDataLoading,
        refetch: refetchEezyPay,
    } = useQuery(GET_PAYMENTS, {
        context: { clientName: 'eezyPayHasura' },
        variables: {
            searchTerm: '%%',
        },
        onError: () => {
            // After login this first results in an error for some unknown reason,
            // subsequent queries work fine, so we refetch with exponential backoff
            setTimeout(() => {
                refetchEezyPay();
                eezyPayRefetchTimeout.current *= 2;
            }, eezyPayRefetchTimeout.current);
        },
    });

    // If period is more than 60 days, we group by month
    const groupByMonth = moment(dateFilter.to).diff(moment(dateFilter.from), 'days') > DAY_CUTOFF;
    const spansMultipleYears = moment(dateFilter.from).year() !== moment(dateFilter.to).year();

    const billingInvoices = useMemo(
        () =>
            allInvoicesData?.allInvoices.items.map((i) => ({
                ...briefInvoiceToStatisticInvoice(i),
                // Date is send date by default, but for revenue forecast we want due date
                ...(forRevenueForecast
                    ? {
                          date: i.dueDate ? moment(i.dueDate).format('YYYY-MM-DD') : '',
                      }
                    : {}),
            })),
        [allInvoicesData, forRevenueForecast],
    );

    const eezyPayInvoices: StatisticInvoice[] | undefined = useMemo(
        () =>
            eezyPayData?.transactions?.map((i: ITransaction) => ({
                id: i.orderNumber.toString(),
                date: i.completed,
                status: 'completed',
                total: priceWithoutVat(i.servicePrice, i.serviceVat),
                totalWithVat: i.servicePrice,
            })),
        [eezyPayData],
    );

    const invoices = useMemo(
        () =>
            (useEezyPay
                ? eezyPayInvoices
                : billingInvoices?.filter(
                      (i) =>
                          checkInvoiceAcceptForStatistics(i) &&
                          (!clientFilter || i.clientName === clientFilter),
                  )
            )?.map(handleInvoiceForStatistics) ?? [],
        [useEezyPay, billingInvoices, eezyPayInvoices, clientFilter],
    );

    const graphDataOptions = useMemo(
        () => ({ groupByMonth, spansMultipleYears, withVat }),
        [groupByMonth, spansMultipleYears, withVat],
    );

    const dateUtilOptions = { dateFilter, maxDate: forRevenueForecast ? undefined : new Date() };

    const chartData = useMemo(
        () =>
            selectedGraphs?.map((graph) =>
                statisticInvoicesToGraphData({
                    invoices,
                    dateFilter: graph.dateFilter,
                    color: graph.color,
                    maxDate: dateUtilOptions.maxDate,
                    options: graphDataOptions,
                }),
            ) ?? [],
        [selectedGraphs, invoices, graphDataOptions],
    );

    const mainGraphData = useMemo(
        // Note, the color is not used for the main graph, as it's hardcoded in SalesStatsBillingChart
        () =>
            statisticInvoicesToGraphData({
                invoices,
                color: '#0369A1',
                ...dateUtilOptions,
                options: graphDataOptions,
            }),
        [invoices, dateFilter, graphDataOptions],
    );

    const clients = useMemo(
        () =>
            Array.from(
                new Set(
                    billingInvoices
                        ?.filter(
                            invoiceDateFilter([
                                dateFilter,
                                ...(selectedGraphs?.map((g) => g.dateFilter) ?? []),
                            ]),
                        )
                        ?.filter(checkInvoiceAcceptForStatistics)
                        .map((i) => i.clientName ?? '') ?? [],
                ),
            ),
        [billingInvoices, dateFilter, selectedGraphs],
    );

    const minDatePicker = useMemo(() => {
        if (!billingInvoices) return new Date();
        const invoicesWithDate = billingInvoices.filter((i) => i.date);
        const firstInvoice = invoicesWithDate.at(-1);
        if (!firstInvoice) {
            return new Date();
        }

        return moment(firstInvoice.date).toDate();
    }, [billingInvoices]);

    const visibleLabelText = (() => {
        if (periodFromStore) {
            const timeRange = getTimeRanges(dateUtilOptions).find((p) => p.includes(periodFromStore));

            if (timeRange)
                return t(useEezyPay ? 'statistic.eezypay-billing' : 'statistic.stats-billing', {
                    when: t(`statistic.date-period.${timeRange}`),
                });
        }

        return getTimeLabel(dateUtilOptions);
    })();

    const onChangeFilterDateRange = (dateRange: DateRange) => {
        if (!dateRange.from || !dateRange.to) return;
        const newDateFilter = {
            ...dateFilter,
            from: moment(dateRange.from).toDate(),
            to: moment(dateRange.to).toDate(),
        };

        setDateFilter(newDateFilter);
    };

    const loading = (loadingInvoices && !useEezyPay) || (eezyPayDataLoading && useEezyPay);

    return {
        invoices,
        chartData,
        mainGraphData,
        clients,
        visibleLabelText,
        dateFilter,
        onChangeFilterDateRange,
        minDatePicker,
        loading,
        groupByMonth,
    };
};
