import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showModals } from 'actions/auth';
import AddInvoiceIcon from '../../assets/images/add-invoice.svg';
import EezyPayIcon from '../../assets/images/eezyPay.svg';
import { BORDER_RADIUS, SCREEN_S } from 'styles/variables';
import type { IRootState } from 'reducers';
import { useTranslation } from 'react-i18next';
import IconCampwire from '../../assets/images/menu/campwire-colorful.svg';
import { Icon } from 'components/Icon';

interface IProps {
    style?: React.CSSProperties;
}

const DashboardQuickActionsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #351f65;

    @media (max-width: ${767}px) {
        flex-direction: column;
        background: #fff;
        border-radius: ${BORDER_RADIUS};
        padding: 24px 20px;
    }
`;
DashboardQuickActionsContainer.displayName = 'DashboardQuickActionsContainer';

const QuickActionIcon = styled.img`
    width: 50px;
    transition: transform 0.3s ease;

    @media (min-width: ${SCREEN_S}px) {
        width: 60px;
    }
`;

const DashboardQuickActions = (props: IProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const eezyPayTermsAccepted = useSelector<IRootState>((state) => state.eezyPay.eezyPayTermsAccepted);
    const eezyPayUserLoadingComplete = useSelector<IRootState>((state) => state.eezyPay.userLoadingComplete);

    const onClickNewInvoice = () => {
        if (window.innerWidth >= SCREEN_S) {
            dispatch(showModals(['CREATION_METHOD']));
        } else {
            navigate('/invoices/new');
        }
    };
    const handleEezyPay = () => {
        if (!eezyPayTermsAccepted && eezyPayUserLoadingComplete) {
            dispatch(showModals(['EEZY_PAY']));
        } else {
            navigate('/sales-pages');
        }
    };

    const handleCampwire = () => {
        dispatch(showModals(['CAMPWIRE']));
    };

    const actions = [
        { label: 'intro.button-invoice', icon: AddInvoiceIcon, onClick: onClickNewInvoice },
        { label: 'intro.button-eezypay', icon: EezyPayIcon, onClick: handleEezyPay },
        { label: 'intro.button-campwire', icon: IconCampwire, onClick: handleCampwire },
    ];

    return (
        <DashboardQuickActionsContainer style={props.style} className="gap-5">
            {actions.map((i, index) => (
                <React.Fragment key={i.label}>
                    <div
                        className="flex flex-row md:flex-col items-center bg-white md:p-9 gap-4 flex-1 md:text-center cursor-pointer group"
                        style={{ borderRadius: BORDER_RADIUS }}
                        onClick={i.onClick}
                    >
                        <QuickActionIcon
                            src={i.icon}
                            alt={i.label}
                            className="mr-6 md:m-0 group-hover:scale-105"
                        />
                        <div className="font-semibold">{t(i.label)}</div>
                        <Icon icon={['fas', 'chevron-right']} className="ml-auto mr-2 md:hidden" />
                    </div>
                    {index < actions.length - 1 ? (
                        <div className="md:hidden h-px" style={{ background: '#E5E7EB' }} />
                    ) : null}
                </React.Fragment>
            ))}
        </DashboardQuickActionsContainer>
    );
};

export default DashboardQuickActions;
