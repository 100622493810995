import type { ChartDataStatus } from 'utils/statistics/statisticUtils';

export const CHART_COMPARE_COLORS = ['#5B35A2', '#418B7E', '#0369A1', '#85e4eb'] as const;

export type ChartColor = (typeof CHART_COMPARE_COLORS)[number];

// this maps the above chart colors to their text tailwind classes, which are a bit darker then the colors
export const CHART_COMPARE_COLOR_TO_TAILWIND_TEXT = {
    '#5B35A2': 'text-violet-950',
    '#418B7E': 'text-green-700',
    '#0369A1': 'text-sky-800',
    '#85e4eb': 'text-blue-800',
} as Record<ChartColor, string>;

export const REVENUE_FORECAST_BAR_COLORS: { [key in ChartDataStatus]: string } = {
    paid: '#8F71E1',
    completed: 'rgba(143, 113, 225, 0.3)',
    overdued: 'rgba(212, 42, 80, 0.3)',
};

export const REVENUE_FORECAST_BAR_BORDER_COLORS: { [key in ChartDataStatus]: string } = {
    paid: '#8F71E1',
    completed: '#8F71E1',
    overdued: '#D42A50',
};

export const ALLOWED_CHART_LINE_NUMBER = 4;

export const invoiceStatuses: readonly ChartDataStatus[] = ['paid', 'completed', 'overdued'] as const;
