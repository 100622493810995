import { gql } from '@apollo/client';

const UPDATE_USER_LANGUAGE = gql`
    mutation updateUserLanguage($id: Int!, $language: String) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { language: $language }
        ) {
            id
            language
        }
    }
`;

export default UPDATE_USER_LANGUAGE;
