import { UniqueTitle } from 'components/textElements';
import { default as duck404 } from '../../assets/images/duck404.svg';
import { logoFull } from '../../assets/images/eezylogo';
import type { IClientError } from 'utils/error';
import { EezyButton } from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type GeneralErrorProps = {
    error: IClientError;
};

export const GeneralError = ({ error }: GeneralErrorProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBackClick = () => {
        if (error?.backButtonUrl) {
            navigate(error.backButtonUrl);
        } else if (error?.backButtonUrlExternal) {
            window.location.href = error.backButtonUrlExternal;
        }
    };

    return (
        <div className="h-screen m-auto p-5 pt-10 flex flex-col items-center">
            <img src={duck404} alt="" className="mb-8 w-[200px] sm:w-[300px]" />
            <UniqueTitle className="mb-4">{error?.title}</UniqueTitle>
            {error?.description && <p>{error.description}</p>}
            {error?.helpText && <p>{error.helpText}</p>}

            {error?.backButtonText ? (
                <EezyButton color="purple" onClick={handleBackClick} transparent className="mt-8">
                    {error.backButtonText}
                </EezyButton>
            ) : (
                <EezyButton
                    color="purple"
                    transparent
                    onClick={() => {
                        window.location.reload();
                    }}
                    className="mt-8"
                >
                    {t('errors.refresh-page')}
                </EezyButton>
            )}
            <img className="mt-12" src={logoFull} alt="" />
        </div>
    );
};
