import { gql } from '@apollo/client';

const UPDATE_USER_DATA = gql`
    mutation UpdateUserData(
        $address: AddressInput
        $bankAccountNumber: String
        $companyName: String
        $email: String
        $finnishCitizen: Boolean
        $firstNameForInvoice: String
        $homeCountry: String
        $notifyWhenInvoicePaid: Boolean
        $longTermStorageConsent: Boolean
        $phone: String
        $phoneCountryCode: Int
        $salaryPaymentRuleId: Int
        $yelInsured: Boolean
    ) {
        updateUserData(
            userData: {
                address: $address
                bankAccountNumber: $bankAccountNumber
                companyName: $companyName
                email: $email
                finnishCitizen: $finnishCitizen
                firstNameForInvoice: $firstNameForInvoice
                homeCountry: $homeCountry
                notifyWhenInvoicePaid: $notifyWhenInvoicePaid
                longTermStorageConsent: $longTermStorageConsent
                phone: $phone
                phoneCountryCode: $phoneCountryCode
                salaryPaymentRuleId: $salaryPaymentRuleId
                yelInsured: $yelInsured
            }
        ) {
            address {
                country
                street1
                town
                zipCode
            }
            homeCountry
            bankAccountNumber
            companyName
            email
            finnishCitizen
            firstNameForInvoice
            id
            nextTaxCardStartDate
            notifyWhenInvoicePaid
            longTermStorageConsent
            phoneCountryCode
            phone
            recruitmentCode
            salaryPaymentRuleId
            servicePercentage
            taxCardDetails {
                addPercentage
                basicPercentage
                basicPercentageLimit
                endDate
                startDate
            }
            yelInsured
        }
    }
`;

export default UPDATE_USER_DATA;
