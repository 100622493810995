import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';
import { recentlyIdentified, showModals } from '../../actions/auth';
import { trans } from '../../utils';
import { errorReason } from '../../utils/user/userUtils';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
    result: string;
    showModals: (modals: string[]) => void;
    flagRecentlyIdentified: () => void;
}

const AuthCallback = (props: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    React.useEffect(() => {
        if (props.result === 'success') {
            toast(trans('auth.user-success'));
            props.flagRecentlyIdentified();
        } else if (props.result === 'error') {
            const urlParams: any = queryString.parse(location.search);
            toast(trans(`auth.user-error.${errorReason(urlParams?.reason)}`), {
                autoClose: 6000,
            });
        } else if (props.result === 'modal') {
            props.showModals(['AUTH']);
        }
        navigate('/');
    });

    return null;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        flagRecentlyIdentified: () => {
            dispatch(recentlyIdentified());
        },
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(null, mapDispatchToProps)(AuthCallback);
