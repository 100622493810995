import { gql } from '@apollo/client';

const CREATE_EMPTY_COSTINVOICE = gql`
    mutation CreateEmptyCostInvoice($invoiceId: Int!) {
        createEmptyCostInvoice(invoiceId: $invoiceId) {
            allowancesTotal
            id
            invoiceId
            costInvoiceNumber
            messageToEezy
            receiptCosts {
                amountsWithVats {
                    amount
                    id
                    vatPercent
                }
                createTime
                description
                id
                purchaseDate
                receiptFiles {
                    contentType
                    createTime
                    id
                    name
                    previewUrl
                    url
                }
                status
                type
            }
            status
            travels {
                allowancesTotal
                dailyAllowancesFull {
                    quantity
                    salaryType
                    status
                    unitPrice
                }
                dailyAllowancesHalf {
                    quantity
                    status
                    unitPrice
                }
                endTime
                id
                receiptCosts {
                    amountsWithVats {
                        amount
                        id
                        vatPercent
                    }
                    createTime
                    description
                    id
                    purchaseDate
                    receiptFiles {
                        contentType
                        createTime
                        id
                        name
                        previewUrl
                        url
                    }
                    status
                    type
                }
                reimbursedKMs {
                    quantity
                    status
                    unitPrice
                }
                reimbursedMeals {
                    quantity
                    status
                    unitPrice
                }
                reimbursedTrailerKMs {
                    quantity
                    status
                    unitPrice
                }
                route
                startTime
                taxable
                totalKMs
                type
            }
            turnBackReason
        }
    }
`;

export default CREATE_EMPTY_COSTINVOICE;
