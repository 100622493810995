import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { COLOR_WHITE_WALKER } from '../styles/variables';

const accordionPanelStyles = {
    root: {
        background: '#ffffff',
        opacity: 0.8,
        borderRadius: '5px',
        boxShadow: 'none',
        marginBottom: '6px',
        transition: '2s',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 0,
            marginBottom: '6px',
            opacity: 1,
        },
        '&.MuiExpansionPanel-root.Mui-disabled': {
            background: '#FFFFFF',
            opacity: 1,
        },
        '& .Mui-disabled': {
            opacity: 1,
        },
        '& .MuiExpansionPanelSummary-root.Mui-disabled': {
            opacity: 1,
        },
        '&.Mui-focused, &.Mui-disabled': {
            backgroundColor: COLOR_WHITE_WALKER,
        },
    },
    expanded: {},
};

const accordionSummaryStyles = {
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
};

const accordionDetailStyles = {
    root: {
        display: 'block',
        paddingTop: 0,
    },
};

const accordionTypographyStyles = {
    root: {
        lineHeight: 1.2,
        paddingLeft: '10px',
        '& .Mui-disabled': {
            opacity: 1,
        },
    },
};

const AccordionPanel = withStyles(Accordion, accordionPanelStyles);

const AccordionPanelSummary = withStyles(AccordionSummary, accordionSummaryStyles);

const AccordionPanelSummarySmall = withStyles(AccordionSummary, {
    ...accordionSummaryStyles,
    content: { ...accordionSummaryStyles.content, margin: '3px 0 0' },
    root: { ...accordionSummaryStyles.root, minHeight: 0, height: '38px' },
});

const AccordionPanelDetails = withStyles(AccordionDetails, accordionDetailStyles);

const AccordionTypography = withStyles(Typography, accordionTypographyStyles);

export {
    AccordionPanel,
    AccordionPanelSummary,
    AccordionPanelSummarySmall,
    AccordionPanelDetails,
    AccordionTypography,
};
