import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { IRootState } from 'reducers';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import { Fade, Hidden, ListItem } from '@mui/material';
import { showModals } from 'actions/auth';
import AppleStoreBadgeEn from 'assets/images/apple-store-badge-en.svg';
import AppleStoreBadgeFi from 'assets/images/apple-store-badge-fi.svg';
import GooglePlayBadge from 'assets/images/google-play-badge.svg';
import { logoRound as logo } from '../../assets/images/eezylogo';
import { Icon } from 'components/Icon';
import { Line } from 'components/Lines';
import SideMenuBar from 'components/SideMenuBar';
import { SectionTitle } from 'components/textElements';
import { UserEventCategories, UserEventNames } from 'constants/user';
import { getMinutesSinceLastEvent } from 'utils';
import { GET_USER_EVENTS_LIST, REGISTER_USER_EVENT } from '../profile/queries';
import Bar from './Bar';
import { type INavItem, navItems } from './navItems';
import NewMessagesBubble from './NewMessagesBubble';
import PurpleBackground from './PurpleBackground';
import LanguageOptions from 'components/LanguageOptions';
import { COLOR_LILA, COLOR_WHITE_WALKER } from 'styles/variables';
import FeedbackImg from '../../assets/images/feedback-v2.svg';
import QuestionmarkRoundButtonImg from '../../assets/images/questionmark-round-button.svg';
import {
    FeedbackButton,
    LanguageOptionsWrapper,
    LanguageOptionsWrapperMobile,
    LogoHolder,
    Main,
    MenuButton,
    MenuList,
    NavContent,
    NavItemBadge,
    StyledStyledDrawer,
    ToggleIntroButton,
    transitions,
    UserNameWrapper,
    WithSubtext,
} from './drawer.styles';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import GET_INVOICES from 'containers/dashboard/queries/getInvoices';
import moment from 'moment';
import NotificationModal from 'containers/dashboard/NotificationModal';
import { useUser } from 'queries/useUserQuery';

interface INavListProps {
    items: INavItem[];
    fadeIn: boolean;
}

const NavList: FC<INavListProps> = ({ items, fadeIn }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const path = location.pathname;
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const eezyPayTermsAccepted: boolean = useSelector(
        (state: IRootState) => state.eezyPay.eezyPayTermsAccepted,
    );
    const eezyPayUserLoadingComplete: boolean = useSelector(
        (state: IRootState) => state.eezyPay.userLoadingComplete,
    );

    const transitionDelay = transitions.inMenuItem.wait;

    return (
        <MenuList>
            {items.map((navItem: INavItem, index) => {
                if (navItem.text === 'underline') {
                    return (
                        <Fade
                            in={fadeIn}
                            timeout={transitions.inMenuItem.fade}
                            style={{
                                transitionDelay: `${
                                    (transitionDelay || 0) + transitions.inMenuItem.wait * index
                                }ms`,
                            }}
                            key={`${navItem.text}-${index}`}
                        >
                            <Line />
                        </Fade>
                    );
                }
                const content = (
                    <>
                        {navItem.icon && (
                            <span
                                style={{
                                    width: 25,
                                    marginRight: 10,
                                    display: 'flex',
                                    alignSelf: navItem.subtext ? 'center' : 'auto',
                                    marginTop: navItem.subtext ? -23 : 0,
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={navItem.icon} alt={navItem.text} />
                            </span>
                        )}
                        {navItem.subtext ? (
                            <WithSubtext key="withSubText">
                                <span>{t(navItem.text)} </span>
                                <span
                                    style={{
                                        fontSize: 13,
                                        marginTop: 8,
                                    }}
                                >
                                    {t(navItem.subtext)}
                                </span>
                            </WithSubtext>
                        ) : navItem.small ? (
                            <span style={{ marginLeft: 11 }} key="withoutSubText">
                                {t(navItem.text)}
                            </span>
                        ) : (
                            t(navItem.text)
                        )}
                    </>
                );
                const href =
                    i18n.language === 'fi' ? navItem.path || '' : navItem.pathEn || navItem.path || '';

                const pathSubstring = navItem.path?.substring(navItem.path?.lastIndexOf('/'));

                let selected = false;
                if (!navItem.external && pathSubstring && path.includes(pathSubstring)) {
                    if (pathSubstring === '/profile') {
                        selected = navItem.path === path;
                    } else {
                        selected = true;
                    }
                }

                const classes =
                    (selected ? 'selected ' : '') +
                    (navItem.small ? 'small ' : '') +
                    (navItem.icon ? 'icon ' : '');

                const navElement = (
                    <Fade
                        in={fadeIn}
                        timeout={transitions.inMenuItem.fade}
                        style={{
                            transitionDelay: `${
                                (transitionDelay || 0) + transitions.inMenuItem.wait * index
                            }ms`,
                        }}
                        key={`${navItem.text}-${index}`}
                    >
                        <ListItem
                            className={classes}
                            style={{
                                color: navItem.color,
                                height: navItem.subtext && 65,
                            }}
                        >
                            {navItem.openModal && navItem.text !== 'menu.eezyPay' ? (
                                <button
                                    type="button"
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        height: '48px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    onClick={() => {
                                        if (navItem.openModal) {
                                            dispatch(showModals([navItem.openModal]));
                                        }
                                    }}
                                >
                                    {content}
                                    {navItem.text === 'menu.campwire' && (
                                        <NavItemBadge>{t('eezyPay:menu-item-badge-new')}</NavItemBadge>
                                    )}
                                </button>
                            ) : navItem.external ? (
                                <a
                                    className={`unstyled ${navItem.customContent ? '' : 'fullwidth'}`}
                                    href={href}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {content}
                                </a>
                            ) : navItem.text === 'menu.eezyPay' ? (
                                <Link
                                    className={`unstyled ${navItem.customContent ? '' : 'fullwidth'}`}
                                    to={href}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {content}
                                    <NavItemBadge>{t('eezyPay:menu-item-badge-new')}</NavItemBadge>
                                </Link>
                            ) : (
                                <Link
                                    className={`unstyled ${navItem.customContent ? '' : 'fullwidth'}`}
                                    to={href}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {content}
                                </Link>
                            )}
                            {navItem.customContent && <>{navItem.customContent()}</>}
                        </ListItem>
                    </Fade>
                );
                if (navItem.hiddenDesktop) {
                    return (
                        <Hidden smUp key={`${navItem.text}-${index}`}>
                            {navElement}
                        </Hidden>
                    );
                }
                if (navItem.text === 'menu.eezyPay' && !eezyPayTermsAccepted && eezyPayUserLoadingComplete) {
                    return (
                        <ListItem className={classes} style={{ color: navItem.color }}>
                            <button
                                type="button"
                                style={{
                                    height: '48px',
                                    textAlign: 'left',
                                    width: '100%',
                                }}
                                onClick={() => {
                                    if (navItem.openModal) {
                                        dispatch(showModals([navItem.openModal]));
                                    }
                                }}
                            >
                                <span
                                    style={{
                                        lineHeight: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {content}
                                    <NavItemBadge>{t('eezyPay:menu-item-badge-new')}</NavItemBadge>
                                </span>
                            </button>
                        </ListItem>
                    );
                }
                return navElement;
            })}
        </MenuList>
    );
};

interface IResponsiveDrawerProps {
    /**
     * Injected by the documentation to work in an iframe.
     */
    container?: Element;
    banner?: ReactNode;
    children: ReactNode;
}

const ResponsiveDrawer: FC<IResponsiveDrawerProps> = ({ banner, children }) => {
    const { t, i18n } = useTranslation();
    const user = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const eezyPayTermsAccepted = useSelector<IRootState>((state) => state.eezyPay.eezyPayTermsAccepted);

    const hash = location.hash;
    const [drawerOpen, setDrawerOpen] = useState(hash === '#menu');

    useEffect(() => {
        setDrawerOpen(hash === '#menu');
    }, [hash]);

    const toggleDrawer = () => {
        if (!drawerOpen) {
            navigate('#menu');
        } else {
            // history.replace() causes history to have multiple consecutive identical paths,
            // which makes browser back button do seemingly nothing. An alternative to replace()
            // is `history.goBack()`, but coming to ...#menu and toggling drawer makes one
            // be redirected back, which seems odd behaviour.
            navigate(location.pathname);
        }
        setDrawerOpen(!drawerOpen);
    };

    const { data: lastUserEvents } = useQuery(GET_USER_EVENTS_LIST, {
        variables: {
            category: UserEventCategories.GIOSG,
            lastEventFlag: true,
        },
    });

    const [getInvoices] = useLazyQuery(GET_INVOICES);
    const [registerUserEvent] = useMutation(REGISTER_USER_EVENT, {
        onCompleted: () => {
            (window as any).userId = user?.id;
        },
        refetchQueries: () => [
            {
                query: GET_USER_EVENTS_LIST,
                variables: {
                    category: UserEventCategories.GIOSG,
                    lastEventFlag: true,
                },
            },
        ],
    });

    const [guideModalVisible, setGuideModalVisible] = useState(false);

    const toggleGuideModal = () => {
        const newState = !guideModalVisible;
        setGuideModalVisible(newState);
    };

    const loggedInAt = localStorage.getItem('login-time') || '';
    const secondsSinceLoggedIn = moment().diff(moment(loggedInAt), 'seconds');

    const minutesSinceLastEventGiosg1 = getMinutesSinceLastEvent(
        lastUserEvents,
        UserEventNames.GIOSG_1_AFTER_INVOICE_SEND_SUCCESS,
    );
    const minutesSinceLastEventGiosg2 = getMinutesSinceLastEvent(
        lastUserEvents,
        UserEventNames.GIOSG_2_SURVEY,
    );
    const minutesSinceLastEventGiosgNewUser = getMinutesSinceLastEvent(
        lastUserEvents,
        UserEventNames.GIOSG_NEW_USER,
    );

    useMemo(() => {
        if (!eezyPayTermsAccepted && secondsSinceLoggedIn > 30) {
            getInvoices({
                onCompleted: (data) => {
                    const sentInvoices = data?.allInvoices?.items?.find(
                        (invoice: IInvoice) => invoice.status !== 'incomplete',
                    );

                    if (!sentInvoices || sentInvoices?.length === 0) {
                        registerUserEvent({
                            variables: {
                                event: {
                                    category: UserEventCategories.GIOSG,
                                    name: UserEventNames.GIOSG_NEW_USER,
                                },
                            },
                        });
                    }
                },
            });
        }
    }, []);

    return (
        <>
            <Bar>
                <MenuButton onClick={toggleDrawer} style={{ marginLeft: 15 }}>
                    <Icon icon={['fal', 'bars']} color={COLOR_LILA} />
                    <SectionTitle color={COLOR_LILA} style={{ textTransform: 'uppercase' }}>
                        {t('menu.menu')}
                    </SectionTitle>
                </MenuButton>
                <NewMessagesBubble color={COLOR_WHITE_WALKER} />

                {minutesSinceLastEventGiosgNewUser < 1 && secondsSinceLoggedIn > 30 && (
                    <div className={`${UserEventNames.GIOSG_NEW_USER}_${i18n.language.toUpperCase()}`} />
                )}
                {minutesSinceLastEventGiosg2 < 1 && (
                    <div className={`${UserEventNames.GIOSG_2_SURVEY}_${i18n.language.toUpperCase()}`} />
                )}
                {minutesSinceLastEventGiosg1 < 1 && (
                    <div
                        className={`${
                            UserEventNames.GIOSG_1_AFTER_INVOICE_SEND_SUCCESS
                        }_${i18n.language.toUpperCase()}`}
                    />
                )}
                <div style={{ flexGrow: 5 }} />
                <FeedbackButton
                    onClick={() => {
                        dispatch(showModals(['FEEDBACK']));
                    }}
                    left={location.pathname.includes('/invoices/view/')}
                >
                    <img src={FeedbackImg} alt="Feedback" />
                </FeedbackButton>
                <ToggleIntroButton onClick={toggleGuideModal}>
                    <img src={QuestionmarkRoundButtonImg} alt="Toggle Intro" />
                </ToggleIntroButton>
                <LanguageOptionsWrapper>
                    <LanguageOptions />
                </LanguageOptionsWrapper>
            </Bar>
            {banner}
            <PurpleBackground>
                <Fade
                    in={drawerOpen}
                    exit={!drawerOpen}
                    timeout={{
                        enter: transitions.inMenu.fade,
                        // exit: transitions.outMenu.fade
                    }}
                    style={{
                        transitionDelay: `${transitions.inMenu.wait}ms`,
                    }}
                >
                    <div>
                        <StyledStyledDrawer
                            anchor="left"
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            onClose={() => toggleDrawer()}
                            onOpen={() => toggleDrawer()}
                            open={drawerOpen}
                            SlideProps={{
                                enter: false, // do not use the default enter
                                exit: false, //  and exit animations, but custom Fade
                                mountOnEnter: true,
                                unmountOnExit: true,
                            }}
                            variant="temporary"
                        >
                            <SideMenuBar>
                                <Fade
                                    in={drawerOpen}
                                    style={{
                                        transitionDelay: `${transitions.inCloseButton.wait}ms`,
                                    }}
                                    timeout={transitions.inCloseButton.fade}
                                >
                                    <MenuButton onClick={toggleDrawer}>
                                        <Icon
                                            icon={['fal', 'times']}
                                            color={COLOR_LILA}
                                            style={{ height: 20, width: 20 }}
                                        />
                                        <SectionTitle
                                            color={COLOR_LILA}
                                            style={{
                                                fontWeight: 600,
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {t('menu.menu-close')}
                                        </SectionTitle>
                                    </MenuButton>
                                </Fade>
                                <LanguageOptionsWrapperMobile>
                                    <LanguageOptions />
                                </LanguageOptionsWrapperMobile>
                            </SideMenuBar>
                            <NavContent>
                                <Fade
                                    in={drawerOpen}
                                    style={{
                                        transitionDelay: `${transitions.inMenuContent.wait}ms`,
                                    }}
                                    timeout={transitions.inMenuContent.fade}
                                >
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <UserNameWrapper data-mf-replace="**REMOVED**">
                                                {user?.firstName}
                                            </UserNameWrapper>
                                            <Fade
                                                in={drawerOpen}
                                                style={{
                                                    transitionDelay: `${transitions.inLogo.wait}ms`,
                                                }}
                                                timeout={transitions.inLogo.fade}
                                            >
                                                <LogoHolder>
                                                    <img
                                                        alt="Eezy"
                                                        className="logo"
                                                        src={logo}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </LogoHolder>
                                            </Fade>
                                        </div>

                                        <nav aria-label="Menu">
                                            <NavList items={navItems} fadeIn={drawerOpen} />
                                        </nav>
                                        <div
                                            style={{
                                                marginTop: '40px',
                                                paddingLeft: '45px',
                                                paddingRight: '30px',
                                            }}
                                        >
                                            <a
                                                href="https://apps.apple.com/us/app/eezy-kevytyritt%C3%A4j%C3%A4t/id1466922600"
                                                target={'_blank'}
                                                rel="noreferrer"
                                                style={{
                                                    display: 'inline-block',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                {i18n.language === 'fi' ? (
                                                    <img
                                                        style={{
                                                            display: 'block',
                                                        }}
                                                        alt="Download on the App Store"
                                                        src={AppleStoreBadgeFi}
                                                    />
                                                ) : (
                                                    <img
                                                        style={{
                                                            display: 'block',
                                                            width: 123,
                                                        }}
                                                        alt="Download on the App Store"
                                                        src={AppleStoreBadgeEn}
                                                    />
                                                )}
                                            </a>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.eezyosk.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                                style={{
                                                    display: 'inline-block',
                                                    marginLeft: '10px',
                                                }}
                                                target={'_blank'}
                                                rel="noreferrer"
                                            >
                                                <img
                                                    style={{
                                                        display: 'block',
                                                        width: 135,
                                                    }}
                                                    alt="Get it on Google Play"
                                                    src={GooglePlayBadge}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </Fade>
                            </NavContent>
                        </StyledStyledDrawer>
                    </div>
                </Fade>
                <Main>{children}</Main>
                <NotificationModal
                    isOpen={guideModalVisible}
                    handleModalClose={() => setGuideModalVisible(false)}
                    notification={{
                        actionType: 'guide',
                        date: '',
                        id: 0,
                        isHidden: false,
                        isRead: false,
                        isSeen: false,
                        type: 'task',
                    }}
                />
            </PurpleBackground>
        </>
    );
};

export default ResponsiveDrawer;
