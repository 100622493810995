import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { isNil } from 'ramda';
import styled from 'styled-components';
import type { IInvoice, IProvision } from '../../../../shared/src/types/invoice';
import { Icon } from '../../components/Icon';
import { COLOR_BLACKWATER, SCREEN_S } from '../../styles/variables';
import { formatCents, formatPercentage, trans } from '../../utils';
import { useUser } from 'queries/useUserQuery';
import { useInvoiceProvisions } from './hooks/useInvoiceProvisions';

interface IProps {
    invoice?: IInvoice;
    isOwner: boolean;
}

const Cell = styled(TableCell)`
    && {
        border-bottom: 0;
        padding: 0;
    }
    &.MuiTableCell-head {
        font-weight: 600;
    }

    & svg {
        margin-left: 10px;
    }
`;

const ParticipantTable = styled(Table)`
    @media (max-width: ${SCREEN_S}px) {
        & thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        & tr {
            border-bottom: 1px solid ${COLOR_BLACKWATER};
            margin-bottom: 10px;
        }

        & td {
            display: block;
            font-size: 0.8em;
            text-align: right;
        }

        & td::before {
            clear: both;
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
`;

const getProvisionAmount = (provision: IProvision, invoice?: IInvoice) => {
    if (!provision) {
        return '';
    }
    const fixedAmount = provision.fixedAmount;
    if (!isNil(fixedAmount)) {
        return `${formatCents(fixedAmount)} €`;
    }
    if (!isNil(provision.percentage)) {
        return `${formatCents((provision.percentage / 100) * (invoice?.total || 0))} €`;
    }
    return '';
};

const getProvisionPercentage = (provision: IProvision, invoice?: IInvoice) => {
    if (!provision) {
        return '';
    }
    if (!isNil(provision.percentage)) {
        return formatPercentage(provision.percentage);
    }
    if (!isNil(provision.fixedAmount) && invoice?.total) {
        return formatPercentage((provision.fixedAmount / invoice.total) * 100);
    }
    return '';
};

export const getTravelExpensePercentage = (
    provision: IProvision,
    allProvisions: IProvision[],
    invoice?: IInvoice,
): string => {
    if (!isNil(provision.travelExpenseVolume)) {
        return `${formatCents(provision.travelExpenseVolume)} €`;
    }

    const ownerId = invoice?.createdBy?.id || 0;
    let percentage = provision.travelExpenses;
    if (provision.personId === ownerId) {
        percentage =
            100 -
            (allProvisions
                .filter((p) => p.personId !== ownerId)
                .reduce((total, p) => total + (p.travelExpenses || 0), 0) || 0);
    }
    const travelExpenses =
        invoice?.invoiceItems
            ?.filter((i) => i.itemType === 'travel')
            .reduce((total, i) => total + (i.totalPrice || 0), 0) || 0;
    return `${formatCents(((percentage || 0) / 100) * travelExpenses)} €`;
};

const GroupInvoiceParticipants = (props: IProps) => {
    const user = useUser();

    const rows = useInvoiceProvisions(props.invoice);

    const totalTravelExpenses =
        props.invoice?.invoiceItems
            ?.filter((i) => i.itemType === 'travel')
            .reduce((total, item) => total + (item.totalPrice || 0), 0) || 0;

    const showTravelColumn = totalTravelExpenses > 0 || !props.isOwner;

    return (
        <TableContainer>
            <ParticipantTable aria-label="Invoice participants">
                <TableHead>
                    <TableRow>
                        <Cell>{trans('invoice.groupInvoice.infoBox.col-name')}</Cell>
                        <Cell align="right">{trans('invoice.groupInvoice.infoBox.col-occupation')}</Cell>
                        {props.isOwner && (
                            <Cell align="right">{trans('invoice.groupInvoice.infoBox.col-share')}</Cell>
                        )}
                        <Cell align="right">{trans('invoice.groupInvoice.infoBox.col-amount')}</Cell>
                        {showTravelColumn && (
                            <Cell align="right">{trans('invoice.groupInvoice.infoBox.col-travel')}</Cell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow key={i}>
                            <Cell
                                data-mf-replace="**REMOVED**"
                                scope="row"
                                data-label={trans('invoice.groupInvoice.infoBox.col-name')}
                            >
                                {row.name}
                                {row.personId === props.invoice?.createdBy?.id && (
                                    <Icon
                                        icon={['far', 'user-crown']}
                                        className="small"
                                        color={COLOR_BLACKWATER}
                                    />
                                )}
                            </Cell>
                            <Cell
                                align="right"
                                data-label={trans('invoice.groupInvoice.infoBox.col-occupation')}
                            >
                                {row.occupationId && <>{trans(`occupations:${row.occupationId}`)}</>}
                            </Cell>
                            {props.isOwner && (
                                <Cell
                                    align="right"
                                    data-label={trans('invoice.groupInvoice.infoBox.col-share')}
                                >
                                    {getProvisionPercentage(row, props.invoice)} %
                                </Cell>
                            )}
                            <Cell align="right" data-label={trans('invoice.groupInvoice.infoBox.col-amount')}>
                                {props.isOwner || row.personId === user?.id
                                    ? getProvisionAmount(row, props.invoice)
                                    : ''}
                            </Cell>
                            {showTravelColumn && (
                                <Cell
                                    align="right"
                                    data-label={trans('invoice.groupInvoice.infoBox.col-travel')}
                                >
                                    {props.isOwner
                                        ? getTravelExpensePercentage(row, rows, props.invoice)
                                        : row.personId === user?.id
                                          ? `${formatCents(row.travelExpenseVolume)} €`
                                          : ''}
                                </Cell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </ParticipantTable>
        </TableContainer>
    );
};

export default GroupInvoiceParticipants;
