import * as R from 'ramda';
import { SCREEN_M } from '../styles/variables';

//  copied from server/src/util

const isStringOrNumberNaN = (arg: string | number): boolean => {
    return typeof arg === 'number' ? Number.isNaN(arg) : false;
};

const isEmpty = (arg: string | number) => arg === undefined || arg === null || isStringOrNumberNaN(arg);

const defaultOrVal = (
    defaultValue: any | null | undefined,
    func: (a: any) => any,
    arg: any | null | undefined,
) => (isEmpty(arg) || isEmpty(func(arg)) ? defaultValue : func(arg));

export const zeroOrVal: (func: (a: any) => any, b: any) => any = R.curry(defaultOrVal)(0);

const convertStrToCents = R.pipe(R.replace(/\s/g, ''), Number.parseFloat, R.multiply(100), Math.round);
const convertStrToCentsWithoutRounding = R.pipe(Number.parseFloat, R.multiply(100));

export const strToCentsOrZero: (a: string) => number = R.curry(zeroOrVal)(convertStrToCents);

export const strToCentsOrZeroWithoutRounding: (a: string) => number = R.curry(zeroOrVal)(
    convertStrToCentsWithoutRounding,
);

export const isMobile = () => window.innerWidth < SCREEN_M;

export const randomSingleId = () => {
    return Math.floor(Math.random() * 1e7);
};
