import { gql } from '@apollo/client';

const INVOICE_STATUS_SUBSCRIPTION = gql`
    subscription OnInvoiceStatusChange {
        invoiceStatus {
            newId
            originalId
            status
            error
            userId
        }
    }
`;

export default INVOICE_STATUS_SUBSCRIPTION;
