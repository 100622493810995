import moment from 'moment';

export const isWeekday = (date: string, weekday: number) => {
    return moment(date).day() === weekday;
};

export const isWeekend = (date?: string) => {
    const dateString = date || moment().toISOString();

    return isWeekday(dateString, 6) || isWeekday(dateString, 0);
};
