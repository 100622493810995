import { DateRangePicker, useDateRangePeriodStore } from 'components/common/DateRangePicker';
import { IconButton } from 'components/common/IconButton';
import moment from 'moment';
import { useMemo, useState } from 'react';
import type { DateRange } from 'react-day-picker';
import { type DateFilter, isFullMonth, isFullQuarter, isFullYear } from 'utils/statistics/dateUtils';
import { StatisticsClientPicker } from './StatisticsClientPicker';
import { useTranslation } from 'react-i18next';

type StatisticPageFiltersProps = {
    dateFilter: DateFilter;
    onChangeDateFilter: (dateFilter: DateFilter) => void;
    clients?: string[];
    clientFilter?: string | null;
    onChangeClientFilter?: (client: string | null) => void;
    minDate: Date;
    showClientFilter?: boolean;
    allowFuture?: boolean;
};

const StatisticPageFilters = ({
    dateFilter,
    onChangeDateFilter,
    clients,
    clientFilter,
    onChangeClientFilter,
    minDate,
    showClientFilter,
    allowFuture,
}: StatisticPageFiltersProps) => {
    const { t } = useTranslation();
    const periodFromStore = useDateRangePeriodStore((state) => state.dateRangePeriod);
    const setStorePeriod = useDateRangePeriodStore((state) => state.setDateRangePeriod);

    // Mobile date change needs to be confirmed, desktop doesn't
    const [unconfirmedDateFilter, setUnconfirmedDateFilter] = useState(dateFilter);

    const dateUtilOptions = { dateFilter, maxDate: allowFuture ? undefined : new Date() };

    const period = useMemo(() => {
        if (periodFromStore) return periodFromStore;
        if (
            dateFilter.from.getFullYear() === dateFilter.to.getFullYear() &&
            dateFilter.from.getMonth() === dateFilter.to.getMonth() &&
            isFullMonth(dateUtilOptions)
        ) {
            return 'month';
        }

        if (dateFilter.from.getFullYear() === dateFilter.to.getFullYear() && isFullQuarter(dateUtilOptions)) {
            return 'quarter';
        }

        return 'year';
    }, [dateFilter, dateUtilOptions]);

    const isFullPeriod = useMemo(() => {
        switch (period) {
            case 'month':
                return isFullMonth(dateUtilOptions);
            case 'quarter':
                return isFullQuarter(dateUtilOptions);
            case 'year':
                return isFullYear(dateUtilOptions);
        }
    }, [period]);

    const canGoToNext = moment().endOf(period).isAfter(moment(dateFilter.from).add(1, period)) || allowFuture;

    // If we are on the current period (eg. current month), we should be able to go to the previous period,
    // if minDate is after dateFilter.from, but before dateFilter.to after the period is subtracted.
    const canGoToPrevious = moment(minDate).isBefore(
        isFullPeriod
            ? moment.min(moment(dateFilter.to).add(-1, period).endOf(period), moment())
            : moment(dateFilter.to).add(-1, period),
    );
    // Used for the next and previous buttons,
    // if next is true, it moves date range to next period, otherwise it moves it to the previous period
    const moveFilterRange = (next: boolean) => {
        // Don't allow going to the future, or before the minDate
        if ((next && !canGoToNext) || (!next && !canGoToPrevious)) {
            return;
        }
        const fromMoment = moment(dateFilter.from).add(next ? 1 : -1, period);
        const toMoment = moment(dateFilter.to).add(next ? 1 : -1, period);

        // Changing date from outside the datePicker makes the period be set to null
        // But when we move the filter range from here, we want to preserve it.
        const periodFromStoreBeforeChange = periodFromStore;

        if (isFullPeriod || periodFromStore) {
            return onChangeFilterDateRange({
                from: fromMoment.startOf(period).toDate(),
                to: allowFuture
                    ? toMoment.endOf(period).toDate()
                    : moment.min(toMoment.endOf(period), moment()).toDate(),
            });
        }

        onChangeFilterDateRange({
            from: fromMoment.toDate(),
            to: toMoment.toDate(),
        });

        if (periodFromStoreBeforeChange) {
            setStorePeriod(periodFromStoreBeforeChange);
        }
    };

    const onChangeFilterDateRange = (dateRange: DateRange, fromMobile?: boolean) => {
        if (!dateRange.from || !dateRange.to) return;
        const newDateFilter = {
            ...dateFilter,
            from: moment(dateRange.from).toDate(),
            to: moment(dateRange.to).toDate(),
        };

        // Mobile date change needs to be confirmed, desktop doesn't
        setUnconfirmedDateFilter(newDateFilter);
        if (!fromMobile) onChangeDateFilter(newDateFilter);
    };

    return (
        <div className="w-full md:w-auto">
            <div className="tg-caption-bold text-gray-800 mb-2">{t('statistic.dateRange')}</div>
            <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex gap-4 items-center justify-between flex-1 md:flex-initial md:justify-start">
                    <DateRangePicker
                        defaultTimeRange="this_year"
                        minDate={minDate}
                        maxDate={allowFuture ? undefined : new Date()}
                        onDateRangeChange={onChangeFilterDateRange}
                        value={unconfirmedDateFilter}
                        onConfirmDateRange={() => onChangeDateFilter(unconfirmedDateFilter)}
                        onChangeCancel={() => setUnconfirmedDateFilter(dateFilter)}
                        className="w-full md:w-auto"
                    />
                    <div className="flex gap-0">
                        <IconButton
                            icon={['fas', 'chevron-left']}
                            onClick={() => moveFilterRange(false)}
                            disabled={!canGoToPrevious}
                        />
                        <IconButton
                            icon={['fas', 'chevron-right']}
                            onClick={() => moveFilterRange(true)}
                            disabled={!canGoToNext}
                        />
                    </div>
                </div>
                {showClientFilter && onChangeClientFilter && (
                    <div className="max-w-[350px]">
                        <StatisticsClientPicker
                            clients={clients ?? []}
                            value={clientFilter ?? ''}
                            onChange={onChangeClientFilter}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StatisticPageFilters;
