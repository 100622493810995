import { type CSSProperties, type HTMLAttributes, type ReactNode, useState } from 'react';
import styled from 'styled-components';
import { COLOR_BLACKWATER, COLOR_BLUM, TOPBAR_HEIGHT } from 'styles/variables';
import { EezyButton } from './Buttons';
import { Flex, type IFlexProps } from './Flex';
import { Label, SectionTitle } from './textElements';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Flex).attrs(() => ({
    column: true,
}))`
    color: ${COLOR_BLACKWATER};
    flex-grow: 1;
`;

export const UtilsHeader = styled(Flex).attrs(() => ({
    center: true,
    spread: true,
}))`
    margin-top: 15px;
`;

const MainHeader = styled(UtilsHeader)`
    height: ${TOPBAR_HEIGHT}px;
    padding: 15px;
    margin-top: 0;
`;

interface ITogglingToolsProps {
    children: ReactNode;
    defaultOpen?: boolean;
    titleProps?: any;
    titleTransKey: string;
}

export const TogglingTools = (props: ITogglingToolsProps) => {
    const { t } = useTranslation();
    const [contentVisible, setContentVisible] = useState(
        props.defaultOpen !== undefined ? props.defaultOpen : true,
    );

    const toggleContent = () => {
        setContentVisible(!contentVisible);
    };

    return (
        <Wrapper>
            <MainHeader className={contentVisible ? 'open' : ''}>
                <SectionTitle color={COLOR_BLACKWATER} {...props.titleProps}>
                    {t(props.titleTransKey)}
                </SectionTitle>

                <EezyButton color="purple" transparent onClick={toggleContent}>
                    {contentVisible ? t('invoice.manual.hide') : t('invoice.manual.show')}
                </EezyButton>
            </MainHeader>
            {contentVisible && props.children}
        </Wrapper>
    );
};

interface IToolsRowProps {
    buttonTransKey?: string;
    children?: ReactNode;
    style?: CSSProperties;
    titleTransKey?: string;
    title?: string;
    onClick?: (e: any) => void;
}

export const ToolsRow = (props: IToolsRowProps) => {
    const { t } = useTranslation();
    return (
        <Wrapper style={props.style}>
            <UtilsHeader>
                <Label as="h4" color={COLOR_BLUM}>
                    {props.titleTransKey ? t(props.titleTransKey) : props.title ? props.title : ''}
                </Label>
                {props.buttonTransKey && (
                    <EezyButton color="purple" transparent onClick={props.onClick}>
                        {t(props.buttonTransKey)}
                    </EezyButton>
                )}
            </UtilsHeader>
            <div>{props.children}</div>
        </Wrapper>
    );
};

interface IToolsProps extends HTMLAttributes<HTMLDivElement>, IFlexProps {
    header: ReactNode;
}

export const Tools = (props: IToolsProps) => {
    const { children, header, ...divProps } = props;
    return (
        <Wrapper {...divProps}>
            {header}
            {children && <div>{children}</div>}
        </Wrapper>
    );
};

interface IToolsHeaderProps {
    children?: ReactNode;
    color?: string;
    disabled?: boolean;
    lineHeight?: string;
    style?: any;
    titleTransKey: string;
    titleExtra?: string;
}

export const ToolsHeader = (props: IToolsHeaderProps) => {
    const { t } = useTranslation();
    return (
        <UtilsHeader>
            <Label
                as="h4"
                color={props.color ? props.color : COLOR_BLACKWATER}
                style={{
                    lineHeight: props.lineHeight ? props.lineHeight : '28px',
                    opacity: props.disabled ? '0.2' : '1',
                    ...props.style,
                }}
            >
                {t(props.titleTransKey)} {props.titleExtra}
            </Label>
            {props.children}
        </UtilsHeader>
    );
};
