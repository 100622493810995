import { gql } from '@apollo/client';

const UPDATE_USER = gql`
    mutation updateUser($id: Int!, $phone: String, $email: String) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { phone: $phone, email: $email }
        ) {
            id
            phone
            email
        }
    }
`;

export default UPDATE_USER;
