import { gql } from '@apollo/client';

const GET_YTJ_COMPANIES = gql`
    query YtjCompanies($query: String!) {
        ytjSearch(limit: 10, query: $query) {
            count
            items {
                businessId
                name
            }
            offset
            total
        }
    }
`;

export default GET_YTJ_COMPANIES;
