import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { IAddress } from '../../../../../shared/src/types/address';
import type { IClient } from '../../../../../shared/src/types/invoice';
import { EezyButton } from 'components/Buttons';
import ColorBox from 'components/ColorBox';
import { Label, P, PSmall } from 'components/textElements';
import { COLOR_BLUM } from 'styles/variables';
import { formatAddress, ytjDiff } from 'utils/invoice/invoiceLogic';

const UpdateItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;
    &&& button {
        border-radius: 4px;
        min-width: 75px;
    }
`;

interface IYtjDiffProps {
    formData: IClient;
    handleAddressChange: (address?: IAddress) => void;
    handleChange: (val: string, name: string) => void;
    ytjData: IClient;
}

const YtjDiff = (props: IYtjDiffProps) => {
    const { t } = useTranslation();
    const [diff, setDiff] = React.useState<any | null>(null);

    const runDiff = () => {
        const difference = ytjDiff(props.formData, props.ytjData);
        setDiff(difference);
    };

    React.useEffect(() => {
        // effect is run only once. If props are added in dependency list,
        // difference is calculated for every form data update
        if (props.ytjData && props.formData) {
            runDiff();
        }
    }, []);

    if (!diff || Object.keys(diff).length === 0) {
        return null;
    }

    return (
        <ColorBox topContent={<P>{t('invoice.ytj-diff')}</P>}>
            <div>
                {diff &&
                    Object.keys(diff).map((d) => {
                        const clientKey = d as keyof IClient;
                        return (
                            <UpdateItem key={clientKey}>
                                <div>
                                    <Label color={COLOR_BLUM}>
                                        {t(`invoice.form.recipient.${clientKey}`)}
                                    </Label>
                                    <PSmall color={COLOR_BLUM}>
                                        {clientKey === 'address'
                                            ? formatAddress(props.ytjData?.address)
                                            : (props.ytjData[clientKey] as string)}
                                    </PSmall>
                                </div>
                                <EezyButton
                                    color="purple"
                                    onClick={() => {
                                        if (props.ytjData[clientKey]) {
                                            if (clientKey === 'address') {
                                                props.handleAddressChange(props.ytjData[clientKey]);
                                            } else {
                                                props.handleChange(props.ytjData[clientKey] as string, d);
                                            }
                                            delete diff[clientKey];
                                        }
                                    }}
                                >
                                    {t('form.update')}
                                </EezyButton>
                            </UpdateItem>
                        );
                    })}
            </div>
        </ColorBox>
    );
};

export default YtjDiff;
