import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressInput, FormRow, FormSection, SplitRow } from 'components/form';
import { FormLabel } from 'components/form/FormLabel';
import { round } from 'utils/calc';
import type { ICostComponentProps, IRound } from 'utils/costs/costLogic';
import 'utils/mapsApi';
import { checkDirectionsServices, getDirections, getDistance, type IDirectionsResult } from 'utils/mapsUtils';

export const TravelRound = (props: ICostComponentProps) => {
    const { t } = useTranslation();
    const { dispatch } = props;

    const getRouteDirections = useMemo(() => getDirections, []);

    const handleKmUpdate = useCallback(
        (val: number) => {
            dispatch({
                payload: { reimbursedKMs: { quantity: round(val) } },
                type: 'SAVE_TRAVEL',
            });
        },
        [dispatch],
    );

    const handleMapsDistanceUpdate = useCallback(
        (totalKMs: number) => {
            dispatch({
                payload: { totalKMs },
                type: 'SAVE_MAPS_DISTANCE',
            });
        },
        [dispatch],
    );

    const saveDirections = useCallback(
        (payload?: { route: IRound; directions: IDirectionsResult }) => {
            dispatch({ payload, type: 'SAVE_ROUND_DIRECTIONS' });
        },
        [dispatch],
    );

    const updateDirections = () => {
        // When roundtrip is changed, calculate new directions.
        // Distance is calculated based on the directions in TravelDistance.tsx.
        if (
            !checkDirectionsServices() ||
            !props.travelRound.origin ||
            !props.travelRound.destination ||
            (props.travelRound.origin === props.travelRoundDirections?.route.origin &&
                props.travelRound.destination === props.travelRoundDirections?.route.destination)
        ) {
            return;
        }

        getRouteDirections(
            {
                destination: props.travelRound?.origin || '',
                origin: props.travelRound?.origin || '',
                travelMode: 'DRIVING',
                waypoints: [{ location: props.travelRound?.destination || '' }],
            },
            (results?: any) => {
                if (results?.status === 'OK') {
                    const distanceM = getDistance(results);
                    handleKmUpdate(round(distanceM / 1000));
                    handleMapsDistanceUpdate(round(distanceM / 1000));
                    saveDirections({
                        directions: results,
                        route: props.travelRound,
                    });
                } else {
                    saveDirections(undefined);
                    handleKmUpdate(0);
                    handleMapsDistanceUpdate(0);
                }
            },
        );
    };

    const handleOriginUpdate = (origin: string) => {
        dispatch({ payload: { origin }, type: 'SAVE_ROUNDTRIP' });
    };

    const handleDestinationUpdate = (destination: string) => {
        dispatch({ payload: { destination }, type: 'SAVE_ROUNDTRIP' });
    };

    return (
        <div>
            <FormSection>
                <SplitRow>
                    <FormRow style={{ flexGrow: 1 }}>
                        <FormLabel htmlFor="round-origin" value={props.travelRound?.origin}>
                            {t('costs.travel-origin')}
                        </FormLabel>
                        <AddressInput
                            data-mf-replace="**REMOVED**"
                            freeSolo
                            id="round-origin"
                            onBlur={() => updateDirections()}
                            onChange={(val: string) => handleOriginUpdate(val)}
                            placeholder={t('costs.address-placeholders.0') || ''}
                            suggestedValue={props.addressSuggestions?.home || ''}
                            value={props.travelRound?.origin}
                        />
                    </FormRow>

                    <FormRow>
                        <FormLabel htmlFor="round-destination" value={props.travelRound?.destination}>
                            {t('costs.travel-destination')}
                        </FormLabel>
                        <AddressInput
                            data-mf-replace="**REMOVED**"
                            freeSolo
                            id="round-destination"
                            onBlur={() => updateDirections()}
                            onChange={(val: string) => handleDestinationUpdate(val)}
                            placeholder={t('costs.address-placeholders.1') || ''}
                            suggestedValue={props.addressSuggestions?.client || ''}
                            value={props.travelRound?.destination}
                        />
                    </FormRow>
                </SplitRow>
            </FormSection>
        </div>
    );
};
