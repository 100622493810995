import { gql } from '@apollo/client';

const GET_OCCUPATIONS = gql`
    query Occupations {
        allOccupations {
            id
            insurancePercentage
        }
    }
`;

export default GET_OCCUPATIONS;
