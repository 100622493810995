import { useState } from 'react';
import { Popover } from '@mui/material';
import { DateCalendar, type DateRange } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import moment from 'moment';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { HoursMinutesTime } from 'components/form';
import { FormLabel } from 'components/form/FormLabel';
import { Line2 } from 'components/Lines';
import SwitchButton from 'components/Switch';
import { ToolsHeader } from 'components/TogglingTools';
import { COLOR_BLACKWATER, COLOR_LILA, COLOR_LILA_LIGHT } from 'styles/variables';
import { dateIsValid } from 'utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
    paper: {
        padding: '0 20px 20px',
    },
    picker: {
        '& .Mui-selected, & .Mui-selected:hover, & .Mui-selected:focus': {
            backgroundColor: COLOR_LILA,
            color: COLOR_BLACKWATER,
            fontWeight: 'bold',
        },
        '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            backgroundColor: COLOR_LILA_LIGHT,
            color: COLOR_BLACKWATER,
        },
        '& .PrivatePickersSlideTransition-root': {
            minHeight: '216px',
        },
        '& button': {
            borderRadius: '50%',
        },
    },
});

const ButtonWrapper = styled.div`
    margin-top: 10px;
    text-align: center;
`;

const DateTimeWrapper = styled.div`
    margin-top: 10px;
    max-width: 130px;
`;

interface IProps {
    anchorEl: HTMLElement | null;
    endTime?: Date;
    range: DateRange<Date>;
    onClose: (date: Date | null) => void;
    onDateRangeChanged: (range: DateRange<Date>) => void;
    onDateRangeFinished: () => void;
    onEndTimeChanged: (time: Date) => void;
    onStartTimeChanged: (time: Date) => void;
    startTime?: Date;
}

const RepeatedTripsPopover = (props: IProps) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const open = Boolean(props.anchorEl);
    const id = open ? 'repeated-trips-popover' : undefined;

    const [date, setDate] = useState<Date | null>(new Date());

    const [useRepeatedTrips, setUseRepeatedTrips] = useState(false);

    const changeDate = (newDate: Date | null) => {
        setDate(newDate);
        props.onClose(newDate);
    };

    const selectDateRange = (newRange: DateRange<Date>) => {
        const startTime = moment(props.startTime);
        const endTime = moment(props.endTime);
        const rangeStart = moment(newRange[0]).set({
            hours: startTime.hours(),
            minutes: startTime.minutes(),
        });
        const rangeEnd = moment(newRange[1]).set({
            hours: endTime.hours(),
            minutes: endTime.minutes(),
        });
        props.onDateRangeChanged([rangeStart.toDate(), rangeEnd.toDate()]);
    };

    const handleClose = () => {
        props.onClose(date);
    };

    const toggle = () => {
        setUseRepeatedTrips(!useRepeatedTrips);
    };

    const isValid = () => {
        const rangeStart = moment(props.range[0]);
        const rangeEnd = moment(props.range[1]);
        if (!rangeStart.isValid() || !rangeEnd.isValid()) {
            return false;
        }
        if (rangeEnd.isBefore(rangeStart)) {
            return false;
        }
        if (!dateIsValid(props.startTime) || !dateIsValid(props.endTime)) {
            return false;
        }
        const timeComparison = moment(props.startTime).set({
            hours: props.endTime!.getHours(),
            minutes: props.endTime!.getMinutes(),
        });
        return !timeComparison.isSameOrBefore(props.startTime, 'm');
    };

    const start = props.startTime ? new Date(props.startTime) : undefined;
    const end = props.endTime ? new Date(props.endTime) : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            onClose={handleClose}
            transformOrigin={{
                horizontal: 'center',
                vertical: 'top',
            }}
        >
            {useRepeatedTrips ? (
                <StaticDateRangePicker // TODO: test it.
                    className={classes.picker}
                    displayStaticWrapperAs="desktop"
                    calendars={1}
                    value={props.range}
                    onChange={selectDateRange}
                    // renderInput={() => <></>}
                />
            ) : (
                <DateCalendar value={date} onChange={changeDate} />
            )}

            <div className={classes.paper}>
                <Line2 />
                <ToolsHeader titleTransKey="costs.repeated-trips.toggle">
                    <SwitchButton
                        checked={useRepeatedTrips}
                        onChange={toggle}
                        inputProps={{
                            'aria-label': t('costs.repeated-trips.toggle') || '',
                        }}
                    />
                </ToolsHeader>

                {useRepeatedTrips && (
                    <>
                        <Line2 />
                        <Flex spread>
                            <DateTimeWrapper>
                                <FormLabel htmlFor="travel-start">{t('costs.travel-time.start')}</FormLabel>
                                <HoursMinutesTime
                                    name="travel-start-time"
                                    onChange={props.onStartTimeChanged}
                                    required
                                    value={start}
                                />
                            </DateTimeWrapper>
                            <DateTimeWrapper>
                                <FormLabel htmlFor="travel-end">{t('costs.travel-time.end')}</FormLabel>
                                <HoursMinutesTime
                                    name="travel-start-end"
                                    onChange={props.onEndTimeChanged}
                                    required
                                    value={end}
                                />
                            </DateTimeWrapper>
                        </Flex>
                        <ButtonWrapper>
                            <EezyButton color={'purple'} onClick={() => props.onClose(null)}>
                                {t('general.cancel')}
                            </EezyButton>
                            <EezyButton
                                color={'purple'}
                                dark
                                disabled={!isValid()}
                                onClick={props.onDateRangeFinished}
                            >
                                {t('invoice.button-next')}
                            </EezyButton>
                        </ButtonWrapper>
                    </>
                )}
            </div>
        </Popover>
    );
};

export default RepeatedTripsPopover;
