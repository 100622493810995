import { gql } from '@apollo/client';

const GET_CLIENT = gql`
    query Client($id: String!, $type: ClientType!) {
        client(id: $id, type: $type) {
            active
            address {
                country
                street1
                street2
                town
                zipCode
            }
            businessId
            contactPeople {
                active
                firstName
                id
                lastName
            }
            einvoiceOvt
            operator
            email
            firstName
            id
            invoiceLanguage
            invoicingMethod
            lastName
            name
            type
        }
    }
`;

export default GET_CLIENT;
