import { useMemo } from 'react';
import { type ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from 'components/ui/chart';
import { Area, CartesianGrid, ComposedChart, Line, XAxis, YAxis } from 'recharts';
import { capitalize } from 'utils/str';
import type { SalesPageStatisticData } from 'utils/statistics/statisticUtils';

interface ISalesStatsBillingChart {
    mainGraph: SalesPageStatisticData;
    data: SalesPageStatisticData[];
}

const SalesStatsBillingChart = ({ mainGraph, data }: ISalesStatsBillingChart) => {
    // this is the tailwindcss md breakpoint
    const isMobile = window.innerWidth < 768;
    const allCharts = useMemo(() => [mainGraph, ...data], [mainGraph, data]);

    const chartConfig = useMemo(() => {
        const conf: ChartConfig = {};
        for (const { name, color } of allCharts) {
            conf[name] = { label: name, color };
        }

        return conf;
    }, [allCharts]);

    // We want to find the longest data, so we can make all the charts the same length by padding them with 0's
    const longestData = useMemo(
        () =>
            allCharts.length
                ? allCharts.reduce((a, b) => (a.items.length > b.items.length ? a : b)).items
                : [],
        [allCharts],
    );

    const chartData = useMemo(() => {
        // Find data.items with longest length
        if (!allCharts.length) return [];
        return longestData.map((item) => {
            // ChartItem is a single data point in x-axis. Label is the date formatted. We always use the label from the longest data,
            // because it has all the labels in the range.
            const chartItem: { label: string } & Record<string, number | string> = { label: item.label };
            // Here we add the values from all the charts (one chart is one line in the graph) to the chartItem
            // We find it by label, and if it's not found, we default to 0.
            for (const value of allCharts) {
                chartItem[value.name] = value.items.find((i) => i.label === item.label)?.values.total ?? 0;
            }
            /**
             * Result is an object that looks something like this:
             * {
             *  label: '2021-01-01',
             *  mainGraph: 1000,
             *  graph1: 2000,
             *  graph2: 3000
             * }
             */

            return chartItem;
        });
    }, [allCharts, longestData]);

    return (
        <ChartContainer
            config={chartConfig}
            className="h-[350px] w-full [&_svg]:overflow-visible !font-jakarta"
        >
            <ComposedChart data={chartData} accessibilityLayer>
                <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#AB9AEB" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#AB9AEB" stopOpacity={0.1} />
                    </linearGradient>
                </defs>
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey="label"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    interval="preserveStartEnd"
                />
                <YAxis
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    tickFormatter={(value) => `${value.toLocaleString('fi')}€`}
                    mirror={isMobile}
                    dy={isMobile ? -10 : 0}
                />
                <ChartTooltip
                    animationDuration={300}
                    content={
                        <ChartTooltipContent
                            labelFormatter={(label) => capitalize(label)}
                            formatValue={(value) =>
                                new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(
                                    Number.isNaN(value) ? 0 : (value as number),
                                )
                            }
                        />
                    }
                />
                <Area dataKey={mainGraph.name} color="#AB9AEB" fill="url(#gradient)" stroke="#AB9AEB" />
                {data.map(({ name, color }) => (
                    <Line key={name} dataKey={name} stroke={color} dot={false} strokeWidth={2} />
                ))}
            </ComposedChart>
        </ChartContainer>
    );
};

export default SalesStatsBillingChart;
