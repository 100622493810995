import { combineReducers } from 'redux';

import appReducer, { type IAppState } from './appReducer';
import authReducer, { type IAuth } from './authReducer';
import coworkerReducer, { type IInvoiceState } from './coworkerReducer';
import fileReducer, { type IFileState } from './fileReducer';
import invoiceReducer from './invoiceReducer';
import salaryReducer, { type ISalaryState } from './salaryReducer';
import eezyPayReducer, { type IEezyPayState } from './eezyPayReducer';

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    coworker: coworkerReducer,
    file: fileReducer,
    invoice: invoiceReducer,
    salary: salaryReducer,
    eezyPay: eezyPayReducer,
});

export interface IRootState {
    app: IAppState;
    auth: IAuth;
    coworker: IInvoiceState;
    file: IFileState;
    invoice: IInvoiceState;
    salary: ISalaryState;
    eezyPay: IEezyPayState;
}
