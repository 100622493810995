import { SALARY_SEARCH_QUERY } from '../actionTypes';

export interface ISalaryState {
    searchQuery: string;
}

const initialState: ISalaryState = {
    searchQuery: '',
};

export default (
    // biome-ignore lint/style/useDefaultParameterLast: Redux will be eventually removed
    state = initialState,
    action: {
        payload: { searchQuery: string };
        type: string;
    },
) => {
    switch (action.type) {
        case SALARY_SEARCH_QUERY:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
