import { Fade } from '@mui/material';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { COLOR_JAFFA } from '../styles/variables';

export const MultiplePostItWrapper = styled.div`
    position: absolute;
    right: 50px;
    top: 100px;
`;

const Wrapper = styled.div`
    background: ${COLOR_JAFFA};
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    padding: 20px 15px;
    z-index: 1;

    p:not(:first-of-type) {
        line-height: 20px;
        margin-top: 15px;
        word-break: break-word;
    }

    &.square {
        min-height: 230px;
        width: 280px;
    }
    &:not(.square) {
        border-radius: 4px;
    }

    & ~ & {
        margin-top: 15px;
    }
`;

interface IPostItProps {
    children: ReactNode;
    square?: boolean;
}

const PostIt = (props: IPostItProps) => {
    return (
        <Fade in={true} timeout={200}>
            <Wrapper id="card" className={props.square ? 'square' : ''}>
                {props.children}
            </Wrapper>
        </Fade>
    );
};

export default PostIt;
