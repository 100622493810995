import styled from 'styled-components';

import { COLOR_BLUM } from '../../styles/variables';
import { formatDate } from '../../utils';
import { EezyButton, EezyLink, InlineButtonLink } from '../Buttons';
import { Icon } from '../Icon';
import { SingleText } from '../textElements';
import { CheckboxButton } from './';
import { useTranslation } from 'react-i18next';

const StyledFileRow = styled.div`
    & {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 0;
    }
    & div:first-of-type {
        display: flex;
        white-space: nowrap;

        button,
        div,
        a {
            margin-left: 5px;
        }
    }

    ${SingleText} {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 5px;
        white-space: nowrap;
    }
`;

interface IProps {
    date?: string;
    handleFileNameClick?: () => void;
    handleRemoval?: (id: number) => void;
    handleSelectionToggle?: (id: number) => void;
    id: number;
    name: string;
    selected?: boolean;
    url?: string;
}

const FileRow = (props: IProps) => {
    const { t } = useTranslation();
    return (
        <StyledFileRow>
            {!props.handleFileNameClick ? (
                <SingleText color="black">{props.name}</SingleText>
            ) : (
                <InlineButtonLink onClick={props.handleFileNameClick} color={COLOR_BLUM}>
                    {props.name}
                </InlineButtonLink>
            )}

            <div>
                {props.date && (
                    <SingleText style={{ display: 'inline' }}>{formatDate(props.date)}</SingleText>
                )}
                {props.handleSelectionToggle && (
                    <CheckboxButton
                        id={`file-${props.id}`}
                        checked={props.selected || false}
                        inverted
                        label={props.selected ? t('file.selected') : t('file.select')}
                        name={`file-${props.id}`}
                        onChange={() => {
                            if (props.handleSelectionToggle) {
                                props.handleSelectionToggle(props.id);
                            }
                        }}
                        value={props.id}
                        style={{
                            width: 75,
                        }}
                    />
                )}
                {props.url && (
                    <EezyLink
                        color="purple"
                        href={props.url}
                        rel="noopener noreferrer"
                        square
                        target="_blank"
                        width={75}
                    >
                        <Icon icon={['far', 'eye']} /> {t('file.show')}
                    </EezyLink>
                )}
                {props.handleRemoval && (
                    <EezyButton
                        aria-label={t('file.remove-label', {
                            name: props.name,
                        })}
                        color="purple"
                        onClick={() => {
                            if (props.handleRemoval) {
                                props.handleRemoval(props.id);
                            }
                        }}
                        square
                        width={30}
                        style={{ padding: 0 }}
                        type="button"
                    >
                        <Icon color={COLOR_BLUM} icon={['far', 'trash-alt']} />
                    </EezyButton>
                )}
            </div>
        </StyledFileRow>
    );
};

export default FileRow;
