import { gql } from '@apollo/client';

const RETURN_INVOICE = gql`
    mutation ReturnInvoice($id: Int!) {
        returnInvoice(invoiceId: $id) {
            id
            costInvoice {
                id
                status
            }
            status
        }
    }
`;

export default RETURN_INVOICE;
