export const SalaryCalcConfig = {
    dailyAllowance: 51.0,
    debug: false,
    eezyServiceRate: 7,
    halfDailyAllowance: 24.0,
    healthInsuranceRate: 1.16,
    mealReimbursement: 12.75,
    mileageReimbursement: 0.57,
    mileageReimbursementTrailer: 0.1,
    vatRate: 24,
    yelRate: 24.1,
    eezyPayServiceRate: 1.5,
};
