import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EezyButton } from 'components/Buttons';
import { AutoSaveCard } from 'components/cards';
import { Flex } from 'components/Flex';
import { P, RowTitle } from 'components/textElements';
import ContractsModal from './ContractsModal';
import { showModals } from 'actions/auth';
import { COLOR_BLUM } from 'styles/variables';

const ProfileImportant = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const [contractsModalOpen, setContractsModalOpen] = useState(false);

    const location = useLocation();

    const scrollRef = useRef<HTMLDivElement>(null);
    const path = location.pathname;
    const pathSubstring = path.substring(path.lastIndexOf('/')); // TODO: Is it correct? Test it.

    useEffect(() => {
        if (pathSubstring === '/documents' && scrollRef.current) {
            window.requestAnimationFrame(() =>
                scrollRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                }),
            );
        }
    }, [pathSubstring]);

    return (
        <div ref={scrollRef}>
            <AutoSaveCard title={t('profile:important.title')}>
                <Flex spread style={{ marginTop: 15 }}>
                    <RowTitle color={COLOR_BLUM}>{t('profile:important.restricted-work.title')}</RowTitle>
                    <EezyButton color="purple" onClick={() => dispatch(showModals(['RESTRICTED_WORK']))}>
                        {t('profile:important.open')}
                    </EezyButton>
                </Flex>

                <P>{t('profile:important.restricted-work.description')}</P>

                <Flex spread style={{ marginTop: 15 }}>
                    <RowTitle color={COLOR_BLUM}>{t('profile:important.gdpr.title')}</RowTitle>
                    <EezyButton color="purple" onClick={() => dispatch(showModals(['GDPR']))}>
                        {t('profile:important.open')}
                    </EezyButton>
                </Flex>

                <P>{t('profile:important.gdpr.description')}</P>

                <Flex spread style={{ marginTop: 15 }}>
                    <RowTitle color={COLOR_BLUM}>{t('profile:important.contracts.title')}</RowTitle>
                    <EezyButton color="purple" onClick={() => setContractsModalOpen(true)}>
                        {t('profile:important.open')}
                    </EezyButton>
                </Flex>

                <P>{t('profile:important.contracts.description')}</P>

                <Flex spread style={{ marginTop: 15 }}>
                    <RowTitle color={COLOR_BLUM}>{t('profile:important.regCert.title')}</RowTitle>
                    <EezyButton color="purple" onClick={() => dispatch(showModals(['REG_CERT']))}>
                        {t('profile:important.download')}
                    </EezyButton>
                </Flex>

                {contractsModalOpen && (
                    <ContractsModal
                        handleModalClose={() => setContractsModalOpen(false)}
                        isOpen={contractsModalOpen}
                    />
                )}
            </AutoSaveCard>
        </div>
    );
};

export default ProfileImportant;
