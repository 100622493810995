import moment from 'moment';
import type { IBriefInvoice } from '../../../../shared/src/types/invoice';
import type { DateRange } from 'react-day-picker';
import {
    type DateFilter,
    dateRangeToString,
    getTimeLabel,
    isWithinSingleMonth,
} from 'utils/statistics/dateUtils';
import type { ChartColor } from 'constants/statisticConstants';

export type StatisticInvoice = {
    id: number;
    date: string;
    dueDate: string;
    updatedDate: string;
    status: string;
    total: number;
    totalWithVat: number;
    paidAmount: number;
    clientName?: string;
    isGroupInvoice?: boolean;
};

export const briefInvoiceToStatisticInvoice = (invoice: IBriefInvoice): StatisticInvoice => ({
    id: invoice.id,
    date: invoice.invoiceDate ? moment(invoice.invoiceDate).format('YYYY-MM-DD') : '',
    dueDate: invoice.dueDate ? moment(invoice.dueDate).format('YYYY-MM-DD') : '',
    updatedDate: invoice.updateDate,
    status: invoice.status,
    total: invoice.total,
    totalWithVat: invoice.totalWithVat,
    paidAmount: invoice.paidAmount ?? 0,
    clientName: invoice?.name?.trim(),
    isGroupInvoice: invoice.isGroupInvoice,
});

export const checkInvoiceAcceptForStatistics = (invoice: StatisticInvoice) =>
    ['completed', 'paid', 'overdued'].includes(invoice.status);

const calculateCreditedPrices = (invoice: StatisticInvoice | IBriefInvoice) => {
    const originalPriceWithVAT = invoice.totalWithVat;
    const originalPriceWithoutVAT = invoice.total;
    const paidAmount = invoice.paidAmount ?? 0;

    // Calculate the VAT percentage
    const vatPercentage = ((originalPriceWithVAT - originalPriceWithoutVAT) / originalPriceWithoutVAT) * 100;

    // Calculate the credited price without VAT
    const creditedPriceWithoutVAT = paidAmount / (1 + vatPercentage / 100);

    // Calculate the credited price with VAT
    const creditedPriceWithVAT = paidAmount;

    return {
        creditedPriceWithoutVAT: +creditedPriceWithoutVAT.toFixed(2),
        creditedPriceWithVAT: +creditedPriceWithVAT.toFixed(2),
        vatPercentage: +vatPercentage.toFixed(2),
    };
};

// Filter invoices that are not accepted for statistics, and handle partly credited invoices
export const handleInvoiceForStatistics = (invoice: StatisticInvoice): StatisticInvoice => {
    const credit = calculateCreditedPrices(invoice);
    if (invoice.status === 'paid' && credit.creditedPriceWithVAT < invoice.totalWithVat) {
        return {
            ...invoice,
            total: credit.creditedPriceWithoutVAT,
            totalWithVat: credit.creditedPriceWithVAT,
        };
    }

    return invoice;
};

export type ChartDataStatus = 'completed' | 'paid' | 'overdued';

type ChartData = {
    total: number | null;
    totalWithVat: number | null;
};

/**
 * Looks like this:
 * {
 *  date: '2021-01-01',
 *  values: {
 *  completed: { total: 1000, totalWithVat: 1200 },
 *  paid: { total: 2000, totalWithVat: 2400 },
 *  overdued: { total: 3000, totalWithVat: 3600 },
 *  total: { total: 6000, totalWithVat: 7200 }
 * }
 */

type ChartDataPoint = {
    date: string;
    values: {
        [key in ChartDataStatus]: ChartData;
    } & {
        total: ChartData;
    };
};

export const groupInvoices = (invoices: StatisticInvoice[], groupByMonth?: boolean) => {
    // Group by period
    const byGroups: { [key: string]: ChartDataPoint } = {};

    for (const invoice of invoices) {
        const key = moment(invoice.date).format(groupByMonth ? 'YYYY-MM' : 'YYYY-MM-DD');

        if (!byGroups[key]) {
            byGroups[key] = {
                date: invoice.date,
                values: {
                    completed: { total: 0, totalWithVat: 0 },
                    paid: { total: 0, totalWithVat: 0 },
                    overdued: { total: 0, totalWithVat: 0 },
                    total: { total: 0, totalWithVat: 0 },
                },
            };
        }

        const item = byGroups[key];

        // These if's aren't needed here, they are just to make typescript happy
        if (item.values.total.total !== null) item.values.total.total += invoice.total / 100;
        if (item.values.total.totalWithVat !== null)
            item.values.total.totalWithVat += invoice.totalWithVat / 100;

        const statusValues = item.values[invoice.status as ChartDataStatus];
        if (statusValues.total !== null) statusValues.total += invoice.total / 100;
        if (statusValues.totalWithVat !== null) statusValues.totalWithVat += invoice.totalWithVat / 100;
    }

    return byGroups;
};

// Add empty data for months that have no data
const fillUnusedGraphData = (
    groupedInvoices: { [key: string]: ChartDataPoint },
    dateRange: DateRange,
    groupByMonth?: boolean,
) => {
    const now = new Date();
    const byGroups = { ...groupedInvoices };

    if (groupByMonth) {
        for (let date = moment(dateRange.from); date.isSameOrBefore(dateRange.to); date.add(1, 'month')) {
            const key = date.format('YYYY-MM');
            const isAfterToday = +date.toDate() - +now > 0;
            const value = isAfterToday ? null : 0;

            if (!byGroups[key]) {
                byGroups[key] = {
                    date: key,
                    values: {
                        completed: { total: value, totalWithVat: value },
                        paid: { total: value, totalWithVat: value },
                        overdued: { total: value, totalWithVat: value },
                        total: { total: value, totalWithVat: value },
                    },
                };
            }
        }
    } else {
        for (let date = moment(dateRange.from); date.isSameOrBefore(dateRange.to); date.add(1, 'day')) {
            const key = date.format('YYYY-MM-DD');
            const isAfterToday = +date.toDate() - +now > 0;
            const value = isAfterToday ? null : 0;

            if (!byGroups[key]) {
                byGroups[key] = {
                    date: key,
                    values: {
                        completed: { total: value, totalWithVat: value },
                        paid: { total: value, totalWithVat: value },
                        overdued: { total: value, totalWithVat: value },
                        total: { total: value, totalWithVat: value },
                    },
                };
            }
        }
    }

    const result = Object.values(byGroups);

    result.sort((a, b) => +moment(a.date).toDate() - +moment(b.date).toDate());

    return result;
};

type InvoiceUtilOptions = {
    groupByMonth: boolean;
    spansMultipleYears: boolean;
    withVat: boolean;
};

function mapInvoiceToChartItem(
    item: ChartDataPoint,
    options: InvoiceUtilOptions & { isWithinMonth: boolean },
) {
    let format = 'DD.MM.';
    if (options.isWithinMonth) format = 'Do';
    if (options.groupByMonth) format = 'MMM';
    if (options.spansMultipleYears) format = 'MMM YYYY';

    return {
        label: moment(item.date).format(format),
        date: item.date,
        values: {
            completed: options.withVat ? item.values.completed.totalWithVat : item.values.completed.total,
            paid: options.withVat ? item.values.paid.totalWithVat : item.values.paid.total,
            overdued: options.withVat ? item.values.overdued.totalWithVat : item.values.overdued.total,
            total: options.withVat ? item.values.total.totalWithVat : item.values.total.total,
        },
    };
}

// Function to be passed to array.filter, notice the currying.
// You can use it like this: invoices.filter(invoiceDateFilter(dateRange))
// You can also pass multiple date ranges as an array.
export const invoiceDateFilter =
    (dateFilter: DateFilter | DateFilter[]) =>
    (invoice: StatisticInvoice): boolean => {
        if (Array.isArray(dateFilter)) {
            return dateFilter.some((range) => invoiceDateFilter(range)(invoice));
        }

        return (
            moment(invoice.date).isSameOrAfter(dateFilter.from, 'date') &&
            moment(invoice.date).isSameOrBefore(dateFilter.to, 'date')
        );
    };

type StatisticInvoicesToGraphDataOptions = {
    invoices: StatisticInvoice[];
    dateFilter: DateFilter;
    color: ChartColor;
    maxDate?: Date;
    options: InvoiceUtilOptions;
};

export const statisticInvoicesToGraphData = ({
    invoices,
    dateFilter,
    color,
    maxDate,
    options,
}: StatisticInvoicesToGraphDataOptions) => {
    const byGroups = groupInvoices(invoices.filter(invoiceDateFilter(dateFilter)), options.groupByMonth);
    const chartData = fillUnusedGraphData(byGroups, dateFilter, options.groupByMonth);

    return {
        dateRangeString: dateRangeToString(dateFilter),
        name: getTimeLabel({ dateFilter: dateFilter, maxDate }),
        color,
        invoices: chartData,
        items: chartData.map((i) =>
            mapInvoiceToChartItem(i, { ...options, isWithinMonth: isWithinSingleMonth(dateFilter) }),
        ),
    };
};

export type SalesPageStatisticData = ReturnType<typeof statisticInvoicesToGraphData>;
