import type { CSSProperties } from 'react';
import styled from 'styled-components';
import { COLOR_IMPORTANT, SCREEN_M } from '../styles/variables';
import { Icon } from './Icon';

const StyledErrorIcon = styled(Icon)`
    height: 25px;
    margin-left: -25px;
    margin-top: 5px;
    position: absolute;
    width: 25px;

    @media (min-width: ${SCREEN_M}px) {
        margin-left: -35px;
    }
`;

const ErrorPointer = ({ style }: { style?: CSSProperties }) => {
    return <StyledErrorIcon icon={['far', 'circle-right']} color={COLOR_IMPORTANT} style={style} />;
};

export default ErrorPointer;
