import { gql } from '@apollo/client';

/*
    ContactPeople cannot be queried here. They would overwrite invoice.recipient.contactPeople.
*/

const GET_INVOICE_RECIPIENTS = gql`
    query InvoiceRecipients {
        allClients {
            active
            address {
                country
                street1
                street2
                town
                zipCode
            }
            businessId
            einvoiceOvt
            operator
            email
            firstName
            invoiceLanguage
            invoicingMethod
            id
            lastName
            name
            type
        }
    }
`;

export default GET_INVOICE_RECIPIENTS;
