import { connect } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { hideConfirmation, type IConfirmAction } from '../actions/app';
import ConfirmationModal from '../components/modals/ConfirmationModal';

/*
    ConfirmAction component provides a way to easily pop a confirmation modal
    asking "Are you sure you want to do this" by dispatching 
    an action `confirmAction(payload: IConfirmAction)`.
    
    E.g.
    props.confirmAction({
        id: 'cost-delete',
        onAction: () =>
            handleDelete(r.id, r.type),
        texts: {
            paragraph:
                'costs.confirm-delete2',
            title: 'costs.confirm-delete'
        }
    })
*/

interface IConfirmActionProps {
    closeModal: () => void;
    isOpen: boolean;
    modalData: IConfirmAction;
}

const ConfirmAction = (props: IConfirmActionProps) => {
    const handleClose = () => {
        props.closeModal();
    };

    const handleSubmit = (reason?: string) => {
        props.modalData.onAction(reason);
        handleClose();
    };

    if (!props.modalData) {
        return null;
    }

    return (
        <ConfirmationModal
            id={props.modalData.id}
            isOpen={props.isOpen}
            handleClose={handleClose}
            handleSubmit={(reason) => {
                handleSubmit(reason);
            }}
            texts={props.modalData.texts}
        />
    );
};

const mapStateToProps = (state: any) => {
    return {
        isOpen: !!state.app.confirmationModal,
        modalData: state.app.confirmationModal,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        closeModal: () => {
            dispatch(hideConfirmation());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAction);
