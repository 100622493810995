import { gql } from '@apollo/client';

const ACCEPT_CAMPWIRE_TERMS = gql`
    mutation AcceptCampwireTerms($id: Int!) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { campwireTermsAccepted: true, termsAccepted: true }
        ) {
            id
            termsAccepted
            campwireTermsAccepted
        }
    }
`;

export default ACCEPT_CAMPWIRE_TERMS;
