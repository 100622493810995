import { gql } from '@apollo/client';

const CREATE_TRAVEL = gql`
    mutation CreateTravel($travel: InputTravel!, $invoiceId: Int, $costInvoiceId: Int) {
        createTravel(travel: $travel, invoiceId: $invoiceId, costInvoiceId: $costInvoiceId) {
            id
            dailyAllowancesFull {
                quantity
                salaryType
            }
            dailyAllowancesHalf {
                quantity
            }
            endTime
            id
            receiptCosts {
                id
                amountsWithVats {
                    amount
                    id
                    vatPercent
                }
                createTime
                description
                purchaseDate
                receiptFiles {
                    contentType
                    createTime
                    id
                    name
                    previewUrl
                    url
                }
                type
            }
            reimbursedKMs {
                quantity
            }
            reimbursedMeals {
                quantity
            }
            reimbursedTrailerKMs {
                quantity
            }
            route
            startTime
            carNumber
        }
    }
`;

export default CREATE_TRAVEL;
