import { type FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import type { IInvoice, LanguageCode } from '../../../../shared/src/types/invoice';
import { Flex } from 'components/Flex';
import { Input } from 'components/form/TextInput';
import { LabelCapsSmall, PSmall } from 'components/textElements';
import { trans } from 'utils';
import { BORDER_OPTIONAL_FIELD, COLOR_DARK_GRAY, COLOR_IMPORTANT, SCREEN_S } from 'styles/variables';
import { transFixed } from 'utils/i18n';
import type { FieldName, IInvoiceField, IInvoiceTemplate } from 'utils/invoice/invoiceTemplates';

const StyledInput = styled(Input)`
    border-bottom: ${BORDER_OPTIONAL_FIELD};
    width: 100%;
    @media (max-width: ${SCREEN_S}px) {
        margin-right: 20px;
    }
    text-align: ${(props: { $column?: boolean }) => (props.$column ? 'left' : 'right')};
    max-width: 100%;
    &:focus {
        border-bottom: 2px solid ${COLOR_IMPORTANT};
    }
    padding: 18px 0;
`;

const StyledP = styled(PSmall)`
    max-width: 10rem;
    text-align: right;
    &:not(.open) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &.open {
        overflow-wrap: break-word;
    }
`;

const InputWrapper = styled.div`
    ${(props: { $column?: boolean }) => (props.$column ? '' : 'max-width: 40%;')}
    ${(props: { $column?: boolean }) => (props.$column ? 'margin-top: 10px;' : '')}
    ${(props: { $column?: boolean }) => (props.$column ? 'margin-bottom: 5px;' : '')}
    position: relative;
`;

const ExpandablePSmall = ({ text }: { text?: string }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    return (
        <StyledP className={isOpen ? 'open' : ''} color={COLOR_DARK_GRAY} title={text} onClick={handleClick}>
            {text}
        </StyledP>
    );
};

interface IInvoiceOtherFieldProps {
    column: boolean;
    editable: boolean;
    handleUpdate: (variable: object) => void;
    invoice: IInvoice;
    invoiceLanguage?: LanguageCode;
    template: IInvoiceTemplate;
}

const InvoiceOtherFields = (props: IInvoiceOtherFieldProps) => {
    const { editable, handleUpdate, invoice, template } = props;
    const [values, setValues] = useState<any>({});
    const [changed, setchanged] = useState(false);

    useEffect(() => {
        const items = props.template.additionalFields
            .map(({ name }: IInvoiceField) => {
                return { [name]: props.invoice[name] };
            })
            .reduce((obj, item) => ({ ...obj, ...item }), {});
        setValues(items);
    }, [props.invoice, props.template]);

    const handleBlur = (val: string, fieldName: FieldName) => {
        if (changed) {
            handleUpdate({ [fieldName]: val });
            setchanged(false);
        }
    };

    const handleChange = (name: string, value: string) => {
        const items = { ...values, [name]: value };
        setValues(items);
        setchanged(true);
    };

    return (
        <>
            {template.additionalFields.map(({ id, name }: IInvoiceField) => {
                return (
                    <Flex column={props.column} key={name} spread>
                        <LabelCapsSmall color="black" htmlFor={`invoice-${name}`}>
                            {transFixed({
                                str: id,
                                lang: props.invoiceLanguage,
                                options: { defaultValue: '' },
                            })}
                        </LabelCapsSmall>
                        {editable ? (
                            <InputWrapper $column={props.column}>
                                <StyledInput
                                    $column={props.column}
                                    autoComplete="off"
                                    value={values[name] || ''}
                                    id={`invoice-${name}`}
                                    maxLength={255}
                                    placeholder={trans('invoice.otherFields.fieldPlaceholder')}
                                    onChange={(e: FormEvent<HTMLInputElement>) => {
                                        handleChange(name, e.currentTarget.value);
                                    }}
                                    onBlur={(e: FormEvent<HTMLInputElement>) => {
                                        handleBlur(e.currentTarget.value, name);
                                    }}
                                />
                            </InputWrapper>
                        ) : (
                            <ExpandablePSmall text={invoice[name]} />
                        )}
                    </Flex>
                );
            })}
        </>
    );
};

export default InvoiceOtherFields;
