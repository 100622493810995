import { gql } from '@apollo/client';

const ACCEPT_USER_TERMS = gql`
    mutation AcceptEezyPayTerms($id: Int!) {
        update_users_by_pk(
            pk_columns: { id: $id }
            _set: { termsAccepted: true }
        ) {
            id
            termsAccepted
        }
    }
`;

export default ACCEPT_USER_TERMS;
