import { validator as v } from '../validation';

export const amountsWithVatsValidator = v
    .array()
    .min(1)
    .required()
    .items(
        v.object({
            amount: v.number().positive().required(),
            vatPercent: v.number(),
        }),
    );

export const dateValidator = v.date().required();

const startTimeValidator = v.date().min(v.ref('minStart')).max(v.ref('maxStart'));
const endTimeValidator = v.date().greater(v.ref('minEnd')).max(v.ref('maxEnd'));

export const descriptionValidator = v.string().trim().min(1).required();

export const receiptFilesValidator = v
    .array()
    .min(1)
    .required()
    .items(
        v.object({
            id: v.number().required(),
            name: v.string().required(),
        }),
    )
    .has(v.object({ selected: v.valid(true) }));

export const costValidator = v.object({
    amountsWithVats: amountsWithVatsValidator,
    description: descriptionValidator,
    purchaseDate: dateValidator,
    receiptFiles: receiptFilesValidator,
});

export const dailyAllowancesFullValidator = v.object({
    quantity: v.number(),
    salaryType: v.string().required(),
});

export const dailyAllowancesHalfValidator = v.object({
    quantity: v.number(),
});

export const reimbursedKMsValidator = v.object({
    quantity: v.number().positive().required(),
});

// https://github.com/sideway/joi/blob/master/API.md#refkey-options
const reimbursedTrailerKMsValidator = v.object({
    quantity: v.number().max(v.ref('...reimbursedKMs.quantity')),
});

export const routeValidator = v.array().required().items(v.string().min(1)).min(2);

const carNumberValidator = v.string().required();

const travelReceiptsValidator = v.array().items(costValidator);

export const travelCarValidator = v.object({
    dailyAllowancesFull: dailyAllowancesFullValidator,
    dailyAllowancesHalf: dailyAllowancesHalfValidator,
    endTime: endTimeValidator,
    receiptCosts: travelReceiptsValidator,
    reimbursedKMs: reimbursedKMsValidator,
    reimbursedTrailerKMs: reimbursedTrailerKMsValidator,
    route: routeValidator,
    startTime: startTimeValidator,
    carNumber: carNumberValidator,
});

export const travelOtherValidator = v.object({
    dailyAllowancesFull: dailyAllowancesFullValidator,
    dailyAllowancesHalf: dailyAllowancesHalfValidator,
    endTime: endTimeValidator,
    receiptCosts: travelReceiptsValidator,
    route: routeValidator,
    startTime: startTimeValidator,
});

const validators = {
    cost: costValidator,
    travelCar: travelCarValidator,
    travelOther: travelOtherValidator,
};

export default validators;
