import { gql } from '@apollo/client';

const CREDIT_INVOICE = gql`
    mutation CreditInvoice($id: Int!, $sendToClient: Boolean!) {
        creditInvoice(invoiceId: $id, sendToClient: $sendToClient) {
            creditRequested
            id
        }
    }
`;

export default CREDIT_INVOICE;
