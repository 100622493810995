import type { CSSProperties } from 'react';
import type { IInvoiceItemKeys } from '../../../../shared/src/types/invoice';
import InvoiceTableDataInput from '../../containers/invoice/InvoiceTableDataInput';
import { COLOR_BLACKWATER, COLOR_GREY_FOG } from '../../styles/variables';

interface IFormNumberInputProps {
    allowNegative?: boolean;
    autoFocus?: boolean;
    disableRounding?: boolean;
    disabled?: boolean;
    endAdornment?: string;
    error?: any;
    forceUpdateOnFocus?: boolean;
    isEur?: boolean;
    label?: string;
    leadingZero?: boolean;
    name: string;
    placeholder?: string;
    required?: boolean;
    style?: CSSProperties;
    value?: number;
    onChange?: (val: number | string, name: string) => void;
}

export const FormNumberInput = (props: IFormNumberInputProps) => {
    const handleUpdate = (changedProperty: IInvoiceItemKeys) => {
        if (props.onChange) {
            const value = Object.values(changedProperty)[0];
            const val = !value || typeof value === 'boolean' ? 0 : value;
            props.onChange(val, props.name);
        }
    };

    return (
        <InvoiceTableDataInput
            allowNegative={props.allowNegative}
            disableRounding={props.disableRounding}
            handleUpdate={handleUpdate}
            endAdornment={props.endAdornment}
            error={props.error}
            forceUpdateOnFocus={props.forceUpdateOnFocus}
            isEur={props.isEur}
            leadingZero={props.leadingZero}
            name={props.name}
            placeholder={props.placeholder}
            style={{
                backgroundColor: 'transparent',
                borderBottom: props.disabled
                    ? 'none'
                    : `1px ${props.required ? 'solid' : 'dashed'} ${COLOR_GREY_FOG}`,
                color: COLOR_BLACKWATER,
                height: 40,
                lineHeight: '40px',
                ...props.style,
            }}
            type="number"
            value={props.value}
        />
    );
};
