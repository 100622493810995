import type { HTMLProps } from 'react';
import styled from 'styled-components';

import type { IInvoiceItem, IInvoiceItemKeys } from '../../../../../shared/src/types/invoice';
import { DatePicker, FormTextArea, SplitRow } from '../../../components/form';
import { COLOR_BLACKWATER } from '../../../styles/variables';
import { formatDateISO, trans, xNotAfterYInDays } from '../../../utils';
import { descriptionPlaceholder, descriptionTitle } from '../InvoiceRowDetails';

interface IDescriptionPopOver extends HTMLProps<HTMLDivElement> {
    item: IInvoiceItem;
    error?: any;
    handleUpdate: (changedProperty: IInvoiceItemKeys) => void;
}

const RowLabel = styled.label`
    color: ${COLOR_BLACKWATER};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    display: inline-block;
    padding-top: 15px;
`;

const DescriptionPopOver = (props: IDescriptionPopOver) => {
    const { item } = props;

    const onStartDateChange = (date: Date | null) => {
        const d = date ? formatDateISO(date) : '';
        props.handleUpdate({ startDate: d });
    };

    const onEndDateChange = (date: Date | null) => {
        const d =
            date && (!item.startDate || xNotAfterYInDays(new Date(item.startDate), date))
                ? formatDateISO(date)
                : '';
        props.handleUpdate({ endDate: d });
    };
    return (
        <div style={{ ...props.style }}>
            <FormTextArea
                data-mf-replace="**REMOVED**"
                label={descriptionTitle(item.itemType)}
                name="description"
                maxLength={255}
                noNewLinesAllowed
                onChange={(val, name) => props.handleUpdate({ [name]: val })}
                placeholder={descriptionPlaceholder(item.itemType)}
                required
                showBorder
                value={item.description || ''}
                variant="plain"
                error={props.error}
            />

            {item.itemType === 'work' && (
                <>
                    <RowLabel>
                        {`${trans('invoice.rows-form.time-q')} (${trans('login.not-mandatory')})`}
                    </RowLabel>
                    <SplitRow style={{ marginTop: 10 }}>
                        <DatePicker
                            disableToolbar
                            icon={['far', 'calendar-alt']}
                            label={trans('dates.startDate')}
                            maxDate={item.endDate ? new Date(item.endDate) : undefined}
                            placeholder={trans('dates.startDate')}
                            id="row-startDate"
                            onChange={onStartDateChange}
                            value={item.startDate}
                        />
                        <DatePicker
                            disableToolbar
                            icon={['far', 'calendar-alt']}
                            label={trans('dates.endDate')}
                            minDate={item.startDate ? new Date(item.startDate) : undefined}
                            placeholder={trans('dates.endDate')}
                            id="row-endDate"
                            onChange={onEndDateChange}
                            value={item.endDate}
                        />
                    </SplitRow>
                </>
            )}
        </div>
    );
};

export default DescriptionPopOver;
