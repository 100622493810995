import { gql } from '@apollo/client';

const UPDATE_COSTINVOICE = gql`
    mutation UpdateCostInvoice($costInvoiceId: Int!, $messageToEezy: String!) {
        updateCostInvoice(
            costInvoiceId: $costInvoiceId
            messageToEezy: $messageToEezy
        ) {
            id
            messageToEezy
        }
    }
`;

export default UPDATE_COSTINVOICE;
