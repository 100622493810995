import type { CSSProperties } from 'react';
import styled from 'styled-components';
import { SCREEN_S } from '../../styles/variables';
import { Flex, type IFlexProps } from '../Flex';
export { AddressInput } from './AddressInput';
export { AutocompleteDropdown } from './AutocompleteDropdown';
export { Checkbox } from './Checkbox';
export { CheckboxButton } from './CheckboxButton';
export { DatePicker } from './DatePicker';
export { DatePickerYel } from './DatePickerYel';
export { Dropdown } from './Dropdown';
export { FormButton } from './FormButton';
export { FormInput, Input } from './FormInput';
export { FormNumber } from './FormNumber';
export { FormNumberInput } from './FormNumberInput';
export { FormRadio } from './FormRadio';
export { FormSelect } from './FormSelect';
export { FormTextArea } from './FormTextArea';
export { HelpLabel } from './HelpLabel';
export { HoursMinutes } from './HoursMinutes';
export { HoursMinutesTime } from './HoursMinutesTime';
export { RadioElement } from './RadioElement';
export { RadioInput } from './RadioInput';
export { TextArea } from './TextArea';
export { TextInput } from './TextInput';
export { UnitElement } from './UnitElement';

export interface IGeneralFormInputProps {
    color?: string;
    disabled?: boolean;
    error?: any;
    helpLink?: string;
    helpTexts?: string[];
    label?: string;
    name: string;
    readonly?: boolean;
    required?: boolean;
    style?: CSSProperties;
    warning?: any;
}

export const FormSection = styled(Flex).attrs(() => ({
    column: true,
}))`
    margin-top: 15px;
    & ~ & {
        margin-top: 45px;
    }
`;

export const SplitRow = styled(Flex).attrs(() => ({
    center: true,
    spread: true,
}))`
    & > div:not(:nth-child(1)) {
        margin-left: ${(props: IFlexProps) => (props.useWrap ? 0 : 10)}px;
        margin-top: 0;
        flex-grow: 1;
    }

    & ~ & {
        margin-top: 15px;
    }
`;

export const StackableRow = styled(Flex).attrs(() => ({
    column: true,
}))`
    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
    }
`;

export const FormRow = styled(Flex).attrs(() => ({
    column: true,
}))`
    & ~ &,
    & ~ ${SplitRow}, ${SplitRow} ~ & {
        margin-top: 15px;
    }
`;
