import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type Props = {};

const PageTitle: React.FC<Props> = () => {
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        try {
            const route = location.pathname;
            const key = `page-title.${route}`;
            const title = t(key);
            const pageTitle = title === key || !title ? t('general.page-title') : title;
            document.title = pageTitle;
        } catch (e) {
            console.error(e);
        }
    }, [location, t]);

    return null;
};

export default PageTitle;
