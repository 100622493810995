import { COLOR_BLUM, COLOR_LILA_LIGHT } from 'styles/variables';
import { Flex } from './Flex';
import { Icon } from './Icon';

type InfoMessageProps = {
    message: string;
    background?: boolean;
};

const InfoMessage = ({ message, background }: InfoMessageProps) => (
    <Flex
        style={{
            fontWeight: 400,
            margin: '24px 0 12px 0',
            backgroundColor: background ? COLOR_LILA_LIGHT : 'unset',
            color: COLOR_BLUM,
            padding: '10px',
            borderRadius: '10px',
        }}
    >
        <Icon icon={['fas', 'info-circle']} style={{ marginRight: 8 }} />
        {message}
    </Flex>
);

export default InfoMessage;
