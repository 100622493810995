import EezyPayTermsChecker from 'containers/EezyPayTermsChecker';
import { Outlet } from 'react-router-dom';
import Drawer from 'containers/nav/Drawer';

export const SalesPagesWrapper = () => {
    return (
        <EezyPayTermsChecker>
            <Drawer>
                <Outlet />
            </Drawer>
        </EezyPayTermsChecker>
    );
};
