import { useState, type ReactNode } from 'react';
import {
    AccordionPanel,
    AccordionPanelDetails,
    AccordionPanelSummary,
    AccordionTypography,
} from 'components/AccordionPanel';
import styled from 'styled-components';
import { EezyButton } from './Buttons';
import { Icon } from 'components/Icon';
import { COLOR_BLUM } from '../styles/variables';

interface IExpandableProps {
    title: string;
    children: ReactNode;
}

const AccordionTypographyStyled = styled(AccordionTypography)`
    font-weight: 600;
    color: ${COLOR_BLUM};
`;

const AccordionPanelStyled = styled(AccordionPanel)`
    opacity: 1;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 20px;
    margin-bottom: 20px;
`;
const AccordionPanelSummaryStyled = styled(AccordionPanelSummary)`
    padding: 0;

    .MuiAccordionSummary-content {
        margin: 0 !important;
        align-items: center;
    }
`;

const AccordionActions = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

const AccordionSummaryContainer = styled.div`
    p {
        font-size: 15px;
        padding: 0;
    }
`;

const Expandable = (props: IExpandableProps) => {
    const { title, children } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <AccordionPanelStyled
            onChange={(_e, expanded) => {
                setIsExpanded(expanded);
            }}
        >
            <AccordionPanelSummaryStyled>
                <AccordionSummaryContainer
                    style={{
                        margin: '0',
                        minWidth: 0,
                    }}
                >
                    <AccordionTypographyStyled>{title}</AccordionTypographyStyled>
                </AccordionSummaryContainer>
                <AccordionActions>
                    <EezyButton
                        style={{
                            borderWidth: 2,
                            padding: 0,
                            minWidth: 'auto',
                            width: 32,
                            height: 32,
                            marginRight: 8,
                            verticalAlign: 'top',
                        }}
                        color="purple"
                        transparent
                    >
                        <Icon
                            style={{
                                cursor: 'pointer',
                                verticalAlign: 'middle',
                                width: 18,
                                height: 18,
                            }}
                            icon={['fas', isExpanded ? 'chevron-up' : 'chevron-down']}
                        />
                    </EezyButton>
                </AccordionActions>
            </AccordionPanelSummaryStyled>
            <AccordionPanelDetails>{children}</AccordionPanelDetails>
        </AccordionPanelStyled>
    );
};

export default Expandable;
