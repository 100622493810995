import { gql } from '@apollo/client';

const CREATE_USER = gql`
    mutation createUser(
        $firstName: String!
        $lastName: String!
        $email: String!
        $phone: String
        $language: String!
        $userAccountId: Int!
        $campwireTermsAccepted: Boolean
    ) {
        insert_users(
            objects: [
                {
                    firstName: $firstName
                    lastName: $lastName
                    email: $email
                    phone: $phone
                    language: $language
                    termsAccepted: true
                    campwireTermsAccepted: $campwireTermsAccepted
                    userAccountId: $userAccountId
                }
            ]
            on_conflict: {
                constraint: users_pkey
                update_columns: [firstName, lastName, email, phone, language, userAccountId]
            }
        ) {
            returning {
                id
                firstName
                lastName
                email
                phone
                language
                termsAccepted
            }
        }
    }
`;

export default CREATE_USER;
