import { Fade } from '@mui/material';
import type { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { showModals } from '../../actions/auth';
import { InlineButtonLink } from '../../components/Buttons';
import ErrorBox from '../../components/ErrorBox';
import { Bold } from '../../components/textElements';
import { trans } from '../../utils';

interface IInvoiceError {
    text: string;
    type?: string; // To handle error specific actions, for now
}

const invoiceErrors: { [key: string]: IInvoiceError } = {
    ADDRESS_CHANGED: {
        text: 'errors.copy.address-changed',
    },
    DATES_DELETED: {
        text: 'errors.copy.dates-deleted',
    },
    EINVOICE_ADDRESS_CHANGED: {
        text: 'errors.copy.einvoiceovt-changed',
    },
    EMAIL_CHANGED: {
        text: 'errors.copy.email-changed',
    },
    INVALID_PROVISIONS: {
        text: 'errors.sending-validation.invalid-provisions',
    },
    INVOICING_METHOD_CHANGED: {
        text: 'errors.copy.invoicing-method-changed',
    },
    MAX_2000_FIRST: {
        text: 'errors.express.MAX_2000_FIRST',
    },
    MAX_5000: {
        text: 'errors.express.MAX_5000',
    },
    MISSING_ADDRESS: {
        text: 'errors.sending-validation.missing-address',
        type: 'profile',
    },
    MISSING_IBAN: {
        text: 'errors.sending-validation.missing-iban',
        type: 'profile',
    },
    MISSING_PIC: {
        text: 'errors.sending-validation.missing-pic',
        type: 'auth',
    },
    MISSING_RECEIPT_FILE: {
        text: 'errors.sending-validation.missing-receipt-file',
    },
    NOT_COMPANY: {
        text: 'errors.express.NOT_COMPANY',
    },
    NOT_DOMESTIC: {
        text: 'errors.express.NOT_DOMESTIC',
    },
    YEL_SELF_REPORT_MISSING: {
        text: 'errors.sending-validation.yel-self-report-missing',
        type: 'yel',
    },
    YEL_SELF_REPORT_NO_TOO_OLD: {
        text: 'errors.sending-validation.yel-self-report-no-too-old',
        type: 'yel',
    },
    EXPENSE_RECEIPT_VAT_NON_ZERO: {
        text: 'errors.sending-validation.expense-receipt-vat-non-zero',
    },
};

interface IInvoiceErrorsProps {
    errors?: string[];
    showModals?: (modals: string[]) => void;
    style?: CSSProperties;
    titleTransKey?: string;
}

const Ul = styled.ul`
    list-style: disc;
    margin-left: 20px;
`;

const InvoiceErrors = (props: IInvoiceErrorsProps) => {
    if (!props.errors || props.errors.length === 0) {
        return null;
    }

    const openModal = (type: string) => {
        if (props.showModals) {
            props.showModals([type]);
        }
    };

    return (
        <Fade in={true} timeout={400}>
            <div>
                <ErrorBox style={props.style}>
                    {props.titleTransKey && <Bold>{trans(props.titleTransKey)}</Bold>}
                    <Ul>
                        {props.errors.map((e: string) => {
                            if (e in invoiceErrors) {
                                return (
                                    <li key={e}>
                                        {trans(invoiceErrors[e].text)}{' '}
                                        {invoiceErrors[e].type === 'auth' && (
                                            <InlineButtonLink color="white" onClick={() => openModal('AUTH')}>
                                                {trans('form.confirm-now')}
                                            </InlineButtonLink>
                                        )}
                                        {invoiceErrors[e].type === 'yel' && (
                                            <InlineButtonLink color="white" onClick={() => openModal('YEL')}>
                                                {trans('form.confirm-now')}
                                            </InlineButtonLink>
                                        )}
                                        {invoiceErrors[e].type === 'profile' && (
                                            <Link to="/profile" style={{ color: 'white' }}>
                                                {trans('errors.sending-validation.profile-go')}
                                            </Link>
                                        )}
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </Ul>
                </ErrorBox>
            </div>
        </Fade>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(null, mapDispatchToProps)(InvoiceErrors);
