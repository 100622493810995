import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/form';
import styled from 'styled-components';

const SettingRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

const SettingLabel = styled.div`
    font-family: 'euclid', sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
`;

export const ProfileSettingsCookieHub: React.FC = () => {
    const { t } = useTranslation();
    const [isCookieHubAvailable, setIsCookieHubAvailable] = useState(false);

    useEffect(() => {
        let timeoutId: number;

        const checkCookieHub = () => {
            if (
                typeof window !== 'undefined' &&
                window.cookiehub &&
                typeof window.cookiehub.openSettings === 'function' &&
                window.cookiehub.hasInitialised === true
            ) {
                setIsCookieHubAvailable(true);
                return true;
            }
            return false;
        };

        if (!checkCookieHub()) {
            timeoutId = window.setTimeout(checkCookieHub, 3000);
        }

        return () => {
            if (timeoutId) window.clearTimeout(timeoutId);
            setIsCookieHubAvailable(false);
        };
    }, []);

    const openCookieSettings = () => {
        if (window.cookiehub?.openSettings) {
            window.cookiehub.openSettings();
        }
    };

    if (!isCookieHubAvailable) {
        return null;
    }

    return (
        <SettingRow>
            <SettingLabel>{t('profile:settings.cookieSettings.label')}</SettingLabel>
            <FormButton dark name="cookie-settings" onClick={openCookieSettings} width={120}>
                {t('profile:settings.cookieSettings.button')}
            </FormButton>
        </SettingRow>
    );
};
