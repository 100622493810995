import axios, { type AxiosInstance, type CreateAxiosDefaults } from 'axios';
import { Cookies } from 'react-cookie';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import { serverWholePageError, showLogin } from 'actions/auth';
import config from 'config';

export default axios.create({
    baseURL: config.backendUrl,
    responseType: 'json',
} as CreateAxiosDefaults);

export const fileAPI = axios.create({
    baseURL: config.backendUrl3,
    headers: { 'Content-Type': 'multipart/form-data' },
    responseType: 'json',
} as CreateAxiosDefaults);

export const setupAxios = (api: AxiosInstance, dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const cookie = new Cookies();
    api.interceptors?.response?.use(
        (response) => response,
        (error) => {
            const { status, data } = error?.response || {};
            const isLogin = error?.response?.config?.url?.includes('/login');
            if (status === 401 && !isLogin) {
                sessionStorage.removeItem('jwt');
                dispatch(showLogin());
                return false;
            }
            if (status === 503 || (process.env.NODE_ENV === 'development' && status === 500)) {
                dispatch(serverWholePageError(data?.reason));
                return false;
            }
            return Promise.reject(error);
        },
    );

    api.interceptors.request.use((rConfig) => {
        rConfig.headers['x-uuid'] = cookie.get('uuid');
        return rConfig;
    });
};
