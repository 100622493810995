import { type CSSProperties, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { COLOR_BLACKWATER, COLOR_GREY_FOG } from 'styles/variables';
import { autoCompleteFetchPlace, checkServices, type IPlaceType } from 'utils/mapsUtils';
import { PSmall } from '../textElements';
import { StyledField } from './AutocompleteDropdown';

interface IAddressInputProps {
    autoSelect?: boolean;
    freeSolo?: boolean;
    id: string;
    inputStyle?: CSSProperties;
    onBlur?: (val: string) => void;
    onChange: (val: string, event: any) => void;
    placeholder?: string;
    suggestedValue?: string;
    value?: string;
}

export const AddressInput = (props: IAddressInputProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<IPlaceType | null | string>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<Array<IPlaceType | string>>([]);
    const [suggestedOptions, setSuggestedOptions] = useState<Array<IPlaceType | string>>([]);

    const fetchPlace = useMemo(() => autoCompleteFetchPlace, []);

    useEffect(() => {
        // handle default value or suggested value
        let active = true;
        if (!checkServices()) {
            return undefined;
        }

        if (props.value) {
            setValue(props.value);
        } else if (props.suggestedValue) {
            fetchPlace({ input: props.suggestedValue }, (results?: IPlaceType[]) => {
                if (active && results && results.length > 0) {
                    setSuggestedOptions([results[0]]);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [props.value, props.suggestedValue, fetchPlace]);

    useEffect(() => {
        //  handle value change
        let active = true;
        if (!checkServices() || !inputValue) {
            return undefined;
        }

        fetchPlace({ input: inputValue }, (results?: IPlaceType[]) => {
            if (active) {
                let newOptions = [inputValue] as Array<IPlaceType | string>;
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetchPlace]);

    useEffect(() => {
        if (inputValue === '') {
            // when options are shown without input, show either value or suggestedOptions
            setOptions(value ? [value] : suggestedOptions);
            return undefined;
        }
    }, [value, inputValue, suggestedOptions]);

    return (
        <Autocomplete
            autoComplete
            autoSelect={props.autoSelect || true}
            disableClearable
            filterOptions={(x) => x}
            filterSelectedOptions
            forcePopupIcon={false}
            freeSolo={props.freeSolo}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            id={props.id}
            includeInputInList
            noOptionsText={t('general.no-options')}
            onBlur={() => {
                if (props.onBlur) {
                    props.onBlur(inputValue);
                }
            }}
            onChange={(event: any, newValue: IPlaceType | null | string) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue || null);
                if (newValue) {
                    if (typeof newValue === 'string') {
                        props.onChange(newValue, event);
                    } else {
                        props.onChange(newValue?.description, event);
                    }
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                if (props.freeSolo && event) {
                    props.onChange(newInputValue, event);
                }
            }}
            options={options}
            renderInput={(params) => (
                <StyledField
                    {...params}
                    fullWidth
                    placeholder={props.placeholder}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            borderBottom: `1px solid ${COLOR_GREY_FOG}`,
                            color: COLOR_BLACKWATER,
                            height: 40,
                            ...props.inputStyle,
                        },
                    }}
                />
            )}
            renderOption={(props, option) => {
                let parts: ReturnType<typeof parse> = [];
                if (typeof option === 'string') {
                    const matches = match(option, inputValue);
                    parts = parse(option, matches);
                } else {
                    const matches = option.structured_formatting?.main_text_matched_substrings || [];
                    parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((m: any) => [m.offset, m.offset + m.length]),
                    );
                }
                return (
                    <Box
                        component="li"
                        {...props}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            '&.MuiAutocomplete-option': {
                                alignItems: 'flex-start',
                            },
                        }}
                    >
                        <PSmall>
                            {parts.map((part, index) => (
                                <span
                                    key={`part-${index}`}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </PSmall>
                        <PSmall>
                            {typeof option !== 'string' && option.structured_formatting.secondary_text}
                        </PSmall>
                    </Box>
                );
            }}
            value={value ? value : ''}
            sx={{
                '&:hover': {
                    border: 'none',
                },
                '& .MuiOutlinedInput-root': {
                    borderSize: '0',
                    borderRadius: '0',
                    padding: '0',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            }}
        />
    );
};
