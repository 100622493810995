import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import type { ISalaryPrice } from '../../../../shared/src/types/costs';
import { EezyButton } from '../../components/Buttons';
import { AutocompleteDropdown, Checkbox, FormRow, FormSection, SplitRow } from '../../components/form';
import type { IDropdownOption } from '../../components/form/AutocompleteDropdown';
import { FormLabel } from '../../components/form/FormLabel';
import { Icon } from '../../components/Icon';
import { Bold, SingleText } from '../../components/textElements';
import { ValidationMessageBlock } from '../../components/ValidationMessageBlock';
import { COLOR_BLACK, COLOR_BLUM, COLOR_IMPORTANT, COLOR_WARINING_BACKGROUND } from '../../styles/variables';
import { formatCents } from '../../utils';
import {
    formatForeignSalaryTypeForAutocompleteValue,
    getDistance,
    getMaxForeignAllowances,
    getMaxFullAllowancesGivenHalfAllowances,
    getMaxHalfAllowances,
    getMaxMeals,
} from '../../utils/costs/allowances';
import {
    getTravelHoursCount,
    type ICostComponentProps,
    isDomesticTrip,
    isRoundTripStep,
    isTravelByCar,
    roundToRoute,
} from '../../utils/costs/costLogic';
import { getTravelExpensePercentage } from '../invoice/GroupInvoiceParticipants';
import { GET_ALLOWANCES } from './queries';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useUser } from 'queries/useUserQuery';

interface IAddSubstractButtonsProps {
    count: number;
    disabled?: boolean;
    handleUpdate: (count: number) => void;
    max?: number;
    min?: number;
    totalCents: number;
}

const AddSubtractButtons = (props: IAddSubstractButtonsProps) => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-end items-center">
            <div className="font-bold mr-5">
                {t('form.eurs', {
                    eurs: formatCents(props.totalCents, true),
                })}
            </div>
            <div className="flex justify-between items-center w-[110px]">
                <EezyButton
                    aria-label={t('costs.allowances-substract')}
                    color="purple"
                    disabled={props.disabled || props.count === props.min}
                    square
                    width={30}
                    style={{ padding: 0 }}
                    onClick={() => {
                        if (props.min === undefined || props.count - 1 >= props.min) {
                            props.handleUpdate(props.count - 1);
                        }
                    }}
                >
                    <Icon icon={['far', 'minus']} />
                </EezyButton>
                <Bold>{props.count}</Bold>
                <EezyButton
                    aria-label={t('costs.allowances-add')}
                    color="purple"
                    disabled={props.disabled || props.count === props.max}
                    square
                    width={30}
                    style={{ padding: 0 }}
                    onClick={() => {
                        if (props.max === undefined || props.count + 1 <= props.max) {
                            props.handleUpdate(props.count + 1);
                        }
                    }}
                >
                    <Icon icon={['far', 'plus']} />
                </EezyButton>
            </div>
        </div>
    );
};

const TravelAllowancesComponent = (props: ICostComponentProps) => {
    const { t } = useTranslation();
    const user = useUser();
    const { costLogicDispatch } = props;
    const [route, setRoute] = useState<string[]>([]);
    const [foreignTrip, setForeignTrip] = useState(!isDomesticTrip(route));

    const { data } = useQuery(GET_ALLOWANCES);

    useEffect(() => {
        // 2 different routes are stored depending on input type
        const r = isRoundTripStep(props.step) ? roundToRoute(props.travelRound) : props.travelRoute;
        setRoute(r);
        if (!foreignTrip) {
            setForeignTrip(!isDomesticTrip(r));
        }
    }, [props.travelRound, props.travelRoute, props.step, foreignTrip]);

    if (!props.currentTravel) {
        return null;
    }

    const travel = props.currentTravel;
    const hours = getTravelHoursCount(travel);

    const filterAllowanceByType = (type: string): ISalaryPrice[] => {
        return data?.salaryPrices.filter(
            (s: ISalaryPrice) =>
                s.type === type &&
                s.year ===
                    (travel?.endTime
                        ? new Date(travel?.endTime || '').getFullYear()
                        : new Date().getFullYear()) &&
                !s.taxable,
        );
    };

    const getPrice = (identifier: string) => {
        return data?.salaryPrices.find(
            (s: ISalaryPrice) =>
                s.identifier === identifier &&
                s.year ===
                    (travel?.endTime
                        ? new Date(travel?.endTime || '').getFullYear()
                        : new Date().getFullYear()),
        )?.price;
    };

    const updateTravel = (payload: any) => {
        costLogicDispatch({ payload, type: 'SAVE_ALLOWANCES' });
    };

    const handleUpdateDomesticAllowance = (quantity: number) => {
        updateTravel({
            dailyAllowancesFull: {
                quantity,
                salaryType: 'domestic-allowance',
            },
        });
    };

    const handleUpdateHalfAllowance = (dailyAllowancesHalf: number) => {
        updateTravel({
            dailyAllowancesHalf: { quantity: dailyAllowancesHalf },
        });
    };

    const handleUpdateMeals = (reimbursedMeals: number) => {
        updateTravel({ reimbursedMeals: { quantity: reimbursedMeals } });
    };

    const handleUpdateForeignAllowance = (quantity: number) => {
        updateTravel({
            dailyAllowancesFull: {
                quantity,
                salaryType: travel?.dailyAllowancesFull?.salaryType,
            },
        });
    };

    const handleUpdateSalaryType = (sType: string) => {
        updateTravel({
            dailyAllowancesFull: {
                quantity: travel?.dailyAllowancesFull?.quantity || 0,
                salaryType: sType,
            },
        });
    };

    const options: IDropdownOption[] = filterAllowanceByType('foreign_allowance')?.map((s: ISalaryPrice) => ({
        label: s.name[props.language] ? s.name[props.language].split(',').slice(-1)[0].trim() : '',
        value: s.identifier,
    }));

    const fullAllowanceIsDomestic = (travel.dailyAllowancesFull?.salaryType || '').includes(
        'domestic-allowance',
    );
    const fullAllowanceQuantity = travel.dailyAllowancesFull?.quantity;
    const fullDomesticCount = fullAllowanceIsDomestic ? fullAllowanceQuantity : 0;

    const kmPriceTotal =
        getPrice('mileage-reimbursement') * travel?.reimbursedKMs?.quantity +
        getPrice('mileage-reimbursement-trailer') * travel?.reimbursedTrailerKMs?.quantity;
    const fullPriceTotal =
        getPrice(travel.dailyAllowancesFull?.salaryType || 'domestic-allowance') * fullDomesticCount;
    const halfPriceTotal = getPrice('domestic-half-daily-allowance') * travel.dailyAllowancesHalf?.quantity;
    const mealPriceTotal = getPrice('meal-reimbursement') * travel.reimbursedMeals?.quantity;
    const foreignPriceTotal =
        getPrice(travel?.dailyAllowancesFull?.salaryType || '') *
        (!fullAllowanceIsDomestic ? fullAllowanceQuantity : 0);

    const distance = getDistance(props);
    const repeatingDates = travel?.repeatingDates && travel.repeatingDates.length > 0;

    const userProvision = props.invoice?.provisions?.find((provision) => provision.personId === user?.id);

    // If data change would make the full allowance exceed the maximum, set it to the maximum
    useEffect(() => {
        const maxFullAllowances = getMaxFullAllowancesGivenHalfAllowances(
            hours,
            travel.dailyAllowancesHalf?.quantity,
            distance,
        );

        if (maxFullAllowances < fullDomesticCount) {
            const newValue = Math.max(0, maxFullAllowances);
            if (newValue !== fullDomesticCount) {
                toast(t('costs.daily-allowance-automatically-adjusted'), { type: 'warning' });
            }

            handleUpdateDomesticAllowance(newValue);
        }
    }, [hours, travel.dailyAllowancesHalf?.quantity, distance]);

    // If data change would make the half allowance exceed the maximum, set it to the maximum
    useEffect(() => {
        const maxHalfAllowances = getMaxHalfAllowances(hours, fullAllowanceQuantity, distance);
        if (maxHalfAllowances < travel.dailyAllowancesHalf?.quantity) {
            const newValue = Math.max(0, maxHalfAllowances);
            if (newValue !== travel.dailyAllowancesHalf?.quantity) {
                toast(t('costs.half-day-allowance-automatically-adjusted'), { type: 'warning' });
            }
            handleUpdateHalfAllowance(newValue);
        }
    }, [hours, distance, fullAllowanceQuantity]);

    // If data change would make the meal allowance exceed the maximum, set it to the maximum
    useEffect(() => {
        if (getMaxMeals(hours) < travel.reimbursedMeals?.quantity) {
            const newValue = Math.max(0, getMaxMeals(hours));
            if (newValue !== travel.reimbursedMeals?.quantity) {
                toast(t('costs.meal-allowance-automatically-adjusted'), { type: 'warning' });
            }
            handleUpdateMeals(newValue);
        }
    }, [hours]);

    return (
        <div>
            <FormSection>
                <SplitRow>
                    <FormLabel>
                        {repeatingDates ? t('costs.allowances-per-travel') : t('costs.allowances')}
                    </FormLabel>
                </SplitRow>

                <ValidationMessageBlock
                    iconColor={COLOR_BLACK}
                    type={'other'}
                    style={{
                        backgroundColor: COLOR_WARINING_BACKGROUND,
                        borderRadius: 4,
                        marginTop: 10,
                        padding: 10,
                    }}
                >
                    <div className="m-1 ml-0">{t('costs.travel-reimbursements-warning')}</div>
                </ValidationMessageBlock>

                {/* Domestic full allowance */}
                <SplitRow>
                    <SingleText color="black" style={{ flexGrow: 1 }}>
                        {t('costs.allowances-daily')}
                    </SingleText>
                    <AddSubtractButtons
                        count={fullDomesticCount}
                        disabled={travel.reimbursedMeals?.quantity > 0}
                        handleUpdate={handleUpdateDomesticAllowance}
                        min={0}
                        max={getMaxFullAllowancesGivenHalfAllowances(
                            hours,
                            travel.dailyAllowancesHalf?.quantity,
                            distance,
                        )}
                        totalCents={fullPriceTotal}
                    />
                </SplitRow>
                {/* Domestic half allowance */}
                <SplitRow>
                    <SingleText color="black" style={{ flexGrow: 1 }}>
                        {t('costs.allowances-half')}
                    </SingleText>
                    <AddSubtractButtons
                        count={travel.dailyAllowancesHalf?.quantity || 0}
                        disabled={travel.reimbursedMeals?.quantity > 0}
                        handleUpdate={handleUpdateHalfAllowance}
                        min={0}
                        max={getMaxHalfAllowances(hours, fullAllowanceQuantity, distance)}
                        totalCents={halfPriceTotal}
                    />
                </SplitRow>
                {/* Meal allowance*/}
                <SplitRow>
                    <SingleText color="black" style={{ flexGrow: 1 }}>
                        {t('costs.allowances-meal')}
                    </SingleText>
                    <AddSubtractButtons
                        disabled={fullAllowanceQuantity > 0 || travel.dailyAllowancesHalf?.quantity > 0}
                        count={travel.reimbursedMeals?.quantity}
                        handleUpdate={handleUpdateMeals}
                        min={0}
                        max={hours >= 11 && hours < 24 ? 2 : getMaxMeals(hours)}
                        totalCents={mealPriceTotal}
                    />
                </SplitRow>
                {travel.reimbursedMeals?.quantity === 2 && hours >= 11 && hours < 24 ? (
                    <ValidationMessageBlock
                        iconColor={COLOR_BLACK}
                        type={'other'}
                        style={{
                            backgroundColor: COLOR_WARINING_BACKGROUND,
                            borderRadius: 4,
                            marginTop: 15,
                            padding: 10,
                        }}
                    >
                        <div style={{ fontWeight: 600 }}>{t('costs.allowances-meal-alert')}</div>
                    </ValidationMessageBlock>
                ) : null}
                {/* Reimbursed kilometers */}
                {travel?.reimbursedKMs?.quantity > 0 && isTravelByCar(props.step) && (
                    <SplitRow>
                        <SingleText color="black" id={'kms-total'} style={{ flexGrow: 1 }}>
                            {t('costs.reimbursed-kms')}
                        </SingleText>
                        <Bold aria-labelledby={'kms-total'} style={{ margin: '0 130px 0 5px' }}>
                            {t('form.eurs', {
                                eurs: formatCents(kmPriceTotal, true),
                            })}
                        </Bold>
                    </SplitRow>
                )}
                {/* Foreign allowance */}
                {route.length > 0 && foreignTrip && (
                    <>
                        <SplitRow>
                            <SingleText color="black" style={{ flexGrow: 1 }}>
                                {t('costs.allowances-foreign')}:
                            </SingleText>
                        </SplitRow>
                        <SplitRow>
                            <AutocompleteDropdown
                                options={options || []}
                                onChange={(val) => {
                                    if (typeof val === 'string') {
                                        handleUpdateSalaryType(val);
                                    }
                                }}
                                styleInput={{
                                    borderBottom: `1px solid ${COLOR_BLUM}`,
                                }}
                                type="text"
                                value={formatForeignSalaryTypeForAutocompleteValue(
                                    travel?.dailyAllowancesFull?.salaryType,
                                )}
                            />
                            <AddSubtractButtons
                                count={!fullAllowanceIsDomestic ? fullAllowanceQuantity : 0}
                                handleUpdate={handleUpdateForeignAllowance}
                                min={0}
                                max={getMaxForeignAllowances(hours)}
                                totalCents={foreignPriceTotal}
                            />
                        </SplitRow>
                    </>
                )}
                {/* Allowances total */}
                <SplitRow style={{ borderTop: `1px solid ${COLOR_BLUM}`, paddingTop: 20 }}>
                    <SingleText color="black" id="allowances-total">
                        {t('costs.allowances-total')}
                    </SingleText>
                    <Bold
                        aria-labelledby={'allowances-total'}
                        style={{ margin: '0 130px 0 5px' }}
                        color={COLOR_IMPORTANT}
                    >
                        {t('form.eurs', {
                            eurs: formatCents(
                                (isTravelByCar(props.step) ? kmPriceTotal : 0) +
                                    fullPriceTotal +
                                    halfPriceTotal +
                                    mealPriceTotal +
                                    (!fullAllowanceIsDomestic ? foreignPriceTotal || 0 : 0),
                                true,
                            ),
                        })}
                    </Bold>
                </SplitRow>
                {/* Travel invoicing total */}
                <SplitRow>
                    <SingleText color="black" id="travel-invoicing-total">
                        {t('costs.travel-costs-total')}
                    </SingleText>
                    <Bold aria-labelledby={'travel-invoicing-total'} style={{ margin: '0 130px 0 5px' }}>
                        {props.invoice?.isGroupInvoice && props.invoice?.provisions && userProvision
                            ? getTravelExpensePercentage(
                                  userProvision,
                                  props.invoice?.provisions,
                                  props.invoice,
                              )
                            : t('form.eurs', {
                                  eurs: formatCents(props.invoicingTravelTotalWithoutVat, true),
                              })}
                    </Bold>
                </SplitRow>
                <FormRow>
                    <Checkbox
                        checked={foreignTrip}
                        color="black"
                        label={t('costs.allowances-foreign-checkbox')}
                        onChange={() => setForeignTrip(!foreignTrip)}
                    />
                </FormRow>
            </FormSection>
        </div>
    );
};

export const TravelAllowances = TravelAllowancesComponent;
