import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { Label } from '../../components/textElements';
import { COLOR_LILA, SCREEN_S } from '../../styles/variables';
import { formatCents, formatDate, trans } from '../../utils';

const PAYMENT_RECIPIENT = 'Eezy Kevytyrittäjät Oy';
const PAYMENT_BIC = 'NDEAFIHH';
const PAYMENT_IBAN = 'FI4710233000240130';

const paymentInformationCopyText = (referenceNumber: string, dueDate: string, total: number) => {
    return `${trans('form.recipient')}: ${PAYMENT_RECIPIENT}\n${trans(
        'general.accountNumber',
    )}: ${PAYMENT_IBAN}\nSWIFT/BIC: ${PAYMENT_BIC}\n${trans(
        'general.referenceNumber',
    )}: ${referenceNumber}\n${trans('general.duedate')}: ${formatDate(
        dueDate,
    )}\n${trans('form.sum')}: ${trans('form.eurs', {
        eurs: formatCents(total, true),
    })}`;
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    width: 100%;

    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
        justify-content: space-between;
    }

    div.col {
        display: flex;
        flex-direction: column;
        &:nth-of-type(2) {
            margin-top: 15px;
        }
        @media (min-width: ${SCREEN_S}px) {
            width: 50%;

            &:first-of-type {
                margin-right: 15px;
            }
            &:nth-of-type(2) {
                margin-top: 0;
                margin-left: 15px;
            }
        }
    }
    div.row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

interface IPaymentInfoProps {
    dueDate?: string;
    reference?: string;
    total?: number;
}

const PaymentInfo = (props: IPaymentInfoProps) => {
    const handleCopy = () => {
        toast(trans('invoice.copy-payment-info-done'));
    };

    return (
        <Wrapper>
            <div className="col">
                <div className="row">
                    <Label color="black">{trans('form.recipient')}</Label>
                    <span>{PAYMENT_RECIPIENT}</span>
                </div>
                <div className="row">
                    <Label color="black">SWIFT/BIC</Label>
                    <span>{PAYMENT_BIC}</span>
                </div>

                <CopyToClipboard
                    onCopy={handleCopy}
                    text={paymentInformationCopyText(
                        props.reference || '',
                        props.dueDate || '',
                        props.total || 0,
                    )}
                >
                    <EezyButton
                        color="purple"
                        dark
                        type="button"
                        style={{ marginTop: 'auto', width: 210 }}
                        hasIcon
                    >
                        <Icon color={COLOR_LILA} icon={['far', 'copy']} />
                        {trans('invoice.copy-payment-info')}
                    </EezyButton>
                </CopyToClipboard>
            </div>
            <div className="col">
                <div className="row">
                    <Label color="black">IBAN</Label>
                    <span>{PAYMENT_IBAN}</span>
                </div>
                <div className="row">
                    <Label color="black">{trans('general.referenceNumber')}</Label>
                    <span>{props.reference}</span>
                </div>
                <div className="row">
                    <Label color="black">{trans('general.duedate')}</Label>
                    <span>{props.dueDate && formatDate(props.dueDate)}</span>
                </div>
                <div className="row">
                    <Label color="black">{trans('form.sum')}</Label>
                    <span>
                        {trans('form.eurs', {
                            eurs: formatCents(props.total, true),
                        })}
                    </span>
                </div>
            </div>
        </Wrapper>
    );
};

export default PaymentInfo;
