import { Outlet } from 'react-router-dom';
import Drawer from '../containers/nav/Drawer';

export const DrawerWrapper = () => {
    return (
        <Drawer>
            <Outlet />
        </Drawer>
    );
};
