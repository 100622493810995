import { useQuery } from '@apollo/client';
import { GET_INVOICES } from 'containers/dashboard/queries';
import { useTranslation } from 'react-i18next';
import { currentMonth, currentYear, formatCents } from 'utils';
import type { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { Icon } from 'components/Icon';
import { useMemo } from 'react';

const PendingPaymentInvoice = () => {
    const { t } = useTranslation();

    const { data } = useQuery<{ allInvoices: { items: IBriefInvoice[] } }>(GET_INVOICES, {
        variables: { year: currentYear(), month: currentMonth() },
    });

    const incomeOpen = useMemo(() => {
        const invoices = data?.allInvoices?.items ?? [];

        const openInvoices = invoices.filter(
            (invoice) =>
                ['invoiced', 'completed', 'overdued', 'unaccepted'].includes(invoice.status) &&
                !invoice.paymentReceived,
        );

        return openInvoices.reduce((acc, invoice) => acc + invoice.total, 0);
    }, [data]);

    return (
        <div className="py-6 flex gap-2.5 items-center" style={{ color: '#351F65' }}>
            <Icon icon={['far', 'clock']} />
            <div>{t('dashboard.invoices.pending-payment')}:</div>
            <b className="text-xl">{formatCents(incomeOpen, true)} €</b>
        </div>
    );
};

export default PendingPaymentInvoice;
