import { type ApolloError, useMutation } from '@apollo/client';
import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import type { IContactList, ICoworker } from '../../../../shared/src/types/user';
import { EezyButton } from '../../components/Buttons';
import ColorBox from '../../components/ColorBox';
import { Bold, P } from '../../components/textElements';
import { COLOR_BLACKWATER, COLOR_LILA_MEDIUM } from '../../styles/variables';
import { getErrorKey } from '../../utils/apolloErrors';
import { ACCEPT_COWORKER_INVITATION, GET_COWORKERS, REJECT_COWORKER_INVITATION } from './queries';
import { useTranslation } from 'react-i18next';

interface IGroupInvoicingInviteProps {
    contacts: IContactList;
}

const Wrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    &:nth-child(2) {
        margin-top: 0px;
    }
`;

const GroupInvoicingInvitation = (props: IGroupInvoicingInviteProps) => {
    const { t } = useTranslation();
    const { contacts } = props;

    const [invitationAccepted, setInvitationAccepted] = useState(false);

    const [acceptInvitation] = useMutation(ACCEPT_COWORKER_INVITATION, {
        onCompleted: () => {
            toast(t('profile:group-invoicing.user-added-toast'));
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('contact_already_exists')) {
                toast.error(t('profile:group-invoicing.delete-coworker-modal.errors.contact-already-exists'));
            } else {
                toast.error(t('errors.general'));
            }
        },
        refetchQueries: [{ query: GET_COWORKERS }],
    });

    const [rejectInvitation] = useMutation(REJECT_COWORKER_INVITATION, {
        onCompleted: () => {
            toast(t('profile:group-invoicing.user-reject-toast'));
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: [{ query: GET_COWORKERS }],
    });

    const handleAcceptInvitation = (id: number) => {
        acceptInvitation({
            variables: {
                personId: id,
            },
        });
        setInvitationAccepted(true);
    };

    const handleRejectInvitation = (id: number) => {
        rejectInvitation({
            variables: {
                personId: id,
            },
        });
    };

    const contactList = (contacts?.requestedContacts || []).map((contact) => (
        <ContactLabel
            title={`${contact.name} ${t('profile:group-invoicing-invite.title')}`}
            description={t('profile:group-invoicing-invite.description')}
            key={contact.id}
            contact={contact}
            handleAcceptInvitation={handleAcceptInvitation}
            handleRejectInvitation={handleRejectInvitation}
        />
    ));

    return (
        <Fragment>
            {contactList}
            {invitationAccepted && (
                <ContactLabel
                    title={t('profile:group-invoicing.invite-accepted')}
                    description={t('profile:group-invoicing.invite-accepted-description')}
                    closeLabel={setInvitationAccepted}
                />
            )}
        </Fragment>
    );
};

interface IContatctLabelProps {
    title: string;
    description: string;
    contact?: ICoworker;
    handleAcceptInvitation?: (id: number) => void;
    handleRejectInvitation?: (id: number) => void;
    closeLabel?: (state: boolean) => void;
}

const ContactLabel = (props: IContatctLabelProps) => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <ColorBox disableClose color={COLOR_LILA_MEDIUM} style={{ padding: 30, marginTop: 0 }}>
                <Bold>{props.title}</Bold>
                <P style={{ color: COLOR_BLACKWATER }}>{props.description}</P>
                {!props.closeLabel ? (
                    <P>
                        <EezyButton
                            color="purple"
                            transparent
                            onClick={() =>
                                props.contact?.id && props.handleRejectInvitation?.(props.contact?.id)
                            }
                        >
                            {t('profile:group-invoicing-invite.reject-invite')}
                        </EezyButton>
                        <EezyButton
                            color="purple"
                            dark
                            onClick={() =>
                                props.contact?.id && props.handleAcceptInvitation?.(props.contact?.id)
                            }
                        >
                            {t('profile:group-invoicing-invite.accept-invite')}
                        </EezyButton>
                    </P>
                ) : (
                    <P>
                        <EezyButton color="purple" dark onClick={() => props.closeLabel?.(false)}>
                            {t('menu.close')}
                        </EezyButton>
                    </P>
                )}
            </ColorBox>
        </Wrapper>
    );
};

export default GroupInvoicingInvitation;
