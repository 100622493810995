import type { ApolloClient } from '@apollo/client';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import { P } from '../../components/textElements';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { trans } from '../../utils';
import { openFillHelper } from './fillHelpers/utils';

interface IInvoiceLanguage {
    invoice?: IInvoice;
    client: ApolloClient<any>;
}

const InvoiceLanguageEdit = (props: IInvoiceLanguage) => {
    const { invoice, client } = props;

    return (
        <div>
            <Tools
                header={
                    <ToolsHeader disabled={!invoice} titleTransKey="invoice.form.recipient.language">
                        <EezyButton
                            color="purple"
                            disabled={!invoice}
                            onClick={() => openFillHelper(client, 'recipient', invoice?.recipient?.id)}
                        >
                            {trans('general.edit')}
                        </EezyButton>
                    </ToolsHeader>
                }
            >
                <P
                    disabled={!invoice}
                    style={{
                        fontSize: '13px',
                        marginTop: '4px',
                    }}
                >
                    {invoice?.recipient?.invoiceLanguage &&
                        trans(`languages.${invoice?.recipient?.invoiceLanguage}`)}
                </P>
            </Tools>
        </div>
    );
};

export default InvoiceLanguageEdit;
