export default function () {
    const now = new Date();
    const newVAT = new Date(2024, 8, 1); // 1 September 2024 will apply new VAT rate

    if (now >= newVAT) {
        return 25.5;
    }

    return 24;
}
