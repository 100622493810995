import type { ICoworker } from '../../../shared/src/types/user';
import { DELETE_SELECTED_COWORKER, SELECTED_COWORKER } from '../actionTypes';

export const setSelectedCoworker = (coworker: ICoworker) => ({
    payload: coworker,
    type: SELECTED_COWORKER,
});

export const deleteSelectedCoworker = () => ({
    type: DELETE_SELECTED_COWORKER,
});
