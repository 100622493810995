import { AppBar, Toolbar } from '@mui/material';
import type { CSSProperties, ReactNode } from 'react';
import { COLOR_BLUM, COLOR_BLUMER, PAGEWIDTH_MAX, SCREEN_M, SCREEN_S, TOPBAR_HEIGHT } from 'styles/variables';
import styled from 'styled-components';

const AppBarStyled = styled(AppBar)`
    background-color: ${COLOR_BLUMER};
    box-shadow: none;
    height: ${TOPBAR_HEIGHT}px;
    z-index: 0;
`;

const ToolbarStyled = styled(Toolbar)`
    background-color: ${COLOR_BLUM};
    margin: auto;
    max-width: ${PAGEWIDTH_MAX}px;
    height: ${TOPBAR_HEIGHT}px;
    min-height: ${TOPBAR_HEIGHT}px;
    padding: 0;
    width: 100%;

    '@media (min-width: ${SCREEN_S}px)': {
        min-height: ${TOPBAR_HEIGHT}px;
        padding: 0;
    }
`;

interface IBarProps {
    children: ReactNode;
    style?: CSSProperties;
    className?: string;
}
const Bar = (props: IBarProps) => {
    return (
        <AppBarStyled classes={{ root: `${props.className}` }} style={props.style} position="static">
            <ToolbarStyled sx={{ padding: '0' }}>{props.children}</ToolbarStyled>
        </AppBarStyled>
    );
};

export default Bar;

export const BarContent = styled('div')`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 0 30px;
    width: 100%;

    img.logo {
        height: 60px;
        margin-bottom: -30px;
        vertical-align: middle;
        width: 60px;

        @media (min-width: ${SCREEN_M}px) {
            height: 64px;
            width: 64px;
        }
    }
`;
