import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NestedPageNavigationTabs from 'components/layout/NestedPageNavigationTabs';

import { StatisticsTab } from 'components/statistics/StatisticsTab';
import { RevenueForecastTab } from 'components/statistics/RevenueForecastTab';
import BackButtonWithTitle from 'components/BackButtonWithTitle';
import { useParams } from 'react-router-dom';

export enum SalesTabEnum {
    Billing = 'billing',
    EezyPay = 'eezypay',
    RevenueForecast = 'revenueForecast',
}

const StatisticsPage = () => {
    const { t } = useTranslation();
    const params = useParams();

    const subNavigationLinks = [
        { label: t('statistic.tabs.invoices'), value: SalesTabEnum.Billing },
        { label: t('statistic.tabs.eezy-page'), value: SalesTabEnum.EezyPay },
        { label: t('statistic.tabs.revenue-forecast'), value: SalesTabEnum.RevenueForecast },
    ];

    const subPageFromParams =
        (subNavigationLinks.some((link) => link.value === params.subPage) &&
            (params.subPage as SalesTabEnum)) ||
        SalesTabEnum.Billing;

    const [activeStatisticsTab, setActiveStatisticsTab] = useState<SalesTabEnum>(subPageFromParams);

    useEffect(() => {
        setActiveStatisticsTab(subPageFromParams);
    }, [subPageFromParams]);

    const handleTabChange = (value: SalesTabEnum) => {
        setActiveStatisticsTab(value);
        // Push it to router history
        history.pushState(null, '', `/statistics/${value}`);
    };

    const activeTabComponent = useMemo(() => {
        switch (activeStatisticsTab) {
            case SalesTabEnum.Billing:
            case SalesTabEnum.EezyPay:
                return <StatisticsTab activeSalesTab={activeStatisticsTab} />;
            case SalesTabEnum.RevenueForecast:
                return <RevenueForecastTab />;
        }
    }, [activeStatisticsTab]);

    return (
        <div className="bg-white md:bg-gray-50 px-6 md:px-14 py-10 flex gap-12 flex-col items-stretch">
            <div>
                <BackButtonWithTitle />
                <div className="mt-4 md:mt-5 tg-heading-2">{t('statistic.page-title')}</div>
            </div>

            <NestedPageNavigationTabs
                links={subNavigationLinks}
                activeValue={activeStatisticsTab}
                onChange={handleTabChange}
            />
            {activeTabComponent}
        </div>
    );
};

export default StatisticsPage;
