import styled from 'styled-components';
import { formatCents, formatDate, trans } from '../../utils';
import { EezyButton } from '../../components/Buttons';
import { COLOR_BLUM } from '../../styles/variables';
import TwoColumnCard from './TwoColumnCard';
import type { ITransaction } from './Transactions';

interface ITransactionsDetailsProps {
    transaction: ITransaction;
    downloadReceipt: (transaction: ITransaction) => void;
}

const DetailsRow = styled.div<{ column?: boolean }>`
    display: flex;
    margin-bottom: 14px;
    ${(props) => props.column && 'flex-direction: column'}
`;

const ServicePrice = styled.div`
    font-size: 64px;
    font-weight: 600;
    color: ${COLOR_BLUM};
`;

const ServicePriceWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const DetailsValue = styled.div<{ left?: boolean }>`
    margin-left: ${(props) => (!props.left ? 'auto' : '0')};
    padding-left: 20px;
`;

const TransactionsDetails = (props: ITransactionsDetailsProps) => {
    const { transaction } = props;

    return (
        <TwoColumnCard
            title={`${transaction.firstName} ${transaction.lastName}`}
            column1={
                <>
                    <DetailsRow>
                        <div>{trans('eezyPay:payments.service-name')}:</div>
                        <DetailsValue data-mf-replace="**REMOVED**">{transaction.serviceName}</DetailsValue>
                    </DetailsRow>
                    <DetailsRow>
                        <div>{trans('eezyPay:payments.date')}:</div>
                        <DetailsValue>{formatDate(transaction.completed)}</DetailsValue>
                    </DetailsRow>
                    <DetailsRow>
                        <div>{trans('eezyPay:payments.method')}:</div>
                        <DetailsValue>{transaction.paymentBrand}</DetailsValue>
                    </DetailsRow>
                </>
            }
            column2={
                <ServicePriceWrapper>
                    <ServicePrice>
                        {trans('form.eurs', {
                            eurs: formatCents(transaction.servicePrice, true),
                        })}
                    </ServicePrice>
                </ServicePriceWrapper>
            }
            footer={
                <div>
                    <EezyButton
                        style={{ width: '100%' }}
                        color="purple"
                        onClick={() => {
                            props.downloadReceipt(transaction);
                        }}
                    >
                        {trans('eezyPay:payments.download-receipt')}
                    </EezyButton>
                </div>
            }
        />
    );
};

export default TransactionsDetails;
