import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import type { IDatePair, IReceiptCost, ITravel, ITravelInput } from '../../../../shared/src/types/costs';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import { EezyButton } from 'components/Buttons';
import LoadingSpinner from '../../components/Loading';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import {
    costToCostInput,
    currentTravelToTravelInput,
    formatTravelRoutes,
    getBackButtonContent,
    getContinueButtonContent,
    getInitialStep,
    getStepContent,
    getStepTitle,
    type ICostState,
    MATERIAL,
    RECEIPT,
    START,
    TRAVEL_CAR_MAP,
    TRAVEL_CAR_ROUND,
    TRAVEL_CAR_ROUTE,
    TRAVEL_OTHER_ROUND,
    TRAVEL_OTHER_ROUTE,
    validateTravel,
} from 'utils/costs/costLogic';
import validators from '../../utils/costs/validators';
import { getTravelInvoicingTotalWithoutVat } from 'utils/invoice/invoiceLogic';
import { formatShortAddress } from 'utils/user/userUtils';
import { formatValidationResult } from 'utils/validation';
import { GET_INVOICE } from '../invoice/queries';
import {
    CREATE_COST,
    CREATE_TRAVEL,
    UPDATE_COST,
    UPDATE_COST_AFTER_SEND,
    UPDATE_TRAVEL,
    UPDATE_TRAVEL_AFTER_SEND,
} from './queries';
import { useNavigate } from 'react-router-dom';
import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_USER_PREFERENCES } from 'containers/dashboard/queries';
import { useUser } from 'queries/useUserQuery';

const CostComponent = styled.div`
    & ~ & {
        margin-top: 20px;
    }
`;

const initialState: ICostState = {
    errors: {
        [MATERIAL]: {},
        [TRAVEL_CAR_ROUND]: {},
        [TRAVEL_CAR_ROUTE]: {},
        [TRAVEL_CAR_MAP]: {},
        [TRAVEL_OTHER_ROUND]: {},
        [TRAVEL_OTHER_ROUTE]: {},
    },
    invoiceTemplate: 'domestic',
    language: 'fi',
    prevSteps: [],
    step: 0,
    travelRound: { origin: '', destination: '' },
    travelRoute: [],
    carNumber: '',
    saveCarNumber: false,
};

const costReducer = (state: ICostState, action: any) => {
    switch (action.type) {
        case 'SETUP': {
            const { costId, currentCost, currentTravel, initialStep } = action.payload;
            const formattedRoutes = formatTravelRoutes(currentTravel?.route);
            return {
                ...initialState,
                step: initialStep || getInitialStep(costId, currentCost, currentTravel),
                ...action.payload,
                ...formattedRoutes,
                travelRoundDirections: { route: formattedRoutes.travelRound },
                travelRouteDirections: { route: formattedRoutes.travelRoute },
            };
        }
        case 'GO_BACK':
            if (state.prevSteps.length === 0) {
                return {
                    ...state,
                    prevSteps: [],
                    step: START,
                };
            }
            return {
                ...state,
                // these need to be same as in 'ADD_TRAVEL_COST_TEMP' below
                prevSteps: [...state.prevSteps.slice(0, -1)],
                step: state.prevSteps.slice(-1)[0],
                travelCostTemp: undefined,
            };
        case 'GO_STEP':
            return {
                ...state,
                ...action.payload,
                prevSteps: [...state.prevSteps, state.step],
            };
        case 'REPLACE_STEP':
            return {
                ...state,
                ...action.payload,
            };
        case 'SAVE_MAPS_DISTANCE': {
            const newState = {
                ...state,
                currentTravel: {
                    ...state.currentTravel,
                    ...action.payload,
                },
            };
            return {
                ...newState,
                currentTravel: {
                    ...newState.currentTravel,
                },
            };
        }
        case 'SAVE_COST': {
            const newCost = {
                ...state.currentCost,
                ...action.payload,
            };
            return {
                ...state,
                currentCost: newCost,
                errors: {
                    ...state.errors,
                    [MATERIAL]: formatValidationResult(validators.cost.validate(newCost)),
                },
            };
        }
        case 'SAVE_TRAVEL': {
            const newTravelState = {
                ...state,
                currentTravel: {
                    ...state.currentTravel,
                    ...action.payload,
                },
            };
            return {
                ...newTravelState,
                currentTravel: {
                    ...newTravelState.currentTravel,
                },
                errors: {
                    ...state.errors,
                    ...validateTravel(newTravelState),
                },
            };
        }
        case 'SAVE_ALLOWANCES': {
            const newTravel = {
                ...state,
                currentTravel: {
                    ...state.currentTravel,
                    ...action.payload,
                },
            };
            return {
                ...newTravel,
                errors: {
                    ...state.errors,
                    ...validateTravel(newTravel),
                },
            };
        }
        case 'SAVE_ROUTE': {
            const newRouteState = {
                ...state,
                travelRoute: action.payload,
            };
            return {
                ...newRouteState,
                errors: {
                    ...state.errors,
                    ...validateTravel(newRouteState),
                },
            };
        }
        case 'SAVE_ROUTE_DIRECTIONS':
            return {
                ...state,
                travelRouteDirections: action.payload,
            };
        case 'SAVE_ROUNDTRIP': {
            const newRoundState = {
                ...state,
                travelRound: { ...state.travelRound, ...action.payload },
            };
            return {
                ...newRoundState,
                errors: {
                    ...state.errors,
                    ...validateTravel(newRoundState),
                },
            };
        }
        case 'SAVE_ROUND_DIRECTIONS':
            return {
                ...state,
                travelRoundDirections: action.payload,
            };
        case 'SAVE_TRAVEL_COST_TEMP': {
            const newTempCost = {
                ...state.travelCostTemp?.cost,
                ...action.payload,
            };
            return {
                ...state,
                travelCostTemp: {
                    ...state.travelCostTemp,
                    cost: newTempCost,
                    errors: formatValidationResult(validators.cost.validate(newTempCost)),
                },
            };
        }
        case 'ADD_TRAVEL_COST_TEMP': {
            // moves temp travel cost data under currentTravel
            const goBackState = {
                // these need to be same as in 'GO_BACK' above
                prevSteps: [...state.prevSteps.slice(0, -1)],
                step: state.prevSteps.slice(-1)[0],
                travelCostTemp: undefined,
            };
            if (!state.travelCostTemp?.cost || !state.currentTravel) {
                return {
                    ...state,
                    ...goBackState,
                };
            }
            if (state.travelCostTemp?.isNew) {
                // totally new travel cost
                const updatedState = {
                    ...state,
                    currentTravel: {
                        ...state.currentTravel,
                        receiptCosts: [
                            ...(state.currentTravel?.receiptCosts ?? []),
                            state.travelCostTemp.cost,
                        ],
                    },
                    ...goBackState,
                };
                return {
                    ...updatedState,
                    errors: { ...state.errors, ...validateTravel(updatedState) },
                };
            }
            const updatedState = {
                ...state,
                currentTravel: {
                    ...state.currentTravel,
                    receiptCosts: state.currentTravel?.receiptCosts.map((r: IReceiptCost, i) => {
                        if (
                            (typeof state.travelCostTemp?.tempId === 'number' && // not yet in DB
                                i === state.travelCostTemp?.tempId) ||
                            (state.travelCostTemp?.id && // already in DB
                                r.id === state.travelCostTemp?.id)
                        ) {
                            return state.travelCostTemp?.cost || r;
                        }
                        return r;
                    }),
                },
                ...goBackState,
            };
            return {
                ...updatedState,
                errors: { ...state.errors, ...validateTravel(updatedState) },
            };
        }
        default:
            return state;
    }
};

interface ICostsModalProps {
    costId?: number; // undefined if creating a new cost
    initialStep?: number;
    invoice: IInvoice;
    isGroupInvoice?: boolean;
    receiptCost?: IReceiptCost;
    travel?: ITravel;
}

const CostsModal = (props: ICostsModalProps) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const user = useUser();

    const [state, dispatch] = useReducer(costReducer, initialState);

    const handleClose = () => {
        navigate(`/${props.isGroupInvoice ? 'group' : 'invoices'}/${props.invoice.id}`);
    };

    const createNewTravel = (isRepeating: boolean, travelInput?: ITravelInput) => {
        const mutationFunc = isRepeating ? createRepeatingTravel : createTravel;
        if (props.invoice.status === 'incomplete') {
            return mutationFunc({
                variables: {
                    invoiceId: props.invoice.id,
                    travel: travelInput,
                },
            });
        }
        if (props.invoice.costInvoice) {
            return mutationFunc({
                variables: {
                    costInvoiceId: props.invoice.costInvoice?.id,
                    travel: travelInput,
                },
            });
        }
        return mutationFunc({
            variables: {
                costInvoiceId: null,
                invoiceId: props.invoice.id,
                travel: travelInput,
            },
        });
    };

    const mutationVariables = {
        onCompleted: () => {
            toast(t('general.saved'));
            handleClose();
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: () => [
            {
                query: GET_INVOICE,
                variables: {
                    id: props.invoice.id,
                    isGroupInvoice: props.isGroupInvoice,
                },
            },
        ],
    };

    const [createCost, { loading: createCostLoading }] = useMutation(CREATE_COST, mutationVariables);

    const [createTravel, { loading: createTravelLoading }] = useMutation(CREATE_TRAVEL, mutationVariables);

    const [createRepeatingTravel, { loading: createRepeatingTravelLoading }] = useMutation(CREATE_TRAVEL, {
        ...mutationVariables,
        onCompleted: () => null,
    });

    const [updateCost, { loading: updateCostLoading }] = useMutation(UPDATE_COST, mutationVariables);

    const [updateCostAfterSend, { loading: updateCostAfterSendLoading }] = useMutation(
        UPDATE_COST_AFTER_SEND,
        mutationVariables,
    );

    const [updateTravel, { loading: updateTravelLoading }] = useMutation(UPDATE_TRAVEL, mutationVariables);

    const [updateTravelAfterSend, { loading: updateTravelAfterSendLoading }] = useMutation(
        UPDATE_TRAVEL_AFTER_SEND,
        mutationVariables,
    );

    const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);

    const handleSave = async () => {
        if (state.step === RECEIPT) {
            // Save travel receipt
            dispatch({ type: 'ADD_TRAVEL_COST_TEMP' });
        } else if (state.step === MATERIAL) {
            if (!props.costId) {
                if (props.invoice.status === 'incomplete') {
                    createCost({
                        variables: {
                            cost: costToCostInput(state.currentCost),
                            invoiceId: props.invoice.id,
                        },
                    });
                } else if (props.invoice.costInvoice) {
                    createCost({
                        variables: {
                            cost: costToCostInput(state.currentCost),
                            costInvoiceId: props.invoice.costInvoice?.id,
                        },
                    });
                } else {
                    createCost({
                        variables: {
                            cost: costToCostInput(state.currentCost),
                            costInvoiceId: null,
                            invoiceId: props.invoice.id,
                        },
                    });
                }
            } else {
                if (props.invoice.status === 'incomplete') {
                    updateCost({
                        variables: {
                            cost: costToCostInput(state.currentCost),
                            costId: props.costId,
                            invoiceId: props.invoice.id,
                        },
                    });
                } else {
                    updateCostAfterSend({
                        variables: {
                            cost: costToCostInput(state.currentCost),
                            costId: props.costId,
                            costInvoiceId: props.invoice.costInvoice?.id,
                        },
                    });
                }
            }
        } else {
            // any TRAVEL step
            if (!props.costId) {
                if (state.currentTravel.repeatingDates) {
                    const currentTravel = state.currentTravel;
                    const days: IDatePair[] = state.currentTravel.repeatingDates;
                    let first = true;
                    for (const d of days) {
                        const input = currentTravelToTravelInput({
                            ...state,
                            currentTravel: {
                                ...currentTravel,
                                endTime: d.endDate,
                                receiptCosts: first ? currentTravel.receiptCosts : [],
                                startTime: d.startDate,
                            },
                        });
                        await createNewTravel(true, input);
                        first = false;
                    }
                    toast(t('general.saved'));
                    handleClose();
                } else {
                    const input = currentTravelToTravelInput(state);
                    createNewTravel(false, input);
                }
            } else {
                if (props.invoice.status === 'incomplete') {
                    updateTravel({
                        variables: {
                            invoiceId: props.invoice.id,
                            travel: currentTravelToTravelInput(state),
                            travelId: props.costId,
                        },
                    });
                } else {
                    updateTravelAfterSend({
                        variables: {
                            costInvoiceId: props.invoice.costInvoice?.id,
                            travel: currentTravelToTravelInput(state),
                            travelId: props.costId,
                        },
                    });
                }
            }
        }

        if (state.currentTravel.carNumberSaved) {
            updateUserPreferences({
                variables: { userPreferences: { carNumber: state.currentTravel.carNumber } },
            });
        }
    };

    useEffect(() => {
        dispatch({
            payload: {
                addressSuggestions: {
                    client: formatShortAddress(props.invoice.recipient?.address),
                    home: formatShortAddress(user?.mailingAddress),
                },
                costId: props.costId,
                currentCost: props.receiptCost,
                currentTravel: props.travel,
                initialStep: props.initialStep,
                invoice: props.invoice,
                invoiceTemplate: props.invoice.template,
                invoicingTravelTotalWithoutVat: getTravelInvoicingTotalWithoutVat(props.invoice, user?.id),
                language: i18n.language,
            },
            type: 'SETUP',
        });
    }, []);

    return (
        <Modal id="modal-costs" isOpen={true} onClose={handleClose} title={getStepTitle(state.step)} noscroll>
            <ModalContent>
                {getStepContent(state, dispatch).map((c: any, index) => {
                    return <CostComponent key={`c-${index}`}>{c}</CostComponent>;
                })}
            </ModalContent>

            <ModalActions>
                <EezyButton
                    color="purple"
                    onClick={() => {
                        state.step === START || state.prevSteps.length === 0
                            ? handleClose()
                            : dispatch({ type: 'GO_BACK' });
                    }}
                >
                    {getBackButtonContent(state.step)}
                </EezyButton>
                <EezyButton
                    color="purple"
                    dark
                    disabled={
                        state.step === START
                            ? true
                            : state.step === RECEIPT
                              ? state.travelCostTemp?.errors
                              : state.errors[state.step]
                    }
                    onClick={handleSave}
                    type="submit"
                >
                    {createCostLoading ||
                    updateCostLoading ||
                    updateCostAfterSendLoading ||
                    createTravelLoading ||
                    createRepeatingTravelLoading ||
                    updateTravelLoading ||
                    updateTravelAfterSendLoading ? (
                        <LoadingSpinner size="1em" />
                    ) : (
                        getContinueButtonContent(state)
                    )}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default CostsModal;
