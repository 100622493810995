import { useQuery } from '@apollo/client';
import { Hidden } from '@mui/material';
import React, { type CSSProperties } from 'react';
import styled from 'styled-components';
import { EezyButton } from '../../components/Buttons';
import ColorBox from '../../components/ColorBox';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { BodyP, LabelStrong } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GRIPE, COLOR_MEDIUM_BG, COLOR_WHITE_WALKER } from '../../styles/variables';
import { GET_CONTENT } from './queries';
import { useTranslation } from 'react-i18next';

const MonthLabel = styled.p`
    color: ${COLOR_WHITE_WALKER};
    font-size: 15px;
    font-weight: normal;
    line-height: 28px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
`;

interface IProps {
    style?: CSSProperties;
}

const ExceptionSchedule = (props: IProps) => {
    const { i18n } = useTranslation();
    const { data: contentData } = useQuery(GET_CONTENT, {
        variables: { identifier: 'specialHours', lang: i18n.language },
    });

    if (!contentData?.content) {
        return null;
    }
    const content = JSON.parse(contentData.content);

    if (!content.fields?.months?.find((m: any) => m.fields)) {
        return null;
    }

    const Schedule = ({ visibleInitial }: { visibleInitial: boolean }) => {
        const [visible, setVisible] = React.useState(visibleInitial);
        return (
            <Flex column>
                <Flex baseline>
                    <LabelStrong color={COLOR_BLUM}>{content.fields.title}</LabelStrong>
                    {!visible && (
                        <EezyButton
                            color="purple"
                            dark
                            onClick={() => setVisible(true)}
                            style={{
                                backgroundColor: COLOR_GRIPE,
                                marginLeft: 24,
                            }}
                        >
                            {content.fields.showButton}
                        </EezyButton>
                    )}
                </Flex>
                {visible &&
                    content.fields.months.map((m: any, index: number) => {
                        if (!m.fields) {
                            return null;
                        }
                        return (
                            <Flex column style={{ marginBottom: 20 }} key={`${index}-${m}`}>
                                <MonthLabel>{m.fields.title}</MonthLabel>
                                {m.fields.specialHoursDays?.map((s: any, i: number) => {
                                    if (!s.fields) {
                                        return null;
                                    }
                                    return <BodyP key={`${i}-${s.fields.title}`}>{s.fields.title}</BodyP>;
                                })}
                            </Flex>
                        );
                    })}
            </Flex>
        );
    };

    return (
        <ColorBox color={COLOR_MEDIUM_BG} disableClose style={{ padding: 24, ...props.style }}>
            <Hidden mdDown>
                <Flex>
                    <Icon
                        icon={['fal', 'calendar-times']}
                        color={COLOR_BLUM}
                        className="big"
                        style={{ marginRight: 24 }}
                    />
                    <Schedule visibleInitial={false} />
                </Flex>
            </Hidden>
            <Hidden mdUp>
                <Schedule visibleInitial={true} />
            </Hidden>
        </ColorBox>
    );
};

export default ExceptionSchedule;
