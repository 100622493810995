import { Wrapper, WrapperRow } from '../../styles/yelStyles';
import { formatCents, formatPercentage, trans } from '../../utils';
import { yelLogic } from '../../utils/yel';
import { useYelData } from 'queries/useYelData';
import useYelStore from 'stores/yelStore';

export const IncomeDetails = () => {
    const { data } = useYelData();
    const yelStoreData = useYelStore((state) => state.yelData);
    return (
        <Wrapper>
            <WrapperRow>
                <div className="mt-1 font-semibold">{trans('yel:income-year')}</div>
                <div className="text-black m-0">
                    {trans('form.eurs', {
                        eurs: formatCents(yelStoreData.estimatedIncome),
                    })}
                </div>
            </WrapperRow>
            <WrapperRow>
                <div className="mt-1 font-semibold">{trans('yel:income-limit')}</div>
                <div className="text-black m-0">
                    {trans('form.eurs', {
                        eurs: formatCents(data.incomeLimits.min),
                    })}
                </div>
            </WrapperRow>
            {yelLogic.shouldShowBasePercentage(yelStoreData) && (
                <WrapperRow>
                    <div className="mt-1 font-semibold">
                        {trans('yel:payment-estimate')} (
                        {trans('form.percent', {
                            percentage: formatPercentage(data.collectionInfo.insurancePercentage),
                        })}
                        )
                        <span
                            style={{
                                display: 'block',
                                fontWeight: 'normal',
                            }}
                        >
                            ({trans('yel:includes-no-discount')})
                        </span>
                    </div>
                    <div className="text-black m-0">
                        2 x{' '}
                        {trans('form.eurs', {
                            eurs: formatCents(
                                yelLogic.calculateYelPayment(
                                    yelStoreData.estimatedIncome,
                                    data.collectionInfo.insurancePercentage,
                                    data.incomeLimits,
                                ) / 2,
                            ),
                        })}
                    </div>
                </WrapperRow>
            )}

            {yelLogic.shouldShowDiscountPercentage(yelStoreData) && (
                <WrapperRow>
                    <div className="mt-1 font-semibold">
                        {trans('yel:payment-estimate')} (
                        {trans('form.percent', {
                            percentage: formatPercentage(data.collectionInfo.insurancePercentageDiscount),
                        })}
                        )
                        <span
                            style={{
                                display: 'block',
                                fontWeight: 'normal',
                            }}
                        >
                            ({trans('yel:includes-discount')})
                        </span>
                    </div>
                    <div className="text-black m-0">
                        2 x{' '}
                        {trans('form.eurs', {
                            eurs: formatCents(
                                yelLogic.calculateYelPayment(
                                    yelStoreData.estimatedIncome,
                                    data.collectionInfo.insurancePercentageDiscount,
                                    data.incomeLimits,
                                ) / 2,
                            ),
                        })}
                    </div>
                </WrapperRow>
            )}
        </Wrapper>
    );
};
