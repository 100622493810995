import { gql } from '@apollo/client';

const GET_USER_CONTACTS = gql`
    query GetUserContacts {
        userData {
            email
            phone
            phoneCountryCode
        }
    }
`;

export default GET_USER_CONTACTS;
