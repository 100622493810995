import type React from 'react';
import type { INotification } from '../../../../shared/src/types/notifications';
import { NotificationDynamic } from './NotificationDynamic';
import { isArchiveDisabled } from 'utils/notifications/notifications';

type Props = {
    isIdentified: boolean;
    data: INotification;
    onOpen: (data: INotification) => void;
    onArchive: (data: INotification) => void;
};

const NotificationsItem: React.FC<Props> = (props: Props) => {
    if (props.data.actionType === 'taxCard' && !props.isIdentified) {
        return null;
    }

    const onOpen = () => {
        props.onOpen(props.data);
    };

    const onArchive = () => {
        if (isArchiveDisabled(props.data)) {
            return;
        }

        props.onArchive(props.data);
    };

    return (
        <NotificationDynamic
            key={`notification-${props.data.id}`}
            notification={props.data}
            onArchive={isArchiveDisabled(props.data) ? undefined : onArchive}
            onOpen={onOpen}
        />
    );
};

export default NotificationsItem;
