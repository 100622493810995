import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';

import type { ExpressStatus } from '../../../../shared/src/types/invoice';
import ExpressIconSvg from '../../assets/images/eezy-express.svg';
import SwitchButton from '../../components/Switch';
import { P } from '../../components/textElements';
import { ToolsHeader } from '../../components/TogglingTools';
import { trans } from '../../utils';
import ExpressModal from './ExpressModal';
import { UPDATE_EXPRESS } from './queries';

interface IExpressProps {
    editable?: boolean;
    expressStatus?: ExpressStatus;
    email?: string;
    invoiceId?: number;
}

const ExpressIcon = styled.div`
    background: url(${ExpressIconSvg});
    float: right;
    margin-left: 30px;
    width: 39px;
    height: 25px;
`;

const EezyExpress = (props: IExpressProps) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [switchOn, setSwitchOn] = React.useState(props.expressStatus && props.expressStatus !== 'rejected');

    const { expressStatus } = props;
    useEffect(() => {
        setSwitchOn(expressStatus && expressStatus !== 'rejected');
    }, [expressStatus]);

    const [updateExpress, { loading }] = useMutation(UPDATE_EXPRESS, {
        onCompleted: () => {
            toast(trans('general.saved'));
            setModalOpen(false);
        },
        onError: () => {
            toast.error(trans('errors.general'));
        },
    });

    const handleChange = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (!loading) {
            if (checked) {
                setSwitchOn(true);
                setModalOpen(true);
            } else {
                updateExpress({
                    variables: { invoiceId: props.invoiceId, state: false },
                });
                setSwitchOn(false);
            }
        }
    };

    const disabled = !props.editable || !props.invoiceId;
    return (
        <>
            <ToolsHeader titleTransKey="invoice.express-switch" disabled={disabled}>
                <SwitchButton
                    checked={switchOn}
                    disabled={disabled}
                    onChange={handleChange}
                    inputProps={{
                        'aria-label': trans('invoice.express-switch'),
                    }}
                />
            </ToolsHeader>
            <div>
                <ExpressIcon style={{ opacity: disabled ? 0.2 : 1 }} />
                <P disabled={disabled}>{trans('invoice.express-note')}</P>
            </div>
            {modalOpen && (
                <ExpressModal
                    isOpen={modalOpen}
                    handleModalClose={() => {
                        setSwitchOn(false);
                        setModalOpen(false);
                    }}
                    email={props.email}
                    invoiceId={props.invoiceId}
                    updateExpress={updateExpress}
                />
            )}
        </>
    );
};

export default EezyExpress;
