import { gql } from '@apollo/client';

const REQUEST_COLLECTION = gql`
    mutation RequestCollection($invoiceId: Int!) {
        requestCollection(invoiceId: $invoiceId) {
            id
            collectionRequested
        }
    }
`;

export default REQUEST_COLLECTION;
