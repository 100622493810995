import { gql } from '@apollo/client';

const GET_SALARY = gql`
    query Salary($id: Int!) {
        salary(id: $id) {
            id
            paymentDate
            paidAmount
            status
            totalAmount
            invoiceIds
            url
        }
    }
`;

export default GET_SALARY;
