import { gql } from '@apollo/client';

const GET_WITHHOLDING_TAX = gql`
    query GetWithholdingTax {
        userWithholdingTax {
            status,
            addPercentage,
            basicPercentageLimit,
            basicPercentage,
            startDate,
            text,
            error {
                reason
                code
                text
            }
        }
    }
`;

export default GET_WITHHOLDING_TAX;
