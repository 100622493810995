import type { CSSProperties, ReactNode } from 'react';
import { COLOR_IMPORTANT, COLOR_STATUS_WAITING } from '../styles/variables';
import { Flex } from './Flex';
import { Icon } from './Icon';
import { ValidationMessage } from './textElements';

interface IProps {
    children?: ReactNode;
    style?: CSSProperties;
    title?: string;
    type: 'error' | 'warning' | 'other';
    iconColor?: string;
    onClose?: () => void;
}

export const ValidationMessageBlock = (props: IProps) => {
    const { iconColor } = props;
    return (
        <Flex style={props.style}>
            <Icon
                icon={
                    props.type === 'warning'
                        ? ['far', 'exclamation-circle']
                        : [iconColor ? 'fas' : 'far', 'exclamation-triangle']
                }
                color={
                    iconColor ? iconColor : props.type === 'warning' ? COLOR_STATUS_WAITING : COLOR_IMPORTANT
                }
                className="small"
                style={{ margin: '5px 8px 0 0' }}
            />
            <div>
                <Flex spread>
                    {props.title && (
                        <ValidationMessage
                            $error={props.type === 'error'}
                            $warning={props.type === 'warning'}
                        >
                            <div style={{ fontWeight: 600 }}>{props.title}</div>
                        </ValidationMessage>
                    )}
                    {!!props.onClose && (
                        <Icon
                            icon={['fal', 'times']}
                            color={iconColor}
                            className="small"
                            style={{ margin: '8px 8px 0 0', cursor: 'pointer' }}
                            onClick={props.onClose}
                        />
                    )}
                </Flex>
                <ValidationMessage $error={props.type === 'error'} $warning={props.type === 'warning'}>
                    {props.children}
                </ValidationMessage>
            </div>
        </Flex>
    );
};
