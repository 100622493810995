import {
    EEZYPAY_USER_DATA_LOADING,
    EEZYPAY_USER_DATA_LOADING_COMPLETE,
    EEZYPAY_USER_DATA_LOADING_ERROR,
    SALES_PAGE_SEARCH_QUERY,
    SUBSCRIPTION_END_DATE,
    TRANSACTIONS_SEARCH_QUERY,
} from '../actionTypes';
import type { ApolloError } from '@apollo/client';

export const saveSalesPageSearchQuery = (salesPageSearchQuery: string) => ({
    payload: { salesPageSearchQuery },
    type: SALES_PAGE_SEARCH_QUERY,
});

export const saveTransactionsSearchQuery = (transactionsSearchQuery: string) => ({
    payload: { transactionsSearchQuery },
    type: TRANSACTIONS_SEARCH_QUERY,
});

export const saveSubscriptionEndDate = (subscriptionEndDate: Date) => ({
    payload: { subscriptionEndDate },
    type: SUBSCRIPTION_END_DATE,
});

export const eezyPayUserDataLoading = () => ({
    payload: { userLoadingComplete: false },
    type: EEZYPAY_USER_DATA_LOADING,
});

export const eezyPayUserLoadingComplete = (
    userState: number,
    eezyPayTermsAccepted: boolean,
    campwireTermsAccepted: boolean,
) => ({
    payload: {
        eezyPayTermsAccepted,
        campwireTermsAccepted,
        userLoadingComplete: true,
        userLoadingError: false,
        userState,
    },
    type: EEZYPAY_USER_DATA_LOADING_COMPLETE,
});

export const eezyPayUserLoadingError = (error: ApolloError) => ({
    payload: { userLoadingError: error },
    type: EEZYPAY_USER_DATA_LOADING_ERROR,
});
