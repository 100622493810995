import styled from 'styled-components';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { COLOR_BLUM } from '../../styles/variables';
import { trans } from '../../utils';
import { isAnkkaId } from '../../utils/invoice/invoiceLogic';

const Wrapper = styled.div`
    margin-top: 20px;
`;

interface IFreeFillActionsProps {
    invoice?: IInvoice;
    loading?: boolean;
    onCopy?: () => void;
    onEdit?: () => void;
}

const FreeFillActions = (props: IFreeFillActionsProps) => {
    return (
        <Wrapper>
            <EezyButton
                color="purple"
                disabled={props.loading}
                hasIcon
                hidden={!props.onCopy || !props.invoice || isAnkkaId(props.invoice.id)}
                iconAlignment="right"
                onClick={props.onCopy}
            >
                {trans('form.copy')}
                <Icon color={COLOR_BLUM} icon={['far', 'copy']} />
            </EezyButton>
            <EezyButton color="purple" hidden={!props.onEdit} onClick={props.onEdit}>
                {trans('general.edit')}
            </EezyButton>
        </Wrapper>
    );
};

export default FreeFillActions;
