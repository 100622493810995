import { type Dispatch, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';
import { type ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import type { IAddress } from '../../../../shared/src/types/address';
import type { ClientType, IContactPerson, IInvoice } from '../../../../shared/src/types/invoice';
import { confirmAction, type IConfirmAction } from 'actions/app';
import { showModals } from 'actions/auth';
import { EezyButton } from 'components/Buttons';
import ErrorBox from 'components/ErrorBox';
import { FormSection, SplitRow } from 'components/form';
import LoadingSpinner from 'components/Loading';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import { LabelMedium, SmallP } from 'components/textElements';
import { COLOR_GREYJOY, COLOR_IMPORTANT } from 'styles/variables';
import { getErrors } from 'utils/apolloErrors';
import { cleanRecipientBeforeSending, clientToClientInput } from 'utils/invoice/invoiceLogic';
import validators from 'utils/invoice/validators';
import {
    initialRecipientForm,
    type IRecipientFormState,
    recipientReducer,
} from 'utils/recipient/recipientLogic';
import type { IDocument } from 'utils/user/userUtils';
import { formatValidationResult } from 'utils/validation';
import RecipientForm from '../invoice/fillHelpers/RecipientForm';
import YtjDiff from '../invoice/fillHelpers/YtjDiff';
import { GET_INVOICE, GET_INVOICE_RECIPIENTS, UPDATE_RECIPIENT } from '../invoice/queries';
import GET_YTJ_COMPANY from '../invoice/queries/getYtjCompany';
import { GET_CLIENT, GET_INVOICES } from './queries';

interface IModalProps {
    clientId: string;
    clientType: ClientType;
    confirmAction: (payload: IConfirmAction) => void;
    handleModalClose: () => void;
    isOpen: boolean;
    invoice: IInvoice;
    showModals?: (types: string[]) => void;
}

const ClientModal = (props: IModalProps) => {
    const { t } = useTranslation();
    const [{ recipient, selectedContactPersonIndex }, dispatch]: [IRecipientFormState, Dispatch<any>] =
        useReducer(recipientReducer, initialRecipientForm);

    const [errors, setErrors] = useState<any>(null);
    const [showErrors, setShowErrors] = useState<any>(false);

    const { data: clientData } = useQuery(GET_CLIENT, {
        variables: { id: props.clientId, type: props.clientType },
    });

    const readonly = !clientData?.client?.active;
    const errorRef = useRef<HTMLSpanElement>(null);

    const [getYtjData, { data: ytjData }] = useLazyQuery(GET_YTJ_COMPANY);

    const [updateRecipient, { loading: updateLoading }] = useMutation(UPDATE_RECIPIENT, {
        onCompleted: () => {
            toast(t('general.saved'));
            props.handleModalClose();
        },
        onError: (e: ApolloError) => {
            const err = getErrors(e);
            if (err?.mustAccept && props.showModals) {
                props.showModals(err?.mustAccept.map((t: IDocument) => t.document));
            } else {
                toast.error(t('errors.general'));
            }
        },
        refetchQueries: () => [
            { query: GET_INVOICE, variables: { id: props.invoice.id } },
            { query: GET_INVOICES },
            { query: GET_INVOICE_RECIPIENTS },
        ],
    });

    useEffect(() => {
        setErrors(formatValidationResult(validators.recipient.validate(recipient)));
    }, [recipient]);

    useEffect(() => {
        if (clientData?.client?.type === 'company') {
            getYtjData({
                variables: {
                    businessId: clientData?.client?.businessId,
                },
            });
        }
    }, [clientData, getYtjData]);

    useEffect(() => {
        if (clientData?.client) {
            dispatch({
                payload: { recipient: clientData.client },
                type: 'INIT',
            });
        }
    }, [clientData]);

    const scrollToError = useCallback(() => {
        if (errorRef.current) {
            errorRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [errorRef]);

    const handleSave = () => {
        const r = cleanRecipientBeforeSending(clientToClientInput(recipient));
        const validationErrors = formatValidationResult(validators.recipient.validate(r));

        setErrors(validationErrors);
        setShowErrors(!!validationErrors);
        if (validationErrors) {
            scrollToError();
        }

        if (!validationErrors) {
            updateRecipient({
                variables: {
                    recipient: r,
                    recipientId: props.clientId,
                },
            });
        }
    };

    const handleHideClient = () => {
        props.confirmAction({
            id: 'client-hide',
            onAction: () => {
                const r = cleanRecipientBeforeSending(clientToClientInput(clientData?.client));
                updateRecipient({
                    variables: {
                        recipient: { ...r, active: false },
                        recipientId: props.clientId,
                    },
                });
                props.handleModalClose();
            },
            texts: {
                submit: 'dashboard.clientModal.hide-button',
                title: 'dashboard.clientModal.hide-confirmation',
            },
        });
    };

    const handleChange = (val: string, name: string) => {
        dispatch({ type: 'UPDATE_RECIPIENT', payload: { [name]: val } });
    };

    const handleAddressChange = (val: string, name: string) => {
        dispatch({ type: 'UPDATE_ADDRESS', payload: { [name]: val } });
    };

    const handleContactPersonsChange = (contactPeople: IContactPerson[]) => {
        dispatch({ type: 'UPDATE_RECIPIENT', payload: { contactPeople } });
    };

    return (
        <Modal
            id="modal-invoice-client"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('dashboard.clientModal.title') || ''}
            noscroll
        >
            <ModalContent>
                <span ref={errorRef} />
                {showErrors && errors && <ErrorBox>{t('errors.recipient-incomplete')}</ErrorBox>}

                {ytjData && ytjData?.ytjCompany?.businessId === recipient.businessId && (
                    <YtjDiff
                        formData={recipient}
                        handleAddressChange={(address?: IAddress) => {
                            dispatch({
                                payload: address,
                                type: 'UPDATE_ADDRESS',
                            });
                        }}
                        handleChange={handleChange}
                        ytjData={clientToClientInput(ytjData?.ytjCompany)}
                    />
                )}

                {props.invoice && (
                    <RecipientForm
                        disableContactPersonSelect
                        disableRecipientChange
                        errors={errors}
                        formData={{ recipient, selectedContactPersonIndex }}
                        handleChange={handleChange}
                        handleAddressChange={handleAddressChange}
                        handleContactPersonsChange={handleContactPersonsChange}
                        handleSave={handleSave}
                        readOnly={readonly}
                        recipientId={props.clientId}
                        showErrors={showErrors}
                    />
                )}

                {!readonly && (
                    <FormSection>
                        <SplitRow style={{ marginTop: 15 }}>
                            <LabelMedium color={COLOR_IMPORTANT}>
                                {t('dashboard.clientModal.hide-label')}
                            </LabelMedium>
                            <EezyButton
                                color="important"
                                onClick={handleHideClient}
                                style={{
                                    border: `1px solid ${COLOR_IMPORTANT}`,
                                }}
                            >
                                {t('dashboard.clientModal.hide-button')}
                            </EezyButton>
                        </SplitRow>
                        <SmallP color={COLOR_GREYJOY} style={{ marginTop: 8 }}>
                            {t('dashboard.clientModal.hide-text')}
                        </SmallP>
                    </FormSection>
                )}
            </ModalContent>
            <ModalActions>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {t('general.cancel')}
                </EezyButton>

                {!readonly && (
                    <EezyButton color="purple" dark onClick={handleSave}>
                        {updateLoading ? <LoadingSpinner size="1em" /> : t('general.save')}
                    </EezyButton>
                )}
            </ModalActions>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        confirmAction: (payload: IConfirmAction) => {
            dispatch(confirmAction(payload));
        },
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(null, mapDispatchToProps)(ClientModal);
