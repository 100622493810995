import styled from 'styled-components';
import { SCREEN_S } from '../styles/variables';

export const HeaderRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;

    a {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 12px;
        @media (min-width: ${SCREEN_S}px) {
            width: 130px;
        }
    }
`;
