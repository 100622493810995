import { gql } from '@apollo/client';

const GET_ATTACHMENTS = gql`
    query {
        allUnusedAttachments {
            contentType
            createTime
            id
            name
            previewUrl
            url
        }
    }
`;

export default GET_ATTACHMENTS;
