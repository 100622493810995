import type { ICoworker } from '../../../shared/src/types/user';
import { DELETE_SELECTED_COWORKER, SELECTED_COWORKER } from '../actionTypes';

export interface IInvoiceState {
    selectedCoworker: ICoworker | null;
    searchQuery?: string;
    searchRecipientId?: string;
    expenseReimbursementWarningHidden?: boolean;
    travelWarningHidden?: boolean;
}

const initialState: IInvoiceState = {
    selectedCoworker: null,
};

// biome-ignore lint/style/useDefaultParameterLast: Redux will be eventually removed
export default (state = initialState, action: any) => {
    switch (action.type) {
        case SELECTED_COWORKER:
            return {
                ...state,
                selectedCoworker: action.payload,
            };
        case DELETE_SELECTED_COWORKER:
            return {
                ...state,
                selectedCoworker: null,
            };
        default:
            return state;
    }
};
