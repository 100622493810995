import { gql } from '@apollo/client';

const GET_CONTRACTS = gql`
    query GetContracts($clientId: Int!, $clientType: ClientType!) {
        contracts(clientId: $clientId, clientType: $clientType) {
            id
            startDate
            endDate
        }
    }
`;

export default GET_CONTRACTS;
