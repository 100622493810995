import React, { type FormEvent, type KeyboardEvent } from 'react';
import { isMobile } from 'utils';
import { InfoTooltip } from './ToolTip';
import { Input } from './common/Input';

interface ISearchFieldProps {
    onChange: (query: string, fireImmediately?: boolean) => void;
    placeholder: string;
    query: string;
    tooltip?: string;
}

const SearchField = (props: ISearchFieldProps) => {
    const [focused, setFocused] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const onMouseOver = () => setHovered(true);
    const onMouseOut = () => setHovered(false);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        props.onChange(e.currentTarget.value);
    };

    const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        const code = e.key;
        if (code === 'Enter') {
            // enter pressed, fire search immediately
            props.onChange(e.currentTarget.value, true);
        }
    };

    const handleEmpty = () => {
        props.onChange('', true);
    };

    const renderSearchField = () => (
        <Input
            onChange={handleChange}
            onKeyDown={handleEnter}
            placeholder={props.placeholder}
            type="text"
            value={props.query}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            startIcon={['far', 'search']}
            endIconButton={props.query ? ['far', 'close'] : undefined}
            onEndIconButtonClick={handleEmpty}
            helpText={props.tooltip}
        />
    );

    return (
        <>
            {props.tooltip ? (
                <InfoTooltip
                    title={props.tooltip}
                    arrow
                    placement={isMobile() ? 'top' : 'right'}
                    open={(focused && isMobile()) || hovered}
                >
                    {renderSearchField()}
                </InfoTooltip>
            ) : (
                renderSearchField()
            )}
        </>
    );
};

export default SearchField;
