import { useMutation } from '@apollo/client';
import { Hidden } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import type { ThunkDispatch } from 'redux-thunk';
import type { IMessageInput, IMessageReferenceInput } from '../../../../shared/src/types/message';
import { deleteMessageAttachment, uploadMessageAttachment } from '../../actions/file';
import { EezyButton } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { FormInput, FormTextArea } from '../../components/form';
import FileInput from '../../components/form/FileInput';
import FileRow from '../../components/form/FileRow';
import { Icon } from '../../components/Icon';
import LoadingSpinner from '../../components/Loading';
import { LabelMedium, UniqueTitle } from '../../components/textElements';
import type { ITempFile } from '../../reducers/fileReducer';
import { COLOR_BLUM } from '../../styles/variables';
import { trans } from '../../utils';
import { stripSpaces } from '../../utils/str';
import { getRelatedObjectUrl, MESSAGE_ATTACHMENT_FILETYPES } from '../../utils/support/messageUtils';
import { messageValidator } from '../../utils/support/validators';
import { formatValidationResult } from '../../utils/validation';
import { DELETE_ATTACHMENT } from '../costs/queries';
import { Message } from './Message';

interface IProps {
    deleteMessageAttachment: (id: number) => void;
    fileLoading?: boolean;
    loadingSend?: boolean;
    onCancel?: () => void;
    onSend?: (message: IMessageInput, callback: () => void) => void;
    isNew?: boolean;
    message?: { content?: string; subject?: string };
    messageAttachments: ITempFile[];
    relatedObject?: IMessageReferenceInput;
    uploadMessageAttachment: (data: FormData) => void;
}

const MessageForm = (props: IProps) => {
    const navigate = useNavigate();
    const [subject, setSubject] = React.useState('');
    const [content, setContent] = React.useState('');
    const [attachments, setAttachments] = React.useState<ITempFile[]>([]);
    const [errors, setErrors] = React.useState<any>(null);

    const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
        onCompleted: ({ deleteAttachment: { id } }) => {
            props.deleteMessageAttachment(id);
        },
        onError: () => null,
    });

    const removeAttachmentsFromStore = () => {
        for (const a of attachments) {
            props.deleteMessageAttachment(a.id);
        }
    };

    const handleCancel = () => {
        removeAttachmentsFromStore();
        if (props.onCancel) {
            props.onCancel();
        } else {
            navigate('/support');
        }
    };

    const validateMessage = React.useCallback(() => {
        return formatValidationResult(messageValidator.validate({ content, isNew: props.isNew, subject }));
    }, [content, subject, props.isNew]);

    const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];

            if (
                attachments.filter(
                    (f: ITempFile) => f.name === newFile.name || f.name === stripSpaces(newFile.name),
                ).length === 0
            ) {
                const uploadData = new FormData();
                uploadData.append('file', newFile);
                props.uploadMessageAttachment(uploadData);
            } else {
                toast.error(trans('file.duplicate-name'));
            }
        }
    };

    const handleRemoveFile = (fileId: number) => {
        deleteAttachment({ variables: { attachmentId: fileId } });
        // Hide the file immediately from UI
        setAttachments(
            attachments.filter((f: ITempFile) => {
                return f.id !== fileId;
            }) || [],
        );
    };

    const handleMessageSend = () => {
        if (props.onSend) {
            const message: IMessageInput = {
                content,
                fileIds: attachments.map((a) => a.id),
                relatedObject: props.relatedObject,
                subject,
            };
            const messageErrors = validateMessage();
            setErrors(messageErrors);
            if (!messageErrors) {
                props.onSend(message, () => removeAttachmentsFromStore());
            }
        }
    };

    React.useEffect(() => {
        if (errors) {
            setErrors(validateMessage());
        }
    }, [content, subject, validateMessage]);

    React.useEffect(() => {
        setAttachments(props.messageAttachments);
    }, [props.messageAttachments]);

    React.useEffect(() => {
        if (props.message) {
            setContent(props.message.content || '');
            setSubject(props.message.subject || '');
        }
    }, [props.message]);

    const SendButton = () => (
        <EezyButton color={props.loadingSend ? 'important' : 'purple'} dark onClick={handleMessageSend}>
            {props.loadingSend ? (
                <LoadingSpinner size="1em" color="white" />
            ) : (
                trans('support:new-message.send')
            )}
        </EezyButton>
    );

    return (
        <Message>
            <Flex spread>
                <UniqueTitle color="black">
                    {props.isNew ? trans('support:new-message.title') : trans('support:message.reply')}
                </UniqueTitle>

                <Hidden mdDown>
                    <div>
                        <EezyButton color="purple" onClick={handleCancel}>
                            {trans('general.cancel')}
                        </EezyButton>
                        {props.onSend && SendButton()}
                    </div>
                </Hidden>
            </Flex>

            <form className="body">
                {props.isNew && (
                    <FormInput
                        error={errors?.subject}
                        name="subject"
                        label={trans('support:new-message.form.subject')}
                        onChange={(val) => setSubject(val)}
                        placeholder={trans('support:new-message.form.subject-placeholder')}
                        required
                        value={subject}
                    />
                )}

                <FormTextArea
                    error={errors?.content}
                    label={trans('support:new-message.form.content')}
                    name="content"
                    onChange={(val) => setContent(val)}
                    placeholder={trans('support:new-message.form.content-placeholder')}
                    required
                    rowsMin={10}
                    value={content}
                />

                {props.relatedObject && (
                    <Flex column style={{ marginTop: 16 }}>
                        <Flex baseline>
                            <LabelMedium color={COLOR_BLUM}>
                                {trans(`support:message.related-object-text.${props.relatedObject.type}`)}{' '}
                                {getRelatedObjectUrl(props.relatedObject) ? (
                                    <Link target="_blank" to={getRelatedObjectUrl(props.relatedObject) || ''}>
                                        {props.relatedObject.id}
                                    </Link>
                                ) : (
                                    <span>{props.relatedObject.id}</span>
                                )}
                            </LabelMedium>
                        </Flex>
                    </Flex>
                )}

                <Flex baseline spread style={{ marginTop: 16 }}>
                    <LabelMedium color={COLOR_BLUM}>
                        {trans('support:new-message.form.attachment')}
                    </LabelMedium>
                    <FileInput accept={MESSAGE_ATTACHMENT_FILETYPES} onChange={handleAddFile}>
                        {props.fileLoading ? (
                            <LoadingSpinner color={COLOR_BLUM} size="1em" />
                        ) : (
                            <Icon icon={['far', 'arrow-to-top']} color={COLOR_BLUM} />
                        )}{' '}
                        <span aria-hidden>{trans('file.upload')}</span>
                        <span style={{ display: 'none' }}>
                            {trans('support:new-message.form.attahcment-upload')}
                        </span>
                    </FileInput>
                </Flex>

                {attachments.map((attachment: ITempFile) => {
                    return (
                        <FileRow
                            handleRemoval={handleRemoveFile}
                            id={attachment.id}
                            key={`message-attachment-${attachment.id}`}
                            name={attachment.name}
                            url={attachment.url}
                        />
                    );
                })}
            </form>

            <Hidden mdUp>
                <div>
                    <Flex spread>
                        <EezyButton color="purple" onClick={handleCancel}>
                            {trans('general.cancel')}
                        </EezyButton>
                        {props.onSend && SendButton()}
                    </Flex>
                </div>
            </Hidden>
        </Message>
    );
};

const mapStateToProps = (state: any) => {
    return {
        fileLoading: state.file.loading,
        messageAttachments: state.file.messageAttachments,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        deleteMessageAttachment: (id: number) => {
            dispatch(deleteMessageAttachment(id));
        },
        uploadMessageAttachment: (data: FormData) => {
            dispatch(uploadMessageAttachment(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageForm);
