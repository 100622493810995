import React from 'react';
import { connect } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { type IStrongAuthData, strongAuth } from 'actions/auth';
import { getI18NextLang } from 'utils/user/userUtils';

interface IProps {
    strongAuth: (data: IStrongAuthData) => void;
}

class RegisterAuth extends React.Component<IProps> {
    // A component to take straight to strong authentication.

    componentDidMount() {
        this.props.strongAuth({ action: 'signup', lang: getI18NextLang() });
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        strongAuth: (data: IStrongAuthData) => {
            dispatch(strongAuth(data));
        },
    };
};

export default connect(null, mapDispatchToProps)(RegisterAuth);
