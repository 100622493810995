import moment from 'moment';
import { yelStatus } from '.';
import { monthsSince, percentOf } from '..';
import type { IIncomeLimits, YelStatusType } from '../../../../shared/src/types/yel';
import type { YelData } from 'queries/useYelData';
import type { YelStoreData } from 'stores/yelStore';

const MONTHS_IN_YEAR = 12;

export const calculateYelPayment = (income: number, percentage: number, incomeLimits: IIncomeLimits) => {
    return percentOf(percentage, Math.min(income, incomeLimits.max));
};

export const shouldShowYelOK = (status?: YelStatusType) =>
    yelStatus.isActive(status) ||
    yelStatus.isActivationRequested(status) ||
    yelStatus.isTerminationRequested(status);

export const shouldShowWizard = (status?: YelStatusType) =>
    yelStatus.hasNoInsurance(status) ||
    yelStatus.isHandledIndependently(status) ||
    yelStatus.isUnknown(status) ||
    yelStatus.isTerminated(status);

export const shouldAskFormerInsuranceCompany = (yelData: YelStoreData) => {
    const { hasSameIncomeAsBefore, hasYel } = yelData;
    return hasYel === 'Yes' && hasSameIncomeAsBefore !== null;
};

export const shouldAskIfIncomeIsTheSame = (yelData: YelStoreData) => {
    const { hasYel } = yelData;
    return hasYel === 'Yes';
};

export const shouldAskIncome = (yelData: YelStoreData) => {
    const now = moment();
    const { isStarting, dateStarted, hasYel } = yelData;
    return (
        hasYel !== 'Yes' &&
        (isStarting || (dateStarted && monthsSince(now, dateStarted) < 2 * MONTHS_IN_YEAR))
    );
};

export const shouldAskStartDate = (yelData: YelStoreData) => {
    return yelData.hasYel === 'No' || yelData.hasYel === 'HasHadBefore';
};

export const shouldShowBasePercentage = (yelData: YelStoreData) => {
    return (
        ((yelData.hasYel === 'Yes' || yelData.hasYel === 'HasHadBefore') && !yelData.hasSameIncomeAsBefore) ||
        monthsSince(moment(), yelData.dateStarted) > MONTHS_IN_YEAR
    );
};

export const shouldShowDiscountPercentage = (yelData: YelStoreData) => {
    const { hasYel } = yelData;
    return (
        hasYel !== 'Yes' &&
        hasYel !== 'HasHadBefore' &&
        (yelData.isStarting || monthsSince(moment(), yelData.dateStarted) < 4 * MONTHS_IN_YEAR)
    );
};

export const shouldShowContinue = (yelData: YelStoreData & Pick<YelData, 'incomeLimits'>) => {
    const {
        estimatedIncome,
        estimatedInvoicing,
        occupation,
        hasYel,
        hasSameIncomeAsBefore,
        insuranceCompany,
    } = yelData;
    return (
        !shouldShowYelNotNeeded(yelData) &&
        ((shouldAskIncome(yelData) &&
            estimatedIncome > 0 &&
            estimatedInvoicing &&
            estimatedInvoicing > 0 &&
            occupation !== '') ||
            (hasYel === 'Yes' && hasSameIncomeAsBefore !== null && Boolean(insuranceCompany)))
    );
};

export const shouldShowExtraCharge = (yelData: YelStoreData) => {
    const now = moment();
    const { dateStarted, hasYel } = yelData;
    const monthsSinceStarted = monthsSince(now, dateStarted);
    return (
        hasYel !== 'Yes' && monthsSinceStarted >= MONTHS_IN_YEAR && monthsSinceStarted < 2 * MONTHS_IN_YEAR
    );
};

export const shouldShowIncomeDetails = (yelData: YelStoreData) => {
    const { estimatedIncome, hasSameIncomeAsBefore, hasYel } = yelData;
    return (
        (shouldAskIncome(yelData) && estimatedIncome > 0) ||
        (hasYel === 'Yes' && hasSameIncomeAsBefore === false)
    );
};

export const shouldShowRenewalNote = (yelData: YelStoreData) => {
    const { hasYel } = yelData;
    return hasYel === 'Yes' || hasYel === 'HasHadBefore';
};

export const shouldShowPayerSelector = (yelData: YelStoreData & Pick<YelData, 'incomeLimits'>) => {
    const insuranceCompanyOk =
        !shouldAskFormerInsuranceCompany(yelData) || yelData.insuranceCompany !== undefined;
    return (
        (shouldShowPaymentNote(yelData) || shouldShowIncomeDetails(yelData)) &&
        insuranceCompanyOk &&
        !shouldShowYelNotNeeded(yelData)
    );
};

export const shouldShowPaymentNote = (yelData: YelStoreData) => {
    const { hasSameIncomeAsBefore } = yelData;
    return hasSameIncomeAsBefore === true;
};

export const shouldShowYelEmergency = (yelData: YelStoreData) => {
    const now = moment();
    const { dateStarted, hasYel } = yelData;
    return hasYel !== 'Yes' && dateStarted && monthsSince(now, dateStarted) >= 2 * MONTHS_IN_YEAR;
};

export const shouldShowNumberOfQuotas = (yelData: YelStoreData) => {
    return yelData.whoPays === 'self';
};

export const getQuotaOptions = () =>
    [1, 2, 3, 4, 6, 12].map((o) => ({
        label: String(o),
        value: String(o),
    }));

export const shouldShowYelNotNeeded = (yelData: YelStoreData & Pick<YelData, 'incomeLimits'>) => {
    const { estimatedIncome, hasSameIncomeAsBefore, hasYel } = yelData;
    return (
        (shouldAskIncome(yelData) || (hasYel === 'Yes' && !hasSameIncomeAsBefore)) &&
        estimatedIncome < yelData.incomeLimits.min &&
        estimatedIncome > 0
    );
};
