import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StatisticsBillingDetail from 'components/statistics/StatisticsBillingDetail';
import StatisticsBillingSuggestions from 'components/statistics/StatisticsBillingSuggestions';
import { cn } from 'utils';
import StatisticsBillingChart from 'components/statistics/StatisticsBillingChart';
import type { DateFilter } from 'utils/statistics/dateUtils';
import { CheckBox } from 'components/ui/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatisticPageFilters from 'components/statistics/StatisticPageFilters';
import type { SalesPageStatisticSuggestion } from 'hooks/statistics/useStatisticSuggestions';
import StatisticBillingSuggestionsMobile from 'components/statistics/StatisticBillingSuggestionsMobile';
import { CHART_COMPARE_COLORS, type ChartColor } from 'constants/statisticConstants';
import { SalesTabEnum } from 'containers/statistics/StatisticsPage';
import { useStatisticsData } from 'hooks/statistics/useStatisticsData';
import type { SalesPageStatisticData } from 'utils/statistics/statisticUtils';

type StatisticsTabProps = {
    activeSalesTab: SalesTabEnum.Billing | SalesTabEnum.EezyPay;
};

export const StatisticsTab = ({ activeSalesTab }: StatisticsTabProps) => {
    const { t } = useTranslation();

    const [selectedGraphs, setSelectedGraphs] = useState<{ dateFilter: DateFilter; color: ChartColor }[]>([]);
    const [withVat, setWithVat] = useState(false);
    const [clientFilter, setClientFilter] = useState<string | null>(null);

    const {
        chartData,
        mainGraphData,
        visibleLabelText,
        dateFilter,
        clients,
        onChangeFilterDateRange,
        minDatePicker,
        loading,
    } = useStatisticsData({
        selectedGraphs,
        withVat,
        useEezyPay: activeSalesTab === SalesTabEnum.EezyPay,
        clientFilter: clientFilter ?? undefined,
    });

    // If time period changes, and the new time period does not include the client.
    useEffect(() => {
        if (clientFilter && !clients.includes(clientFilter)) {
            setClientFilter(null);
        }
    }, [clients]);

    // If we change the time period, we should clear the selected graphs
    useEffect(() => {
        setSelectedGraphs([]);
    }, [dateFilter, activeSalesTab]);

    const onSelectSuggestionRange = (item: SalesPageStatisticSuggestion) => {
        // Check duplicate
        const alreadyExist = chartData.some((i) => i.dateRangeString === item.dateRangeString);
        if (alreadyExist) {
            return;
        }

        // Find unused color
        const color =
            CHART_COMPARE_COLORS.find((c) => !selectedGraphs.some((g) => g.color === c)) || '#0369A1';

        setSelectedGraphs([...selectedGraphs, { dateFilter: item.value, color }]);
    };

    const onRemoveSuggestionRange = (item: SalesPageStatisticData) => {
        setSelectedGraphs(selectedGraphs.filter((g) => g.color !== item.color));
    };

    // We have different components for mobile and desktop, so we define this here and pass it to both.
    const suggestionsComponentProps = {
        dateFilter,
        chartDataDateStrings: [mainGraphData, ...chartData].map((i) => i.dateRangeString),
        onSelect: onSelectSuggestionRange,
        minDate: minDatePicker,
    };

    // This is in different place in mobile view, so we should have it here.
    const vatCheckBox = (
        <CheckBox
            checked={withVat}
            label={t('statistic.stats-include-vat')}
            onCheckedChange={(checked) => setWithVat(!!checked.valueOf())}
        />
    );

    if (loading) {
        return (
            <div className="flex flex-col justify-center items-center gap-4 mt-8">
                <span>{t('statistic.loading-statistics')}</span>
                <FontAwesomeIcon
                    icon={['fas', 'spinner-third']}
                    className="animate-spin size-8 text-violet-600"
                />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col gap-8">
                <div className="flex gap-8 flex-col xl:flex-row">
                    <StatisticPageFilters
                        dateFilter={dateFilter}
                        onChangeDateFilter={onChangeFilterDateRange}
                        clients={clients}
                        clientFilter={clientFilter}
                        onChangeClientFilter={setClientFilter}
                        minDate={minDatePicker}
                        showClientFilter={activeSalesTab === SalesTabEnum.Billing}
                    />

                    <div className="w-px min-w-px hidden xl:block bg-gray-200" />
                    <div className="hidden md:block">
                        <div className="tg-caption-bold text-gray-800 mb-2">{t('statistic.compare')}</div>
                        <StatisticsBillingSuggestions {...suggestionsComponentProps} />
                    </div>
                </div>
                <div className="block md:hidden">{vatCheckBox}</div>
            </div>

            <div className="bg-white md:rounded-2xl md:border md:p-11 md:pt-8 flex flex-col gap-8">
                <div className="md:flex md:justify-between">
                    <div className="flex flex-col md:flex-row gap-7 md:gap-11">
                        <div className="flex justify-between">
                            <StatisticsBillingDetail
                                data={{
                                    ...mainGraphData,
                                    value: mainGraphData.items.reduce(
                                        (acc, i) => acc + (i.values.total ?? 0),
                                        0,
                                    ),
                                }}
                                subtext={visibleLabelText}
                                isMainData
                            />
                            <StatisticBillingSuggestionsMobile {...suggestionsComponentProps} />
                        </div>
                        <div
                            className={cn(
                                'flex gap-4 md:gap-11 pb-4 pt-2 md:pb-0',
                                chartData.length >= 3 &&
                                    'overflow-x-auto md:overflow-x-visible -mr-[26px] md:mr-0',
                            )}
                        >
                            {chartData.map((data) => (
                                <StatisticsBillingDetail
                                    key={data.dateRangeString}
                                    data={{
                                        ...data,
                                        value: data.items.reduce((acc, i) => acc + (i.values.total ?? 0), 0),
                                    }}
                                    onRemove={() => onRemoveSuggestionRange(data)}
                                    canRemove
                                    includeBackgroundOnMobile
                                />
                            ))}
                        </div>
                    </div>
                    <div className="hidden md:block">{vatCheckBox}</div>
                </div>
                <div className="md:mx-0">
                    <StatisticsBillingChart mainGraph={mainGraphData} data={chartData} />
                </div>
            </div>
        </>
    );
};
