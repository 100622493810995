import { useState, type ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import SwitchButton from 'components/Switch';
import { ToolsHeader } from 'components/TogglingTools';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { EezyButton } from 'components/Buttons';
import { UPDATE_USER_TWO_FACTOR_AUTHENTICATION } from './queries';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useUserQuery } from 'queries/useUserQuery';

const FieldWrapper = styled.div`
    margin-top: 15px;
`;

const FieldWrapperInner = styled.div`
    position: relative;
    display: inline-block;
`;

const TWO_FACTOR_AUTHENTICATOR = 'TavoittajaApi';

export const ProfileSettings2FA = () => {
    const { t } = useTranslation();
    const { data: user, refetch } = useUserQuery();
    const [modalEnableOpen, setModalEnableOpen] = useState(false);
    const [modalDisableOpen, setModalDisableOpen] = useState(false);
    const [updateUserTwoFactorAuthentication] = useMutation(UPDATE_USER_TWO_FACTOR_AUTHENTICATION, {
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const has2FAEnabled = user?.twoFactorAuthentication === TWO_FACTOR_AUTHENTICATOR;

    const handleChange = (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            setModalEnableOpen(true);
        } else {
            setModalDisableOpen(true);
        }
    };

    const handleEnable = () => {
        setModalEnableOpen(false);
        updateUserTwoFactorAuthentication({
            variables: {
                input: TWO_FACTOR_AUTHENTICATOR,
            },
            onCompleted: () => {
                refetch();
                toast(t('general.saved'));
            },
        });
    };

    const handleDisable = () => {
        setModalDisableOpen(false);
        updateUserTwoFactorAuthentication({
            variables: {
                input: '',
            },
            onCompleted: () => {
                refetch();
                toast(t('general.saved'));
            },
        });
    };

    return (
        <>
            <FieldWrapper>
                <FieldWrapperInner style={{ width: '100%' }}>
                    <ToolsHeader
                        titleTransKey="profile:settings.two-factor-authentication.label"
                        style={{ fontWeight: 600, lineHeight: '16px' }}
                    >
                        <SwitchButton
                            checked={has2FAEnabled}
                            onChange={handleChange}
                            inputProps={{
                                'aria-label':
                                    t('profile:settings.two-factor-authentication.label', {
                                        defaultValue: 'Default Label',
                                    }) || 'Default Label', // Make sure not null for TypeScript?
                            }}
                        />
                    </ToolsHeader>
                </FieldWrapperInner>
            </FieldWrapper>
            {modalEnableOpen && (
                <Modal
                    id="two-factor-authentication-modal"
                    isOpen={modalEnableOpen}
                    onClose={() => {
                        setModalEnableOpen(false);
                    }}
                    title={t('profile:2fa-modal.title')} // Translation key for the title
                >
                    <ModalContent>
                        <p>
                            {t('profile:2fa-modal.verification-code-sent-to')} <strong>{user?.phone}</strong>
                        </p>
                        <p>{t('profile:2fa-modal.verify-phoneNumber')}</p>

                        {/* New paragraph */}
                        <p>&nbsp;</p>
                        <p>{t('profile:2fa-modal.additional-information')}</p>
                    </ModalContent>
                    <ModalActions>
                        <EezyButton
                            color="purple"
                            onClick={() => {
                                setModalEnableOpen(false);
                            }}
                        >
                            {t('general.cancel')}
                        </EezyButton>
                        <EezyButton color="purple" dark onClick={handleEnable}>
                            {t('profile:2fa-modal.enable-button')}
                        </EezyButton>
                    </ModalActions>
                </Modal>
            )}
            {modalDisableOpen && (
                <Modal
                    id="two-factor-authentication-modal"
                    isOpen={modalDisableOpen}
                    onClose={() => {
                        setModalDisableOpen(false);
                    }}
                    title={t('profile:2fa-modal.title')}
                >
                    <ModalContent>{t('profile:2fa-modal.disable-confirmation')}</ModalContent>
                    <ModalActions>
                        <EezyButton
                            color="purple"
                            onClick={() => {
                                setModalDisableOpen(false);
                            }}
                        >
                            {t('general.cancel')}
                        </EezyButton>
                        <EezyButton color="purple" dark onClick={handleDisable}>
                            {t('profile:2fa-modal.disable-button')}
                        </EezyButton>
                    </ModalActions>
                </Modal>
            )}
        </>
    );
};
