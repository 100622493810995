import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IContainerScrollTop {
    container: HTMLElement;
}

const ContainerScrollTop = (props: IContainerScrollTop) => {
    const buttonHeight = 48; // necessary to calculate button position on screen

    const [visible, setVisible] = useState(false);

    const { t } = useTranslation();

    const scrollToTop = () => {
        props.container.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const checkCanScroll = () => {
            const offset = 100;
            const y = props.container.scrollTop - offset;

            setVisible(y > 0);
        };

        checkCanScroll();

        props.container.addEventListener('scroll', checkCanScroll);

        return () => {
            props.container.removeEventListener('scroll', checkCanScroll);
        };
    }, []);

    return (
        <div className={['absolute bottom-8 right-2', visible ? undefined : 'hidden'].join(' ')}>
            <EezyButton
                className="v2-btn"
                color="purple"
                hasIcon={true}
                iconAlignment="right"
                style={{ height: `${buttonHeight}px` }}
                onClick={scrollToTop}
            >
                {t('utilities.scroll-back-top')}
                <Icon icon={['far', 'arrow-up']} />
            </EezyButton>
        </div>
    );
};

export default ContainerScrollTop;
