import styled from 'styled-components';
import { COLOR_WHITE_WALKER, INVOICE_WIDTH, SCREEN_M, SCREEN_S } from '../../styles/variables';

// The white invoice document
export const WhiteDocumentArea = styled.div<{ disabled?: boolean }>`
    background-color: ${COLOR_WHITE_WALKER};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 15px;
    min-height: 1018px;

    &&:before {
        content: '';
        opacity: 0.5;
        display: ${(props) => (props.disabled ? 'block' : 'none')};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${COLOR_WHITE_WALKER};
        z-index: 9999;
    }

    @media (max-width: ${SCREEN_M}px) {
        padding: 30px;
        width: calc(100% - 30px);
    }
    @media (min-width: ${SCREEN_M}px) {
        padding: 30px 50px 30px 50px;
        margin: 0;
        min-width: ${INVOICE_WIDTH}px;
        max-width: ${INVOICE_WIDTH}px;
    }

    .hidden-sm {
        @media (max-width: ${SCREEN_S}px) {
            display: none;
        }
    }
`;
