/**
 * Creates a debounced function that delays invoking the provided function
 * until after 'wait' milliseconds have elapsed since the last time it was invoked.
 *
 * @param func The function to debounce
 * @param wait The number of milliseconds to delay
 * @returns A debounced version of the provided function with a cancel method
 */
export function debounce<Args extends unknown[], R>(
    func: (...args: Args) => R,
    wait: number,
): ((...args: Args) => void) & { cancel: () => void } {
    let timeout: number;

    const debounced = (...args: Args): void => {
        window.clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            func(...args);
        }, wait);
    };

    debounced.cancel = () => {
        window.clearTimeout(timeout);
    };

    return debounced;
}
