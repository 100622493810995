import styled from 'styled-components';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import { Line2 } from '../../components/Lines';
import { COLOR_WHITE_WALKER } from '../../styles/variables';
import FreeFillActions from './FreeFillActions';
import { CostInvoicePanel } from './InvoiceSalary';

const Panel = styled.div`
    background: ${COLOR_WHITE_WALKER};
    border-radius: 5px;
    padding: 30px 16px;
    margin-top: 20px;
}`;

interface IInvoiceCompletedPanelProps {
    editable: boolean;
    handleAddCostInvoice: () => void;
    invoice: IInvoice;
    isCoworkerInvoice: boolean;
    onCopy?: () => void;
    onEdit?: () => void;
    hasContract?: boolean;
}

const InvoiceCompletedPanel = (props: IInvoiceCompletedPanelProps) => {
    return (
        <Panel>
            <CostInvoicePanel {...props} />
            {!props.isCoworkerInvoice && (
                <>
                    <Line2 />
                    <FreeFillActions invoice={props.invoice} onCopy={props.onCopy} onEdit={props.onEdit} />
                </>
            )}
        </Panel>
    );
};

export default InvoiceCompletedPanel;
