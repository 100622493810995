import type { CSSProperties } from 'react';
import Esc from '../assets/images/esc.svg';
import { COLOR_IMPORTANT } from '../styles/variables';

const EscIcon = (props: { style?: CSSProperties }) => (
    <img
        src={Esc}
        alt="Esc"
        style={{
            backgroundColor: COLOR_IMPORTANT,
            borderRadius: '3px',
            opacity: 0.5,
            padding: '3px 4px',
            verticalAlign: 'sub',
            ...props.style,
        }}
    />
);

export default EscIcon;
