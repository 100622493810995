import { useEffect } from 'react';
import queryString from 'query-string';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'components/Toast';
import { type ApolloError, useApolloClient, useQuery } from '@apollo/client';
import NewUserGuider from 'containers/guider/NewUserGuider';
import InvoiceSendingStatusListener from 'containers/invoice/InvoiceSendingStatusListener';
import VersionMismatch from 'containers/error/VersionMismatch';
import FileUploadError from 'containers/error/FileUploadError';
import MaintenanceBreak from 'containers/error/MaintenanceBreak';
import { useSelector } from 'react-redux';
import type { IRootState } from 'reducers';
import Footer from 'components/Footer';
import GET_DOCUMENTS_USER_MUST_ACCEPT from './dashboard/queries/getDocumentsUserMustAccept';
import { getErrors } from 'utils/apolloErrors';
import { dispatch } from 'utils/store';
import { showModals } from 'actions/auth';
import type { IDocument } from 'utils/user/userUtils';
import WithholdingTax from './withholding-tax/WithholdingTax';
import { useUserQuery } from 'queries/useUserQuery';
import { changeLanguage } from 'utils';

const LoginProtectedComponent = () => {
    const location = useLocation();
    const client = useApolloClient();
    const navigate = useNavigate();
    const { data: userData } = useUserQuery();

    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    const { data, error, startPolling, stopPolling } = useQuery(GET_DOCUMENTS_USER_MUST_ACCEPT, {
        skip: !isLoggedIn,
        onCompleted: (response) => {
            dispatch(showModals(response.userMustAccept.map((t: IDocument) => t.document)));
        },
        onError: (e: ApolloError) => {
            const errors = getErrors(e);

            if (errors?.mustAccept) {
                dispatch(showModals(errors.mustAccept.map((t: IDocument) => t.document)));
            }
        },
    });

    const hasAcceptedAllDocuments = (() => {
        if (data) {
            return !data.userMustAccept.length;
        }
        if (error) {
            const errors = getErrors(error);

            return !errors?.mustAccept?.length;
        }
        return true;
    })();

    // Manage polling based on isLoggedIn and allDocumentsAccepted
    useEffect(() => {
        if (isLoggedIn && !hasAcceptedAllDocuments) {
            startPolling(5000); // Slowed down to 5000ms
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [isLoggedIn, hasAcceptedAllDocuments, startPolling, stopPolling]);

    useEffect(() => {
        // Add the next param in the url if the user is redirected to login
        if (!isLoggedIn) {
            client?.resetStore(); //TODO: test client;
            const urlParams = queryString.parse(location.search);
            const path = location.pathname;
            if (path !== '/' && path !== '/logout' && !urlParams.next) {
                navigate(`/login?next=${path}`);
            } else {
                navigate('/login');
            }
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (!userData) return;

        changeLanguage(userData.language);
    }, [userData]);

    if (!isLoggedIn || !hasAcceptedAllDocuments) {
        return null;
    }

    return (
        <>
            <WithholdingTax />
            <Toast />
            <MaintenanceBreak />
            <FileUploadError />
            <VersionMismatch />
            <InvoiceSendingStatusListener />
            <NewUserGuider />
            <Outlet />
            <Footer />
        </>
    );
};

export const LoginProtected = LoginProtectedComponent;
