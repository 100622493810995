import { Autocomplete, createFilterOptions, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import type { IClient } from '../../../../../shared/src/types/invoice';
import { Flex } from '../../../components/Flex';
import { Checkbox } from '../../../components/form';
import { Icon } from '../../../components/Icon';
import { Label } from '../../../components/textElements';
import { COLOR_GREY_FOG, COLOR_GREYHOUND } from '../../../styles/variables';
import { trans } from '../../../utils';
import { OPERATORS } from '../../../utils/invoice/constants';

interface IOperatorSelection {
    handleChange: (val: string, name: string) => void;
    formData: IClient;
}

const useStyles = makeStyles()({
    input: {
        '& fieldSet': {
            border: 'none',
        },
        borderBottom: `1px solid ${COLOR_GREY_FOG}`,
    },
});

const NameField = styled.div`
    font-weight: 600;
`;

const IdField = styled.div`
    color: ${COLOR_GREYHOUND};
`;

interface IOptions {
    name: string;
    id: string;
}

const OperatorSelection = (props: IOperatorSelection) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const { classes } = useStyles();

    const filterOptions = createFilterOptions<IOptions>({
        stringify: ({ name, id }) => `${name} ${id}`,
    });

    useEffect(() => {
        if (props.formData.operator) {
            setCheckboxChecked(true);
        }
    }, [props.formData.operator]);

    const handleCheckbox = () => {
        setCheckboxChecked(!checkboxChecked);

        if (checkboxChecked) {
            props.handleChange('', 'operator');
        }
    };

    const getDefaultValue = () => {
        if (props.formData.operator) {
            return OPERATORS.filter((obj) => {
                return obj.id === props.formData.operator;
            })[0];
        }
    };

    return (
        <Flex style={{ marginTop: '30px', minHeight: '60px' }} fullWidth center>
            <Flex fullWidth>
                <Checkbox
                    checked={checkboxChecked}
                    onChange={handleCheckbox}
                    label={trans('invoice.form.recipient.select-operator')}
                />
            </Flex>
            {checkboxChecked && (
                <Flex style={{ marginLeft: '30px' }} fullWidth column>
                    <Flex spread fullWidth>
                        <Label color="black">{trans('invoice.form.recipient.operator-list')}</Label>
                    </Flex>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={OPERATORS}
                            onInputChange={(_e, val) => {
                                props.handleChange(val, 'operator');
                            }}
                            defaultValue={getDefaultValue()}
                            noOptionsText={trans('general.no-options')}
                            filterOptions={filterOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.input}
                                    placeholder={trans('invoice.search')}
                                    value={props.formData.operator}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Icon
                                                style={{
                                                    position: 'absolute',
                                                    right: 4,
                                                }}
                                                className="small"
                                                icon={['fal', 'search']}
                                                color="#707070"
                                            />
                                        ),
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props: any, option: any) => {
                                return (
                                    <div {...props} style={{ display: 'block' }}>
                                        <NameField>{option.name}</NameField>
                                        <IdField>{option.id}</IdField>
                                    </div>
                                );
                            }}
                        />
                    </FormControl>
                </Flex>
            )}
        </Flex>
    );
};

export default OperatorSelection;
