import React, { type MouseEvent } from 'react';
import styled from 'styled-components';
import { EezyButton } from '../../components/Buttons';
import { ListItem } from '../../components/layout/List';
import { COLOR_BLUM } from '../../styles/variables';
import { trans } from '../../utils';
import type { ITransaction } from './Transactions';

interface IListItemProps {
    transaction: ITransaction;
    selected?: boolean;
    handleClose?: (e: MouseEvent) => void;
}

const EllipsisWrapper = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
`;

const TransactionsListItem = (props: IListItemProps) => {
    const { transaction, selected, handleClose } = props;

    return (
        <ListItem color={COLOR_BLUM}>
            <div className="item-content">
                <div style={{ color: COLOR_BLUM, minWidth: 0 }}>
                    <EllipsisWrapper style={{ fontWeight: 600 }} data-mf-replace="**REMOVED**">
                        {transaction.firstName} {transaction.lastName}
                    </EllipsisWrapper>
                    <EllipsisWrapper data-mf-replace="**REMOVED**">{transaction.serviceName}</EllipsisWrapper>
                </div>
                {selected && (
                    <div style={{ marginLeft: 'auto' }}>
                        <EezyButton color="purple" dark onClick={handleClose} width={90}>
                            {trans('menu.close')}
                        </EezyButton>
                    </div>
                )}
            </div>
        </ListItem>
    );
};

export default React.memo(TransactionsListItem);
