import { HIDE_CONFIRMATION, SHOW_CONFIRMATION, SHOW_ALERT, SHOW_INTRO, HIDE_ALERT } from '../actionTypes';

export interface IAppState {
    showIntro: boolean;
    confirmationModal?: {
        id: string;
        onAction: () => void;
        texts: {
            title: string;
            submit?: string;
            paragraph?: string;
        };
    };
}

const initialState: IAppState = { showIntro: false };

// biome-ignore lint/style/useDefaultParameterLast: Redux will be eventually removed
export default (state = initialState, action: any) => {
    switch (action.type) {
        case SHOW_ALERT:
            return {
                ...state,
                alertModal: {
                    ...action.payload,
                },
            };
        case HIDE_ALERT:
            return {
                ...state,
                alertModal: undefined,
            };
        case SHOW_CONFIRMATION:
            return {
                ...state,
                confirmationModal: {
                    ...action.payload,
                },
            };
        case HIDE_CONFIRMATION:
            return {
                ...state,
                confirmationModal: undefined,
            };
        case SHOW_INTRO:
            return {
                ...state,
                showIntro: action.payload,
            };
        default:
            return state;
    }
};
