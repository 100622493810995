import type { CSSProperties } from 'react';
import styled from 'styled-components';
import { hAndMinToH, hToHAndMin } from '../../utils/calc';
import { FormNumberInput } from './FormNumberInput';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;

    input {
        text-align: center;
    }
    input:first-of-type {
        margin-right: 10px;
    }
    input ~ input {
        margin-left: 10px;
    }
`;

interface IHoursMinutesProps {
    color?: string;
    error?: any;
    name: string;
    required?: boolean;
    onChange: (val: number | string, name: string) => void;
    style?: CSSProperties;
    value?: number;
}

export const HoursMinutes = (props: IHoursMinutesProps) => {
    const { hours, minutes } = hToHAndMin(props.value);

    const handleChange = (val: number | string, name: string) => {
        const v = typeof val === 'number' ? val : Number.parseInt(val, 10);
        const min = name === 'hours' ? hAndMinToH(v, minutes) : hAndMinToH(hours, v);
        props.onChange(min, props.name);
    };

    return (
        <Wrapper>
            <div>
                <FormNumberInput
                    error={props.error}
                    name={'hours'}
                    placeholder="h"
                    onChange={handleChange}
                    required={props.required}
                    style={props.style}
                    value={props.value ? hours : undefined}
                />
            </div>
            <span>:</span>
            <div>
                <FormNumberInput
                    error={props.error}
                    leadingZero
                    name={'minutes'}
                    onChange={handleChange}
                    placeholder="min"
                    required={props.required}
                    style={props.style}
                    value={props.value ? minutes : undefined}
                />
            </div>
        </Wrapper>
    );
};
