import React from 'react';
import type { IInvoiceItem, IInvoiceItemKeys } from '../../../../../shared/src/types/invoice';
import { Bold } from '../../../components/textElements';
import { formatCents, trans } from '../../../utils';
import InvoiceTableData from './InvoiceTableData';

const TotalPriceWithVat = (props: {
    editable: boolean;
    handleFocus?: () => void;
    handleUpdate: (changedProperty: IInvoiceItemKeys) => void;
    item: IInvoiceItem;
}) => {
    return (
        <InvoiceTableData
            allowNegative
            editable={props.editable}
            endAdornment="€"
            readOnlyContent={trans('form.eurs', {
                eurs: formatCents(props.item.totalPriceWithVat, true),
            })}
            handleFocus={props.handleFocus}
            handleUpdate={props.handleUpdate}
            isEur={true}
            item={props.item}
            name="totalPriceWithVat"
            style={{ width: '80px' }}
            type="number"
            warning={
                !props.item.totalPriceWithVat ? undefined : props.item.totalPriceWithVat < 0 ? (
                    <React.Fragment>
                        <Bold>{trans('invoice.warning-minus-title')}</Bold>
                        <br />
                        {trans('invoice.warning-minus-text')}
                    </React.Fragment>
                ) : undefined
            }
        />
    );
};

export default TotalPriceWithVat;
