import { useQuery } from '@apollo/client';
import { useState } from 'react';
import type { IInvoice, IOccupation } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import { FormRadio, FormSection, FormSelect } from '../../components/form';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { P } from '../../components/textElements';
import { trans } from '../../utils';
import { vatDocumentUrl } from '../../utils/invoice/constants';
import invoiceTemplates, { type IInvoiceTemplate } from '../../utils/invoice/invoiceTemplates';
import validators from '../../utils/invoice/validators';
import { sortObjectsByLabel } from '../../utils/str';
import { formatValidationResult } from '../../utils/validation';
import { GET_OCCUPATIONS } from './queries';
import { useTranslation } from 'react-i18next';

interface IInvoiceTypeModalProps {
    handleModalClose: () => void;
    handleInvoiceUpdate: (o: object) => void;
    invoice?: IInvoice;
    isOpen: boolean;
    selectedTemplate: IInvoiceTemplate;
}

const InvoiceTypeModal = (props: IInvoiceTypeModalProps) => {
    const { i18n } = useTranslation();
    const { handleModalClose, handleInvoiceUpdate, invoice, isOpen } = props;
    const clientType = invoice?.recipient?.type;

    const { data, loading: occupationsLoading } = useQuery(GET_OCCUPATIONS);
    const showArrows = true;

    const [selectedTemplate, setSelectedTemplate] = useState(props.selectedTemplate.id);
    const [selectedOccupation, setSelectedOccupation] = useState(invoice?.occupation?.toString());
    const [isGroupInvoice, setGroupInvoice] = useState(invoice?.isGroupInvoice);

    const occupationOptions =
        data?.allOccupations?.map((opt: IOccupation) => {
            return {
                label: trans(`occupations:${opt.id}`),
                value: opt.id.toString(),
            };
        }) || [];

    const changeOccupation = (occupation: string) => {
        setSelectedOccupation(occupation);
        handleInvoiceUpdate({ occupation: Number.parseInt(occupation, 10) });
    };

    const changeTemplate = (template: string) => {
        setSelectedTemplate(template);
        handleInvoiceUpdate({ template });
    };

    const changeGroupInvoice = (value: boolean) => {
        setGroupInvoice(value);
        handleInvoiceUpdate({ isGroupInvoice: value });
    };

    const occupationError = formatValidationResult(validators.occupation.validate(selectedOccupation));

    const templateError = formatValidationResult(validators.template.validate(selectedTemplate));

    const templateOptions = invoiceTemplates
        .filter((t: IInvoiceTemplate) => !clientType || t.allowedClientTypes.includes(clientType))
        .map((template: IInvoiceTemplate) => {
            return {
                label: trans(template.name),
                value: template.id,
            };
        });

    return (
        <Modal
            id="invoice-type"
            isOpen={isOpen}
            onClose={handleModalClose}
            title={trans('invoice.type-title')}
        >
            <ModalContent>
                {/* todo write error message to replace arrows */}
                <FormSelect
                    error={showArrows && templateError}
                    label={trans('invoice.template-title')}
                    name="invoice-template"
                    onChange={(val: string | number) => {
                        if (typeof val === 'string') {
                            changeTemplate(val);
                        }
                    }}
                    options={templateOptions}
                    required
                    selectStyle={{ maxWidth: 230 }}
                    showIcon
                    splitRow
                    value={selectedTemplate}
                />

                {selectedTemplate === 'domestic' && <P>{trans('invoice.templates.domestic-description')}</P>}

                {selectedTemplate === 'foreign' && (
                    <P>
                        {trans('invoice.templates.foreign-description')}{' '}
                        <a href={vatDocumentUrl(i18n.language)}>
                            {trans('invoice.templates.foreign-description-link-anchor')}
                        </a>
                        .
                    </P>
                )}
                {selectedTemplate === 'eu' && (
                    <P>
                        {trans('invoice.templates.eu-description')}{' '}
                        <a href={vatDocumentUrl(i18n.language)}>
                            {trans('invoice.templates.eu-description-link-anchor')}
                        </a>
                        .
                    </P>
                )}

                {selectedTemplate === 'reverse_vat_construction_industry' && (
                    <P>{trans('invoice.templates.reverse_vat_construction_industry-description')}</P>
                )}

                {/* todo write error message to replace arrows */}
                <FormSelect
                    error={showArrows && occupationError}
                    label={trans('invoice.occupation')}
                    name="invoice-occupation"
                    onChange={(val: string | number) => {
                        if (typeof val === 'string') {
                            changeOccupation(val);
                        }
                    }}
                    options={sortObjectsByLabel(occupationOptions)}
                    optionsLoading={occupationsLoading}
                    required
                    selectStyle={{ maxWidth: 230 }}
                    showIcon
                    splitRow
                    value={selectedOccupation || ''}
                />

                <P>{trans('invoice.occupation-description')}</P>

                <FormSection>
                    <FormRadio
                        label={trans('invoice.groupInvoice.toggle')}
                        name="isGroupInvoice"
                        onChange={(val: string) => changeGroupInvoice(val === 'true')}
                        options={[
                            { label: trans('general.yes'), value: 'true' },
                            { label: trans('general.no'), value: 'false' },
                        ]}
                        value={isGroupInvoice ? 'true' : 'false'}
                    />
                </FormSection>
                <P>{trans('invoice.groupInvoice.description')}</P>
            </ModalContent>
            <ModalActions>
                <span />
                <EezyButton color="purple" dark onClick={handleModalClose}>
                    {trans('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default InvoiceTypeModal;
