import { getLocalStorage, setLocalStorage } from 'utils/user/userUtils';
import type { BannerStoredState } from './types';

const initialState = (): BannerStoredState => ({
    closeTimes: 0,
    closeDate: null,
    visible: true,
});

export const FEATURES_BANNER_MODAL_BULLET_ICONS = ['chart-mixed', 'arrows-cross'];
export const FEATURES_BANNER_MODAL_UPCOMING_BULLET_ICONS = ['/imgs/banners/phone_bullet_point.png', 'coins'];

export const getBannerStoredState = (name: string): BannerStoredState => {
    const stored: BannerStoredState = getLocalStorage(name) || { ...initialState() };

    if (stored.closeDate) {
        stored.closeDate = new Date(stored.closeDate);
    }

    return stored;
};

export const setBannerCloseTimes = (name: string, times: number) => {
    const stored = getBannerStoredState(name);
    stored.closeTimes = times;
    stored.closeDate = new Date();

    setLocalStorage(name, stored);
};

export const toggleBannerVisible = (name: string, visible: boolean) => {
    const stored = getBannerStoredState(name);
    stored.visible = visible;
    setLocalStorage(name, stored);
};

export const resetBannerStoredState = (name: string) => {
    setLocalStorage(name, { ...initialState() });
};
