import styled from 'styled-components';
import { COLOR_BLUM, COLOR_DARK_GRAY, COLOR_GREY_FOG } from '../../styles/variables';
import { tokenize } from '../../utils';

const StyledLabel = styled.label`
    cursor: pointer;
`;

const StyledCheckbox = styled.input.attrs(() => ({
    type: 'checkbox',
}))`
    cursor: pointer;
    opacity: 0;
    position: absolute;

    & + label:before {
        border: 1px solid ${COLOR_GREY_FOG};
        border-radius: 2px;
        content: ' ';
        display: inline-block;
        height: 32px;
        margin-right: 15px;
        vertical-align: middle;
        width: 32px;
    }

    &:checked + label:after {
        border: 2.5px solid ${COLOR_BLUM};
        border-top: none;
        border-right: none;
        content: '';
        height: 4px;
        left: 10px;
        position: absolute;
        top: 12px;
        transform: rotate(-45deg);
        width: 14px;
    }
`;

const CheckboxWrapper = styled.div`
    color: ${(props) => (props.color ? props.color : COLOR_DARK_GRAY)};
    position: relative;
    left: 8px;
`;

interface ICheckboxProps {
    checked: boolean;
    color?: string;
    id: string;
    onChange: () => void;
}

export const CheckboxForSubscriptionTable = (props: ICheckboxProps) => {
    return (
        <CheckboxWrapper color={props.color}>
            <StyledCheckbox id={tokenize(props.id)} checked={props.checked} onChange={props.onChange} />
            <StyledLabel htmlFor={tokenize(props.id)}>{''}</StyledLabel>
        </CheckboxWrapper>
    );
};
