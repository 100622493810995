import { type ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import type { IAddress } from '../../../../../shared/src/types/address';
import type { IClient, IContactPerson, IInvoice } from '../../../../../shared/src/types/invoice';
import { EezyButton } from '../../../components/Buttons';
import ErrorBox from '../../../components/ErrorBox';
import LoadingSpinner from '../../../components/Loading';
import { trans } from '../../../utils';
import { getErrors } from '../../../utils/apolloErrors';
import { cleanRecipientBeforeSending, clientToClientInput } from '../../../utils/invoice/invoiceLogic';
import validators from '../../../utils/invoice/validators';
import {
    findSelectedContactPersonId,
    initialRecipientForm,
    type IRecipientFormState,
    recipientReducer,
} from '../../../utils/recipient/recipientLogic';
import type { IDocument } from '../../../utils/user/userUtils';
import { formatValidationResult } from '../../../utils/validation';
import {
    GET_INVOICE,
    GET_INVOICE_RECIPIENTS,
    UPDATE_INVOICE_WITH_RECIPIENT,
    UPDATE_RECIPIENT,
} from '../queries';
import GET_YTJ_COMPANY from '../queries/getYtjCompany';
import { RECIPIENT_FH_ERRORS_VISIBLE } from '../queries/invoiceStateQueries';
import FillHelper from './FillHelper';
import RecipientForm from './RecipientForm';
import { closeAllFillHelpers } from './utils';
import YtjDiff from './YtjDiff';

interface IRecipientFillHelper {
    invoiceId?: number;
    showModals?: (types: string[]) => void;
    readOnly?: boolean;
    recipientId: string;
}

const EditRecipientFillHelper = (props: IRecipientFillHelper) => {
    const recipientId = props.recipientId;

    const { client: apolloClient, data: localData } = useQuery(RECIPIENT_FH_ERRORS_VISIBLE);
    const showErrors = localData?.recipientFHErrorsVisible;

    const { data: invoiceData } = useQuery(GET_INVOICE, {
        variables: { id: props.invoiceId },
    });
    const invoice: IInvoice | undefined = invoiceData?.invoice;

    const [getYtjData, { data: ytjData }] = useLazyQuery(GET_YTJ_COMPANY);
    const ytjCompany: IClient | undefined = ytjData?.ytjCompany;

    const [{ recipient, selectedContactPersonIndex }, dispatch]: [IRecipientFormState, React.Dispatch<any>] =
        React.useReducer(recipientReducer, initialRecipientForm);

    const [errors, setErrors] = React.useState<any>(null);

    const [updateInvoiceWithRecipient] = useMutation(UPDATE_INVOICE_WITH_RECIPIENT, {
        onCompleted: () => {
            toast(trans('general.saved'));
        },
        onError: (e: ApolloError) => {
            const err = getErrors(e);
            if (err?.mustAccept && props.showModals) {
                props.showModals(err?.mustAccept.map((t: IDocument) => t.document));
            } else {
                toast.error(trans('errors.general'));
            }
        },
    });

    const [updateRecipient, { loading: updateLoading }] = useMutation(UPDATE_RECIPIENT, {
        onCompleted: (data) => {
            updateInvoiceWithRecipient({
                variables: {
                    contactPersonId: findSelectedContactPersonId(
                        selectedContactPersonIndex,
                        recipient.contactPeople,
                        data?.updateClient?.contactPeople,
                    ),
                    id: props.invoiceId,
                    recipient: {
                        id: data?.updateClient.id,
                        type: data?.updateClient.type,
                    },
                },
            });
            closeAllFillHelpers(apolloClient);
        },
        onError: (e: ApolloError) => {
            const err = getErrors(e);
            if (err?.mustAccept && props.showModals) {
                props.showModals(err?.mustAccept.map((t: IDocument) => t.document));
            } else {
                toast.error(trans('errors.general'));
            }
        },
        refetchQueries: () => [{ query: GET_INVOICE_RECIPIENTS }],
    });

    React.useEffect(() => {
        if (invoice?.recipient?.type === 'company') {
            getYtjData({
                variables: {
                    businessId: invoice?.recipient?.businessId,
                },
            });
        }
    }, [getYtjData, props.recipientId]);

    React.useEffect(() => {
        if (recipientId === invoice?.recipient?.id) {
            dispatch({
                payload: {
                    recipient: invoice?.recipient,
                    selectedContactPersonId: invoice?.contactPersonId,
                },
                type: 'INIT',
            });
        }
    }, [recipientId, invoice]);

    React.useEffect(() => {
        setErrors(formatValidationResult(validators.recipient.validate(recipient)));
    }, [recipient]);

    const handleChange = (val: string, name: string) => {
        dispatch({ type: 'UPDATE_RECIPIENT', payload: { [name]: val } });
    };

    const handleAddressChange = (val: string, name: string) => {
        dispatch({ type: 'UPDATE_ADDRESS', payload: { [name]: val } });
    };

    const handleContactPeopleChange = (contactPeople: IContactPerson[], selectedCPIndex?: number) => {
        dispatch({
            payload: {
                contactPeople,
                selectedContactPersonIndex: selectedCPIndex,
            },
            type: 'UPDATE_CONTACT_PEOPLE',
        });
    };

    const handleSave = () => {
        const r = cleanRecipientBeforeSending(clientToClientInput(recipient));
        const validationErrors = formatValidationResult(validators.recipient.validate(r));
        setErrors(validationErrors);
        if (validationErrors) {
            apolloClient.writeQuery({
                data: { recipientFHErrorsVisible: true },
                query: RECIPIENT_FH_ERRORS_VISIBLE,
            });
        } else {
            apolloClient.writeQuery({
                data: { recipientFHErrorsVisible: false },
                query: RECIPIENT_FH_ERRORS_VISIBLE,
            });
            updateRecipient({
                variables: {
                    recipient: r,
                    recipientId,
                },
            });
        }
    };

    return (
        <FillHelper
            renderMenuRight={() => (
                <EezyButton color="purple" onClick={handleSave}>
                    {updateLoading ? <LoadingSpinner size="1em" /> : trans('general.save')}
                </EezyButton>
            )}
        >
            {showErrors && errors && <ErrorBox>{trans('errors.recipient-incomplete')}</ErrorBox>}

            {ytjCompany && ytjCompany?.businessId === recipient?.businessId && (
                <YtjDiff
                    formData={recipient}
                    handleAddressChange={(address?: IAddress) => {
                        dispatch({
                            payload: address,
                            type: 'UPDATE_ADDRESS',
                        });
                    }}
                    handleChange={handleChange}
                    ytjData={clientToClientInput(ytjCompany)}
                />
            )}

            <RecipientForm
                readOnly={props.readOnly}
                recipientId={recipientId}
                handleChange={handleChange}
                handleAddressChange={handleAddressChange}
                handleContactPersonsChange={handleContactPeopleChange}
                handleSave={handleSave}
                invoiceId={props.invoiceId}
                errors={errors}
                formData={{ recipient, selectedContactPersonIndex }}
                showErrors={showErrors}
                showModals={props.showModals}
            />
        </FillHelper>
    );
};

export default React.memo(EditRecipientFillHelper);
