import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CampaignButton } from '../constants';
import MiniCampaignBanner from '../MiniCampaignBanner';
import { getBannerStoredState, setBannerCloseTimes, toggleBannerVisible } from '../utils';
import { BannerStoreKeyName } from './constants';

const EezyDataBanner = () => {
    const { t } = useTranslation();
    const [isTempClose, setTempClose] = useState(false);
    const from = t('banner.release-features.from-date');
    const to = t('banner.release-features.to-date');
    const image = '/imgs/banners/campaign_banner_lg.png';
    const title = t('banner.release-features.title');
    const description = t('banner.release-features.description');
    const shortDescription = t('banner.release-features.short-description');

    const buttons = t('banner.release-features.buttons', { returnObjects: true }) as CampaignButton[];

    if (isTempClose) {
        return null;
    }

    const inPeriod = moment().isBetween(from, to, 'day', '[]');

    if (!inPeriod) {
        return null;
    }

    const stored = getBannerStoredState(BannerStoreKeyName);

    if (!stored.visible || stored.closeTimes > 0) {
        return null;
    }

    const onClose = () => {
        setTempClose(true);
        setBannerCloseTimes(BannerStoreKeyName, stored.closeTimes + 1);
        toggleBannerVisible(BannerStoreKeyName, false);
    };

    const handleButtonClick = (item: CampaignButton) => {
        if (item.value.startsWith('http')) {
            window.open(item.value, '_blank');
        } else if (item.value === 'open_modal') {
            // Handle modal opening logic here
            // Implement modal opening logic
        } else {
            // Handle internal routes
            // You might want to use react-router's navigate here
            // Implement navigation logic
        }
    };

    return (
        <MiniCampaignBanner
            image={image}
            title={title}
            description={description}
            shortDescription={shortDescription}
            buttons={buttons}
            onClose={onClose}
            onClickButton={handleButtonClick}
            onClickReadMore={() => {
                const readMoreButton = buttons.find((b) => b.type === 'default') || buttons[0];
                handleButtonClick(readMoreButton);
            }}
        />
    );
};
export default EezyDataBanner;
