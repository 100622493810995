import moment from 'moment';
import { formatDate } from 'utils/time';

// Same as DateRange but with from and to as mandatory
export type DateFilter = {
    from: Date;
    to: Date;
};

const timeRangeObject = {
    this_month: () => ({
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate(),
    }),
    last_month: () => ({
        from: moment().subtract(1, 'months').startOf('month').toDate(),
        to: moment().subtract(1, 'months').endOf('month').toDate(),
    }),
    this_quarter: () => ({
        from: moment().startOf('quarter').toDate(),
        to: moment().endOf('quarter').toDate(),
    }),
    last_quarter: () => ({
        from: moment().subtract(1, 'quarters').startOf('quarter').toDate(),
        to: moment().subtract(1, 'quarters').endOf('quarter').toDate(),
    }),
    this_year: () => ({
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate(),
    }),
    last_year: () => ({
        from: moment().subtract(1, 'years').startOf('year').toDate(),
        to: moment().subtract(1, 'years').endOf('year').toDate(),
    }),
};

export type TimeRange = keyof typeof timeRangeObject;

export const timeRanges = Object.keys(timeRangeObject).map((key) => ({
    id: key as TimeRange,
    dateRange: timeRangeObject[key as TimeRange],
}));

type DateUtilOptions = {
    dateFilter: DateFilter;
    maxDate?: Date;
};

export const getTimeRanges = (options: DateUtilOptions) => {
    const timePeriods = timeRanges.filter(({ dateRange }) => {
        const range = dateRange();
        const toCompare = options.maxDate ? moment.min(moment(options.maxDate), moment(range.to)) : range.to;
        return (
            moment(options.dateFilter.from).isSame(range.from, 'date') &&
            moment(options.dateFilter.to).isSame(toCompare, 'date')
        );
    });

    return timePeriods.map((p) => p.id);
};

const isThisMonth = (options: DateUtilOptions) => getTimeRanges(options).includes('this_month');
export const isFullMonth = (options: DateUtilOptions) =>
    (moment(options.dateFilter.from).isSame(moment(options.dateFilter.from).startOf('month'), 'date') &&
        moment(options.dateFilter.to).isSame(moment(options.dateFilter.to).endOf('month'), 'date') &&
        moment(options.dateFilter.from).isSame(options.dateFilter.to, 'month')) ||
    isThisMonth(options);

const isThisYear = (options: DateUtilOptions) => getTimeRanges(options).includes('this_year');
export const isFullYear = (options: DateUtilOptions) =>
    (moment(options.dateFilter.from).isSame(moment(options.dateFilter.from).startOf('year'), 'date') &&
        moment(options.dateFilter.to).isSame(moment(options.dateFilter.to).endOf('year'), 'date') &&
        moment(options.dateFilter.from).isSame(options.dateFilter.to, 'year')) ||
    isThisYear(options);

const isThisQuarter = (options: DateUtilOptions) => getTimeRanges(options).includes('this_quarter');
export const isFullQuarter = (options: DateUtilOptions) =>
    (moment(options.dateFilter.from).isSame(moment(options.dateFilter.from).startOf('quarter'), 'date') &&
        moment(options.dateFilter.to).isSame(moment(options.dateFilter.to).endOf('quarter'), 'date') &&
        moment(options.dateFilter.from).isSame(options.dateFilter.to, 'quarter')) ||
    isThisQuarter(options);

export const getTimeLabel = (options: DateUtilOptions) => {
    if (isFullYear(options)) return formatDate(options.dateFilter.from, 'yyyy');
    if (isFullQuarter(options)) {
        return `${formatDate(options.dateFilter.from, 'yyyy')} Q${moment(options.dateFilter.from).quarter()}`;
    }
    if (isFullMonth(options)) return formatDate(options.dateFilter.from, 'MMMM yyyy');

    return `${formatDate(options.dateFilter.from, 'DD.MM.yyyy')} - ${formatDate(
        options.dateFilter.to,
        'DD.MM.yyyy',
    )}`;
};

export const isWithinSingleMonth = (dateFilter: DateFilter) =>
    moment(dateFilter.from).isSame(dateFilter.to, 'month') &&
    moment(dateFilter.from).isSame(dateFilter.to, 'year');

export const getPeriod = (options: DateUtilOptions) => {
    if (isFullYear(options)) return 'year';
    if (isFullQuarter(options)) return 'quarter';
    if (isFullMonth(options)) return 'month';

    return null;
};

export const yearDateRange = (year: number) => ({
    from: moment().year(year).startOf('year').toDate(),
    to: moment().year(year).endOf('year').toDate(),
});

export const monthDateRange = (year: number, month: number) => ({
    from: moment().year(year).month(month).startOf('month').toDate(),
    to: moment().year(year).month(month).endOf('month').toDate(),
});

export function dateRangeToString(dateRange: DateFilter) {
    return `${moment(dateRange.from).format('YYYY-MM-DD')} - ${moment(dateRange.to).format('YYYY-MM-DD')}`;
}
