import type { FC, ReactNode } from 'react';
import { TwoColumnView } from 'components/layout/TwoColumnView';
import MessageList from './MessageList';

interface IProps {
    customerServiceOpen?: boolean;
    children: ReactNode;
}

const Support: FC<IProps> = ({ customerServiceOpen, children }) => {
    return (
        <TwoColumnView>
            <div>
                <MessageList serviceOpen={customerServiceOpen} />
            </div>
            <div className="hide-scroll">{children}</div>
        </TwoColumnView>
    );
};

export default Support;
