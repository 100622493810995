import type { ReactElement } from 'react';
import styled from 'styled-components';

interface ICardTitle {
    title: string;
    subIcon?: ReactElement;
    subtitle?: string;
}

const TitleElement = styled.h3`
    color: #351F65;
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
}
`;

const CardTitle = (props: ICardTitle) => {
    const visibleSubtitle = !!props.subtitle || !!props.subIcon;
    return (
        <div className="flex flex-col md:flex-row gap-4 md:items-center">
            <TitleElement>{props.title}</TitleElement>
            {visibleSubtitle && (
                <div className="flex gap-1.5">
                    {props.subIcon}
                    <span className="text-xs">{props.subtitle}</span>
                </div>
            )}
        </div>
    );
};

export default CardTitle;
