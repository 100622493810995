import { gql } from '@apollo/client';

const REGISTER_USER_EVENT = gql`
    mutation RegisterUserEvent($event: UserEventInput!) {
        registerUserEvent(userEvent: $event) {
            id,
            created
        }
    }
`;

export default REGISTER_USER_EVENT;
