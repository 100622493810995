import Numeral from 'numeral';
import * as R from 'ramda';
import { divide, vatFactor } from './calc';
import { zeroOrVal } from './common';

export const centsToEur: (cents: number) => number = R.curry(zeroOrVal)(
    R.pipe(Math.round, R.divide(R.__, 100)),
);

const centsToEurWithoutRounding: (cents: number) => number = R.curry(zeroOrVal)(R.pipe(R.divide(R.__, 100)));

export const eursToCents: (eurs: number) => number = R.curry(zeroOrVal)(R.pipe(R.multiply(100), Math.round));

export const toYearly = (no: number) => {
    return no * 12;
};

export const percentOf = (percentage: number, value: number) => {
    return (percentage / 100) * value;
};

export const percentOff = (percentage: number, value: number): number => {
    return divide(value, vatFactor(percentage));
};

export const formatMoney = (money: number): string => {
    return Numeral(money).format('0,0[.]00');
};

export const formatMoneyWithTwoDecimals = (money = 0): string => {
    return Numeral(money).format('0,0.00');
};

const formatMoneyWithAllDecimals = (money = 0): string => {
    // arbitrary number of optional decimals in the square brackets to improve
    // calculation accuracy
    return Numeral(money).format('0,0.00[000000000000]');
};

export const formatCents = (cents = 0, showTwoDecimals?: boolean): string => {
    if (showTwoDecimals) {
        return formatMoneyWithTwoDecimals(centsToEur(cents));
    }
    return formatMoney(centsToEur(cents));
};

export const formatCentsWithoutRounding = (cents = 0): string => {
    return formatMoneyWithAllDecimals(centsToEurWithoutRounding(cents));
};

export const formatPercentage = (percentage: number): string => {
    // Prevent NaN error for small numbers https://github.com/adamwdraper/Numeral-js/issues/563
    return Numeral(percentage < 0.001 ? 0 : percentage).format('0[.]0');
};

export const formatFloat = (float: number): string => {
    return Numeral(float).format('0,0[.]0[0]');
};

export const priceWithoutVat = (price: number, vat: number) => {
    const vatDecimal = vat / 100;
    return price / (1 + vatDecimal);
};
