import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EezyButton } from '../../components/Buttons';
import ErrorBox from '../../components/ErrorBox';
import { Icon } from '../../components/Icon';
import { COLOR_WHITE_WALKER } from '../../styles/variables';
import { formatCents, trans } from '../../utils';
import type { ISalesPage } from './SalesPageCreate';
import { QRCodeContainer, ShareWrapper } from './SalesPages';
import TwoColumnCard from './TwoColumnCard';

interface ISalesPageDetailsProps {
    page: ISalesPage;
    qrCodeUrl?: string;
    handleCopyLinkClick?: () => void;
    handleDeleteClick?: () => void;
    subscriptionExpired?: boolean;
}

const DetailsRow = styled.div<{ column?: boolean }>`
    display: flex;
    margin-bottom: 14px;
    ${(props) => props.column && 'flex-direction: column'}
`;

const DetailsValue = styled.div<{ left?: boolean }>`
    margin-left: ${(props) => (!props.left ? 'auto' : '0')};
`;

const SalesPageDetails = (props: ISalesPageDetailsProps) => {
    const { page, qrCodeUrl, handleCopyLinkClick, handleDeleteClick, subscriptionExpired } = props;
    const amountOfPayments = page.transactions_aggregate.aggregate.count;
    const navigate = useNavigate();

    return (
        <TwoColumnCard
            title={page.serviceName}
            column1={
                <>
                    {page.state === 0 && (
                        <ErrorBox style={{ margin: 50 }}>{trans('eezyPay:closed')}</ErrorBox>
                    )}
                    {page.state === 2 && (
                        <ErrorBox style={{ margin: 50 }}>{trans('eezyPay:waiting-for-approval')}</ErrorBox>
                    )}
                    <DetailsRow>
                        <div>{trans('eezyPay:price')}: </div>
                        <DetailsValue>
                            {trans('form.eurs', {
                                eurs: formatCents(page.servicePrice, true),
                            })}
                        </DetailsValue>
                    </DetailsRow>
                    {page.serviceDescription && (
                        <DetailsRow column>
                            <div>{trans('eezyPay:description')}:</div>
                            <DetailsValue left data-mf-replace="**REMOVED**">
                                {page.serviceDescription}
                            </DetailsValue>
                        </DetailsRow>
                    )}
                    <DetailsRow>
                        <div>{trans('eezyPay:payments-amount')}: </div>
                        <DetailsValue>{amountOfPayments}</DetailsValue>
                    </DetailsRow>
                    <div style={{ marginTop: '70px' }}>
                        <EezyButton
                            color="purple"
                            transparent
                            onClick={() => {
                                navigate(`/sales-pages/edit/${page.publicPath}`);
                            }}
                            disabled={page.state === 0 || subscriptionExpired}
                            style={{ marginBottom: '14px', width: '100%' }}
                        >
                            {trans('eezyPay:edit-page')}
                        </EezyButton>
                        <EezyButton
                            color="purple"
                            transparent
                            onClick={handleDeleteClick}
                            style={{ marginLeft: 0, width: '100%' }}
                        >
                            {trans('eezyPay:delete-page')}
                        </EezyButton>
                    </div>
                </>
            }
            column2={
                <ShareWrapper>
                    <QRCodeContainer>
                        <img src={qrCodeUrl} alt="" />
                    </QRCodeContainer>
                    <EezyButton
                        style={{ width: '100%', textAlign: 'left' }}
                        color="purple"
                        dark
                        disabled={page.state !== 1 || subscriptionExpired}
                        iconAlignment="right"
                        onClick={handleCopyLinkClick}
                    >
                        <Icon
                            color={COLOR_WHITE_WALKER}
                            icon={['fas', 'link']}
                            style={{
                                marginRight: 5,
                                marginTop: 6,
                                fontWeight: 600,
                            }}
                        />
                        {trans('eezyPay:copy-link')}
                    </EezyButton>
                </ShareWrapper>
            }
        />
    );
};

export default SalesPageDetails;
