import { gql } from '@apollo/client';

const SEND_COST_INVOICE = gql`
    mutation SendCostInvoice($id: Int!) {
        sendCostInvoice(costInvoiceId: $id) {
            id
            status
        }
    }
`;

export default SEND_COST_INVOICE;
