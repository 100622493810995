import { gql } from '@apollo/client';

const GET_BACKEND_MESSAGE = gql`
    query BackendMessage {
        backendMessage {
            duration
            time
            type
        }
    }
`;

export default GET_BACKEND_MESSAGE;
