import { useQuery } from '@apollo/client';
import React, { type CSSProperties } from 'react';
import { connect } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { uploadTaxCard } from '../../actions/file';
import FileInput from '../../components/form/FileInput';
import { Icon } from '../../components/Icon';
import LoadingSpinner from '../../components/Loading';
import { RowTitle, Span } from '../../components/textElements';
import type { ITempFile } from '../../reducers/fileReducer';
import { COLOR_BLACKWATER, COLOR_BLUM } from '../../styles/variables';
import { trans } from '../../utils';
import { GET_USER_DATA } from '../profile/queries';

const DashboardLabel = styled(RowTitle)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

interface IProps {
    fileLoading: boolean;
    hideLabel?: boolean;
    style?: CSSProperties;
    taxCardFile: ITempFile | null;
    uploadTaxCard: (data: FormData) => void;
}

const UploadTaxCard = (props: IProps) => {
    const { data: userData, refetch: refetchUser } = useQuery(GET_USER_DATA);

    const handleUploadTaxCard = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];
            const uploadData = new FormData();
            uploadData.append('file', newFile);
            props.uploadTaxCard(uploadData);
        }
    };

    React.useEffect(() => {
        // props.uploadTaxCard() adds the file in redux store
        // and that fires the data refetch...
        refetchUser();
    }, [refetchUser, props.taxCardFile]);

    return (
        <DashboardLabel color={COLOR_BLACKWATER} style={props.style}>
            {!props.hideLabel && <Span>{trans('salaries:taxCard.label')}</Span>}
            <FileInput
                accept=".pdf, image/jpeg, image/png"
                color={COLOR_BLUM}
                disabled={!!userData?.userData?.taxCardUploadedAt}
                onChange={handleUploadTaxCard}
                style={{
                    backgroundColor: 'transparent',
                    borderColor: COLOR_BLUM,
                    borderRadius: 100,
                    color: COLOR_BLUM,
                    minWidth: 165,
                }}
            >
                {props.fileLoading ? (
                    <LoadingSpinner color={COLOR_BLUM} size="1em" />
                ) : (
                    <Icon icon={['far', 'arrow-to-top']} color={COLOR_BLUM} />
                )}{' '}
                <span aria-hidden>{trans('salaries:taxCard.button')}</span>
                <span style={{ display: 'none' }}>{trans('salaries:taxCard.button')}</span>
            </FileInput>
        </DashboardLabel>
    );
};

const mapStateToProps = (state: any) => {
    return {
        fileLoading: state.file.loading,
        taxCardFile: state.file.taxCard,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        uploadTaxCard: (data: FormData) => {
            dispatch(uploadTaxCard(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadTaxCard);
