import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { MobileRowButton } from '../../../components/Buttons';
import { Icon } from '../../../components/Icon';
import { SectionTitle } from '../../../components/textElements';
import { COLOR_WHITE_WALKER, FOCUS_COLOR } from '../../../styles/variables';
import { trans } from '../../../utils';
import FillHelper from './FillHelper';
import { openFillHelper } from './utils';

const StyledButton = styled(MobileRowButton)`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${COLOR_WHITE_WALKER};
    height: 40;
    margin-top: 40px;

    &:focus {
        border-color: ${FOCUS_COLOR};
    }
`;

interface IRecipientFillHelperProps {
    invoiceId?: number;
    recipientId: string;
}

const RecipientFillHelperChangeOrEdit = (props: IRecipientFillHelperProps) => {
    const client = useApolloClient();

    const handleNew = () => {
        openFillHelper(client, 'new-recipient');
    };

    const handleEdit = () => {
        openFillHelper(client, 'recipient', props.recipientId);
    };

    return (
        <FillHelper>
            <SectionTitle color="white">{trans('invoice.form.recipient.what-to-do')}</SectionTitle>
            <StyledButton color="white" onClick={handleNew}>
                {trans('invoice.form.recipient.change-recipient')}
                <Icon icon={['far', 'chevron-right']} color="white" />
            </StyledButton>
            <StyledButton color="white" onClick={handleEdit}>
                {trans('invoice.form.recipient.edit-recipient')}
                <Icon icon={['far', 'chevron-right']} color="white" />
            </StyledButton>
        </FillHelper>
    );
};

export default RecipientFillHelperChangeOrEdit;
