import { useEffect, type FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/Flex';
import LoadingSpinner from 'components/Loading';
import { H1, PageWrapper } from 'styles/yelStyles';
import { useYelData } from 'queries/useYelData';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from 'utils/API';
import { ERROR_GENERAL_YEL, getError } from 'utils/error';
import { AxiosError } from 'axios';
import useYelStore from 'stores/yelStore';

type AuthType = 'createYel' | 'terminateYel' | 'transferYel' | 'updateYel' | 'yelWithdrawal';

interface IYelTempProps {
    authAction: AuthType;
}

const YelAuthCallback: FC<IYelTempProps> = ({ authAction }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, error, setError } = useYelData();
    const queryclient = useQueryClient();
    const updateYelStore = useYelStore((state) => state.updateYelStore);

    const authorizeYel = useMutation({
        mutationFn: async (authAction: AuthType) => {
            try {
                const response = await API.post(`yel/auth?action=${authAction}`);
                if (response.status >= 200 && response.status < 300) {
                    switch (authAction) {
                        case 'createYel':
                            updateYelStore({ justAuthorized: true });
                            break;
                        case 'terminateYel':
                            updateYelStore({ justTerminated: true });
                            break;
                        case 'transferYel':
                            updateYelStore({ justAuthorized: true });
                            break;
                        case 'updateYel':
                            updateYelStore({ justUpdated: true });
                            break;
                        case 'yelWithdrawal':
                            updateYelStore({ justWithdrew: true });
                            break;
                    }

                    navigate('/yel');
                    queryclient.invalidateQueries({ queryKey: ['yelData'] });
                } else {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }

                throw error;
            }
        },
    });

    useEffect(() => {
        authorizeYel.mutate(authAction);
    }, []);

    if (error) {
        return (
            <PageWrapper>
                <H1>{error?.title}</H1>
                <Flex column style={{ padding: 10 }}>
                    <p>
                        <Link to="/yel">{t('menu.back-frontpage')}</Link>
                    </p>
                </Flex>
            </PageWrapper>
        );
    }
    if (loading) {
        return (
            <PageWrapper>
                <LoadingSpinner />
            </PageWrapper>
        );
    }
    return null;
};

export default YelAuthCallback;
