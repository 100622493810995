import { validator as v } from '../validation';
import { EMAIL_REGEX } from './../validation';

export const registrationValidator = v.object({
    bonusCode: v.string().max(256).allow('').optional(),
    email: v.string().regex(EMAIL_REGEX).required(),
    firstName: v.string().max(256).min(1).required(),
    language: v.string().required(),
    lastName: v.string().max(256).min(1).required(),
    phone: v.string(),
    phoneCountryCode: v.number(),
    referralInfo: v.string().max(256).allow('').optional(),
    referralOther: v.string().max(256).allow('').optional(),
});

const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9])/; // has a number and a letter

export const resetPasswordValidator = v.object({
    email: v.string().regex(EMAIL_REGEX).required(),
    password: v.string().regex(PASSWORD_REGEX).min(8).required(),
    password2: v.valid(v.ref('password')).required(),
});

export const changePasswordValidator = v.object({
    password: v.string().required(),
    password1: v.string().regex(PASSWORD_REGEX).min(8).required(),
    password2: v.valid(v.ref('password1')).required(),
});
