const EezyPayTermsEn = () => {
    return (
        <div>
            <div>
                <p>
                    <b>Contents</b>
                </p>
                <ul>
                    <li>
                        <a href="#point1">1. Definitions</a>
                    </li>
                    <li>
                        <a href="#point2">2. General information about using the Service</a>
                    </li>
                    <li>
                        <a href="#point3">3. Service Provider and contact information</a>
                    </li>
                    <li>
                        <a href="#point4">4. Using the Service</a>
                    </li>
                    <ul>
                        <li>
                            <a href="#point4.1">
                                4.1 Rights and restrictions applicable to the use of the Service
                            </a>
                        </li>
                        <li>
                            <a href="#point4.2">4.2 Changes to the Service and termination</a>
                        </li>
                        <li>
                            <a href="#point4.3">4.3 Registration</a>
                        </li>
                        <li>
                            <a href="#point4.4">4.4 Invoicing and payment of wages</a>
                        </li>
                        <li>
                            <a href="#point4.5">4.5 Price of the Service</a>
                        </li>
                        <li>
                            <a href="#point4.6">4.6 Payment</a>
                        </li>
                        <li>
                            <a href="#point4.7">4.7 Payment methods</a>
                        </li>
                    </ul>
                    <li>
                        <a href="#point5">5. Limitations of liability</a>
                    </li>
                    <li>
                        <a href="#point6">6. Amendments to the Terms and Conditions and notifications</a>
                    </li>
                    <li>
                        <a href="#point7">7. Force majeure</a>
                    </li>
                    <li>
                        <a href="#point8">8. Other terms and conditions</a>
                    </li>
                </ul>
            </div>

            <div id="point1">
                <p>
                    <b>1. Definitions</b>
                </p>
                <div className="p1">
                    <p>
                        In these Terms and Conditions, the following words and expressions have the meanings
                        below, unless otherwise expressly stated or context otherwise indicates:
                    </p>
                    <ul>
                        <li>
                            <p>1. “Eezy” stands for Eezy Light Entrepreneurs Cooperative</p>
                        </li>
                        <li>
                            <p>2. “Service” refers to the EezyPay Service</p>
                        </li>
                        <li>
                            <p>
                                3. “Performer of work” means a light entrepreneur using Eezy’s service, who
                                uses the EezyPay Service to receive payments from their Clients
                            </p>
                        </li>
                        <li>
                            <p>
                                4. “Client” means the Client of the Performer of work who has ordered the
                                service from the Performer of work and pays it through the Service
                            </p>
                        </li>
                        <li>
                            <p>
                                5. “Terms and Conditions” mean the Terms and Conditions of Use of the Eezy
                                Light Entrepreneurs/EezyPay Service
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div id="point2">
                <p>
                    <b>2. General information about using the Service</b>
                </p>
                <div className="p1">
                    <p>
                        By using the Eezy’s Service, the Performer of work accepts the current Terms and
                        Conditions of the EezyPay Service and undertakes to follow them as well as the Terms
                        and Conditions of the Eezy Light Entrepreneurs Service and the terms and guidelines
                        given separately in the Service. The Performer of work must approve these Terms and
                        Conditions when registering. The Performer of work undertakes to use the Service only
                        for the purpose for which the Service is intended.
                    </p>
                    <p>
                        These Terms and Conditions apply to all activities of the Performer of work in the
                        Service when the Performer of work uses the EezyPay Service. The Performer of work may
                        only use the Service in accordance with the Terms and Conditions valid at each time,
                        applicable legislation and generally accepted practices. The Terms and Conditions
                        constitute the agreement between the Performer of work and Eezy, and specify the
                        rights and obligations of Eezy and the Performer of work concerning the Service.
                    </p>
                    <p>
                        The use of the Service for illegal, inappropriate or competitive purposes is
                        prohibited. Eezy reserves the right to intervene in any violation of the law that it
                        discovers.
                    </p>
                </div>
            </div>

            <div id="point3">
                <p>
                    <b>3. Service Provider and contact information</b>
                </p>
                <div className="p1">
                    <p>Eezy Light Entrepreneurs’ Cooperative</p>
                    <p>Business ID: 2197002-2</p>
                    <p>Maistraatinportti 1</p>
                    <p>FI-00240 Helsinki, Finland</p>
                    <p>Tel. +358 (0)9 4247 5630</p>
                    <p>info.kevytyrittajat@eezy.fi</p>
                </div>
            </div>

            <div id="point4">
                <p>
                    <b>4. Using the Service</b>
                </p>
                <div className="p1" id="point4.1">
                    <p>
                        <b>4.1 Rights and restrictions applicable to the use of the Service</b>
                    </p>
                    <div className="p1">
                        <p>
                            Eezy grants the Performer of work a restricted, revocable, non-exclusive and
                            personal right, as defined in these Terms and Conditions, to use the Service and
                            its contents, solely in accordance with these Terms and Conditions, and any terms,
                            conditions and instructions possibly separately provided in the Service. The
                            Performer of work has the right to use the Service and its contents for personal
                            purposes only.
                        </p>
                        <p>
                            The Performer of work must comply with legislation and official instructions valid
                            in Finland. The Performer of work must follow any mandatory legal orders,
                            including occupational safety instructions, applicable to his/her operations.
                        </p>
                        <p>
                            The Performer of work may not use the Service (a) to present themselves as a
                            representative of Eezy; (b) to falsify or process header information, credentials
                            or identity data so as to try to conceal the origin of contents transmitted
                            through the Service; (c) to provide false information in any other way; (d) to
                            save or collect other users’ personal data; (e) to transmit junk mail or any other
                            unauthorised data transfer, or (f) for any other measures contrary to law or
                            generally accepted practices.
                        </p>
                        <p>
                            Use of the Service is not permitted in the restricted industries. An up-to-date
                            list of the restricted industries can be found at:{' '}
                            <a
                                href="https://kevytyrittajat.eezy.fi/en/what-situations-is-light-entrepreneurship-not-suitable-for/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://kevytyrittajat.eezy.fi/en/what-situations-is-light-entrepreneurship-not-suitable-for/
                            </a>
                        </p>
                        <p>
                            The Performer of work using the Service is responsible for the content of the
                            payment page and for ensuring that the payment page complies with good marketing
                            practice. The content of the payment page may not be in violation of good practice
                            or good advertising practice. The Performer of work is responsible for not using
                            any unethical procedure when using the Service.
                        </p>
                        <p>
                            It is only allowed to accept payments for work performed in Finland through the
                            Service.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.2">
                    <p>
                        <b>4.2 Changes to the Service and termination</b>
                    </p>
                    <div className="p1">
                        <p>
                            The Service and its contents are provided “as is”. Eezy has the right to modify or
                            change the Service or its contents at any time, without liability to compensate.
                        </p>
                        <p>
                            This means that Eezy has the right, for example, to change or modify the
                            information provided in the Service, the products offered, or prices, to add and
                            remove parts of the Service, and make free-of-charge sections of the service
                            subject to a charge, or vice versa.
                        </p>
                        <p>
                            Eezy will inform the Performer of work about any fundamental changes to the
                            service, including pricing, 14 days prior to the entry into force of the changes.
                            The notification period does not apply to mandatory changes resulting from
                            amendments to law or official instructions.
                        </p>
                        <p>
                            Eezy also has the right, at any time, to interrupt or terminate the Service, the
                            provision of its contents or any part thereof, in full, or restrict the use of the
                            Service or sections of the Service.
                        </p>
                        <p>
                            If a Performer of work has acted contrary to these Terms and Conditions or any
                            terms, conditions or instructions provided separately in the Service, or
                            legislation and generally accepted practices, Eezy has the right to prevent the
                            Performer of work from accessing the Service and prohibit further use of the
                            Service.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.3">
                    <p>
                        <b>4.3 Registration</b>
                    </p>
                    <div className="p1">
                        <p>
                            To gain the right of use specified above, the Performer of work is required to
                            register in the Service in accordance with the Terms and Conditions of Use of the
                            Eezy Light Entrepreneurs. The Performer of work is responsible for the timeliness
                            of its own data, the careful storage of the password and its confidentiality and
                            for all transactions with its usernames in the Service.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.4">
                    <p>
                        <b>4.4 Invoicing and payment of wages</b>
                    </p>
                    <div className="p1">
                        <p>
                            Payment of wages to the Performer of work is based on the sum charged, excluding
                            VAT, invoiced by the Performer of work through Eezy or paid through the EezyPay
                            Service. The payment of wages process is described in more detail in the Terms and
                            Conditions of Use of the Eezy Light Entrepreneurs.
                        </p>
                        <p>
                            The Performer of work is responsible for ensuring the correctness of all
                            information provided in the Service, including creating the payment page and
                            applying for compensation of expenses.
                        </p>
                        <p>
                            Eezy reserves the right not to pay wages for charges/work that are in violation of
                            the law or the terms and conditions of the Service.
                        </p>
                        <p>
                            Eezy has the right to remove the Performer of work's payment pages, through which
                            payments have not been received for 1 year.
                        </p>
                        <p>
                            The Performer of work must specify the price for their own work so that the gross
                            sum of wages based on it is in accordance with applicable regulations, and
                            corresponds to the general level of earnings for entrepreneurs operating in the
                            same line of business.
                        </p>
                        <p>
                            If Eezy has outstanding receivables from the Performer of work, Eezy has the right
                            to withhold them from the net wages paid to the Performer of work when wages are
                            paid.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.5">
                    <p>
                        <b>4.5 Price of the Service</b>
                    </p>
                    <div className="p1">
                        <p>
                            The price of the Service is determined according to Eezy's currently valid price
                            list. The up-to-date price list can be found at{' '}
                            <a
                                href="https://kevytyrittajat.eezy.fi/en/price-list/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://kevytyrittajat.eezy.fi/en/price-list/
                            </a>
                            . Eezy reserves the right to change the price list.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.6">
                    <p>
                        <b>4.6 Payment</b>
                    </p>
                    <div className="p1">
                        <p>
                            The payment intermediary is VismaPay, which is registered in the register of
                            payment service providers maintained by the Financial Supervisory Authority.
                            Payment is made through the VismaPay online service. EezyPay will forward the
                            payments to the Performer of work. Paying is secure, as all transaction
                            information is transmitted using an encrypted connection so that no third party
                            can see the transaction information.
                        </p>
                    </div>
                </div>
                <div className="p1" id="point4.7">
                    <p>
                        <b>4.7 Payment methods</b>
                    </p>
                    <div className="p1">
                        <p>The most common payment methods supported by VismaPay are available.</p>
                    </div>
                </div>
            </div>

            <div id="point5">
                <p>
                    <b>5. Limitations of liability</b>
                </p>
                <div className="p1">
                    <p>
                        The Performer of work must settle any disputes concerning their work performance
                        directly with the Client.
                    </p>
                    <p>
                        In all circumstances, Eezy is only responsible for its own operations and only for the
                        Service agreed upon in accordance with these Terms and Conditions. Eezy is not liable
                        for any direct, indirect, consequential or inconsequential damage or special damage,
                        including damage caused by loss of business, contract, revenue or information, or
                        damage caused by an interruption of business, resulting from work performed by the
                        Performer of work charged through Eezy, or these Terms and Conditions, regardless of
                        whether the damage is based on an agreement, violation of the law, negligence or
                        another reason, even if Eezy had been informed about the possibility of such damage in
                        advance. In any case, Eezy’s liability is always restricted to liability pursuant to
                        imperative law.
                    </p>
                    <p>
                        The Performer of work releases Eezy and all its employees, managers and others working
                        on its behalf from liability with regard to all such action and claims that result
                        from the assignments of the Performer of work, and use of the Service.
                    </p>
                    <p>
                        The Client must submit all complaints and inquiries concerning the services/work
                        charged through the Service (such as cancellations, schedule changes, deficiencies in
                        the assignment, performance or quality of the work, etc.) directly to the Performer of
                        work.
                    </p>
                    <p>
                        If the Client has paid for the ordered work performance remotely through the Service,
                        the Client may be entitled to a refund of the payment in certain situations, if the
                        Performer of work has not delivered the work performance within the agreed time limit
                        or if the Client is otherwise entitled to a statutory or separately agreed refund of
                        the payment before the work performance. In this case, the person submitting the
                        complaint must primarily contact the Performer of work. Eezy may refund the payment if
                        the Client is entitled to the refund and the Performer of work has not yet been paid
                        the wage for the performance of the work.
                    </p>
                    <p>
                        Transaction complaints regarding the use of the Service must be submitted to Eezy
                        within 14 days of the detection of the transaction.
                    </p>
                </div>
            </div>

            <div id="point6">
                <p>
                    <b>6. Amendments to the Terms and Conditions and notifications</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy reserves the right to amend these Terms and Conditions at its discretion by
                        notifying the Performer of work about it by email or via the Service. The amendments
                        to the Terms and Conditions become valid as soon as the notification about them has
                        been given. Use of the Service after the notification is regarded as a notification by
                        the Performer of work of having accepted the amendments made to the Terms and
                        Conditions.
                    </p>
                </div>
            </div>

            <div id="point7">
                <p>
                    <b>7. Force majeure</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy is not liable for delays or losses in the Service caused by an obstacle beyond
                        Eezy’s control which Eezy cannot have reasonably been required to have taken into
                        consideration when concluding the agreement and the consequences of which the Service
                        Provider also could not have reasonably avoided or overcome. Force majeure is
                        considered to be, among other things, a disturbance in the communication or other
                        electronic communication independent of the Service, as well as an interruption or
                        delay in the operation of the Service caused by a fire or other accident. A strike,
                        lockout, boycott and other industrial actions are considered as force majeure even if
                        Eezy is itself the subject or participant in it.
                    </p>
                </div>
            </div>

            <div id="point8">
                <p>
                    <b>8. Other terms and conditions</b>
                </p>
                <div className="p1">
                    <p>
                        Otherwise, the Terms and Conditions of Use of the Eezy Light Entrepreneurs apply,
                        unless otherwise agreed between the parties.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EezyPayTermsEn;
