import type { ReactNode } from 'react';
import { Card, CardHeader } from '.';

interface IProps {
    children?: ReactNode;
    title: string;
}

export const AutoSaveCard = (props: IProps) => {
    return (
        <Card>
            <CardHeader title={props.title} />
            {props.children}
        </Card>
    );
};
