import { useApolloClient } from '@apollo/client';
import { Backdrop, Fade } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import { EezyButton } from '../../../components/Buttons';
import SideMenuBar from '../../../components/SideMenuBar';
import {
    COLOR_GRIPE,
    COLOR_LIGHT_BG,
    COLOR_WHITE_WALKER,
    DRAWERWIDTH_MAX,
    INVOICE_WIDTH,
    LIGHT_GRADIENT,
    PAGEWIDTH_MAX,
    SCREEN_M,
    SCREEN_S,
    TOPBAR_HEIGHT,
} from '../../../styles/variables';
import { trans } from '../../../utils';
import { RECIPIENT_FH_ERRORS_VISIBLE } from '../queries/invoiceStateQueries';
import { closeAllFillHelpers } from './utils';

const Wrapper = styled.div`
    background-color: ${COLOR_LIGHT_BG};
    bottom: 0;
    color: ${COLOR_WHITE_WALKER};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;

    @media (max-width: ${SCREEN_M}px) {
        width: 100%;
    }

    @media (min-width: ${SCREEN_M}px) {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        max-width: calc(${DRAWERWIDTH_MAX}px + 15px);
        right: 0;
        width: calc(100vw - (${INVOICE_WIDTH}px) - 45px);
    }

    @media (min-width: ${PAGEWIDTH_MAX}px) {
        right: calc((100vw - ${PAGEWIDTH_MAX}px) / 2);
    }
`;

const Content = styled.div`
    height: 95%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    min-height: calc(100% - (${TOPBAR_HEIGHT}px));
    padding: 20px 15px;
    @media (min-width: ${SCREEN_S}px) {
        padding: 20px 30px 20px 15px;
    }
`;

const useBackdropStyles = makeStyles()({
    backdrop: {
        background: LIGHT_GRADIENT,
        pointerEvents: 'none',
        zIndex: 0,
    },
});

const Arrow = styled.div`
    border-bottom: 30px solid transparent;
    border-right: 30px solid ${COLOR_GRIPE};
    border-top: 30px solid transparent;
    display: none;
    height: 0;
    position: absolute;
    right: calc((100vw - ${INVOICE_WIDTH}px - 45px));
    width: 0;

    @media (min-width: ${SCREEN_M}px) {
        display: initial;
    }
    @media (min-width: ${PAGEWIDTH_MAX}px) {
        right: calc(
            (100vw - ${PAGEWIDTH_MAX}px) / 2 + (${DRAWERWIDTH_MAX}px + 15px)
        );
    }
`;

interface IFillHelperProps {
    arrowOffset?: number;
    children: React.ReactNode;
    renderMenuLeft?: (handleClose?: () => void) => React.ReactNode;
    renderMenuRight?: (handleClose?: () => void) => React.ReactNode;
}
const FillHelper = (props: IFillHelperProps) => {
    const { classes } = useBackdropStyles();
    const client = useApolloClient();

    const handleClose = React.useCallback(() => {
        closeAllFillHelpers(client);
        client.writeQuery({
            data: {
                recipientFHErrorsVisible: false,
            },
            query: RECIPIENT_FH_ERRORS_VISIBLE,
        });
    }, [client]);

    const handleEsc = React.useCallback(
        (event: any) => {
            if (event.keyCode === 27) {
                handleClose();
            }
        },
        [handleClose],
    );

    React.useEffect(() => {
        document.addEventListener('keydown', handleEsc, false);
        return () => {
            document.removeEventListener('keydown', handleEsc, false);
        };
    }, [handleEsc]);

    return (
        <>
            <Backdrop open={true} className={classes.backdrop} />
            {props.arrowOffset ? <Arrow style={{ top: props.arrowOffset }} /> : null}
            <Fade in={true} timeout={200}>
                <Wrapper className="hide-scroll">
                    <SideMenuBar>
                        {props.renderMenuLeft ? (
                            props.renderMenuLeft(handleClose)
                        ) : (
                            <EezyButton
                                border
                                color="purple"
                                dark
                                onClick={handleClose}
                                textColor={COLOR_WHITE_WALKER}
                            >
                                {trans('general.cancel')}
                            </EezyButton>
                        )}
                        {props.renderMenuRight?.(handleClose)}
                    </SideMenuBar>
                    <Content className="fillhelper-content">{props.children}</Content>
                </Wrapper>
            </Fade>
        </>
    );
};

export default FillHelper;
