import YouTube from 'react-youtube';

import { EezyButton } from '../../components/Buttons';
import { Modal, ModalActions, ModalContent, ModalHeader } from '../../components/modals/Modal';
import { COLOR_BLUM, SCREEN_S } from '../../styles/variables';
import { trans } from '../../utils';
import styled from 'styled-components';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const VideoPlayerHolder = styled.div`
    @media (min-width: ${SCREEN_S}px) {
        width: 500px;
    }
`;

const videoOptions = {
    playerVars: {
        autoplay: 1 as const,
        modestbranding: 1 as const,
        origin: window.location.host,
        rel: 0 as const,
    },
    width: '100%',
};

const IntroVideoModal = (props: IModalProps) => {
    return (
        <Modal id="modal-strong-intro-video" isOpen={props.isOpen} onClose={props.handleModalClose}>
            <ModalHeader />
            <ModalContent
                style={{
                    borderRadius: '10px',
                    lineHeight: 0,
                    overflow: 'clip',
                    padding: '1px 33px 9px',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        borderRadius: '10px',
                        overflow: 'clip',
                    }}
                >
                    <VideoPlayerHolder>
                        <YouTube videoId={trans('intro.videoId')} opts={videoOptions} />
                    </VideoPlayerHolder>
                </div>
            </ModalContent>
            <ModalActions
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '15px 0 40px 0',
                }}
            >
                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    onClick={props.handleModalClose}
                >
                    {trans('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default IntroVideoModal;
