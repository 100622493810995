import type { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { IRootState } from 'reducers';
import { logoFull } from 'assets/images/eezylogo';
import { RoundBox } from 'components/Balloon';
import LoadingSpinner from '../../components/Loading';
import type { IAuth } from 'reducers/authReducer';
import { H1, PageWrapper } from 'styles/yelStyles';
import { yelLogic } from 'utils/yel';
import YelOk from './YelOk';
import YelSummary from './YelSummary';
import YelTerminate from './YelTerminate';
import YelWithdraw from './YelWithdraw';
import YelWizard from './YelWizard';
import { useYelData } from 'queries/useYelData';
import { GeneralError } from 'containers/error/GeneralError';
import useYelStore from 'stores/yelStore';

const YelPageWrapper = ({ children }: { children: ReactNode }) => (
    <PageWrapper>
        <div style={{ margin: '0 auto', maxWidth: '150px' }}>
            <img src={logoFull} alt="Eezy Logo" />
        </div>
        <RoundBox>{children}</RoundBox>
    </PageWrapper>
);

const Yel: FC = () => {
    const { t } = useTranslation();
    const { data, loading, error } = useYelData();
    const yelStoreData = useYelStore((state) => state.yelData);

    const auth: IAuth = useSelector((state: IRootState) => state.auth);

    if (error && error.place === 'yelPage') {
        return (
            <PageWrapper>
                <GeneralError error={error} />
            </PageWrapper>
        );
    }

    if (!auth.loggedIn || auth.error) {
        return null;
    }
    if (loading) {
        return (
            <YelPageWrapper>
                <LoadingSpinner />
            </YelPageWrapper>
        );
    }

    if (yelStoreData.showTerminate) {
        return (
            <YelPageWrapper>
                <YelTerminate />
            </YelPageWrapper>
        );
    }
    if (yelStoreData.showWithdrawMoney) {
        return (
            <YelPageWrapper>
                <H1>{t('yel:withdraw-money')}</H1>
                <YelWithdraw />
            </YelPageWrapper>
        );
    }
    if (yelStoreData.showSummary) {
        return (
            <YelPageWrapper>
                <YelSummary />
            </YelPageWrapper>
        );
    }
    if (yelLogic.shouldShowYelOK(data.status)) {
        return (
            <YelPageWrapper>
                <YelOk />
            </YelPageWrapper>
        );
    }
    if (yelLogic.shouldShowWizard(data.status)) {
        return (
            <YelPageWrapper>
                <YelWizard />
            </YelPageWrapper>
        );
    }
    return null;
};

export default Yel;
