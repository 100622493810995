import { gql } from '@apollo/client';

const UPDATE_EXPRESS = gql`
    mutation UpdateExpress(
        $invoiceId: Int!
        $email: String
        $confirmationRequired: Boolean
        $quick: Boolean
        $state: Boolean!
    ) {
        updateEezyExpress(
            express: {
                invoiceId: $invoiceId
                finalityConfirmationEmail: $email
                finalityConfirmationRequired: $confirmationRequired
                quick: $quick
                state: $state
            }
        ) {
            eezyExpressStatus
            id
        }
    }
`;

export default UPDATE_EXPRESS;
