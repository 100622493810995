import type { FC } from 'react';
import EmptyGraphics from '../assets/images/empty-graphics.svg';
import { Flex } from './Flex';
import { P } from './textElements';

interface IProps {
    text: string;
    textColor?: string;
}

export const EmptyListPlaceholder: FC<IProps> = ({ text, textColor }) => {
    return (
        <Flex center justifyCenter fullWidth column>
            <P
                style={{
                    padding: '15px 30px',
                    textAlign: 'center',
                    color: textColor,
                }}
            >
                {text}
            </P>
            <img
                alt="Empty graphics"
                src={EmptyGraphics}
                style={{
                    margin: '32px 0 32px 32px',
                    width: '65%',
                }}
            />
        </Flex>
    );
};
