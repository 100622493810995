import styled from 'styled-components';
import { COLOR_WHITE_WALKER } from './../../styles/variables';

export const FormBackground = styled.div`
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: 4px;
    padding: 20px 10px;

    div.fillhelper-content & {
        margin-top: 22px;
    }
`;
