import { t } from 'i18next';
import type { formatValidationResult } from 'utils/validation';

export const humanReadableInvoiceErrors = (errors: ReturnType<typeof formatValidationResult>) => {
    if (!errors) return [];
    return Object.entries(errors).map(([key, { type }]): string => {
        switch (key) {
            case 'invoiceItems':
                if (type === 'array.hasUnknown') return t('errors.noItems');
                break;

            case 'dueDate':
                // TODO: this should have more info on what is the limiting factor,
                // but our current validation library is the limitation
                if (type === 'date.min') return t('errors.dueDateMin');
                break;

            case 'totalWithVat':
                if (type === 'number.min') return t('errors.invoice-total-negative');
                break;

            case 'dispatchDate':
                return t('errors.invalid-dispatch-date');

            case 'paymentTerm':
                if (type === 'number.min') return t('errors.paymentTermMin');
                break;
        }

        // The key is something like invoiceItems[0].description, including the index,
        // so we can't have this in the switch
        if (key.startsWith('invoiceItems')) {
            if (key.endsWith('description')) return t('errors.invoiceItemMissingDescription');
            if (key.endsWith('quantity')) return t('errors.invoiceItemMissingQuantity');
            if (key.endsWith('price')) return t('errors.invoiceItemMissingPrice');
        }

        return '';
    });
};
