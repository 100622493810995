import { getLocalStorage, setLocalStorage } from '../user/userUtils';

export const saveTermination = (email: string) => {
    setLocalStorage('account-terminated', { terminatedEmail: email });
};

export const isAccountTerminated = (email: string) => {
    const localData = getLocalStorage('account-terminated');
    if (localData) {
        const { terminatedEmail } = localData;
        return terminatedEmail === email;
    }
    return false;
};
