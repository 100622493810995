import { gql } from '@apollo/client';

const ADD_RECIPIENT = gql`
    mutation CreateClient($client: ClientInput!) {
        createClient(client: $client) {
            active
            address {
                country
                street1
                street2
                town
                zipCode
            }
            businessId
            contactPeople {
                active
                firstName
                id
                lastName
            }
            einvoiceOvt
            operator
            email
            firstName
            id
            invoiceLanguage
            invoicingMethod
            lastName
            name
            type
        }
    }
`;

export default ADD_RECIPIENT;
