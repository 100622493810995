import { useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { IUserData, UiLanguage } from '../../../shared/src/types/user';
import { changeLanguage } from 'utils';
import { logout } from 'actions/auth';
import { dispatch } from 'utils/store';
import API from 'utils/API';

export const useUserQuery = () =>
    useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            const response = await API.get<IUserData | undefined>('/user');
            return response.data ?? null;
        },
        retry: (failureCount: number, error: AxiosError) => {
            if (error?.response?.status === 401) {
                dispatch(logout());
                return false;
            }
            return failureCount < 1;
        },
        // refetchOnWindowFocus: false,
        // retryDelay: 1000,
        // staleTime: 30000, // Should not use due to 401 redirect and re-login will not fetch user's info
        // refetchOnMount: true,
        // refetchInterval: false,
    });

export const useUser = () => {
    return useUserQuery().data ?? null;
};

export const useChangeUserLanguageMutation = () => {
    const changeLanguageMutation = useMutation({
        mutationFn: async (lang: string) => {
            await API.put('/user', { language: lang });
        },
    });

    const timeoutRef = useRef<NodeJS.Timeout>();

    return (lang: UiLanguage) => {
        changeLanguage(lang);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => changeLanguageMutation.mutate(lang), 3000);
    };
};
