import type { CSSProperties } from 'react';
import styled from 'styled-components';
import { logoFull } from '../assets/images/eezylogo';
import {
    COLOR_LIGHT_BG,
    LIGHT_GRADIENT_INVERT,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    SCREEN_M,
} from '../styles/variables';

const StyledFooter = styled.footer`
    background: ${COLOR_LIGHT_BG};
`;

const Dark = styled.div`
    background: ${LIGHT_GRADIENT_INVERT};
    width: 100%;
    display: flex;
    justify-content: center;
    height: ${FOOTER_HEIGHT_MOBILE}px;
    @media (min-width: ${SCREEN_M}px) {
        height: ${FOOTER_HEIGHT}px;
    }
`;

interface IFooterProps {
    style?: CSSProperties;
    noLogo?: boolean;
}

const Footer = (props: IFooterProps) => {
    return (
        <StyledFooter role="alert" style={props.style}>
            <Dark>{!props.noLogo && <img src={logoFull} alt="Eezy Logo" style={{ width: '180px' }} />}</Dark>
        </StyledFooter>
    );
};

export default Footer;
