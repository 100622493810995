import React from 'react';
import type { IReceiptCost } from '../../../../shared/src/types/costs';
import { FormRadio, FormRow, FormSection } from '../../components/form';
import { trans } from '../../utils';
import { type ICostComponentProps, isSupplyReceipt } from '../../utils/costs/costLogic';

export const CostReceiptType = (props: ICostComponentProps) => {
    const { dispatch } = props;
    const receipt: IReceiptCost | undefined = props.currentCost;
    const [costType, setCostType] = React.useState('supply');

    React.useEffect(() => {
        if (receipt && !isSupplyReceipt(receipt)) {
            setCostType('other');
        }
    }, [receipt]);

    if (!receipt) {
        return null;
    }

    const saveReceiptCost = (payload: any) => {
        dispatch({ payload, type: 'SAVE_COST' });
    };

    const handleTypeChange = (val: string) => {
        setCostType(val);
        if (val === 'supply') {
            saveReceiptCost({
                description: trans('costs.costReceiptType.supply'),
            });
        } else {
            saveReceiptCost({ description: '' });
        }
    };

    return (
        <div>
            <FormSection>
                <FormRow>
                    <FormRadio
                        label={trans('costs.costReceiptType.question')}
                        name="travel-type"
                        options={[
                            {
                                label: trans('costs.costReceiptType.supply'),
                                value: 'supply',
                            },
                            {
                                label: trans('costs.costReceiptType.other'),
                                value: 'other',
                            },
                        ]}
                        onChange={handleTypeChange}
                        required
                        value={costType}
                    />
                </FormRow>
            </FormSection>
        </div>
    );
};
