import type { IInvoiceItem, IInvoiceItemKeys } from '../../../../../shared/src/types/invoice';
import { trans } from '../../../utils';
import InvoiceTableData from './InvoiceTableData';

const Description = (props: {
    editable: boolean;
    handleFocus?: () => void;
    handleUpdate: (changedProperty: IInvoiceItemKeys) => void;
    error?: any;
    item: IInvoiceItem;
}) => {
    return (
        <InvoiceTableData
            readOnlyContent={
                <span data-mf-replace="**REMOVED**" style={{ wordBreak: 'break-word' }}>
                    {props.item.description}
                </span>
            }
            editable={props.editable}
            handleFocus={props.handleFocus}
            handleUpdate={props.handleUpdate}
            error={props.error}
            item={props.item}
            name="description"
            type="text"
            placeholder={trans('invoice.tableColumns.description-placeholder')}
        />
    );
};

export default Description;
