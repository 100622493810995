import { useMutation, useQuery } from '@tanstack/react-query';
import useYelStore from 'stores/yelStore';
import API from 'utils/API';
import type { IIncomeLimits, IYelInfo, IYelWithdrawal } from '../../../shared/src/types/yel';
import { AxiosError } from 'axios';
import {
    ERROR_GENERAL_YEL,
    ERROR_YEL_ADDRESS_MISSING,
    ERROR_YEL_CONTACT_SUPPORT,
    ERROR_YEL_PIC_MISSING,
    type IClientError,
    getError,
} from 'utils/error';
import { useState } from 'react';
import { currentDate } from 'utils';

const errorMap = {
    MISSING_PIC: ERROR_YEL_PIC_MISSING,
    MISSING_ADDRESS: ERROR_YEL_ADDRESS_MISSING,
    INVALID_STATUS: ERROR_YEL_CONTACT_SUPPORT,
};

export type YelData = {
    withdrawals: IYelWithdrawal[];
    incomeLimits: IIncomeLimits;
} & IYelInfo;

type YelPostBody = {
    estimatedIncome: number | null;
    whoPays: string;
    numberOfQuotas: number;
    insuranceCompanyName?: string;
    entrepreneurStartDate?: string;
    estimatedInvoicing?: number;
    occupation?: string;
};

export const useYelData = () => {
    const yelStoreData = useYelStore((state) => state.yelData);
    const [error, setError] = useState<IClientError | null>(null);

    const yelDataQuery = useQuery({
        queryKey: ['yelData'],
        queryFn: async () => {
            try {
                const { data } = await API.get('/yel');
                return data;
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    const errorToCreate = errorMap[error.response?.data.error as keyof typeof errorMap];
                    if (!errorToCreate) setError(getError({ errorType: ERROR_GENERAL_YEL }));
                    else setError(getError({ errorType: errorToCreate }));
                }

                throw error;
            }
        },
    });

    const yelPostMutation = useMutation({
        mutationFn: async (postData: YelPostBody) => {
            try {
                // New yel insurance has occupation, transfer does not
                const response = await API.post(postData.occupation ? '/yel' : '/yel/transfer', postData);
                if (response.status === 200 && response.data.status === 'do_redirect') {
                    window.location = response.data.location;
                } else {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }
                return response.data;
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }

                throw error;
            }
        },
    });

    const yelWithdrawalsQuery = useQuery({
        queryKey: ['yelWithdrawal'],
        queryFn: async () => {
            try {
                const { data } = await API.get('/yel/withdrawal');
                return data;
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    setError(getError({ errorType: ERROR_GENERAL_YEL }));
                }
            }
        },
    });

    const data = {
        ...(yelDataQuery.data as IYelInfo & {
            incomeLimits: IIncomeLimits;
        }),
        withdrawals: yelWithdrawalsQuery.data as IYelWithdrawal[],
    };

    const postYelData = async () => {
        const income = Math.min(yelStoreData.estimatedIncome, data.incomeLimits.max);

        const postData =
            yelStoreData.hasYel === 'Yes'
                ? {
                      estimatedIncome: yelStoreData.hasSameIncomeAsBefore ? null : income,
                      insuranceCompanyName: yelStoreData.insuranceCompany,
                      numberOfQuotas: yelStoreData.numberOfQuotas,
                      whoPays: yelStoreData.whoPays,
                  }
                : {
                      entrepreneurStartDate: yelStoreData.isStarting
                          ? currentDate()
                          : yelStoreData.dateStarted,
                      estimatedIncome: income,
                      estimatedInvoicing: yelStoreData.estimatedInvoicing,
                      occupation: yelStoreData.occupation,
                      numberOfQuotas: yelStoreData.numberOfQuotas,
                      whoPays: yelStoreData.whoPays,
                  };

        yelPostMutation.mutate(postData);
    };

    return {
        loading: yelDataQuery.isLoading || yelPostMutation.isPending,
        data,
        error,
        setError,
        postYelData,
    };
};
