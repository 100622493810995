import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden } from '@mui/material';
import styled from 'styled-components';
import { DatePicker } from '.';
import { COLOR_BLUM, COLOR_WHITE_WALKER } from 'styles/variables';
import { formatDateISO } from 'utils';
import { localDateFormat } from 'utils/i18n';

const StyledNativeDateInput = styled.input`
    appearance: none;
    background-color: ${COLOR_WHITE_WALKER};
    border: 2px solid ${COLOR_BLUM};
    border-radius: 4px;
    color: ${COLOR_BLUM};
    cursor: text;
    padding: 10px 10px 10px 50%;
    min-height: 48px;
    text-align: left;
    width: 100%;
    && {
        font-weight: bold;
    }
    & + span {
        color: ${COLOR_BLUM};
        font-weight: bold;
        left: 0;
        line-height: 24px;
        position: absolute;
        text-align: end;
        top: 25%;
        vertical-align: middle;
        width: 50%;
    }
    &.hasValue,
    &:hover {
        background-color: ${COLOR_BLUM};
        color: ${COLOR_WHITE_WALKER};
    }
    &.hasValue + span,
    &:hover + span {
        color: ${COLOR_WHITE_WALKER};
        padding-right: 5px;
    }
`;

const StyledPicker = styled.div`
    position: relative;

    .MuiFormControl-root {
        border: 2px solid ${COLOR_BLUM};
        border-radius: 4px;
        height: 48px;
        padding: 4px;
        width: 100%;
        input {
            border: none;
            color: ${COLOR_BLUM};
            font-size: 15px;
            font-weight: bold;
            margin-left: 48px;
            text-align: center;
        }
        input::placeholder {
            color: ${COLOR_BLUM};
            opacity: 1;
        }
        &:hover input::placeholder {
            color: ${COLOR_WHITE_WALKER};
        }
        & div::after,
        & div::before,
        div:hover div::before {
            content: none;
        }
        #date-picker-dialog-helper-text {
            display: none;
        }
        svg {
            fill: ${COLOR_BLUM};
        }

        & div {
            height: 100%;
        }
    }

    .MuiFormControl-root.hasValue {
        background-color: ${COLOR_BLUM};
        padding-left: 50%;
        input {
            color: ${COLOR_WHITE_WALKER};
            margin-left: 0;
            text-align: left;
        }
        svg {
            fill: ${COLOR_WHITE_WALKER};
        }
    }
    .MuiFormControl-root:hover {
        background-color: ${COLOR_BLUM};
        input {
            color: ${COLOR_WHITE_WALKER};
        }
        svg {
            fill: ${COLOR_WHITE_WALKER};
        }
    }
    .date-wrapper + span {
        color: ${COLOR_WHITE_WALKER};
        font-size: 15px;
        font-weight: bold;
        left: 0;
        line-height: 24px;
        padding-right: 5px;
        position: absolute;
        text-align: end;
        top: 23%;
        width: 50%;
    }
`;

interface IDatePickerProps {
    label: string;
    labelShort?: string;
    onChange: (e: string) => void;
    selectedDate: string;
}

export const DatePickerYel = (props: IDatePickerProps) => {
    const { t } = useTranslation();
    const { label, labelShort, onChange, selectedDate } = props;
    const [focused, setFocused] = useState(false);

    const handleNativeDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = (event.target as HTMLInputElement).value;
        onChange(val);
    };

    const handleCustomDateChange = (date: Date | null) => {
        if (date) {
            onChange(formatDateISO(date));
        }
    };

    return (
        <>
            <Hidden mdUp>
                <div style={{ position: 'relative', width: '100%' }}>
                    <StyledNativeDateInput
                        aria-label={t('dates.select-date') || ''}
                        className={selectedDate ? 'hasValue' : ''}
                        id="date"
                        onChange={handleNativeDateChange}
                        placeholder={selectedDate ? label : `${label}...`}
                        type="date"
                        value={selectedDate}
                    />
                    <span aria-hidden>{selectedDate || labelShort ? label : `${label}...`}</span>
                </div>
            </Hidden>

            <Hidden mdDown>
                <StyledPicker>
                    <DatePicker
                        buttonStyle={{
                            color: COLOR_BLUM,
                        }}
                        id="date-picker-dialog"
                        className={selectedDate || focused ? 'hasValue' : ''}
                        disableBorder
                        label={t('dates.select-date')}
                        onBlur={() => setFocused(false)}
                        onChange={handleCustomDateChange}
                        onFocus={() => setFocused(true)}
                        placeholder={
                            selectedDate || focused ? localDateFormat() : labelShort ? label : `${label}...`
                        }
                        value={selectedDate}
                    />
                    <span aria-hidden>
                        {selectedDate || focused ? (labelShort ? labelShort : label) : ''}
                    </span>
                </StyledPicker>
            </Hidden>
        </>
    );
};
