import { useEffect, useState } from 'react';
import { Hidden, LinearProgress, type LinearProgressProps } from '@mui/material';
import styled from 'styled-components';
import type { IInvoice, InvoiceStatusMessage } from '../../../../shared/src/types/invoice';
import Stars from '../../assets/images/notifications/stars.svg';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import LoadingSpinner from 'components/Loading';
import { BodyP, LabelCapsSmall } from 'components/textElements';
import { COLOR_BLACKWATER, COLOR_IMPORTANT, COLOR_MEDIUM_BG, COLOR_STATUS_WAITING } from 'styles/variables';
import { errHasKey } from 'utils/error';
import { isEditable } from 'utils/invoice/invoiceLogic';
import type { formatValidationResult } from 'utils/validation';
import { useTranslation } from 'react-i18next';

const LinearProgressBar = styled(LinearProgress)`
    &.MuiLinearProgress-root {
        border-radius: 10px;
        height: 10px;
        width: 97%;
    }

    &.MuiLinearProgress-colorPrimary {
        background-color: ${COLOR_MEDIUM_BG};
    }
    
    & .MuiLinearProgress-bar {
        width: 97%;
        background-color: red;
        background-color: ${COLOR_STATUS_WAITING};
        border-radius: 5px;
    };
`;

const StatusTitle = styled.p`
    margin-top: 22px;
    color: ${COLOR_BLACKWATER};
    font-size: 15px;
`;

const StarImg = styled.img`
    position: absolute;
    right: 10px;
    top: -10px;
`;

interface IInvoiceFillProgressStatus extends LinearProgressProps {
    invoice?: IInvoice;
    invoiceErrors: ReturnType<typeof formatValidationResult>;
    invoiceSendingStatus?: InvoiceStatusMessage;
    isNew?: boolean;
}

const calculateProgress = (
    err: ReturnType<typeof formatValidationResult>,
    invoice?: IInvoice,
    isNew?: boolean,
): number => {
    let progress = 0;
    if (invoice?.recipient && !errHasKey('recipient')(err)) {
        progress += 23; // Total 23%
    }
    if (
        invoice?.template &&
        !errHasKey('template')(err) &&
        invoice?.occupation &&
        !errHasKey('occupation')(err)
    ) {
        progress += 23; // Total 46%
    }
    if (
        (invoice?.dueDate !== null && !errHasKey('dueDate')(err)) ||
        (invoice?.paymentTerm !== null && !errHasKey('paymentTerm')(err))
    ) {
        progress += 24; // Total 70%
    }
    if (invoice?.invoiceItems?.length && !errHasKey('invoiceItems')(err) && !errHasKey('totalWithVat')(err)) {
        progress += 25; // Total 95%
    }
    if (!isEditable(invoice, isNew)) {
        progress += 5; // Total 100%
    }
    return progress;
};

const InvoiceFillProgressStatus = (props: IInvoiceFillProgressStatus) => {
    const { t } = useTranslation();
    const { variant, invoice, isNew, invoiceErrors } = props;

    const [progress, setProgress] = useState(calculateProgress(invoiceErrors, invoice, isNew));
    const [title, setTitle] = useState('');

    useEffect(() => {
        const percentage = calculateProgress(invoiceErrors, invoice, isNew);
        setProgress(percentage);
        if (percentage < 95) {
            setTitle(t('invoice.progress.title1') || '');
        } else if (percentage < 100) {
            setTitle(t('invoice.progress.title2') || '');
        } else {
            setTitle(t('invoice.progress.title4') || '');
        }
    }, [invoice, isNew, invoiceErrors]);

    const showPending =
        invoice?.status === 'sending_pending' ||
        (invoice?.id === props.invoiceSendingStatus?.originalId &&
            props.invoiceSendingStatus?.status === 'pending');

    const showError =
        invoice?.sendingFailed ||
        (invoice?.id === props.invoiceSendingStatus?.originalId &&
            props.invoiceSendingStatus?.status === 'error');

    // Skip render when invoice is loading
    if (!invoice && !isNew) {
        return null;
    }
    if (showPending) {
        return (
            <Flex style={{ margin: '20px 0', padding: '0 20px' }}>
                <LoadingSpinner size="1.5em" />
                <BodyP color={COLOR_BLACKWATER} style={{ marginLeft: 16 }}>
                    {t('invoice.sending-pending-1')} {t('invoice.sending-pending-2')}
                </BodyP>
            </Flex>
        );
    }
    if (showError) {
        return (
            <Flex style={{ margin: '20px 0' }}>
                <Icon icon={['far', 'exclamation-triangle']} color={COLOR_IMPORTANT} />
                <div style={{ marginLeft: 16 }}>
                    <BodyP color={COLOR_IMPORTANT}>{t('invoice.sending-failed-1')}</BodyP>
                    <BodyP color={COLOR_BLACKWATER}>{t('invoice.sending-failed-2')}</BodyP>
                </div>
            </Flex>
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            <StatusTitle>{title}</StatusTitle>
            <Flex
                fullWidth
                center
                style={{
                    marginBottom: 20,
                    marginTop: 5,
                }}
            >
                <LabelCapsSmall
                    style={{
                        color: 'black',
                        marginRight: 10,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {t('form.percent', { percentage: progress })}
                </LabelCapsSmall>
                <Flex fullWidth>
                    <LinearProgressBar variant={variant} value={progress} />
                </Flex>
            </Flex>
            {progress === 100 && (
                <Hidden mdDown>
                    <StarImg src={Stars} />
                </Hidden>
            )}
        </div>
    );
};

export default InvoiceFillProgressStatus;
