import React, { useEffect, useRef } from 'react';
import type { ApolloQueryResult } from '@apollo/client';
import * as R from 'ramda';
import { connect } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';

import type { FillType, IInvoice, IInvoiceKeys, ISpreadState } from '../../../../shared/src/types/invoice';
import { showModals } from '../../actions/auth';
import { handleInvoiceSpread, handleInvoiceSpreadClientSet } from '../../actions/invoice';
import { errHasKey } from '../../utils/error';
import type { IInvoiceTemplate } from '../../utils/invoice/invoiceTemplates';
import type { formatValidationResult } from '../../utils/validation';
import InvoiceCompletedPanel from './InvoiceCompletedPanel';
import InvoiceErrors from './InvoiceErrors';
import InvoiceSpread from './InvoiceSpread';

const FillingBackground = styled.div`
    margin-left: 12px;
    margin-right: 12px;
`;

type InvoiceFillWizardProps = {
    editable?: boolean;
    expressErrors?: string[];
    fillType?: FillType;
    handleAddCostInvoice: () => void;
    handleAttachmentClicked: (index: number) => void;
    handleAttachmentDelete: (index: number) => void;
    handleCreateTempCostInvoice: () => void;
    handleCopy?: () => void;
    handleEdit?: () => void;
    handleInvoiceItemUpdate?: (o: object) => void;
    handleInvoiceSend: () => void;
    // Redux
    handleInvoiceSpread: (panel: number, openSecondMenu?: boolean, id?: number) => void;
    // Redux
    handleInvoiceSpreadClientSet: (clientJustSet: boolean) => void;
    handleInvoiceUpdate: (changedProperty: IInvoiceKeys) => void;
    invoice?: IInvoice;
    invoiceErrors: ReturnType<typeof formatValidationResult>;
    isCoworkerInvoice: boolean;
    refetchInvoice?: () => Promise<ApolloQueryResult<any>>;
    setTypeModalOpen: (val: boolean) => void;
    sideErrors?: string[];
    // Redux
    spreadState: ISpreadState;
    template: IInvoiceTemplate;
    hasContract?: boolean;
};

const getFirstInvalidPanel = (
    invoiceErrors: ReturnType<typeof formatValidationResult>,
    invoice?: IInvoice,
): number | undefined => {
    if (!invoice) {
        return 1;
    }
    return R.cond([
        // No errors => send
        [() => !invoiceErrors, R.always(7)],
        // 1 Client info
        [(err) => errHasKey('recipient')(err), R.always(1)],
        // 2 Invoice type and occupation
        [(err) => errHasKey('template')(err), R.always(2)],
        [(err) => errHasKey('occupation')(err), R.always(2)],
        // 3  3. Invoice duedate
        [(err) => errHasKey('paymentTerm')(err), R.always(3)],
        [(err) => errHasKey('dueDate')(err), R.always(3)],
        // 4. Invoice rows
        [(err) => errHasKey('invoiceItems')(err), R.always(4)],
        [(err) => errHasKey('totalWithVat')(err), R.always(4)],
        // 5. Invoice attachments and description
        // No attachments and description are optional
        // 6. Extra services: Invoice costs & express
        // Cost invoice errors are handled in other place, express really cannot be invalid
        // 7. Send invoice
        [R.T, R.always(undefined)],
    ])(invoiceErrors);
};

const InvoiceFillWizard: React.FC<InvoiceFillWizardProps> = (props: InvoiceFillWizardProps) => {
    const {
        invoice,
        invoiceErrors,
        handleInvoiceSpread: handleInvoiceSpreadFunc,
        handleInvoiceUpdate,
        handleInvoiceItemUpdate,
        setTypeModalOpen,
        // Redux
        spreadState,
    } = props;

    const panelFromRedux = spreadState?.openPanelId;
    const firstErrorPanel = getFirstInvalidPanel(invoiceErrors, invoice);
    const expanded = spreadState?.justSetClient
        ? 1
        : !R.isNil(panelFromRedux) && panelFromRedux <= (firstErrorPanel || 7)
          ? panelFromRedux
          : !R.isNil(firstErrorPanel)
            ? firstErrorPanel
            : 6; // By default show Extra services if no errors

    useEffect(() => {
        handleInvoiceSpreadFunc(expanded);
    }, [handleInvoiceSpreadFunc, expanded]);

    const invoiceId = invoice?.id;
    useEffect(() => {
        return () => {
            handleInvoiceSpreadFunc(6); // By default show Extra services if no errors
        };
    }, [handleInvoiceSpreadFunc, invoiceId]);

    const errorRef = useRef<HTMLSpanElement>(null);
    const scrollToError = React.useCallback(() => {
        if (errorRef.current) {
            errorRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [errorRef]);

    useEffect(() => {
        if (props.expressErrors || props.sideErrors) {
            scrollToError();
        }
    }, [props.expressErrors, props.sideErrors, scrollToError]);

    const handleExpandChange = (panel: number) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        handleInvoiceSpreadFunc(newExpanded ? panel : 6);
    };

    const handleInvoiceSpreadFromSpread: (panel: number, openSecondMenu?: boolean, id?: number) => void = (
        panel,
        openSecodnMenu,
        id,
    ) => {
        if (spreadState?.justSetClient) {
            // Reset justSetClient status
            props.handleInvoiceSpreadClientSet(false);
        }
        handleInvoiceSpreadFunc(panel, openSecodnMenu, id);
    };

    const handleRecipientUpdate: (changedProperty: IInvoiceKeys) => void = (
        changedProperty: IInvoiceKeys,
    ) => {
        handleInvoiceUpdate(changedProperty);
        props.handleInvoiceSpreadClientSet(true);
    };

    return (
        <FillingBackground>
            <span ref={errorRef} />
            <InvoiceErrors
                errors={props.sideErrors}
                style={{ margin: '10px 0' }}
                titleTransKey={'errors.sending-validation.title'}
            />
            <InvoiceErrors
                errors={props.expressErrors}
                style={{ margin: '10px 0' }}
                titleTransKey={'errors.express.title'}
            />
            {props.editable || !invoice ? (
                <InvoiceSpread
                    handleAttachmentClicked={props.handleAttachmentClicked}
                    handleAttachmentDelete={props.handleAttachmentDelete}
                    handleCreateTempCostInvoice={props.handleCreateTempCostInvoice}
                    handleExpandChange={handleExpandChange}
                    handleInvoiceSend={props.handleInvoiceSend}
                    handleInvoiceSpread={handleInvoiceSpreadFromSpread}
                    handleInvoiceUpdate={handleInvoiceUpdate}
                    handleInvoiceItemUpdate={handleInvoiceItemUpdate}
                    handleRecipientUpdate={handleRecipientUpdate}
                    expanded={expanded}
                    invoice={invoice}
                    invoiceErrors={invoiceErrors}
                    refetchInvoice={props.refetchInvoice}
                    setTypeModalOpen={setTypeModalOpen}
                    showModals={showModals}
                    template={props.template}
                    hasContract={props.hasContract}
                />
            ) : (
                <InvoiceCompletedPanel
                    editable
                    invoice={invoice}
                    isCoworkerInvoice={props.isCoworkerInvoice}
                    handleAddCostInvoice={props.handleAddCostInvoice}
                    onCopy={props.handleCopy}
                    onEdit={props.handleEdit}
                    hasContract={props.hasContract}
                />
            )}
        </FillingBackground>
    );
};

const mapStateToProps = (state: any) => {
    return {
        spreadState: state.invoice.spreadState,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        handleInvoiceSpread: (openPanelId: number, openSecondMenu?: boolean, openSubObjectId?: number) => {
            dispatch(handleInvoiceSpread(openPanelId, openSecondMenu, openSubObjectId));
        },
        handleInvoiceSpreadClientSet: (clientJustSet: boolean) => {
            dispatch(handleInvoiceSpreadClientSet(clientJustSet));
        },
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceFillWizard);
