import React, { type MouseEvent } from 'react';
import styled from 'styled-components';
import { EezyButton } from '../../components/Buttons';
import { ListItem } from '../../components/layout/List';
import { COLOR_BLUM } from '../../styles/variables';
import { formatCents, trans } from '../../utils';
import type { ISalesPage } from './SalesPageCreate';

interface IListItemProps {
    salesPage: ISalesPage;
    selected?: boolean;
    handleClose?: (e: MouseEvent) => void;
}

const ServiceName = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
`;

const SalesPageListItem = (props: IListItemProps) => {
    const { salesPage, selected, handleClose } = props;

    return (
        <ListItem color={COLOR_BLUM}>
            <div className="item-content">
                <div style={{ color: COLOR_BLUM, minWidth: 0 }}>
                    <ServiceName style={{ fontWeight: 600 }} data-mf-replace="**REMOVED**">
                        {salesPage.serviceName}
                    </ServiceName>
                    <div>
                        {trans('eezyPay:price')}:{' '}
                        {trans('form.eurs', {
                            eurs: formatCents(salesPage.servicePrice, true),
                        })}
                    </div>
                </div>
                {selected && (
                    <div style={{ marginLeft: 'auto' }}>
                        <EezyButton color="purple" dark onClick={handleClose} width={90}>
                            {trans('menu.close')}
                        </EezyButton>
                    </div>
                )}
            </div>
        </ListItem>
    );
};

export default React.memo(SalesPageListItem);
