import type { ChangeEvent, CSSProperties, MouseEvent } from 'react';
import styled from 'styled-components';
import { COLOR_BLUM, COLOR_LILA, COLOR_WHITE_WALKER } from '../../styles/variables';

const Label = styled.label`
    background-color: transparent;
    border-radius: 4px;
    color: ${COLOR_LILA};
    cursor: pointer;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    letter-spacing: 0.125px;
    min-width: 70px;
    text-align: center;

    &.inverted {
        color: ${COLOR_BLUM};
        border: 1px solid ${COLOR_BLUM};
    }
`;

const Input = styled.input.attrs(() => ({
    type: 'checkbox',
}))`
    height: 1px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 0px;

    &:checked + label:not(.inverted) {
        background-color: ${COLOR_WHITE_WALKER};
        color: ${COLOR_BLUM};
    }
    &:checked + label.inverted {
        background-color: ${COLOR_BLUM};
        color: ${COLOR_LILA};
    }
    &:disabled + label {
        cursor: default;
    }
`;

interface ICheckboxProps {
    disabled?: boolean;
    checked: boolean;
    label: string;
    id: string;
    inverted?: boolean;
    name: string;
    value?: number | string;
    onChange: (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => void;
    style?: CSSProperties;
}

export const CheckboxButton = (props: ICheckboxProps) => {
    const classNames = props.inverted ? 'inverted' : '';
    return (
        <div>
            <Input
                id={props.id}
                disabled={props.disabled}
                name={props.name}
                onChange={props.onChange}
                checked={props.checked}
                value={props.value}
            />
            <Label htmlFor={props.id} style={props.style} className={classNames}>
                {props.label}
            </Label>
        </div>
    );
};
