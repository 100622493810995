import type React from 'react';
import styled from 'styled-components';
import type { IClient } from '../../../../shared/src/types/invoice';
import { trans } from '../../utils';
import Show from 'components/ui/Show';

const P = styled.p`
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type InvoiceRecipientDetails = {
    recipient: IClient | undefined;
};

export const InvoiceRecipientDetails: React.FC<InvoiceRecipientDetails> = ({
    recipient,
}: InvoiceRecipientDetails) => {
    if (!recipient) {
        return <></>;
    }

    switch (recipient.invoicingMethod) {
        case 'einvoice':
            return <P data-mf-replace="**REMOVED**">{recipient.einvoiceOvt}</P>;
        case 'mail':
            return (
                <>
                    <Show when={!!recipient.address?.street1}>
                        <P data-mf-replace="**REMOVED**">{recipient.address?.street1}</P>
                    </Show>
                    <Show when={!!recipient.address?.street2}>
                        <P data-mf-replace="**REMOVED**">{recipient.address?.street2}</P>
                    </Show>
                    <Show when={!!(recipient.address?.zipCode || recipient.address?.town)}>
                        <P data-mf-replace="**REMOVED**">
                            {recipient.address?.zipCode} {recipient.address?.town}
                        </P>
                    </Show>
                    <Show when={!!recipient.address?.country}>
                        <P data-mf-replace="**REMOVED**">
                            {trans(`countries:${recipient.address?.country}`)}
                        </P>
                    </Show>
                </>
            );
        case 'nosend':
            return null;
        default:
            return <P data-mf-replace="**REMOVED**">{recipient.email}</P>;
    }
};
