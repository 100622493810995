import { gql } from '@apollo/client';

const COPY_INVOICE = gql`
    mutation CopyInvoice($invoiceId: Int!) {
        copyInvoice(invoiceId: $invoiceId) {
            invoice {
                clientOrderNumber
                clientIdSiteNumber
                clientSiteNumber
                contactPersonId
                contactPersonName
                debtCollection
                description
                draftNumber
                dueDate
                eezyExpressStatus
                id
                interestPercentage
                invoiceNumber
                invoiceDate
                invoiceItems {
                    description
                    endDate
                    id
                    itemType
                    price
                    quantity
                    quantityUnit
                    startDate
                    totalPrice
                    totalPriceWithVat
                    vat
                }
                isGroupInvoice
                notificationDays
                occupation
                paymentInformation {
                    paidAmount
                    referenceNumber
                    totalWithVat
                    unpaidAmount
                }
                paymentTerm
                recipient {
                    active
                    address {
                        country
                        street1
                        street2
                        town
                        zipCode
                    }
                    businessId
                    contactPeople {
                        active
                        firstName
                        id
                        lastName
                    }
                    einvoiceOvt
                    operator
                    email
                    id
                    invoicingMethod
                    name
                    type
                }
                status
                template
                total
                totalVats {
                    percentage
                    total
                }
                totalWithVat
                turnBackReason
                url
            }
            meta {
                messages
            }
        }
    }
`;

export default COPY_INVOICE;
