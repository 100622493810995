import { gql } from '@apollo/client';

const CREATE_COST = gql`
    mutation CreateCost(
        $cost: InputReceiptCost!
        $invoiceId: Int
        $costInvoiceId: Int
    ) {
        createReceiptCost(
            cost: $cost
            invoiceId: $invoiceId
            costInvoiceId: $costInvoiceId
        ) {
            id
            amountsWithVats {
                amount
                id
                vatPercent
            }
            createTime
            description
            purchaseDate
            receiptFiles {
                contentType
                createTime
                id
                name
                previewUrl
                url
            }
            type
        }
    }
`;

export default CREATE_COST;
