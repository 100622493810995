export enum UserEventNames {
    GIOSG_1_AFTER_INVOICE_SEND_SUCCESS = 'GIOSG_1_AFTER_INVOICE_SEND_SUCCESS',
    GIOSG_2_EEZY_PAY_AD = 'GIOSG_2_EEZY_PAY_AD',
    GIOSG_2_SURVEY = 'GIOSG_2_SURVEY',
    GIOSG_NEW_USER = 'GIOSG_NEW_USER',
}

export enum UserEventCategories {
    GIOSG = 'GIOSG',
}

export enum EmailVerificationStatus {
    catch_all = 'catch_all',
    mailbox_full = 'mailbox_full',
    role_based = 'role_based',
    unknown = 'unknown',
    syntax_error = 'syntax_error',
    typo = 'typo',
    mailbox_not_found = 'mailbox_not_found',
    disposable = 'disposable',
    mailbox_blocked = 'mailbox_blocked',
}

export enum GTMEvents {
    sign_up = 'sign_up',
    reset_password = 'reset_password',
    eezypay_interest = 'eezypay_interest',
    eezypay_sign_up = 'eezypay_sign_up',
    first_invoice = 'first_invoice',
    payment_page_created = 'payment_page_created',
    invoice_sent = 'invoice_sent',
    identity_verified = 'identity_verified',
}

export enum GtagCommands {
    event = 'event',
}
