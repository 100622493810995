import { TextareaAutosize } from '@mui/material';
import styled from 'styled-components';
import {
    BORDER_OPTIONAL_FIELD,
    COLOR_BLACKWATER,
    COLOR_DARK_GRAY,
    COLOR_GREYJOY,
    FOCUS_COLOR,
} from '../../styles/variables';

interface IProps {
    $required?: boolean;
    $showBorder?: boolean;
    $variant: 'form' | 'plain' | string;
}

export const TextArea = styled(TextareaAutosize).attrs(() => ({
    spellCheck: 'false',
}))`
    background-color: transparent;
    border-bottom: ${(props: IProps) =>
        (props.$variant === 'form' || props.$showBorder) && props.$required
            ? `1px solid ${COLOR_GREYJOY}`
            : (props.$variant === 'form' || props.$showBorder) && !props.$required
              ? BORDER_OPTIONAL_FIELD
              : 'none'};
    color: ${(props: IProps) => (props.$variant === 'form' ? COLOR_BLACKWATER : COLOR_DARK_GRAY)};
    font-size: 15px;
    line-height: ${(props: IProps) => (props.$variant === 'form' ? '40px' : '20px')};
    margin-top: ${(props: IProps) => (props.$variant === 'form' ? '5px' : '0px')};
    resize: none;
    width: 100%;

    &::placeholder {
        color: ${COLOR_GREYJOY};
    }

    &:focus {
        border-style: solid;
        border-color: ${FOCUS_COLOR};
    }

    &:disabled {
        border-bottom: none;
    }
`;
