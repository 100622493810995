import { gql } from '@apollo/client';

const SEND_INVOICE = gql`
    mutation SendInvoice($id: Int!, $messageToEezy: String, $hasContract: Boolean) {
        sendInvoiceById(invoiceId: $id, messageToEezy: $messageToEezy, hasContract: $hasContract) {
            id
            status
        }
    }
`;

export default SEND_INVOICE;
