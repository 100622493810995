import {
    ADD_COST_FILE,
    ADD_INVOICE_ATTACHMENT,
    ADD_INVOICE_LOGO,
    ADD_MESSAGE_ATTACHMENT,
    ADD_TAX_CARD,
    DELETE_COST_FILE_ID,
    DELETE_INVOICE_ATTACHMENT,
    DELETE_MESSAGE_ATTACHMENT,
    FILE_UPLOAD_BEGIN,
    FILE_UPLOAD_FAILURE,
    FILE_UPLOAD_FAILURE_HIDE,
} from '../actionTypes';
import { getError, type IClientError } from '../utils/error';

export interface ITempFile {
    id: number;
    name: string;
    size?: number;
    url?: string;
}

export interface IFileState {
    costReceiptFiles: ITempFile[];
    error: IClientError | null;
    invoiceAttachment: ITempFile | null;
    loading: boolean;
    messageAttachments: ITempFile[];
    taxCard: ITempFile | null;
    invoiceLogo?: string;
}

const initialState: IFileState = {
    costReceiptFiles: [],
    error: null,
    invoiceAttachment: null,
    loading: false,
    messageAttachments: [],
    taxCard: null,
};

// biome-ignore lint/style/useDefaultParameterLast: Redux will be eventually removed
export default (state = initialState, action: any) => {
    switch (action.type) {
        case FILE_UPLOAD_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case FILE_UPLOAD_FAILURE:
            return {
                ...state,
                error: getError(action.payload),
                loading: false,
            };
        case FILE_UPLOAD_FAILURE_HIDE:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case ADD_COST_FILE:
            return {
                ...state,
                costReceiptFiles: [...state.costReceiptFiles, action.payload],
                loading: false,
            };
        case ADD_TAX_CARD:
            return {
                ...state,
                loading: false,
                taxCard: action.payload,
            };
        case DELETE_COST_FILE_ID:
            return {
                ...state,
                costReceiptFiles: state.costReceiptFiles.filter((f) => f.id !== action.payload.id),
            };
        case ADD_INVOICE_ATTACHMENT:
            return {
                ...state,
                invoiceAttachment: action.payload,
                loading: false,
            };
        case ADD_INVOICE_LOGO:
            return {
                ...state,
                invoiceLogo: action.payload,
                loading: false,
            };
        case ADD_MESSAGE_ATTACHMENT:
            return {
                ...state,
                messageAttachments: [...state.messageAttachments, action.payload],
                loading: false,
            };
        case DELETE_INVOICE_ATTACHMENT:
            if (state.invoiceAttachment?.id === action.payload.id) {
                return {
                    ...state,
                    invoiceAttachment: null,
                };
            }
            return state;
        case DELETE_MESSAGE_ATTACHMENT:
            return {
                ...state,
                messageAttachments: state.messageAttachments.filter((f) => f.id !== action.payload.id),
            };
        default:
            return state;
    }
};
