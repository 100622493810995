import { useMutation } from '@apollo/client';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import type { IMessageInput, IMessageReferenceInput } from '../../../../shared/src/types/message';
import { RightSideBody } from '../../components/layout/RightSideBody';
import { trans } from '../../utils';
import MessageForm from './MessageForm';
import { CREATE_MESSAGE_THREAD, GET_MESSAGE_THREADS } from './queries';

const NewMessageView = () => {
    const navigate = useNavigate();
    const [relatedObject, setRelatedObject] = React.useState<IMessageReferenceInput | undefined>(undefined);
    const [subject, setSubject] = React.useState<string | undefined>(undefined);
    const [content, setContent] = React.useState<string | undefined>(undefined);

    const location = useLocation();
    const routeState: any = location.state;

    React.useEffect(() => {
        // To preserve state when route is changed, e.g. when menu opened
        if (routeState?.relatedObject) {
            setRelatedObject(routeState.relatedObject);
        }
        if (routeState?.message) {
            setContent(routeState?.message?.content);
            setSubject(routeState?.message?.subject);
        }
    }, [routeState]);

    const [createMessageThread, { loading }] = useMutation(CREATE_MESSAGE_THREAD, {
        onCompleted: () => {
            toast(trans('support:new-message.sent-success'));
            // if createThread returnend the thread, we could redirect
            // to thread view
            navigate('/support');
        },
        onError: () => toast.error(trans('errors.general')),
        refetchQueries: () => [{ query: GET_MESSAGE_THREADS }], // createThread returns a message, not a thread it created
    });

    const handleSend = (message: IMessageInput, callback: () => void) => {
        createMessageThread({ variables: { message } }).then(() => {
            callback();
        });
    };

    return (
        <RightSideBody>
            <MessageForm
                isNew
                onSend={handleSend}
                loadingSend={loading}
                message={{ content, subject }}
                relatedObject={relatedObject}
            />
        </RightSideBody>
    );
};

export default NewMessageView;
