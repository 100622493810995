import React from 'react';
import styled from 'styled-components';
import type { ICostComponentProps } from '../../utils/costs/costLogic';
import { checkDirectionsRendererServices, initMap, initMapWithDirections } from '../../utils/mapsUtils';

const Wrapper = styled.div``;

export const TravelMap = (props: ICostComponentProps) => {
    React.useEffect(() => {
        if (!checkDirectionsRendererServices()) {
            return undefined;
        }
        if (props.travelRouteDirections?.directions) {
            initMapWithDirections(
                document.getElementById('route-map'),
                props.travelRouteDirections.directions,
            );
        } else if (props.travelRoundDirections?.directions) {
            initMapWithDirections(
                document.getElementById('route-map'),
                props.travelRoundDirections.directions,
            );
        } else {
            initMap(document.getElementById('route-map'));
        }
    }, [props.travelRouteDirections, props.travelRoundDirections]);

    return (
        <Wrapper>
            <div id="route-map" style={{ height: 250 }} />
        </Wrapper>
    );
};
