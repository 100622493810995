import styled from 'styled-components';
import { COLOR_GREY_FOG } from '../styles/variables';

export const ExampleImage = styled.div`
    border: 1px solid ${COLOR_GREY_FOG};
    box-sizing: border-box;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 15px;
`;
