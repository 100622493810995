import { gql } from '@apollo/client';

const DELETE_ATTACHMENT = gql`
    mutation DeleteAttachment($attachmentId: Int!) {
        deleteAttachment(attachmentId: $attachmentId) {
            id
        }
    }
`;

export default DELETE_ATTACHMENT;
