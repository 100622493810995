import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import ErrorBox from '../../components/ErrorBox';
import LoadingSpinner from '../../components/Loading';
import { COLOR_BLUM } from 'styles/variables';
import { trans } from 'utils';
import { canCoworkerAddExpenses } from 'utils/invoice/invoiceLogic';
import CostDocument from '../costs/CostDocument';
import CREATE_EMPTY_COSTINVOICE from '../costs/queries/createEmptyCostInvoice';
import GroupInvoiceInfoBox from './GroupInvoiceInfoBox';
import { GET_INVOICE } from './queries';

const LoaderWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

const GroupInvoicePreview = () => {
    const navigate = useNavigate();
    const params = useParams();

    const id = Number.parseInt(params?.invoiceId || '', 10);

    const { data, loading, error } = useQuery(GET_INVOICE, {
        onError: () => {
            navigate('/invoices');
        },
        variables: { id, isGroupInvoice: true },
    });

    const invoice: IInvoice | undefined = data?.invoice;

    const redirectToInvoiceView = () => {
        navigate(`/group/${invoice?.id}`);
    };

    const [createEmptyCostInvoice] = useMutation(CREATE_EMPTY_COSTINVOICE, {
        onCompleted: redirectToInvoiceView,
        onError: () => {
            toast.error(trans('errors.general'));
        },
        variables: { invoiceId: id },
    });

    const addExpenses = () => {
        if (!invoice?.costInvoice) {
            createEmptyCostInvoice();
        } else {
            redirectToInvoiceView();
        }
    };

    return (
        <>
            {loading && !error && (
                <LoaderWrapper>
                    <LoadingSpinner color={COLOR_BLUM} size="3em" />
                </LoaderWrapper>
            )}
            {!loading && !error && (
                <>
                    <GroupInvoiceInfoBox
                        buttonDisabled={!canCoworkerAddExpenses(invoice)}
                        invoice={invoice}
                        preview={true}
                        isOwner={false}
                        handleAddExpenses={addExpenses}
                    />
                    {invoice?.costInvoice && (
                        <CostDocument editable={false} invoice={invoice} invoiceStatus={invoice.status} />
                    )}
                </>
            )}
            {error && <ErrorBox>{error.message}</ErrorBox>}
        </>
    );
};

export default GroupInvoicePreview;
