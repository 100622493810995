import { gql } from '@apollo/client';

const GET_ALLOWANCES = gql`
    query GetSalaryPrices {
        salaryPrices {
            identifier
            year
            name {
                en
                fi
            }
            price
            taxable
            type
        }
    }
`;

export default GET_ALLOWANCES;
