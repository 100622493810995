import styled from 'styled-components';

export const EmbedA4Wrapper = styled.div`
    height: 0;
    padding-bottom: 138%;
    position: relative;
    width: 100%;

    object {
        height: 100%;
        position: absolute;
        width: 100%;
    }
`;
