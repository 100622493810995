import { type ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import type {
    ClientType,
    IClientChoiceInput,
    IContactPerson,
    IYtjBriefCompany,
} from '../../../../../shared/src/types/invoice';
import { EezyButton } from '../../../components/Buttons';
import { FormInput, FormRadio, FormSection, FormSelect, SplitRow } from '../../../components/form';
import type { IDropdownOption } from '../../../components/form/AutocompleteDropdown';
import { FormBackground } from '../../../components/form/FormBackground';
import { FormLabel } from '../../../components/form/FormLabel';
import { trans } from '../../../utils';
import { getErrors } from '../../../utils/apolloErrors';
import { invoicingMethodOptions, recipientTypeOptions } from '../../../utils/invoice/invoiceLogic';
import { getCountriesOptions } from '../../../utils/profile/contact';
import type { IRecipientFormState } from '../../../utils/recipient/recipientLogic';
import { sortObjectsByLabel } from '../../../utils/str';
import type { IDocument } from '../../../utils/user/userUtils';
import { UPDATE_INVOICE_WITH_RECIPIENT } from '../queries';
import { NEW_INVOICE_NEW_RECIPIENT } from '../queries/invoiceStateQueries';
import CompanyNameField from './CompanyName';
import ContactPeopleForm from './ContactPeopleForm';
import PersonNameField from './PersonName';
import OperatorSelection from './OperatorSelection';
import { closeAllFillHelpers, openFillHelper } from './utils';
import InfoMessage from 'components/InfoMessage';

interface IProps {
    disableContactPersonSelect?: boolean;
    disableRecipientChange?: boolean;
    errors: any;
    formData: IRecipientFormState;
    handleAddressChange: (val: string, name: string) => void;
    handleChange: (val: string, name: string) => void;
    handleContactPersonsChange: (contacts: IContactPerson[], selectedContactPersonIndex?: number) => void;
    handleSave: () => void;
    handleTypeChange?: (val: string, name: string) => void;
    invoiceId?: number;
    readOnly?: boolean;
    recipientId?: string;
    showErrors?: boolean;
    showModals?: (types: string[]) => void;
}

const RecipientForm = (props: IProps) => {
    const client = useApolloClient();

    const [updateInvoiceWithRecipient, { loading: updateLoading }] = useMutation(
        UPDATE_INVOICE_WITH_RECIPIENT,
        {
            onError: (e: ApolloError) => {
                const errors = getErrors(e);
                if (errors?.mustAccept && props.showModals) {
                    props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
                } else {
                    toast.error(trans('errors.general'));
                    closeAllFillHelpers(client);
                }
            },
        },
    );

    const handleRecipientChange = (recipient: IClientChoiceInput) => {
        if (props.invoiceId) {
            updateInvoiceWithRecipient({
                variables: {
                    contactPersonId: null,
                    id: props.invoiceId,
                    recipient,
                },
            });
            openFillHelper(client, 'recipient', recipient.id);
        } else {
            // User is creating new recipient on new invoice, thus the recipient
            // has to somehow be passed to NewInvoice.tsx. A bit hacky way to do it.
            closeAllFillHelpers(client);
            client.writeQuery({
                data: {
                    newInvoiceRecipient: {
                        __typename: 'NewInvoiceRecipient',
                        contactPersonId: null,
                        id: recipient.id,
                        type: recipient.type,
                    },
                },
                query: NEW_INVOICE_NEW_RECIPIENT,
            });
        }
    };

    const handleYtjRecipientChange = (r: IYtjBriefCompany) => {
        openFillHelper(client, 'new-ytj-recipient', r.businessId);
    };

    const recipientLanguageOptions = (): IDropdownOption[] => {
        return [
            { label: trans('languages.fi'), value: 'fi' },
            { label: trans('languages.en'), value: 'en' },
            { label: trans('languages.sv'), value: 'sv' },
        ];
    };

    return (
        <FormBackground as={'form'}>
            <FormSection>
                <FormRadio
                    disabled={props.readOnly || !!props.recipientId}
                    name="type"
                    onChange={props.handleTypeChange || props.handleChange}
                    options={recipientTypeOptions()}
                    value={props.formData.recipient.type}
                />
            </FormSection>

            <FormSection>
                {props.formData.recipient.type === 'company' && (
                    <>
                        <CompanyNameField
                            disableRecipientChange={props.disableRecipientChange}
                            errors={props.showErrors && props.errors?.company}
                            formData={props.formData.recipient}
                            handleChange={props.handleChange}
                            handleRecipientChange={handleRecipientChange}
                            handleYtjRecipientChange={handleYtjRecipientChange}
                            invoiceId={props.invoiceId}
                            recipientId={props.recipientId}
                            readOnly={props.readOnly}
                            required
                            showErrors={props.showErrors}
                        />
                        <FormInput
                            error={props.showErrors && props.errors?.businessId}
                            disabled={props.readOnly}
                            label={trans('invoice.form.recipient.businessId.label')}
                            name="businessId"
                            onBlur={props.handleChange}
                            placeholder={trans('invoice.form.recipient.businessId.ph')}
                            required
                            value={props.formData.recipient.businessId}
                        />

                        {props.formData.recipient.type === 'company' && (
                            <ContactPeopleForm
                                contactPeople={props.formData.recipient.contactPeople || []}
                                disableSelect={props.disableContactPersonSelect}
                                handleChange={props.handleContactPersonsChange}
                                invoiceId={props.invoiceId}
                                loading={updateLoading}
                                readonly={props.readOnly}
                                selectedContactPersonIndex={props.formData.selectedContactPersonIndex}
                                showErrors={props.showErrors}
                            />
                        )}
                    </>
                )}

                {props.formData.recipient.type === 'person' && (
                    <PersonNameField
                        disableRecipientChange={props.disableRecipientChange}
                        errors={props.showErrors && props.errors}
                        formData={props.formData.recipient}
                        handleChange={props.handleChange}
                        invoiceId={props.invoiceId}
                        recipientId={props.recipientId}
                        readOnly={props.readOnly}
                        required={true}
                        showErrors={props.showErrors}
                    />
                )}
            </FormSection>
            <FormSection>
                <SplitRow>
                    <FormLabel style={{ paddingRight: '30px' }}>
                        {trans('invoice.form.recipient.invoicingMethod.label')}
                    </FormLabel>
                    <FormSelect
                        disabled={props.readOnly}
                        name="invoicingMethod"
                        onChange={props.handleChange}
                        options={invoicingMethodOptions(props.formData.recipient.type as ClientType)}
                        required
                        showIcon
                        value={props.formData.recipient.invoicingMethod || 'email'}
                    />
                </SplitRow>
                {props.formData.recipient.invoicingMethod === 'mail' && (
                    <InfoMessage message={trans('invoice.templates.mailSendingAdditionalFee')} background />
                )}
            </FormSection>
            {props.formData.recipient.type === 'company' &&
            props.formData.recipient.invoicingMethod === 'einvoice' ? (
                <OperatorSelection handleChange={props.handleChange} formData={props.formData.recipient} />
            ) : null}
            <FormSection>
                {props.formData.recipient.invoicingMethod === 'email' && (
                    <FormInput
                        data-mf-replace="**REMOVED**"
                        error={props.showErrors && props.errors?.email}
                        disabled={props.readOnly}
                        label={trans('invoice.form.recipient.invoicingMethod.email')}
                        name="email"
                        required
                        type="email"
                        onBlur={props.handleChange}
                        value={props.formData.recipient.email}
                    />
                )}
                {props.formData.recipient.invoicingMethod === 'einvoice' && (
                    <FormInput
                        data-mf-replace="**REMOVED**"
                        error={props.showErrors && props.errors?.einvoiceOvt}
                        disabled={props.readOnly}
                        label={trans('invoice.form.recipient.invoicingMethod.einvoice')}
                        name="einvoiceOvt"
                        onBlur={props.handleChange}
                        required
                        value={props.formData.recipient.einvoiceOvt}
                    />
                )}
            </FormSection>
            <FormSection>
                <FormInput
                    data-mf-replace="**REMOVED**"
                    error={props.showErrors && props.errors && props.errors['address.street1']}
                    disabled={props.readOnly}
                    label={trans('invoice.form.recipient.street1.label')}
                    name={'street1'}
                    onBlur={props.handleAddressChange}
                    placeholder={trans('invoice.form.recipient.street1.ph')}
                    required
                    value={props.formData.recipient.address?.street1}
                />
                <FormInput
                    data-mf-replace="**REMOVED**"
                    error={props.showErrors && props.errors && props.errors['address.street2']}
                    disabled={props.readOnly}
                    label={trans('invoice.form.recipient.street2')}
                    name="street2"
                    onBlur={props.handleAddressChange}
                    value={props.formData.recipient.address?.street2}
                />
                <SplitRow>
                    <FormInput
                        data-mf-replace="**REMOVED**"
                        error={props.showErrors && props.errors && props.errors['address.zipCode']}
                        disabled={props.readOnly}
                        label={trans('invoice.form.recipient.zipCode.label')}
                        name="zipCode"
                        onBlur={props.handleAddressChange}
                        placeholder={trans('invoice.form.recipient.zipCode.ph')}
                        required
                        value={props.formData.recipient.address?.zipCode}
                    />
                    <FormInput
                        data-mf-replace="**REMOVED**"
                        error={props.showErrors && props.errors && props.errors['address.town']}
                        disabled={props.readOnly}
                        label={trans('invoice.form.recipient.town.label')}
                        name="town"
                        onBlur={props.handleAddressChange}
                        placeholder={trans('invoice.form.recipient.town.ph')}
                        required
                        value={props.formData.recipient.address?.town}
                    />
                </SplitRow>

                <FormSelect
                    data-mf-replace="**REMOVED**"
                    error={props.showErrors && props.errors && props.errors['address.country']}
                    disabled={props.readOnly}
                    label={trans('invoice.form.recipient.country')}
                    name="country"
                    onChange={props.handleAddressChange}
                    options={sortObjectsByLabel(getCountriesOptions())}
                    required
                    showIcon
                    value={props.formData.recipient.address?.country}
                />
            </FormSection>
            <FormSection>
                <FormRadio
                    disabled={props.readOnly}
                    label={trans('invoice.form.recipient.language')}
                    name="invoiceLanguage"
                    onChange={props.handleChange}
                    options={recipientLanguageOptions()}
                    value={props.formData.recipient.invoiceLanguage}
                />
            </FormSection>
            <FormSection>
                <div>
                    <EezyButton
                        color="important"
                        dark
                        onClick={() => {
                            props.handleSave();
                        }}
                    >
                        {trans('general.save')}
                    </EezyButton>
                </div>
            </FormSection>
        </FormBackground>
    );
};

export default RecipientForm;
