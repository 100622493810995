export const yelInsuranceCompanyList = [
    { key: 'Elo', value: 'Elo' },
    { key: 'Ilmarinen', value: 'Ilmarinen' },
    { key: 'Varma', value: 'Varma' },
    { key: 'Veritas', value: 'Veritas' },
];

export const eloDocumentUrl = (lang: string) => {
    switch (lang) {
        case 'fi':
            return 'https://www.elo.fi/-/media/files/vakuuttaminen-esitteet/vakuutusehdot-yel';
        default:
            return 'https://www.elo.fi/-/media/files/vakuuttaminen-esitteet/terms-and-conditions-yel';
    }
};

export const yelSummaryLink = (lang: string) => {
    switch (lang) {
        case 'fi':
            return 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/kevytyrittajan-vakuutukset/yel-vakuutus/';
        default:
            return 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/light-entrepreneurs-insurances/yel-insurance/';
    }
};
