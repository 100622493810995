import { gql } from '@apollo/client';

const GET_MESSAGE_THREADS = gql`
    query AllThreads {
        allThreads {
            hasUnread
            id
            lastMessageDate
            title
            messages {
                id
                category
                content
                createdAt
                files {
                    contentType
                    createTime
                    id
                    name
                    previewUrl
                    size
                    url
                }
                isFromEezy
                isRead
                relatedObject {
                    id
                    number
                    type
                }
            }
        }
    }
`;

export default GET_MESSAGE_THREADS;
