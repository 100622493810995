import { useQuery } from '@apollo/client';
import React from 'react';
import { P } from '../../components/textElements';
import { COLOR_IMPORTANT } from '../../styles/variables';
import { trans } from '../../utils';
import { getById } from '../../utils/invoice/invoiceLogic';
import invoiceTemplates, {
    DEFAULT_INVOICE_TEMPLATE,
    type IInvoiceTemplate,
} from '../../utils/invoice/invoiceTemplates';
import { GET_INVOICES } from '../dashboard/queries';
import InvoiceDocument from '../invoice/InvoiceDocument';
import { GET_INVOICE } from '../invoice/queries';

interface IProps {
    firstItemFetchedCallback?: () => void;
    invoiceId: number;
}

const SalaryPreviewInvoiceWrapper = (props: IProps) => {
    const { data: allInvoicesData } = useQuery(GET_INVOICES, {
        errorPolicy: 'all',
    });

    const {
        data: invoiceData,
        loading,
        networkStatus,
    } = useQuery(GET_INVOICE, {
        notifyOnNetworkStatusChange: true,
        variables: { id: props.invoiceId },
    });

    const selectedTemplate: IInvoiceTemplate =
        invoiceTemplates.find((t) => t.id === invoiceData?.invoice?.template) || DEFAULT_INVOICE_TEMPLATE;

    React.useEffect(() => {
        if (networkStatus === 7 && props.firstItemFetchedCallback) {
            // 7: ready
            // https://github.com/apollographql/apollo-client/blob/main/src/core/networkStatus.ts
            props.firstItemFetchedCallback();
        }
    }, [props, networkStatus]);

    if (invoiceData || loading) {
        return (
            <InvoiceDocument
                editable={false}
                invoice={invoiceData?.invoice}
                invoiceErrors={undefined}
                invoiceBrief={getById(props.invoiceId, allInvoicesData?.allInvoices?.items)}
                loading={loading}
                handleInvoiceUpdate={() => null}
                template={selectedTemplate}
            />
        );
    }
    return <P color={COLOR_IMPORTANT}>{trans('salaries:invoiceError', { id: props.invoiceId })}</P>;
};

export default SalaryPreviewInvoiceWrapper;
