import type { ReactNode } from 'react';
import styled from 'styled-components';

interface IDashboardWrapper {
    children?: ReactNode;
}

const Wrapper = styled.div`
    .help-button-xs,
    .feedback-button-xs {
        display: none;
    }
`;

// Indicate Dashboard Page root
const DashboardWrapper = (props: IDashboardWrapper) => {
    return <Wrapper id="dashboard">{props.children}</Wrapper>;
};

export default DashboardWrapper;
