import { gql } from '@apollo/client';

const GET_SUBSCRIPTION = gql`
    query getSubscription {
        subscriptions {
            endDate
            campwireEndDate
        }
    }
`;

export default GET_SUBSCRIPTION;
