import type { ClientType } from './../../../../shared/src/types/invoice';
export type FieldName = 'clientIdSiteNumber' | 'clientOrderNumber' | 'clientSiteNumber';

export interface IInvoiceField {
    id: string;
    name: FieldName;
}

export const VAT_PERCENTAGES = (() => {
    const now = new Date();
    const newVAT = new Date(2024, 7, 14); // 14 Aug 2024 will show new VAT rate
    const removeOldVAT = new Date(2025, 0, 1); // 1 Jn 2025 will remove old VAT rate

    const rates = [0, 10];

    if (now < removeOldVAT) {
        rates.push(24);
    }

    if (now >= newVAT) {
        rates.push(25.5);
    }

    return rates;
})();

export interface IInvoiceTemplate {
    additionalFields: IInvoiceField[];
    allowedClientTypes: ClientType[];
    id: string; // VAT group in Aava
    name: string;
    vatPercentages: number[];
}

const clientOrderNumberField: IInvoiceField = {
    id: 'invoice.otherFields.clientOrderNumber',
    name: 'clientOrderNumber',
};

export const DEFAULT_INVOICE_TEMPLATE: IInvoiceTemplate = {
    additionalFields: [clientOrderNumberField],
    allowedClientTypes: ['company', 'person'],
    id: 'domestic',
    name: 'invoice.templates.domestic',
    vatPercentages: [0, 10, 24, 25.5],
};

const invoiceTemplates: IInvoiceTemplate[] = [
    DEFAULT_INVOICE_TEMPLATE,
    {
        additionalFields: [
            clientOrderNumberField,
            {
                id: 'invoice.otherFields.clientIdSiteNumber',
                name: 'clientIdSiteNumber',
            },
            {
                id: 'invoice.otherFields.clientSiteNumber',
                name: 'clientSiteNumber',
            },
        ],
        allowedClientTypes: ['company'],
        id: 'reverse_vat_construction_industry',
        name: 'invoice.templates.reverse_vat_construction_industry',
        vatPercentages: [0],
    },
    {
        additionalFields: [clientOrderNumberField],
        allowedClientTypes: ['company', 'person'],
        id: 'eu',
        name: 'invoice.templates.eu',
        vatPercentages: [0],
    },
    {
        additionalFields: [clientOrderNumberField],
        allowedClientTypes: ['company', 'person'],
        id: 'foreign',
        name: 'invoice.templates.foreign',
        vatPercentages: [0, 24, 25.5],
    },
];

export default invoiceTemplates;
