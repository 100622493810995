import { trans } from '..';
import type { IDropdownOption } from './../../components/form/AutocompleteDropdown';

const salaryPayments = [
    '10', // 'immediately',
    '2', // 'first_day_of_month',
    '12', // 'on_7th_of_month',
    '3', // 'middle_of_month',
    '13', // 'on_22nd_of_month',
    '4', // 'last_day_of_month',
    '14', // 'on_1st_and_14th_of_month',
    '15', // 'on_7st_and_22nd_of_month',
    '16', // 'on_14th_and_last_day_of_month',
];

export const getSalaryPaymentOptions = (): IDropdownOption[] => {
    return salaryPayments.map((id: string) => ({
        label: trans(`profile:salary.payments.${id}`),
        value: id,
    }));
};
