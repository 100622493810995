import React from 'react';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import { Checkbox } from '../../components/form';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { P } from '../../components/textElements';
import { trans } from '../../utils';
import { isInvoicePartlyPaid } from '../../utils/invoice/invoiceLogic';

interface IModalProps {
    handleModalClose: () => void;
    handleCredit: (sendToClient: boolean) => void;
    invoice: IInvoice;
    isOpen: boolean;
}

const InvoiceCreditModal = (props: IModalProps) => {
    const [sendToClient, setSendToClient] = React.useState(true);

    const handleClose = () => {
        props.handleModalClose();
    };

    const handleSend = () => {
        props.handleCredit(sendToClient);
        handleClose();
    };

    const partlyPaid = isInvoicePartlyPaid(props.invoice);
    const transKey = partlyPaid ? 'partly-paid' : 'unpaid';

    return (
        <Modal
            id="invoice-credit"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={trans(`invoice.credit.${transKey}.title`)}
        >
            <ModalContent>
                <P>{trans(`invoice.credit.${transKey}.paragraph`)}</P>
                {transKey === 'partly-paid' && <P>{trans(`invoice.credit.${transKey}.paragraph2`)}</P>}

                <div style={{ marginTop: 30 }}>
                    <Checkbox
                        checked={sendToClient}
                        color="black"
                        label={trans(`invoice.credit.${transKey}.checkbox`)}
                        onChange={() => setSendToClient(!sendToClient)}
                    />
                </div>
            </ModalContent>
            <ModalActions>
                <EezyButton color="purple" onClick={handleClose}>
                    {trans('general.cancel')}
                </EezyButton>
                <EezyButton color="purple" dark onClick={handleSend}>
                    {trans(`invoice.credit.${transKey}.button`)}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default InvoiceCreditModal;
