import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormInput, FormRow, SplitRow } from '../../components/form';
import { SCREEN_S } from '../../styles/variables';
import type { ICostComponentProps } from '../../utils/costs/costLogic';
import { FormCheckmark } from 'components/form/FormCheckmark';
import { useTranslation } from 'react-i18next';
import { useUser } from 'queries/useUserQuery';

const Wrapper = styled.div`
    @media (min-width: ${SCREEN_S}px) {
        display: flex;
        justify-content: flex;
        margin-left: auto;
        padding-left: 5px;
    }
    margin: auto;
`;

const InputWrapper = styled.div`
    .form-input-label {
        font-weight: 600;
    }
    
    input:placeholder-shown {
        border-color: ${(props) => props.theme.colors?.warning || '#ff9800'};
        background-color: ${(props) => props.theme.colors?.warningLight || '#fff3e0'};
    }
`;

export const CarRegistrationNumber = (props: ICostComponentProps) => {
    const { t } = useTranslation();
    const user = useUser();
    const { dispatch } = props;
    const [numberSaved, setNumberSaved] = useState(false);
    const [carNumber, setCarNumber] = useState('');
    const [initialSetupDone, setInitialSetupDone] = useState(false);

    const savedCarNumber = user?.preferences?.carNumber;

    useEffect(() => {
        if (!initialSetupDone) {
            if (!props.currentTravel?.carNumber && savedCarNumber) {
                setCarNumber(savedCarNumber);
                dispatch({
                    payload: { carNumber: savedCarNumber },
                    type: 'SAVE_TRAVEL',
                });
            } else if (props.currentTravel?.carNumber) {
                setCarNumber(props.currentTravel.carNumber);
            }
            setInitialSetupDone(true);
        }
    }, [initialSetupDone, props.currentTravel?.carNumber, savedCarNumber, dispatch]);

    const handleNumberInput = React.useCallback(
        (val: string) => {
            setCarNumber(val);
            dispatch({
                payload: { carNumber: val },
                type: 'SAVE_TRAVEL',
            });
        },
        [dispatch],
    );

    const handleNumberSave = React.useCallback(() => {
        dispatch({
            payload: { carNumberSaved: !numberSaved },
            type: 'SAVE_TRAVEL',
        });
    }, [dispatch, numberSaved]);

    return (
        <Wrapper>
            <SplitRow style={{ width: '100%' }}>
                <FormRow style={{ flexGrow: 1 }}>
                    <InputWrapper>
                        <div
                            style={{
                                alignItems: 'flex-end',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <FormInput
                                label={`${t('costs.car-number')} *`}
                                onChange={handleNumberInput}
                                name="car-registration"
                                placeholder="ABC-123"
                                required
                                value={carNumber}
                            />
                        </div>
                    </InputWrapper>
                </FormRow>
                <FormRow style={{ flexGrow: 1 }}>
                    <div
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <FormCheckmark
                            id="save-car-number"
                            label={t('costs.car-number-save') || ''}
                            name="eezy-pay-terms"
                            checked={numberSaved}
                            onChange={() => {
                                setNumberSaved(!numberSaved);
                                handleNumberSave();
                            }}
                            required
                            value={'acceptTerms'}
                        />
                    </div>
                </FormRow>
            </SplitRow>
        </Wrapper>
    );
};
