import type React from 'react';
import type { ChangeEvent, CSSProperties, MouseEvent } from 'react';
import styled from 'styled-components';

import {
    COLOR_BLACKWATER,
    COLOR_DARK_GRAY,
    COLOR_GREY_FOG,
    COLOR_GREYJOY,
    FOCUS_COLOR,
} from '../../styles/variables';
import { FormRow, type IGeneralFormInputProps } from './';
import type { IDropdownOption } from './AutocompleteDropdown';
import { FormLabel } from './FormLabel';

const StyledCheckbox = styled.input.attrs(() => ({
    type: 'checkbox',
}))`
    opacity: 0;
    position: absolute;

    & + label:before {
        border: 1px solid ${COLOR_GREY_FOG};
        box-sizing: border-box;
        border-radius: 2px;
        content: ' ';
        display: inline-block;
        height: 30px;
        margin-right: 10px;
        vertical-align: middle;
        width: 30px;
    }

    &:checked + label:after {
        border: 3px solid ${COLOR_BLACKWATER};
        border-top: none;
        border-right: none;
        content: '';
        height: 5px;
        left: 7px;
        position: absolute;
        top: 10px;
        transform: rotate(-45deg);
        width: 14px;
    }

    &:disabled + label:after {
        border-color: ${COLOR_GREYJOY};
    }
`;

const StyledCircleCheckbox = styled.input.attrs(() => ({
    type: 'checkbox',
}))`
    opacity: 0;
    position: absolute;

    & + label:before {
        border: 1px solid ${COLOR_GREYJOY};
        box-sizing: border-box;
        border-radius: 50%;
        content: ' ';
        display: inline-block;
        height: 32px;
        margin-right: 10px;
        vertical-align: middle;
        width: 32px;
    }

    & + label {
        margin-right: 10px;
    }

    &:checked + label:after {
        border: 2px solid ${COLOR_BLACKWATER};
        border-top: none;
        border-right: none;
        content: '';
        height: 5px;
        left: 9px;
        position: absolute;
        top: 12px;
        transform: rotate(-45deg);
        width: 12px;
    }

    &:focus + label:before {
        outline: 1px solid ${FOCUS_COLOR};
        outline-offset: 3px;
    }
    &:disabled + label:after {
        border-color: ${COLOR_GREYJOY};
    }
    &:disabled + label:before {
        border-color: ${COLOR_GREY_FOG};
    }
    &:disabled + label {
        color: ${COLOR_GREYJOY};
    }
`;

const CheckboxWrapper = styled.div`
    color: ${COLOR_DARK_GRAY};
    position: relative;

    label {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 30px;
        letter-spacing: 0.15px;
        text-transform: none;
    }
    &:not(.disabled) {
        label:hover {
            cursor: pointer;
        }
    }
`;

const CheckboxFormBody = styled.div`
    flex: 1;
`;

interface IFormCheckmarkProps extends IGeneralFormInputProps {
    checked?: boolean;
    id: string;
    onChange: (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => void;
    value: string;
    style?: CSSProperties;
}

export const FormCheckmark = (props: IFormCheckmarkProps) => {
    return (
        <FormRow>
            <CheckboxWrapper className={props.disabled ? 'disabled' : ''} style={props.style}>
                <StyledCheckbox
                    id={props.id}
                    disabled={props.disabled}
                    checked={props.checked || false}
                    onChange={props.onChange}
                />
                <FormLabel error={props.error} htmlFor={props.id} warning={props.warning}>
                    {props.label}
                </FormLabel>
            </CheckboxWrapper>
        </FormRow>
    );
};

type IFormCheckmarkRowProps = IGeneralFormInputProps & {
    checked?: boolean;
    options: IDropdownOption[];
    value: string;
    className?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => void;
};

export const FormCheckmarkRow: React.FC<IFormCheckmarkRowProps> = (props: IFormCheckmarkRowProps) => {
    return (
        <div style={props.style} className={props.className}>
            {props.options.map((opt: any) => {
                return (
                    <CheckboxFormBody key={opt.value}>
                        <CheckboxWrapper
                            key={opt.value}
                            className={props.disabled || opt.disabled ? 'disabled' : ''}
                        >
                            <StyledCircleCheckbox
                                id={opt.value}
                                disabled={props.disabled || opt.disabled}
                                checked={props.value === opt.value}
                                onChange={props.onChange}
                                name={props.name}
                                value={opt.value}
                            />
                            <FormLabel
                                disabled={props.disabled || opt.disabled}
                                error={props.error}
                                warning={props.warning}
                                htmlFor={opt.value}
                            >
                                {opt.value}%
                            </FormLabel>
                        </CheckboxWrapper>
                    </CheckboxFormBody>
                );
            })}
        </div>
    );
};
