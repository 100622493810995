import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import type { ISalary } from '../../../../shared/src/types/salary';
import type { IUserBasicData } from '../../../../shared/src/types/user';
import { EezyButton, EezyLink } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { EmbedA4Wrapper } from 'components/layout/EmbedWrapper';
import { PreviewButtonWrapper, PreviewWrapper } from 'components/layout/PreviewWrapper';
import { WhiteDocumentArea } from 'components/layout/WhiteDocumentArea';
import LoadingSpinner from 'components/Loading';
import { P } from 'components/textElements';
import { Tools, ToolsHeader, ToolsRow } from 'components/TogglingTools';
import { COLOR_BLUM } from 'styles/variables';
import { formatCents, trans } from 'utils';
import { allowSalaryPdfDisplay, allowSalaryPdfDownload } from 'utils/salary/salaryLogic';
import RelatedObjectMessage from '../dashboard/RelatedObjectMessage';
import GET_USER_DATA from '../profile/queries/getUserData';
import ACCEPT_SALARY_PAYMENT from './queries/acceptSalaryPayment';
import GET_SALARY from './queries/getSalary';
import SalaryPreviewInvoiceWrapper from './SalaryPreviewInvoiceWrapper';

const InvoicesWrapper = styled.div`
    margin-top: 15px;

    & ${WhiteDocumentArea} {
        margin: 30px 0;
        width: 100%;
    }
    & > p {
        margin: 30px 0;
    }
`;

const SalaryPreview = () => {
    const [showInvoices, setShowInvoices] = React.useState(false);
    const [salaryWithdrawClicked, setSalaryWithdrawClicked] = React.useState(false);
    const scrollRef = React.useRef<HTMLSpanElement>(null);

    const params = useParams();
    const id = Number.parseInt(params?.salaryId || '', 10);

    const { data: salaryData } = useQuery(GET_SALARY, {
        variables: { id },
    });

    const { data: userData } = useQuery(GET_USER_DATA);

    const [acceptSalaryPayment, { loading: withdrawLoading }] = useMutation(ACCEPT_SALARY_PAYMENT, {
        onCompleted: () => {
            toast(trans('salaries:accept-salary-toast'));
            setSalaryWithdrawClicked(true);
        },
        onError: () => {
            toast.error(trans('errors.general'));
        },
        refetchQueries: [{ query: GET_SALARY, variables: { id } }],
    });

    const salary: ISalary = salaryData?.salary;
    const userProfileData: IUserBasicData = userData?.userData;

    const showRelatedInvoices = () => {
        setShowInvoices(true);
        scrollToInvoices();
    };

    const handleSalaryPay = (id: number) => {
        if (!withdrawLoading) {
            acceptSalaryPayment({ variables: { id } });
        }
    };

    React.useEffect(() => {
        setShowInvoices(false);
    }, [id]);

    const scrollToInvoices = React.useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [scrollRef]);

    const handleFirstInvoiceFetched = () => {
        scrollToInvoices();
    };

    return (
        <PreviewWrapper>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                {salary?.invoiceIds.length > 0 && (
                    <ToolsRow
                        buttonTransKey="file.show"
                        title={trans('salaries:showInvoices', {
                            amount: salary?.invoiceIds.length,
                        })}
                        onClick={showRelatedInvoices}
                    />
                )}

                {salary?.url && allowSalaryPdfDownload(salary?.status) && (
                    <Tools
                        header={
                            <ToolsHeader titleTransKey="salaries:downloadSalary">
                                <EezyLink
                                    color="purple"
                                    href={salary?.url}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <Icon icon={['far', 'arrow-to-bottom']} /> {trans('file.download')}
                                </EezyLink>
                            </ToolsHeader>
                        }
                        style={{ marginBottom: 30 }}
                    />
                )}
            </Box>
            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                <PreviewButtonWrapper>
                    <div>
                        {salary?.invoiceIds.length > 0 && (
                            <EezyButton
                                color={'purple'}
                                dark
                                onClick={showRelatedInvoices}
                                style={{ marginLeft: 12 }}
                            >
                                {trans('salaries:showInvoices', {
                                    amount: salary?.invoiceIds.length,
                                })}
                            </EezyButton>
                        )}
                    </div>
                    <div>
                        {salary?.url && allowSalaryPdfDownload(salary?.status) && (
                            <EezyLink
                                color="purple"
                                href={salary?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icon icon={['far', 'arrow-to-bottom']} /> {trans('file.download')}
                            </EezyLink>
                        )}
                    </div>
                </PreviewButtonWrapper>
            </Box>

            {salary && allowSalaryPdfDisplay(salary?.status) ? (
                <EmbedA4Wrapper>
                    <object
                        data={`${salary?.url}#toolbar=0&navpanes=0&view=fit`}
                        title={trans('salaries:documentTitle')}
                    >
                        <P color={COLOR_BLUM} style={{ marginTop: 60 }}>
                            {trans('salaries:salaryPreviewError')}
                        </P>
                    </object>
                </EmbedA4Wrapper>
            ) : salary ? (
                <>
                    <P color={COLOR_BLUM} style={{ margin: '15px 12px' }}>
                        {trans('salaries:notCounted-1')}
                    </P>
                    <P color={COLOR_BLUM} style={{ margin: '15px 12px' }}>
                        {trans('salaries:notCounted-2', {
                            amount: formatCents(salary.totalAmount),
                        })}
                    </P>
                    <P color={COLOR_BLUM} style={{ margin: '15px 12px' }}>
                        {trans('salaries:notCounted-3')}
                    </P>
                </>
            ) : null}

            {userProfileData &&
                userProfileData.salaryPaymentRuleId !== 10 &&
                salary?.status === 'unaccepted' &&
                !salaryWithdrawClicked && (
                    <>
                        <P color={COLOR_BLUM} style={{ margin: '15px 12px' }}>
                            {trans('salaries:withdraw-text')}
                        </P>
                        <EezyButton
                            color={'purple'}
                            dark
                            onClick={() => {
                                handleSalaryPay(salary!.id);
                            }}
                            style={{ marginLeft: 12 }}
                        >
                            {withdrawLoading ? <LoadingSpinner size="1em" /> : trans('salaries:withdraw')}
                        </EezyButton>
                    </>
                )}

            {salary && (
                <RelatedObjectMessage
                    relatedObject={{ id: salary.id, type: 'salary_payment' }}
                    style={{ marginLeft: 12 }}
                />
            )}

            {showInvoices && salary?.invoiceIds.length > 0 && (
                <InvoicesWrapper>
                    <span ref={scrollRef} />
                    {salary?.invoiceIds.map((invoiceId: number) => {
                        return (
                            <SalaryPreviewInvoiceWrapper
                                key={invoiceId}
                                invoiceId={invoiceId}
                                firstItemFetchedCallback={handleFirstInvoiceFetched}
                            />
                        );
                    })}
                </InvoicesWrapper>
            )}
        </PreviewWrapper>
    );
};

export default SalaryPreview;
