import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';
import { showModals } from '../actions/auth';

interface ITermsProps {
    showTerms: () => void;
}

const Terms = (props: ITermsProps) => {
    React.useEffect(() => {
        props.showTerms();
    });

    return <Navigate to="/" />;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        showTerms: () => {
            dispatch(showModals(['TERMS']));
        },
    };
};

export default connect(null, mapDispatchToProps)(Terms);
