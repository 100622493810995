const TermsEn = () => {
    return (
        <div>
            <div>
                <p>
                    <b>Contents</b>
                </p>
                <ul>
                    <li>
                        <a href="#point1">1. General information about using the Service</a>
                    </li>
                    <li>
                        <a href="#point2">2. Service Provider</a>
                    </li>
                    <li>
                        <a href="#point3">
                            3. The contractual relationships between the performer of work, Eezy and the
                            client
                        </a>
                    </li>
                    <li>
                        <a href="#point4">4. Processing of personal data and data protection</a>
                    </li>
                    <li>
                        <a href="#point5">5. Using the Service</a>
                    </li>
                    <ul>
                        <li>
                            <a href="#point5.1">
                                5.1 Rights and restrictions applicable to the use of the Service
                            </a>
                        </li>
                        <li>
                            <a href="#point5.2">5.2 Changes to the Service and Termination</a>
                        </li>
                        <li>
                            <a href="#point5.3">5.3 Registration</a>
                        </li>
                        <li>
                            <a href="#point5.4">5.4 Invoicing and payment of wages</a>
                        </li>
                        <li>
                            <a href="#point5.5">5.5. Insurances</a>
                        </li>
                        <li>
                            <a href="#point5.6">5.6 Price of the Service</a>
                        </li>
                    </ul>
                    <li>
                        <a href="#point6">6. Intellectual property rights</a>
                    </li>
                    <li>
                        <a href="#point7">7. Limitations of liability</a>
                    </li>
                    <li>
                        <a href="#point8">8. Amendments to the Terms and Conditions, and notifications</a>
                    </li>
                    <li>
                        <a href="#point9">9. Applicable law and resolving of disputes</a>
                    </li>
                </ul>
            </div>

            <div id="point1">
                <p>
                    <b>1. General information about using the Service</b>
                </p>
                <div className="p1">
                    <p>
                        By using Eezy Light Entrepreneurs Cooperative’s (“Eezy”) invoicing service
                        (“Service”), the performer of work accepts Eezy’s Terms and Conditions of Use (“Terms
                        and Conditions”) valid at each time and agrees to follow them and the terms,
                        conditions and instructions provided separately in the Service. The performer of work
                        must approve these Terms and Conditions when registering.
                    </p>
                    <p>
                        These Terms and Conditions apply to all actions by the performer of work in the
                        Service. The performer of work may only use the Service in accordance with the Terms
                        and Conditions valid at each time, applicable legislation and generally accepted
                        practices. The Terms and Conditions constitute the agreement between the performer of
                        work and Eezy, and specify the rights and obligations of Eezy and the performer of
                        work concerning the Service.
                    </p>
                    <p>
                        Eezy’s invoicing service may only be used by private individuals (“performers of
                        work”), who do not have their own business name and business ID.
                    </p>
                    <p>
                        Eezy’s invoicing service may not be used in situations in which an employment
                        relationship is effective or formed between the client and the performer of work.
                    </p>
                </div>
            </div>

            <div id="point2">
                <p>
                    <b>2. Service Provider</b>
                </p>
                <div className="p1">
                    <p>Eezy Light Entrepreneurs’ Cooperative</p>
                    <p>Business ID: 2197002-2</p>
                    <p>Maistraatinportti 1</p>
                    <p>00240 HELSINKI, Finland</p>
                    <p>Tel.+358 (0)9 4247 5630</p>
                    <p>info.kevytyrittajat@eezy.fi</p>
                </div>
            </div>

            <div id="point3">
                <p>
                    <b>3. The contractual relationships between the performer of work, Eezy and the client</b>
                </p>
                <div className="p1">
                    <p>
                        No employment relationship, as specified in Chapter 1, section 1 of the Finnish
                        Employment Contracts Act (55/2001), is formed between Eezy and the performer of work,
                        and the performer of work is not an employee of Eezy in terms of pension legislation.
                    </p>
                    <p>
                        When the performer of work has registered with Eezy and performs work which is
                        invoiced through Eezy in accordance with these Terms and Conditions, this person is an
                        employee in terms of taxation and has no legal obligation to keep books.
                    </p>
                    <p>
                        The performer of work finds assignments him/herself and agrees directly with the
                        client on the performance of work and the related prices. The performer of work must
                        agree on the work performances and set the prices for work taking into account the
                        fact that the client is not the work performer’s employer, and no employment
                        relationship is formed between the contracting parties. For the avoidance of doubt, it
                        is stated that the ‘client’ refers to the client of the performer of work.
                    </p>
                    <p>
                        Eezy is not a party to the agreement on the performance of work, which is concluded
                        between the performer of work and the client. The performer of work has no right to
                        conclude commission agreements, subcontracting agreements, instalment or credit
                        agreements in Eezy’s name, or make purchases to be invoiced in Eezy’s name. Eezy’s
                        business ID cannot be used for making purchases exclusive of VAT within the EU.
                    </p>
                    <p>
                        The performer of work must ensure that the client and any third parties are aware of
                        the contractual relationship between Eezy and the performer of work, and the contents
                        of these Terms and Conditions.
                    </p>
                    <p>
                        After Eezy has sent the invoice to the client, the performer of work is not entitled
                        to invoice their work through other means or to collect the amount received from the
                        client, unless expressly agreed otherwise with Eezy or Eezy informs that the
                        collection of the invoice by Eezy and its partners has ended.
                    </p>
                </div>
            </div>

            <div id="point4">
                <p>
                    <b>4. Processing of personal data and data protection</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy will process the personal data of the performer of work and the client, obtained
                        through the Service, in ways permitted and obligated in applicable personal data
                        legislation, and in compliance with the personal data processing agreement between
                        Eezy and the performer of work. The processing of personal data is described in
                        further detail in the privacy statements of data files, based on both the customer
                        relationship with Eezy and other material links.
                    </p>
                    <p>
                        Eezy agrees to keep all data of the performer of work, considered confidential,
                        absolutely secret and will not use such data in any way other than that required by
                        the cooperation between Eezy and the performer of work.
                    </p>
                    <p>
                        You can find the service's privacy policy on our{' '}
                        <a
                            href="https://kevytyrittajat.eezy.fi/en/privacy-policy/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            website.
                        </a>
                    </p>
                </div>
            </div>

            <div id="point5">
                <p>
                    <b>5. Using the Service</b>
                </p>

                <div className="p1" id="point5.1">
                    <p>
                        <b>5.1 Rights and restrictions applicable to the use of the Service</b>
                    </p>
                    <div className="p1">
                        <p>
                            Eezy grants the performer of work a restricted, revocable, non-exclusive and
                            personal right, as defined in these Terms and Conditions, to use the Service and
                            its contents, solely in accordance with these Terms and Conditions, and any terms,
                            conditions and instructions possibly separately provided in the Service. The
                            performer of work has the right to use the Service and its contents for personal
                            purposes only.
                        </p>
                        <p>
                            The performer of work must comply with legislation and official instructions valid
                            in Finland. The performer of work must follow any mandatory legal orders,
                            including occupational safety instructions, applicable to his/her operations.
                        </p>
                        <p>
                            The performer of work may not use the Service (a) to present him/herself as a
                            representative of Eezy; (b) to falsify or process header information, credentials
                            or identity data so as to try to conceal the origin of contents transmitted
                            through the Service; (c) to provide false information in any other way; (d) to
                            save or collect other users’ personal data; (e) to transmit junk mail or any other
                            unauthorised data transfer, or (f) for any other measures contrary to law or
                            generally accepted practices.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.2">
                    <p>
                        <b>5.2 Changes to the Service and Termination</b>
                    </p>
                    <div className="p1">
                        <p>
                            The Service and its contents are provided “as is”. Eezy has the right to modify or
                            change the Service or its contents at any time, without liability to compensate.
                        </p>
                        <p>
                            This means that Eezy has the right, i.a., to change or modify the information
                            provided in the Service, the products offered, or prices, to add and remove parts
                            of the Service, and make free-of-charge sections of the service subject to a
                            charge, or vice versa.
                        </p>
                        <p>
                            Eezy will inform the performer of work about any fundamental changes to the
                            service, including pricing, 14 days prior to the entry into force of the changes.
                            The notification period does not apply to mandatory changes resulting from
                            amendments to law or official instructions.
                        </p>
                        <p>
                            Eezy also has the right, at any time, to interrupt or terminate the Service, the
                            provision of its contents or any part thereof, in full, or restrict the use of the
                            Service or sections of the Service.
                        </p>
                        <p>
                            If a performer of work has acted contrary to these Terms and Conditions or any
                            terms, conditions or instructions provided separately in the Service, or
                            legislation and generally accepted practices, Eezy has the right to prevent the
                            performer of work from accessing the Service and prohibit further use of the
                            Service.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.3">
                    <p>
                        <b>5.3 Registration</b>
                    </p>
                    <div className="p1">
                        <p>
                            To gain the right of use specified above, the performer of work is required to
                            register in the Service.
                        </p>
                        <p>
                            When registering in the Service, the performer of work must provide the correct
                            personal data as requested. It is prohibited to provide false data, and doing so
                            may result in sanctions, including refusal to use the Service.
                        </p>
                        <p>
                            When registering, the performer of work selects an individual username (email
                            address) and password. The performer of work is responsible for keeping the
                            password secret and storing it with due care, and is personally responsible for
                            any transactions in the Service performed using his/her credentials. The performer
                            of work must contact Eezy immediately if the performer of work finds out, or
                            suspects, that his/her credentials have been disclosed to outsiders or that the
                            credentials are being misused. In such cases, Eezy has the right to freeze or
                            close the credentials of the performer of work without giving prior notice to the
                            performer of work.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.4">
                    <p>
                        <b>5.4 Invoicing and payment of wages</b>
                    </p>
                    <div className="p1">
                        <p>
                            Payment of wages to the performer of work is based on the sum, excluding VAT,
                            invoiced by the performer of work through Eezy.
                        </p>
                        <p>
                            The wage is paid to the performer of work after the client has paid the invoice to
                            Eezy's account. Eezy does not pay advances to the performer of work, but the date
                            of wage payment can be agreed earlier than mentioned above, for example, when
                            agreeing on EezyExpress wage.
                        </p>
                        <p>
                            Withholding tax will be calculated on the basis of the sum received through
                            invoicing, excluding VAT, and paid in accordance with the work performer’s tax
                            card, or information received from the Tax Administration. Eezy’s service fee and
                            applicable indirect costs will be calculated on the basis of the invoiced sum,
                            excluding VAT, and deducted from the net earnings of the performer of work.
                        </p>
                        <p>
                            For the purpose of payment of wages, the performer of work must submit a tax card
                            to Eezy, unless Eezy has received the information automatically from the Tax
                            Administration. If the performer of work has not delivered a valid tax card at the
                            time of wage payment, Eezy will withold tax at 60% from the wages of the performer
                            of work, as instructed by the Tax Administration.
                        </p>
                        <p>
                            The performer of work is responsible for ensuring the correctness of all
                            information provided in the Service, including filling in invoicing information
                            and applying for compensation of expenses.
                        </p>
                        <p>
                            Eezy reserves the right to not approve invoices which are contrary to these Terms
                            and Conditions, and Eezy’s practices.
                        </p>
                        <p>
                            If Eezy has paid the wage of the performer of work before the client has paid the
                            invoice to Eezy, and the client does not pay the invoice in full within a
                            reasonable time or Eezy has to refund the same payment to the client in full or in
                            part for some reason, the performer of work is obligated to refund the payment
                            received to Eezy in a similar proportion.
                        </p>
                        <p>
                            Eezy has the right to remove from the service any invoices which have not been
                            paid in full within one year from the date of the invoice.
                        </p>
                        <p>
                            The performer of work must specify his/her invoicing price so that the gross sum
                            of wages based on it is in accordance with applicable regulations, and corresponds
                            to the general level of earnings for entrepreneurs operating in the same line of
                            business.
                        </p>
                        <p>
                            If Eezy has outstanding receivables from the performer of work, Eezy has the right
                            to withhold them from the net wages paid to the performer of work when wages are
                            paid.
                        </p>
                        <p>
                            In order to establish the obligation to pay the employer’s health insurance
                            contribution, the performer of work must inform Eezy, truthfully, whether he or
                            she is under the obligation to take self-employed persons' pension insurance, and
                            update this information immediately if circumstances change. In case Eezy deducts
                            too high health insurance contributions on the payslip of the performer of work,
                            and pays these contributions to the Tax Administration due to incorrect
                            information provided by the performer of work, Eezy is not obliged to rectify the
                            calculations, notifications or payments, nor compensate any excess deductions
                            included in the payslip to the performer of work. If Eezy pays to the Tax
                            Administration too low health insurance contributions due to incorrect information
                            provided by the performer of work, the performer of work is obliged to compensate
                            to Eezy all payments falling due for payment by Eezy, inclusive of any increases
                            and interests, and all other expenses incurred to Eezy in the case.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.5">
                    <p>
                        <b>5.5 Insurances</b>
                    </p>
                    <div className="p1">
                        <p>
                            The performer of work is not insured under statutory insurance, but under Eezy’s
                            insurances valid at each time, which may include occupational accident and disease
                            insurance for working hours, business liability insurance and consultancy
                            insurance. A prerequisite for the validity of the insurances is that the performer
                            of work has registered in the Service.
                        </p>
                        <p>
                            Operational liability insurance does not cover losses for which the performer or
                            work is not liable on the grounds that they have been caused by an independent
                            entrepreneur who is to be paralleled an employee within the meaning of chapter 3,
                            section 1, subsection 1 of the Tort Liability Act.
                        </p>
                        <p>
                            If loss events occur, the performer of work is responsible for the payment of the
                            deductible of business liability insurance’s insurance compensation to the
                            insurance company.
                        </p>
                        <p>
                            The performer of work must take out self-employed persons' pension insurance if
                            the statutory preconditions are met.
                        </p>
                    </div>
                </div>

                <div className="p1" id="point5.6">
                    <p>
                        <b>5.6 Price of the Service </b>
                    </p>
                    <div className="p1">
                        <p>
                            Eezy’s service fee is 7 per cent of the invoiced sum, excluding VAT, unless
                            otherwise agreed with the performer of work.
                        </p>
                        <p>
                            For the avoidance of doubt, it is stated that the service fee referred to above
                            does not cover any additional services purchased separately by the performer of
                            work, which will be charged in accordance with{' '}
                            <a
                                href="https://kevytyrittajat.eezy.fi/en/price-list/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                the price list
                            </a>{' '}
                            valid at each time.
                        </p>
                        <p>Eezy will charge the service fee from the wages paid to the performer of work.</p>
                    </div>
                </div>
            </div>

            <div id="point6">
                <p>
                    <b>6. Intellectual property rights</b>
                </p>
                <div className="p1">
                    <p>
                        The Service and its contents, including text, pictures, product information, trade
                        marks and logos, are the property of Eezy or its partners, protected by copyright
                        legislation and international copyright agreements, as well as other legislation and
                        registrations of intellectual property rights.
                    </p>
                    <p>
                        All intellectual property rights related to the Service and its contents (including
                        copyrights, registered and unregistered trademark rights and design rights, domain
                        names, patents, database rights and business secrets) and the goodwill value resulting
                        from their use, belong to Eezy or its partners. Eezy does not grant the performer of
                        work any direct or indirect rights to any intellectual property rights.
                    </p>
                    <p>
                        Intellectual property rights belonging to the performer of work (including copyrights,
                        registered and unregistered trademark rights and design rights, patents and rights to
                        inventions) are not transferred to Eezy, and Eezy does not gain the rights to use them
                        on the basis of Service use.
                    </p>
                </div>
            </div>

            <div id="point7">
                <p>
                    <b>7. Limitations of liability</b>
                </p>
                <div className="p1">
                    <p>
                        The performer of work must settle any disputes concerning his/her work performance
                        directly with the client.
                    </p>
                    <p>
                        Eezy is not liable for any direct, indirect, consequential or inconsequential damage
                        or special damage, including damage caused by loss of business, contract, revenue or
                        information, or damage caused by an interruption of business, resulting from work
                        performed by the performer of work and invoiced through Eezy, or these Terms and
                        Conditions, regardless of whether the damage is based on a contract, violation of the
                        law, negligence or another reason, even if Eezy had been informed about the
                        possibility of such damage in advance. In any case, Eezy’s liability is always
                        restricted to liability pursuant to imperative law.
                    </p>
                    <p>
                        The performer of work releases Eezy and all its employees, managers and others working
                        on its behalf from liability with regard to all such action and claims that result
                        from the assignments of the performer of work, and use of the Service.
                    </p>
                </div>
            </div>

            <div id="point8">
                <p>
                    <b>8. Amendments to the Terms and Conditions, and notifications</b>
                </p>
                <div className="p1">
                    <p>
                        Eezy reserves the right to amend these Terms and Conditions at its discretion by
                        notifying the performer of work about it by email or via the Service. The amendments
                        to the Terms and Conditions become valid as soon as the notification about them has
                        been given. Use of the Service after the notification is regarded as a notification by
                        the performer of work of having accepted the amendments made to the Terms and
                        Conditions.
                    </p>
                    <p>
                        Eezy may include notifications in the Service. Eezy may also send notifications to the
                        performer of work about the Service to the email address or telephone number submitted
                        by the performer of work. The notifications will be considered as received seven (7)
                        days from Eezy having sent or posted them. Continuing use of the Service means that
                        the performer of work will be regarded as having received all notifications,
                        regardless of the method of sending.
                    </p>
                    <p>
                        Intellectual property rights belonging to the performer of work (including copyrights,
                        registered and unregistered trademark rights and design rights, patents and rights to
                        inventions) are not transferred to Eezy, and Eezy does not gain the rights to use them
                        on the basis of Service use.
                    </p>
                </div>
            </div>

            <div id="point9">
                <p>
                    <b>9. Applicable law and resolving of disputes</b>
                </p>
                <div className="p1">
                    <p>
                        Finnish law shall apply to the use of the Service and these Terms and Conditions,
                        excluding the choice of law rules included.
                    </p>
                    <p>
                        The parties will strive to resolve any disputes primarily through negotiations. If
                        agreement cannot be reached, the disputes will be resolved at Helsinki District Court.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsEn;
