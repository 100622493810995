import { UniqueTitle } from '../../components/textElements';
import { H3, PageWrapper, Wrapper } from '../../styles/yelStyles';
import { useSelector } from 'react-redux';
import type { IRootState } from 'reducers';
import { useTranslation } from 'react-i18next';

const InsuranceInfoDocument = () => {
    const { t } = useTranslation();
    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    if (!isLoggedIn) {
        return <PageWrapper />;
    }

    return (
        <PageWrapper>
            <UniqueTitle>{t('yel:document.title')}</UniqueTitle>
            <Wrapper>
                <H3>{t('yel:document.header1')}</H3>
                <Wrapper>
                    <p>
                        <b>{t('yel:document.name')}:</b> Eezy Kevytyrittäjät Oy
                    </p>
                    <p>
                        <b>{t('yel:document.businessId')}:</b> 2197002-2
                    </p>
                    <p>
                        <b>{t('yel:document.address')}:</b> Itämerenkatu 3, 00240 Helsinki
                    </p>
                    <p>
                        <b>{t('yel:document.tel')}:</b> 09 4247 5630
                    </p>
                    <p>
                        <b>{t('yel:document.email')}:</b> info.kevytyrittajat@eezy.fi
                    </p>
                </Wrapper>
            </Wrapper>

            <Wrapper>
                <H3>{t('yel:document.header2')}</H3>
                <Wrapper>
                    <p>{t('yel:document.section2')}</p>
                </Wrapper>
            </Wrapper>

            <Wrapper>
                <H3>{t('yel:document.header3')}</H3>
                <Wrapper>
                    <p>{t('yel:document.section3')}</p>
                    <a
                        href="https://sol.itella.net/b/vakuutusedustajarekisteri/"
                        rel="noopener noreferrer"
                        style={{ wordBreak: 'break-all' }}
                        target="_blank"
                    >
                        https://sol.itella.net/b/vakuutusedustajarekisteri/
                    </a>
                </Wrapper>
            </Wrapper>

            <Wrapper>
                <H3>{t('yel:document.header4')}</H3>
                <Wrapper>
                    <p>{t('yel:document.section4')}</p>
                </Wrapper>
            </Wrapper>

            <Wrapper>
                <H3>{t('yel:document.header5')}</H3>
                <Wrapper>
                    <p>{t('yel:document.section5')}</p>
                </Wrapper>
            </Wrapper>
        </PageWrapper>
    );
};

export default InsuranceInfoDocument;
