import { gql } from '@apollo/client';

const GET_YTJ_COMPANY = gql`
    query YtjCompany($businessId: String!) {
        ytjCompany(businessId: $businessId) {
            address {
                country
                street1
                street2
                town
                zipCode
            }
            businessId
            einvoiceOvt
            operator
            email
            name
            type
        }
    }
`;

export default GET_YTJ_COMPANY;
