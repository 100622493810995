import SalaryCalculator, { CalculatorViewEnum } from '@eezydev/eezy-salary-calculator';
import { SalaryCalcConfig } from '.';

const SalaryCalculatorPlainEn = () => {
    return (
        <SalaryCalculator language={'en'} config={SalaryCalcConfig} initialView={CalculatorViewEnum.Simple} />
    );
};

export default SalaryCalculatorPlainEn;
