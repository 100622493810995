import { invoiceStatuses } from 'constants/statisticConstants';
import { Calendar, CircleCheck, Clock, Coins } from 'lucide-react';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cn, formatCents, formatMoneyWithTwoDecimals } from 'utils';
import type { StatisticInvoice, statisticInvoicesToGraphData } from 'utils/statistics/statisticUtils';

type RevenueForecastInvoiceListProps = {
    selectedBar: string | null;
    withVat: boolean;
    mainGraphData: ReturnType<typeof statisticInvoicesToGraphData>;
    groupByMonth: boolean;
    invoices: StatisticInvoice[];
};

export const RevenueForecastInvoiceList = ({
    withVat,
    selectedBar,
    mainGraphData,
    groupByMonth,
    invoices,
}: RevenueForecastInvoiceListProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const selectedData = mainGraphData.items.find((i) => i.label === selectedBar);
    const formattedSelectedDataTotal = selectedData?.values.total
        ? `${formatMoneyWithTwoDecimals(selectedData?.values.total)} €`
        : '-';
    const selectedDataDate = selectedData?.date
        ? moment(selectedData?.date).format(groupByMonth ? 'MMMM YYYY' : 'DD.MM.YYYY')
        : '-';

    const selectedDataInvoices = useMemo(() => {
        const groupedByStatus = {
            paid: { total: 0, invoices: [] },
            completed: { total: 0, invoices: [] },
            overdued: { total: 0, invoices: [] },
        } as Record<string, { total: number; invoices: StatisticInvoice[] }>;

        if (!selectedData) {
            return groupedByStatus;
        }

        for (const invoice of invoices) {
            if (
                invoice.dueDate &&
                moment(invoice.dueDate).isSame(selectedData.date, groupByMonth ? 'month' : 'day')
            ) {
                groupedByStatus[invoice.status].invoices.push(invoice);
                groupedByStatus[invoice.status].total += withVat ? invoice.totalWithVat : invoice.total;
            }
        }

        return groupedByStatus;
    }, [selectedData, withVat]);

    const openInvoice = (invoice: StatisticInvoice) => {
        const url = invoice.isGroupInvoice
            ? `/group/${invoice.id}?fromStatistics`
            : `/invoices/${invoice.id}?fromStatistics`;
        if (invoice.status !== 'sending_pending') {
            navigate(url);
        }
    };

    return (
        <div className="xl:w-[350px]">
            <div className="flex p-6 border-b border-b-gray-100 justify-around">
                <div className="text-violet-800 xl:w-36 border-r pr-8 flex flex-col items-center gap-3 flex-1">
                    <Coins size={20} strokeWidth={1.5} />
                    <div className="tg-caption text-center">{t('statistic.total-due')}</div>
                    <div className="tg-subtitle">{formattedSelectedDataTotal}</div>
                </div>
                <div className="xl:w-40 pl-8 flex flex-col items-center gap-3 flex-1">
                    <Calendar size={20} strokeWidth={1.5} />
                    <div className="tg-caption text-center text-gray-500">{t('statistic.due-date')}</div>
                    <div className="tg-subtitle text-center">{selectedDataDate}</div>
                </div>
            </div>
            <div className="flex flex-col gap-7 mt-5 overflow-y-auto max-h-[335px]">
                {invoiceStatuses.toReversed().map((status) => {
                    if (!selectedDataInvoices?.[status].invoices.length) return null;
                    return (
                        <div key={status} className="">
                            <div className="flex justify-between px-6">
                                <div className="tg-caption text-gray-500">
                                    {t(`statistic.invoice-status.${status}`)}
                                </div>
                                <div className="tg-caption-bold text-gray-800">
                                    {formatCents(selectedDataInvoices?.[status].total, true)} €
                                </div>
                            </div>
                            <div className="flex flex-col mt-3">
                                {selectedDataInvoices?.[status].invoices.map((i) => (
                                    <div
                                        key={i.id}
                                        className="flex justify-between items-center py-3 px-6 border-b border-gray-100 cursor-pointer hover:bg-gray-50 active:bg-gray-100"
                                        onClick={() => openInvoice(i)}
                                    >
                                        <div className="tg-caption-bold text-gray-800 flex gap-4 items-center">
                                            <div
                                                className={cn(
                                                    'rounded-full size-6 flex items-center justify-center',
                                                    {
                                                        overdued: 'bg-red-50 text-red-800',
                                                        completed: 'bg-violet-50 text-violet-800',
                                                        paid: 'bg-violet-700 text-white',
                                                    }[status],
                                                )}
                                            >
                                                {status === 'paid' ? (
                                                    <CircleCheck className="size-3" />
                                                ) : (
                                                    <Clock className="size-3" />
                                                )}
                                            </div>
                                            <div>
                                                {formatCents(withVat ? i.totalWithVat : i.total, true)} €
                                            </div>
                                        </div>
                                        <div className="tg-caption text-gray-500 text-nowrap text-ellipsis overflow-hidden max-w-[150px]">
                                            {i.clientName}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
