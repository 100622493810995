import type { IInvoice } from '../../../../shared/src/types/invoice';
import ColorBox from '../../components/ColorBox';
import { P, RowTitle } from '../../components/textElements';
import { trans } from '../../utils';
import { showCreditRequestedInfoBox } from '../../utils/invoice/invoiceLogic';

interface IProps {
    invoice?: IInvoice;
}
export const CreditRequestedInfo = (props: IProps) => {
    if (!props.invoice || !showCreditRequestedInfoBox(props.invoice)) {
        return null;
    }

    return (
        <ColorBox disableClose style={{ marginBottom: 30, padding: '45px 30px' }}>
            <RowTitle>{trans('invoice.credit.info-box.1')}</RowTitle>
            <P color="white">{trans('invoice.credit.info-box.2')}</P>
        </ColorBox>
    );
};
