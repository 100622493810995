import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type ApolloError, useLazyQuery } from '@apollo/client';
import { eezyPayUserLoadingComplete, eezyPayUserLoadingError } from 'actions/eezyPay';
import GET_USER from 'containers/eezypay/queries/getUser';

let initialUserFetchDone = false;

const EezyPayUserLoader = () => {
    const [token, setToken] = useState(sessionStorage.getItem('jwt'));
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            if (!token) {
                setToken(sessionStorage.getItem('jwt'));
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [token]);

    const [fetchUser] = useLazyQuery(GET_USER, {
        context: { clientName: 'eezyPayHasura' },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (userData) => {
            const user = userData?.users?.[0];
            dispatch(
                eezyPayUserLoadingComplete(user?.state, user?.termsAccepted, user?.campwireTermsAccepted),
            );
        },
        onError: (error: ApolloError) => {
            dispatch(eezyPayUserLoadingError(error));
        },
    });

    useEffect(() => {
        if (token) {
            setTimeout(() => {
                fetchUser();
            }, 2000);
            if (!initialUserFetchDone) {
                fetchUser();
                initialUserFetchDone = true;
            }
        }
    }, [fetchUser, token]);

    return <></>;
};

export default EezyPayUserLoader;
