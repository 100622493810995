import styled from 'styled-components';
import type { IInvoice } from '../../../../shared/src/types/invoice';
import AdditionalCostIcon from '../../assets/images/additional-expenses.svg';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { Line2 } from '../../components/Lines';
import { P } from '../../components/textElements';
import { Tools, ToolsHeader } from '../../components/TogglingTools';
import { COLOR_BLACKWATER } from '../../styles/variables';
import { trans } from '../../utils';
import { isCostInvoiceEditable } from '../../utils/invoice/invoiceLogic';
import EezyExpress from './EezyExpress';

interface IInvoiceSalaryProps {
    editable: boolean;
    hasContract?: boolean;
    handleAddCostInvoice: () => void;
    invoice?: IInvoice;
}

const CostIcon = styled.div`
    background: url(${AdditionalCostIcon});
    float: right;
    margin-left: 30px;
    width: 30px;
    height: 30px;
`;

export const CostInvoicePanel = (props: IInvoiceSalaryProps) => {
    const editable = isCostInvoiceEditable(props.invoice);

    const handleAddCost = () => {
        props.handleAddCostInvoice();
    };

    return (
        <Tools
            header={
                <ToolsHeader lineHeight="16px" titleTransKey="costs.attach-receipt" disabled={!editable}>
                    <EezyButton color="black" disabled={!editable} hasIcon onClick={handleAddCost}>
                        <Icon color={COLOR_BLACKWATER} icon={['far', 'plus']} /> {trans('form.add')}
                    </EezyButton>
                </ToolsHeader>
            }
        >
            <div>
                <CostIcon style={{ opacity: !editable ? 0.2 : 1 }} />
                <P disabled={!editable}>{trans('costs.attach-receipt-description')}</P>
            </div>
        </Tools>
    );
};

const InvoiceSalary = (props: IInvoiceSalaryProps) => {
    return (
        <div>
            <CostInvoicePanel {...props} />

            <Line2 />

            <EezyExpress
                editable={props.editable && props.invoice?.recipient?.type === 'company'}
                email={props.invoice?.recipient?.email}
                expressStatus={
                    props.invoice?.recipient?.type === 'company'
                        ? props.invoice?.eezyExpressStatus
                        : undefined
                }
                invoiceId={props.invoice?.id}
            />
        </div>
    );
};

export default InvoiceSalary;
