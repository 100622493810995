import { gql } from '@apollo/client';

const DELETE_SALES_PAGE = gql`
    mutation deleteSalesPage($id: Int!) {
        update_sales_pages_by_pk(
            pk_columns: {id: $id},
            _set: {deleted: "now()"}
        ) {
            id
        }
    }
`;

export default DELETE_SALES_PAGE;
