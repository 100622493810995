import { gql } from '@apollo/client';

const GET_USER = gql`
    query getUser {
        users {
            id
            state
            termsAccepted
            campwireTermsAccepted
        }
    }
`;

export default GET_USER;
