import styled from 'styled-components';
import { SCREEN_M } from '../../styles/variables';
import { EmbedA4Wrapper } from './EmbedWrapper';
import { WhiteDocumentArea } from './WhiteDocumentArea';

export const PreviewWrapper = styled.div`
    & > ${WhiteDocumentArea}, & > ${EmbedA4Wrapper} {
        margin: 0;
        width: 100%;
    }

    @media (min-width: ${SCREEN_M}px) {
        margin: 0;
        padding: 0px;
    }
`;

export const PreviewButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
    position: relative;

    &:only-of-type {
        margin-bottom: 45px;
        margin-top: 45px;
    }
`;
