import React from 'react';
import type { ISalary } from '../../../../shared/src/types/salary';
import { EezyButton } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { ListItem } from '../../components/layout/List';
import { Bold, Span } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND, COLOR_WHITE_WALKER } from '../../styles/variables';
import { formatCents, formatDate } from '../../utils';
import { getSalaryStatus } from '../../utils/salary/salaryLogic';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IListItemProps {
    salary: ISalary;
    selected?: boolean;
}

const SalaryListItem = (props: IListItemProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { salary } = props;
    const status = getSalaryStatus(salary);

    const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        navigate('/salaries');
    };

    return (
        <ListItem color={COLOR_BLUM} iconColor={status?.color}>
            <div className="item-content">
                <div className="icon-wrapper" title={status?.title}>
                    {status && (
                        <Icon
                            aria-hidden={false}
                            aria-label={status?.title}
                            icon={status?.icon}
                            color={COLOR_WHITE_WALKER}
                            className="small"
                        />
                    )}
                </div>
                <p>
                    <span>
                        {t('salaries:salary')} {formatDate(salary.paymentDate || '')}
                    </span>
                </p>

                {props.selected ? (
                    <div>
                        <EezyButton color="purple" dark onClick={handleClose} width={90}>
                            {t('menu.close')}
                        </EezyButton>
                    </div>
                ) : !status.hideTotal ? (
                    <div>
                        <Bold color={COLOR_BLUM}>{formatCents(salary.paidAmount, true)}</Bold>
                        <Span color={COLOR_GREYHOUND}>{t('form.eurs', { eurs: '' })}</Span>
                    </div>
                ) : null}
            </div>
        </ListItem>
    );
};

export default React.memo(SalaryListItem);
