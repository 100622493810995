import styled from 'styled-components';
import { UniqueTitle } from '../../components/textElements';
import {
    BORDER_RADIUS,
    COLOR_GREYJOY,
    COLOR_GRIPE,
    COLOR_WHITE_WALKER,
    SCREEN_S,
} from '../../styles/variables';

export const Message = styled.div`
    background-color: ${COLOR_WHITE_WALKER};
    padding: 32px 12px 48px;
    border-radius: ${BORDER_RADIUS};
    min-height: 589px;
    width: 100%;

    @media (min-width: ${SCREEN_S}px) {
        padding: 48px;
    }

    ${UniqueTitle} {
        font-size: 28px;
    }

    .body {
        margin: 16px 0;
    }

    .message {
        border-left: 3px solid ${COLOR_GREYJOY};
        margin-bottom: 32px;
        padding: 0 0 16px 16px;

        &.from-eezy {
            border-color: ${COLOR_GRIPE};
        }
        .time {
            margin: 16px 0 8px;
        }
        .content {
            margin-top: 16px;
            overflow-wrap: break-word;
            white-space: pre-wrap;
        }
        &:first-of-type {
            border: none;
            padding: 0;
            margin-bottom: 58px;
        }
        .attachments {
            margin-top: 40px;

            & > p {
                margin-bottom: 8px;
            }

            svg {
                font-size: 15px;
                height: 15px;
                margin-right: 8px;
                width: 15px;
            }
            div {
            }
        }
    }

    & ~ & {
        margin-top: 32px;
    }
`;
