import { type ChangeEvent, type CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import { type IGeneralFormInputProps, TextArea } from '.';
import { COLOR_GREYJOY } from '../../styles/variables';
import { Flex } from '../Flex';
import { FormLabel } from './FormLabel';

interface IFormTextAreaProps extends IGeneralFormInputProps {
    autoFocus?: boolean;
    labelStyle?: CSSProperties;
    maxLength?: number;
    onChange: (val: string, name: string) => void;
    placeholder?: string;
    rowsMin?: number;
    showBorder?: boolean;
    type?: string;
    value?: string;
    variant?: string;
    noNewLinesAllowed?: boolean;
}

const Wrapper = styled(Flex).attrs(() => ({
    column: true,
}))`
    position: relative;
    & span {
        display: none;
    }
    &:focus-within span {
        display: block;
    }
`;

const RemainingCharactersIndicator = styled.span`
    color: ${COLOR_GREYJOY};
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const FormTextArea = (props: IFormTextAreaProps) => {
    const [value, setValue] = useState(props.value || '');
    const [valueChanged, setValueChanged] = useState(false);

    const handleBlur = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (valueChanged) {
            props.onChange(e.currentTarget.value, props.name);
            setValueChanged(false);
        }
    };
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const valueToSet = props.noNewLinesAllowed
            ? e.currentTarget.value.replace(/(\r\n|\n|\r)/gm, '')
            : e.currentTarget.value;
        setValue(valueToSet);
        setValueChanged(true);
    };

    useEffect(() => {
        if (props.value !== undefined) {
            setValue(props.value);
        }
    }, [props.value]);

    return (
        <Wrapper>
            <FormLabel
                error={props.error}
                disabled={props.disabled}
                helpLink={props.helpLink}
                helpTexts={props.helpTexts}
                htmlFor={props.name}
                style={props.labelStyle}
                value={props.value}
                warning={props.warning}
            >
                {props.label}
            </FormLabel>

            <TextArea
                aria-invalid={!!props.error}
                autoFocus={props.autoFocus}
                className={props.color ? '' : 'dark'}
                disabled={props.disabled || props.readonly}
                id={props.name}
                maxLength={props.maxLength}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={props.placeholder || ''}
                $required={props.required}
                minRows={props.rowsMin}
                $showBorder={props.showBorder}
                value={value}
                $variant={props.variant || 'form'}
            />
            {props.maxLength && value?.length > 0 && (
                <RemainingCharactersIndicator>{props.maxLength - value.length}</RemainingCharactersIndicator>
            )}
        </Wrapper>
    );
};
