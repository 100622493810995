import { Popover } from '@mui/material';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import { COLOR_GREY_FOG, COLOR_WHITE_WALKER } from '../../styles/variables';

interface IArrowPopoverProps {
    anchorEl: any;
    children: ReactNode;
    onClose: () => void;
    popoverOpen: boolean;
}

const useStyles = makeStyles()(() => ({
    paper: {
        overflow: 'visible',
    },
}));

const Wrapper = styled.div`
    background: ${COLOR_WHITE_WALKER};
    border: 1px solid ${COLOR_GREY_FOG};
    border-radius: 4px;
    box-shadow: 2px -2px 5px rgba(0, 0, 0, 0.15);
    padding: 20px;
`;

const Arrow = styled.div`
    position: absolute;
    top: -9px;
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background: ${COLOR_WHITE_WALKER};
    transform: rotate(45deg);
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.15);
`;

const ArrowPopover = (props: IArrowPopoverProps) => {
    const { classes } = useStyles();
    return (
        <Popover
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            classes={classes}
            onClose={props.onClose}
            open={props.popoverOpen}
            anchorEl={props.anchorEl}
            transformOrigin={{
                horizontal: 'center',
                vertical: 'top',
            }}
            style={{ marginTop: '20px', overflow: 'visible' }}
        >
            <Arrow />
            <Wrapper>{props.children}</Wrapper>
        </Popover>
    );
};

export default ArrowPopover;
