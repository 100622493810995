import { StrictMode, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { type ApolloClient, ApolloProvider, type NormalizedCacheObject } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Provider, useSelector } from 'react-redux';
import { initFAIcons } from 'FontAwesomeIcons';
import { theme } from './styles/global';
import App from './containers/App';
import i18n from './utils/i18n';
import API, { fileAPI, setupAxios } from './utils/API';
import { dispatch, store } from './utils/store';
import { createApolloClient } from './utils/ApolloClient';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './globals.css';
import './polyfills';
import type { IRootState } from 'reducers';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY || 'MUI_X_LICENSE_KEY:NOT_SET');

setupAxios(API, dispatch);
setupAxios(fileAPI, dispatch);

initFAIcons();

// This is needed so that the graphql subscriptions will be
// reset when user logs in or out.
// Seems that ApolloProvider needs to be at root-level of the app
// so the client gets initialized before login. This will counter that.
const ApolloClientResetProvider = () => {
    const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>();
    const { i18n } = useTranslation();

    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    useEffect(() => {
        setApolloClient(createApolloClient(dispatch));
    }, [isLoggedIn]);

    if (!apolloClient) {
        return null;
    }

    return (
        <ApolloProvider client={apolloClient}>
            <App language={i18n.language} />
        </ApolloProvider>
    );
};

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <StrictMode>
        <I18nextProvider i18n={i18n}>
            <StyledEngineProvider injectFirst>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                        <Provider store={store}>
                            <ApolloClientResetProvider />
                        </Provider>
                    </ThemeProvider>
                </QueryClientProvider>
            </StyledEngineProvider>
        </I18nextProvider>
    </StrictMode>,
);
