import styled from 'styled-components';
import Bell from '../assets/images/bell.svg';
import { COLOR_BLACKWATER, COLOR_LILA_LIGHT, COLOR_WHITE_WALKER, SCREEN_M } from '../styles/variables';

export const RoundBox = styled.div`
    background: ${COLOR_WHITE_WALKER};
    border-radius: 10px;
    box-sizing: border-box;
    display: block;
    margin: 32px;
    padding: 60px;
    position: relative;

    && p {
        color: ${COLOR_BLACKWATER};
        margin: 15px 0;
    }

    &.alert {
        padding-right: 40px;
    }
    &.alert:before {
        background: url(${Bell});
        content: '';
        height: 28px;
        position: absolute;
        right: 12px;
        top: 10px;
        width: 28px;
    }

    h3 {
        color: ${COLOR_BLACKWATER};
        font-size: 16px;
        font-weight: bold;
        margin: 15px 0;

        @media (min-width: ${SCREEN_M}px) {
            font-size: 18px;
        }
    }
`;

export const HighlightBox = styled(RoundBox)`
    background: ${COLOR_LILA_LIGHT};
    margin: 20px 0 0;
    padding: 10px 20px;
`;
